<template>
  <!--Pagenation-->
  <div class="relative h-10" v-show="visiblePages.length > 1">
    <div
      class="fixed bottom-10 right-10 flex justify-center border border-gray-400"
    >
      <nav class="inline-flex">
        <a
          href="#"
          v-if="showStartEllipsis"
          class="bg-white px-3 py-2 text-gray-500 hover:bg-gray-200"
          @click="goToPage(visiblePages[0] - 1)"
        >
          ...
        </a>
        <a
          href="#"
          v-for="page in visiblePages"
          :key="page"
          @click="goToPage(page)"
          :class="{
            'bg-sky-200 px-3 py-2 text-gray-700 hover:bg-gray-200':
              page === currentPage,
            'bg-white px-3 py-2 text-gray-500 hover:bg-gray-200':
              page !== currentPage,
          }"
        >
          {{ page }}
        </a>
        <a
          href="#"
          v-if="showEndEllipsis"
          class="bg-white px-3 py-2 text-gray-500 hover:bg-gray-200"
          @click="goToPage(visiblePages[visiblePages.length - 1] + 1)"
        >
          ...
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "../stores/mainStore";
import { watch } from "vue";
export default {
  name: "Pagination-component",
  props: {
    materials: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  setup() {
    const store = useMainStore();
    return { store };
  },
  mounted() {
    watch(
        ()=>this.store.currentPage,
        ()=>{
            this.currentPage=1
        }
    )
    watch(
      () => this.displayedItems,
      () => {
        this.$emit("displayedItems", this.displayedItems);
      }
    );
  },
  computed: {
    totalPages() {
      return Math.ceil(this.materials.length / this.itemsPerPage);
    },
    displayedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.materials.slice(start, end);
    },
    visiblePages() {
      const maxVisiblePages = 5;
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;

      if (totalPages <= maxVisiblePages) {
        // If total pages is less than or equal to maxVisiblePages, show all pages
        return Array.from({ length: totalPages }, (_, i) => i + 1);
      } else {
        let startPage = Math.max(
          currentPage - Math.floor(maxVisiblePages / 2),
          1
        );
        let endPage = startPage + maxVisiblePages - 1;

        if (endPage > totalPages) {
          // Adjust start and end pages if endPage exceeds totalPages
          startPage = totalPages - maxVisiblePages + 1;
          endPage = totalPages;
        }

        return Array.from(
          { length: endPage - startPage + 1 },
          (_, i) => startPage + i
        );
      }
    },
    showStartEllipsis() {
      return this.visiblePages[0] > 1;
    },
    showEndEllipsis() {
      return this.visiblePages[this.visiblePages.length - 1] < this.totalPages;
    },
  },
  methods: {
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
};
</script>

<style>
</style>