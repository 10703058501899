<template>
  <div class="createpack-form">
    <div class="main-form">
      <div class="text-gray-900 font-medium text-lg text-left pl-8">
        {{ packFormMode == "create" ? "創建資源包" : "編輯資源包" }}
      </div>
      <div class="scroll-wrap">
        <div class="left-part">
          <div class="upload-item">
            <label>
              <p class="title">{{ "標題" }}</p>
              <input type="text" v-model="editTitle" placeholder="必填" />
            </label>
          </div>
          <div class="upload-item">
            <label>
              <p class="title">{{ "簡介" }}</p>
              <textarea v-model="editIntro" placeholder="必填" />
            </label>
          </div>
          <div class="upload-item">
            <p class="title">年級</p>
            <SelectMenu
              :category="cateGrade"
              :defaultSelect="'未指定'"
              ref="gradeSelect"
            />
          </div>

          <div class="upload-item">
            <p class="title">科目</p>
            <SelectMenu
              :category="cateSubject"
              :defaultSelect="'未指定'"
              ref="subjectSelect"
            />
          </div>

          <div class="upload-item">
            <p class="title">教材語言</p>
            <SelectMenu
              :category="cateLang"
              :defaultSelect="'未指定'"
              ref="langSelect"
            />
          </div>

          <div class="upload-item">
            <label class="title">
              自訂標籤(請用逗號分隔,ex:"二元一次方程式,三角函數")<br />
              <input type="text" v-model="category" />
            </label>
          </div>
          <div class="upload-item">
            <form class="title">
              {{ packFormMode == "create" ? "選擇封面來源*" : "更新封面來源"
              }}<br />
              <label class="title"
                ><input
                  type="radio"
                  name="location"
                  v-model="pickedType"
                  :value="0"
                />
                自行上傳 &nbsp;</label
              >
              <label class="title">
                <input
                  type="radio"
                  name="location"
                  v-model="pickedType"
                  :value="1"
                />
                選擇素材插圖 &nbsp;</label
              >
              <label class="title"
                ><input
                  type="radio"
                  name="location"
                  v-model="pickedType"
                  :value="2"
                />
                隨機組合縮圖 &nbsp;
              </label>
              <label class="title" v-show="packFormMode == 'edit'">
                <input
                  type="radio"
                  name="location"
                  v-model="pickedType"
                  :value="3"
                />
                不更新 &nbsp;
              </label>
            </form>
          </div>
          <div class="upload-item" v-show="pickedType == 0">
            <label class="title"
              >自行上傳
              <input
                id="inputfile"
                type="file"
                accept="image/gif, image/jpeg, image/png"
                @change="imagefileChangeHandler"
                required
              />
            </label>
            <img
              class="img-preview"
              v-show="editImgPreview"
              :src="editImgPreview"
              alt=""
            />
          </div>
          <div class="clipart-wrap" v-show="pickedType == 1">
            <div
              class="imgitem-wrap"
              v-for="(image, index) in images"
              :key="index"
              :class="{ 'imgitem-light': this.imageFile == image.pathLong }"
              @click="setImgCoverByClipart(image)"
            >
              <img :src="image.pathLong" alt="" />
            </div>
          </div>
          <!-- {{ currentSelectMaterials }} -->
        </div>
        <div class="right-part">
          <div class="upload-item">
            <p class="title mb-2 ml-5">
              {{
                packPageMode == "profile"
                  ? "選取加入我已上傳資源"
                  : "選取加入群組內、我上傳的資源"
              }}
            </p>
            <div class="material-wrap">
              <label
                :for="item.id"
                class="material-item"
                v-for="item in materials"
                :key="item.id"
                :class="{ 'light-item': isMaterialSelected(item) }"
              >
                <!-- {{ item }} -->
                <div class="material-item-layout">
                  <input
                    type="checkbox"
                    :value="item"
                    v-model="currentSelectMaterials"
                    :id="item.id"
                  />
                  <div
                    class="material-img"
                    :style="{ background: getImgUrl(item) }"
                  ></div>
                  <div class="material-info">
                    <p class="material-type">{{ getTypeText(item) }}</p>
                    <p class="material-title">{{ item.title }}</p>
                    <p class="material-time">{{ getTime(item.timeStamp) }}</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="ml-auto mt-5 normal p-4 pt-0"
        v-show="packFormMode == 'create'"
      >
        <button
          class="left-btn"
          v-if="
            (editTitle &&
              editIntro &&
              pickedType == 0 &&
              editImgPreview &&
              currentSelectMaterials != '') ||
            (editTitle &&
              editIntro &&
              pickedType == 1 &&
              imageFile != '' &&
              currentSelectMaterials != '') ||
            (editTitle &&
              editIntro &&
              pickedType == 2 &&
              currentSelectMaterials != '')
          "
          @click="confirm()"
        >
          {{ $t("確定") }}
        </button>
        <button class="right-btn" @click="close()">
          {{ $t("取消") }}
        </button>
      </div>
      <div class="ml-auto mt-5 normal p-4 pt-0" v-show="packFormMode == 'edit'">
        <button
          class="left-btn"
          v-if="
            (editTitle &&
              editIntro &&
              pickedType == 0 &&
              editImgPreview &&
              currentSelectMaterials != '') ||
            (editTitle &&
              editIntro &&
              pickedType == 1 &&
              imageFile != '' &&
              currentSelectMaterials != '') ||
            (editTitle &&
              editIntro &&
              pickedType == 2 &&
              currentSelectMaterials != '') ||
            (editTitle &&
              editIntro &&
              pickedType == 3 &&
              currentSelectMaterials != '')
          "
          @click="confirm()"
        >
          {{ $t("確定") }}
        </button>
        <button class="right-btn" @click="close()">
          {{ $t("取消") }}
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import profile from "../api/profile";
import { inject } from "vue";
import { useMainStore } from "../stores/mainStore";
import SelectMenu from "./SelectMenu.vue";
export default {
  name: "createpack-form",
  props: ["materials", "blobsas", "packFormMode", "packPageMode"],
  components: { SelectMenu },
  data() {
    return {
      editTitle: "",
      editIntro: "",
      editImgPreview: "",
      imageFile: "",
      pickedType: "",
      currentSelectMaterials: [],
      images: [],
      currentEditItem: "",
      cateGrade: [
        "未指定",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      cateSubject: ["未指定", "國文", "英文", "數學", "社會", "自然", "綜合"],
      cateLang: ["未指定", "tw", "cn", "en", "other"],
      category: "",
    };
  },
  setup() {
    const store = useMainStore();
    const mountMsg = inject("mountMsg"); //從App注入
    return { store, mountMsg };
  },
  mounted() {
    if (this.packFormMode == "edit") {
      this.currentEditItem = this.store.currentEditItem;
      this.editTitle = this.currentEditItem.title;
      this.editIntro = this.currentEditItem.intro;
      this.$refs.gradeSelect.currentSelect =
        this.currentEditItem.grade == 0 ? "未指定" : this.currentEditItem.grade;
      this.$refs.subjectSelect.currentSelect =
        this.currentEditItem.subject == 0||!this.currentEditItem?.subject
          ? "未指定"
          : this.currentEditItem.subject;
      this.$refs.langSelect.currentSelect =
        this.currentEditItem.language == 0||!this.currentEditItem?.language
          ? "未指定"
          : this.currentEditItem.language;
     
        this.category = this.currentEditItem?.category?this.currentEditItem?.category.join(","):"";
     
      this.currentSelectMaterials = [];
      this.currentEditItem.materialsList.forEach((item) => {
        let id = this.materials.findIndex((material) => material.id == item.id);
        this.currentSelectMaterials.push(this.materials[id]);
      });
      this.pickedType = 3;
      console.log(this.currentSelectMaterials);
    }
    this.importAll(
      require.context("../assets/Openclipart", true, /\.png$/),
      this.images
    );
  },
  methods: {
    importAll(r, target) {
      r.keys().forEach((key) =>
        target.push({ pathLong: r(key), pathShort: key })
      );
    },
    isMaterialSelected(material) {
      return (
        this.currentSelectMaterials.findIndex(
          (item) => item?.id == material?.id
        ) > -1
      );
    },
    checkBackgroundImageLoaded(imageUrl) {
      if (imageUrl) {
        const tempImg = new Image();
        tempImg.src = imageUrl;
        tempImg.onload = () => {
          this.isLoadingNow = false;
          // console.log('背景图像已加载完成。');
        };
      }
    },
    getTypeText(item) {
      let text = "";
      if (item.type == "htex") text = this.$t("布題頁面");
      else if (item.type == "audio") text = this.$t("音訊");
      else if (item.type == "video") text = this.$t("影片");
      else if (item.type == "back") text = this.$t("背景圖");
      else if (item.type == "exam") text = this.$t("測驗卷");
      else if (item.type == "tutorial") text = this.$t("教材");
      else if (item.type == "plan") text = this.$t("教案");
      return text;
    },
    getImgUrl(materials) {
      try {
        if (materials.imgBlobUrl && this.blobsas) {
          if (materials.imgBlobUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials?.imgBlobUrl + "?" + this.blobsas}`
            );
            return 'url("' + materials.imgBlobUrl + "?" + this.blobsas + '")';
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.uploader.id +
              "/" +
              materials.id +
              "/" +
              materials.imgBlobUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`);
            return 'url("' + finalUrl + '")';
          }
        } else if (!materials?.imgBlobUrl) {
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getTime(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString();
    },
    imagefileChangeHandler(e) {
      this.imageFile = Array.from(e.target.files);
      if (this.imageFile) {
        this.editImgPreview = URL.createObjectURL(this.imageFile[0]);
      }
    },
    setImgCoverByClipart(url) {
      console.log(url);
      this.imageFile = url.pathLong;
    },
    confirm() {
      console.log(this.category)
      let data = {
        title: this.editTitle,
        intro: this.editIntro,
        imgUrl: this.imageFile,
        pickImgType: this.pickedType,
        materialsList: this.currentSelectMaterials,
        grade:
          this.$refs.gradeSelect.currentSelect == "未指定"
            ? 0
            : Number(this.$refs.gradeSelect.currentSelect),
        subject:
          this.$refs.subjectSelect.currentSelect == "未指定"
            ? ""
            : this.$refs.subjectSelect.currentSelect,
        language:
          this.$refs.langSelect.currentSelect == "未指定"
            ? ""
            : this.$refs.langSelect.currentSelect,
        category: this.category.split(","),
      };
      this.$emit("confirm", data);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
  
  <style lang="less">
.createpack-form {
  z-index: 999;
  @apply flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800/50;

  .main-form {
    width: 80%;
    height: 90vh;
    .scroll-wrap {
      @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8;
      width: 100%;
      height: 80vh;
      overflow: hidden;
      .left-part {
        @apply px-8 py-4;
        overflow: scroll;
        .clipart-wrap {
          @apply bg-slate-200 p-2 grid grid-cols-3 md:grid-cols-5 gap-1 text-left overflow-auto rounded-md;

          position: relative;
          height: 45vh;
          padding-bottom: 100px;
          .imgitem-wrap {
            @apply text-center flex items-center justify-center p-2 m-1 cursor-pointer rounded bg-white transition-shadow hover:shadow-md;
            height: 10vh;
            img {
              @apply cursor-pointer  max-w-full;
              max-height: 100%;
            }
          }
          .imgitem-light {
            @apply bg-yellow-100 border-2 border-sky-400;
          }
        }
      }
      .right-part {
        .material-wrap {
          max-height: 70vh;
          overflow: auto;
          padding-bottom: 100px;

          .light-item {
            @apply bg-slate-200 border-2 border-sky-500 !important;
          }
          .material-item {
            @apply border border-gray-300;
            display: block;
            cursor: pointer;
            @apply m-4 p-2;

            border-radius: 5px;
            .material-item-layout {
              display: flex;
            }
            .material-img {
              height: 100px;
              width: 150px;
              background-size: cover !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
              border-bottom: 1px solid rgba(128, 128, 128, 0.3);
              @apply mx-2;
            }
            .material-info {
              .material-type {
                @apply font-semibold bg-purple-600 inline-block text-white text-sm px-2 py-1 text-center;
              }
              .material-title {
                @apply font-bold text-xl mt-1;
              }
              .material-time {
                @apply text-sm text-gray-400;
              }
            }
          }
        }
      }
    }

    @apply bg-white  rounded-lg relative  flex flex-col pt-4;
    .img-preview {
      margin-top: 10px;
      max-height: 200px;
      max-width: 200px;
    }
    .upload-item {
      @apply my-3;
      input[type="text"],
      textarea {
        @apply ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6 w-full py-2 px-5 rounded-md mt-2;
      }
      textarea {
        max-height: 180px;
        overflow: auto;
      }
    }
    .title {
      @apply font-bold;
    }
  }

  .normal {
    .left-btn {
      @apply text-white font-bold border py-1 px-4 rounded mr-2 bg-sky-500 border-sky-500;

      &:hover {
        @apply bg-sky-600;
      }
    }

    .right-btn {
      @apply bg-transparent text-gray-700 font-semibold py-1 px-4 border border-gray-500 rounded;

      &:hover {
        @apply bg-sky-100;
      }
    }
  }
}
</style>