<template>
  <div class="cate-page">
    <SelectedGroupForm
      v-if="showSelectedPackForm"
      :groupsNamelist="packslist"
      :listType="'pack'"
      @close="showSelectedPackForm = false"
      @confirm="updatePack"
    />
    <div class="text-3xl font-bold">{{ currentTitle }}</div>
    <div class="flex-wrap">
      <div class="search-wrap">
        <SearchBar />
      </div>
      <div class="select-wrap">
        <div class="select-block">
          <span class="select-title">{{ $t("年級") }}</span>
          <SelectMultiMenu
            class="select-multiitem select-item"
            :category="cateMulti"
            ref="selectMulti"
          />
        </div>

        <!-- <span class="select-title">{{ $t("年級") }}</span>
        <SelectMenu
          class="select-item"
          :category="cateOne"
          :defaultSelect="$t('全部')"
          
          ref="selectOne"
        /> -->
        <div class="select-block">
          <span class="select-title">{{ $t("科目") }}</span>
          <SelectMenu
            class="select-item"
            :category="cateTwo"
            :defaultSelect="$t('全部')"
            :option="cardOption"
            ref="selectTwo"
          />
        </div>
      </div>
      <div class="sorter-wrap">
        <Sorter />
      </div>
    </div>

    <span class="text-md ml-5 font-bold" v-show="showTitle">{{
      showTitle
    }}</span>
    <div class="card-wrap">
      <Card
        v-for="item in displayedItems"
        :key="item.id"
        :materials="item"
        :blobsas="blobsas"
        :option="cardOption"
        @viewContent="viewContent(item)"
        @confirmAddToPack="showSelectedPackForm = true"
      />
    </div>
    <Pagination
      :materials="materialsByType"
      @displayedItems="setDisplayedItems"
    />
    <div class="text-md ml-5 mt-5 font-bold" v-show="!materialsByType.length">
      {{ $t("暫無資料") }}
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { useMainStore } from "../stores/mainStore";
import Card from "@/components/Card.vue";
import Sorter from "@/components/Sorter.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import SelectMultiMenu from "@/components/SelectMultiMenu.vue";
import SelectedGroupForm from "@/components/SelectedGroupForm.vue";
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/Pagination.vue";
import { watch, ref, getCurrentInstance, provide, inject } from "vue";
import { jsFn } from "@/utils/jsFn";
import jwt_decode from "jwt-decode";
export default {
  name: "cate-page",
  setup() {
    const store = useMainStore();
    const showTitle = ref("");
    const that = getCurrentInstance().ctx;
    const $t = inject("$t");
    const mountMsg = inject("mountMsg"); //從App注入
    const searchContent = (text) => {
      if (text == "") {
        that.getMaterialsByType(store.currentPage);
        showTitle.value = "";
      } else {
        that.getMaterialsBySearch(text, store.currentPage);
        showTitle.value = `"${text}"` + $t("的搜尋結果");
      }
    };
    provide("searchContentAtCate", searchContent);

    return { showTitle, store,mountMsg };
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
      displayedItems: [],
      materialsByType: [],
      orinMaterials: [],
      blobsas: "",
      cateMulti: [
        { label: "通用(無年級)", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
      ],
      cateOne: [
        this.$t("全部"),
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      cateTwo: [
        this.$t("全部"),
        "國文",
        "英文",
        "數學",
        "社會",
        "自然",
        "綜合",
      ],
      titles: [
        { name: this.$t("布題頁面"), type: "htex" },
        { name: this.$t("音訊"), type: "audio" },
        { name: this.$t("影片"), type: "video" },
        { name: this.$t("背景圖"), type: "back" },
        { name: this.$t("測驗卷"), type: "exam" },
        { name: this.$t("教材"), type: "tutorial" },
        { name: this.$t("教案"), type: "plan" },
      ],
      currentTitle: "",
      selectAll: this.$t("全部"),
      cardOption: {
        pageName: "cate",
        allowEdit: false,
      },
      packsOriginal: [],
      packslist: [],
      showSelectedPackForm: false,
    };
  },
  components: {
    Card,
    Sorter,
    SelectMenu,
    SelectMultiMenu,
    SearchBar,
    Pagination,
    SelectedGroupForm,
  },
  created() {
    const that = this;
    window.addEventListener("beforeunload", async (e) => {
      e.preventDefault();
      const allHits = await JSON.parse(JSON.stringify(that.store.getAllHits));
      try {
        await api.main.saveAllMaterialsHits(allHits);
      } catch (err) {
        console.log(err);
      }
      return "";
    });
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", () => {});
  },
  async mounted() {
    //vue3 watch寫法
    watch(
      () => this.store.currentPage,
      () => {
        this.$refs.selectMulti.currentSelect =
          this.$refs.selectMulti.category.map((item) => item.value);
        this.$refs.selectTwo.currentSelect = this.selectAll;
      }
    );
    watch(
      () => this.$refs.selectMulti.currentSelect,
      () => {
        this.updateFilterMaterials();
      }
    );
    watch(
      () => this.$refs.selectTwo.currentSelect,
      () => {
        this.updateFilterMaterials();
      }
    );
    watch(
      () => this.store.currentSortBy,
      (newValue) => {
        this.materialsByType = jsFn.sortMaterials(
          newValue,
          this.materialsByType
        );
      }
    );
    watch(
      () => this.store.currentPage,
      (newValue) => {
        if (newValue) {
          this.mapTitle(newValue);
          this.getMaterialsByType(newValue);
          if (this.$refs.pagination) this.$refs.pagination.gotoPage(1);
        }
      }
    );
    watch(
      () => this.store.isLogin,
      (value) => {
        if (!value) {
          this.cardOption.allowEdit = false;
        }
      }
    );
    jsFn
      .checkSessionAccessTokenReady()
      .then(async () => {
        this.blobsas =
          this.store.blobsas !== ""
            ? this.store.blobsas
            : await api.main.getSas();
        //第一次load或重刷
        const type = this.store.currentPage
          ? this.store.currentPage
          : sessionStorage.getItem("cateType");
        this.store.setCurrentPage(type);
        this.mapTitle(type);
        await this.getMaterialsByType(type);
        await this.getReasourcePacksIcreated();
        this.checkAllowEdit();
      })
      .catch((err) => {
        console.log(err); //未取到access_token
      });
  },
  methods: {
    checkAllowEdit() {
      const idToken = sessionStorage.getItem("id_token");
      if (idToken) {
        const decoded = jwt_decode(idToken);
        if (decoded) {
          this.cardOption.allowEdit = true;
        }
      }
    },
    updateFilterMaterials() {
      let selectMulti = this.$refs.selectMulti.currentSelect;
      let selectTwo = this.$refs.selectTwo.currentSelect;
      this.materialsByType = this.orinMaterials.filter((item) =>
        selectMulti.includes(item.grade.toString())
      );
      this.materialsByType = this.materialsByType.filter((item) =>
        selectTwo != this.selectAll ? item.subject == selectTwo : true
      );
    },
    async getReasourcePacksIcreated() {
      const idToken = sessionStorage.getItem("id_token");
      if (idToken) {
        const decoded = jwt_decode(idToken);
        decoded;
        const res = await api.main.getResourcePacksIcreated({
          tmid: decoded.sub,
        });
        this.packsOriginal = res.data;
        this.packslist = res.data.filter(item=>item.groupId=='').map((item) => ({
          name: item.title,
          id: item.id,
        }));
      }
    },
    async updatePack(formData) {
      console.log(formData);
      const index = this.packsOriginal.findIndex(
        (item) => item.id === formData.groupName.id
      );
      console.log(index);
      let updateMaterials = this.packsOriginal[index].materialsList;
      const mId = updateMaterials.findIndex(
        (item) => item.id === this.store.currentEditItem.id
      );
      if (mId == -1) {
        updateMaterials.push(this.store.currentEditItem);
      }

      const data = {
        id: this.packsOriginal[index].id,
        materialsList: updateMaterials,
      };
      console.log(data);
      try {
        await api.main.updateResourcePackInfoById(data);
        this.mountMsg("更新成功!");
        this.showSelectedPackForm = false;
      } catch (err) {
        console.log(err);
      }
    },
    setDisplayedItems(data) {
      this.displayedItems = data;
    },
    getMaterialsByType(type) {
      api.main.getMaterialsByType({ type: type }).then((res) => {
        this.materialsByType = res.data.filter((item) => !item.hidden);
        this.orinMaterials = res.data.filter((item) => !item.hidden);
      });
    },
    getMaterialsBySearch(text, type) {
      api.main
        .getMaterialsBySearch({ searchText: text, type: type })
        .then((res) => {
          this.materialsByType = res.data.filter((item) => !item.hidden);
          this.orinMaterials = res.data.filter((item) => !item.hidden);
        });
    },
    viewContent(item) {
      this.store.viewContent(item);
    },
    mapTitle(type) {
      const titleIndex = this.titles.findIndex((item) => item.type === type);
      this.currentTitle = this.titles[titleIndex].name;
    },
  },
};
</script>

<style lang="less">
.cate-page {
  @apply p-10;

  @media (min-width: theme("screens.lg")) {
    .flex-wrap {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(128, 128, 128, 0.2);
      margin-bottom: 20px;
    }
    .search-wrap {
      flex: 10;
    }
    .select-block {
      @apply inline-block;
    }

    .select-wrap {
      flex: 10;
      text-align: right;
      margin-top: 15px;
      .select-title {
        @apply mr-2 text-sm  font-semibold;
        display: inline-block;
      }
      .select-multiitem {
        @apply mr-5 !important;
        width: 200px !important;
      }
      .select-item {
        @apply mr-2;
        display: inline-block;
        width: 160px;
      }
    }
    .sorter-wrap {
      flex: 4;
      margin-top: 20px;
    }
  }
  @media (max-width: theme("screens.lg")) {
    .select-block {
      @apply block;
    }

    .search-wrap {
      display: block;
    }
    .select-wrap {
      display: block;
      margin: 20px;
      .select-title {
        @apply mr-2 text-sm  font-semibold;
        display: inline-block;
      }
      .select-item {
        @apply mr-2;
        width: 80%;
        display: inline-block;
      }
    }
    .sorter-wrap {
      display: block;
    }
  }
}
</style>
