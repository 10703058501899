<template>
  <div class="pack-content">
    <!---資源包-->
    <!-- 
    {{ currentPackContent }} -->
    <div class="userinfo-card">
      <div class="left-part" v-show="currentPackContent.imgUrl != ''">
        <div
          class="profile-img"
          :style="{
            background: getPackImgUrl(currentPackContent),
            'background-color': checkImgStyle(currentPackContent)
              ? '#fef9c3'
              : 'unset',
          }"
        ></div>
      </div>
      <div class="right-part">
        <div class="ml-5 text-3xl font-bold">
          {{ currentPackContent.title }}&nbsp;資源包
        </div>
        <p class="ml-5">{{ $t("簡介") }}: <span v-html="urlify(currentPackContent.intro)"></span></p>
        <p class="ml-5">{{ "年級" }}: {{currentPackContent.grade}}</p>
        <p class="ml-5">{{ "科目" }}: {{currentPackContent.subject}}</p>
        <p class="ml-5">{{ "語言" }}: {{currentPackContent.language}}</p>
        <div class="tag-group">
            <span
              v-for="(item, index) in currentPackContent.category"
              :key="index + currentPackContent.category + currentPackContent.id"
              class="tag"
              >#{{ item }}
            </span>
          </div>
      </div>
    </div>
    <div class="card-wrap">
      <Card
        v-for="item in materialsList"
        v-show="!item.hidden"
        :key="item.id"
        :materials="item"
        :blobsas="blobsas"
        :option="checkCardOption()"
        @viewContent="viewContent(item)"
        @moveOutFromPack="moveOutFromPack"
        
      />
      <div class="text-md ml-5 mt-5 font-bold" v-show="materialsList == ''">
        {{ $t("暫無資料") }}
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "../stores/mainStore";
import { inject } from "vue";
import { jsFn } from "@/utils/jsFn";
import api from "@/api";
import profile from "../api/profile";
import Card from "@/components/Card.vue";
import jwt_decode from "jwt-decode";
export default {
  name: "Pack_content",
  setup() {
    const store = useMainStore();
    const mountMsg = inject("mountMsg"); //從App注入
    return { store, mountMsg };
  },
  components: {
    Card,
  },
  data() {
    return {
      currentPackContent: "",
      blobsas: "",
      isLoadingNow: true,
      currentLoginAccount: "",
      isLogin: false,
      materialsList: [],
    };
  },
  async mounted() {
    await jsFn.checkSessionAccessTokenReady();
    this.blobsas =
      this.store.blobsas !== "" ? this.store.blobsas : await api.main.getSas();
    if (this.store.currentPackContent) {
      this.currentPackContent = this.store.currentPackContent;
      this.Init();
      this.saveLocal(this.currentPackContent);
    } else if (sessionStorage.getItem("currentViewPackContent")) {
      const deSavedContent = jsFn.decrypt(
        sessionStorage.getItem("currentViewPackContent"),
        "123"
      );
      const getSavedContent = JSON.parse(deSavedContent);
      if (this.$route.params.id === getSavedContent.id) {
        this.currentPackContent = getSavedContent;
        this.Init();
      }
    } else {
      //沒有本地也沒有點擊，通常是直接給網址的時候，需要直接用api要資料
      await this.refreshPackInfo();
    }

    this.materialsList = this.currentPackContent.materialsList;
    console.log(this.materialsList);
  },
  methods: {
    urlify(text) {
      return jsFn.urlify(text);
    },
    async refreshPackInfo() {
      try {
        this.currentPackContent = await this.getPackInfo();
        this.Init();
        this.saveLocal(this.currentPackContent);
      } catch (error) {
        console.error("刷新資源包信息時出錯：", error);
      }
    },
    async getPackInfo() {
      try {
        const response = await api.main.getResourcePackInfoById({
          id: this.$route.params.id,
        });
        return response.data;
      } catch (error) {
        console.error("獲取資源包信息時出錯：", error);
        throw error;
      }
    },
    Init() {
      this.checkIsLogin();
    },
    checkIsLogin() {
      const idToken = sessionStorage.getItem("id_token");
      if (idToken) {
        const decoded = jwt_decode(idToken);
        this.currentLoginAccount = {
          id: decoded.sub,
          name: decoded.name,
          picture: decoded.picture,
        };
        this.isLogin = true;
      }
    },
    saveLocal(item) {
      const savedContent = JSON.parse(JSON.stringify(item));
      delete savedContent.pk;
      delete savedContent.ttl;
      // // delete savedContent.hidden;
      // delete savedContent.reported;
      console.log(savedContent);

      const encSavedContent = jsFn.encrypt(JSON.stringify(savedContent), "123");
      sessionStorage.setItem("currentViewPackContent", encSavedContent);
    },
    checkCardOption() {
      return this.currentPackContent.packCreator.id ===
        this.currentLoginAccount.id
        ? {
            allowEdit: true,
            allowMoveOut: false,
            allowRemoveFromPack: true,
          }
        : this.cardOption;
    },
    
    checkImgStyle(materials) {
      if (materials) {
        return (
          materials.imgUrl.slice(0, 4) == "/img" ||
          materials.imgUrl.slice(0, 5) == "data:"
        );
      }
    },
    checkBackgroundImageLoaded(imageUrl) {
      const tempImg = new Image();
      tempImg.src = imageUrl;
      tempImg.onload = () => {
        this.isLoadingNow = false;
        // console.log('背景图像已加载完成。');
      };
    },
    getPackImgUrl(pack) {
      try {
        if (pack?.imgUrl && this.blobsas) {
          //console.log(group);
          if (pack.imgUrl == "") {
            return `url("https://${location.host}/no-image.jpg")`;
          } else if (pack.imgUrl.slice(0, 4) == "/img") {
            return `url("https://${location.host}${pack.imgUrl}")`;
          } else if (pack.imgUrl.slice(0, 5) == "data:") {
            return `url("${pack.imgUrl}")`;
          } else {
            const finalUrl =
              profile.blobsasurl +
              pack.packCreator.id +
              "/packImgs/" +
              pack.imgUrl +
              "?" +
              this.blobsas;
            //console.log(finalUrl);

            this.checkBackgroundImageLoaded(`${finalUrl}`);
            return 'url("' + finalUrl + '")';
          }
        } else if (!pack.imgUrl) {
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
    viewContent(item) {
      this.store.viewContent(item);
    },
    async moveOutFromPack() {
        if(this.materialsList.length>1){
        const item=this.store.currentEditItem
        console.log(item)
        const index=this.materialsList.findIndex(material=>material.id==item.id)
        
        console.log(index)
        if(index!=-1){
            this.materialsList.splice(index,1)
        }
        console.log(this.materialsList)
        const commonData = {
        id: this.currentPackContent.id,
        finalEditor: {
          id: this.currentLoginAccount.id,
          name: this.currentLoginAccount.name,
          picture: this.currentLoginAccount.picture,
        },
        materialsList: this.materialsList,
      };
      await this.updatePackInfo(commonData)
      await this.refreshPackInfo()}
      else{
        this.mountMsg("無法刪除，最少需要保留一個資源!");
      }

    },
    async updatePackInfo(data) {
      try {
        await api.main.updateResourcePackInfoById(data);
        this.mountMsg("更新成功!");
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less">
.pack-content {
  @apply p-10;
  .userinfo-card {
    background-color: #fff;
    @apply flex rounded-md p-5 mb-5;

    .btn-group {
      .normal-btn {
        @apply inline-block cursor-pointer text-white text-center border my-2 px-4 rounded mr-2 bg-sky-500 border-sky-500 text-sm;

        &:hover {
          @apply bg-sky-600;
        }
      }
    }
    .left-part {
      .profile-img {
        @apply rounded-md;
        height: 100px;
        width: 100px;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
      }
    }
  }
  .tag-group {
    @apply mt-4 ml-4 text-xs font-bold text-sky-500;

    .tag {
      @apply mx-1;
    }
  }
}
</style>