<template>
  <div class="card-item" :class="{ 'card-disable': materials.hidden }">
    <div
      class="img-top"
      v-show="materials"
      v-lazy-background="getImgUrl(materials)"
      :class="{ loaded: isLoadingNow && materials.imgBlobUrl }"
      ref="imgTop"
      :key="getImgUrl(materials)"
      @click="emitViewContent(materials)"
    ></div>
    <div class="card-textwrap">
      <h5 @click="emitViewContent(materials)">{{ materials.title }}</h5>
      <p>(可秀:{{ materials.groupAllowShow }})</p>
      <p class="text-xs">
        <a href="#" class="uploader">{{ materials.uploader.name }}</a
        ><span class="text-gray-500">・{{ getTime(materials.timeStamp) }}</span>
      </p>
      <p class="intro-text">{{ truncatedIntro(materials.intro) }}</p>
      <div ref="moreDropdown" v-show="!materials.hidden">
        <div
          class="more-btn"
          v-show="option.allowAddInMyPack"
          @click="showMore(materials)"
        >
          <svg-icon icon-class="more" />
        </div>

        <div
          class="more-btn"
          v-show="option.allowEdit"
          @click="showMore(materials)"
        >
          <svg-icon icon-class="more" />
        </div>
        <!-- more dropdown -->
        <div
          class="more-dropdown"
          v-show="showMoreMenu && !option.allowRemoveFromPack"
        >
          <!--Ｈome 頁面的選單-->
          <div
            class="menubtn"
            @click="confirmAddToPack()"
            v-show="
              option.pageName == 'home' ||
              option.pageName == 'profile' ||
              option.pageName == 'cate' ||
              option.allowAddInMyPack
            "
          >
            <svg-icon icon-class="file-right" class="edit-icon" />{{
              "加到我的資源包"
            }}
          </div>

          <div
            class="menubtn"
            @click="confirmAddToGroupPack()"
            v-show="
              option.allowAddInGroupPack && option.pageName == 'group-profile'
            "
          >
            <svg-icon icon-class="file-right" class="edit-icon" />{{
              "加到群組的資源包"
            }}
          </div>

          <div
            class="menubtn"
            v-show="option.allowMoveOut"
            @click="moveOutFromGroup()"
          >
            <svg-icon icon-class="file-right" class="edit-icon" />{{
              "移出群組"
            }}
          </div>
          <div
            class="menubtn"
            @click="openSelectedGroupForm('move')"
            v-show="option.allowMoveIn"
          >
            <svg-icon icon-class="file-left" class="edit-icon" />{{
              "移入群組"
            }}
          </div>
          <div
            class="menubtn"
            @click="openSelectedGroupForm('copy')"
            v-show="option.allowCopy"
          >
            <svg-icon icon-class="copy" class="copy-icon" />{{ "複製到群組" }}
          </div>

          <div
            class="menubtn"
            @click="openEditMaterialForm()"
            v-show="
              option.pageName != 'home' &&
              option.pageName != 'cate' &&option.allowEdit
            "
          >
            <svg-icon icon-class="edit" class="edit-icon" />{{ $t("編輯") }}
          </div>
          <div
            class="menubtn"
            @click="confirmDelete()"
            v-show="
              option.pageName != 'home' &&
              option.pageName != 'cate' &&option.allowEdit
            "
          >
            <svg-icon icon-class="delete" class="del-icon" />{{ $t("刪除") }}
          </div>
        </div>
        <div
          class="more-dropdown"
          v-show="showMoreMenu && option.allowRemoveFromPack"
        >
          <div
            class="menubtn"
            v-show="option.allowRemoveFromPack"
            @click="moveOutFromPack()"
          >
            <svg-icon icon-class="delete" class="del-icon" />{{
              "從資源包移除"
            }}
          </div>
        </div>
      </div>

      <span class="hits-num" v-show="materials.hits"
        ><span><svg-icon class="eye-icon" icon-class="eye" /></span
        >{{ materials.hits }}</span
      >
      <!-- {{  materials.isCopy }}    -->
      <div class="badge-group">
        <span class="badge">{{ materials.type }}</span>
        <span class="badge"
          ><span v-show="$i18n.locale == 'en-US'">Grade&nbsp;</span
          >{{ materials.grade
          }}<span v-show="$i18n.locale !== 'en-US'">年級</span></span
        >

        <span class="badge" v-show="materials.subject">{{
          materials.subject
        }}</span>
        <!-- <span class="badge">標籤2</span> -->
      </div>
      <!-- <div class="tag-group">
        <span v-for="(item, index) in materials.category" :key="index + materials.category + materials.id" class="tag">#{{ item }} </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
import profile from "../api/profile";
import { useMainStore } from "../stores/mainStore";
export default {
  components: { SvgIcon },
  name: "card-item",
  props: {
    materials: {
      type: Object,
      required: true,
    },
    blobsas: {
      type: String,
      required: true,
    },
    option: {
      type: Object,
      required: false,
      default: function () {
        return {
          pageName: "",
          allowEdit: false,
          allowMoveIn: true,
          allowMoveOut: false,
          allowCopy: true,
          allowAddInMyPack: false,
          allowRemoveFromPack: false,
          allowAddInGroupPack: false,
        };
      },
    },
  },
  setup() {
    const store = useMainStore();
    return { store };
  },
  mounted() {
    document.addEventListener("click", this.closeMenu);
  },
  data() {
    return {
      isLoadingNow: true,
      showMoreMenu: false,
    };
  },
  methods: {
    moveOutFromPack() {
      this.$emit("moveOutFromPack");
      this.showMoreMenu = false;
    },
    moveOutFromGroup() {
      this.$emit("moveOutFromGroup");
      this.showMoreMenu = false;
    },
    openSelectedGroupForm(mode) {
      this.store.setCurrentEditMaterialGroupMode(mode);
      this.$emit("openSelectedGroupForm", "Material");
      this.showMoreMenu = false;
    },
    openEditMaterialForm() {
      this.$emit("openEditMaterialForm");
    },
    confirmDelete() {
      this.$emit("confirmDelete");
    },
    closeMenu(event) {
      // 檢查點擊事件是否在選單內部
      if (this.$refs.moreDropdown) {
        if (!this.$refs.moreDropdown.contains(event.target)) {
          this.showMoreMenu = false;
        }
      }
    },
    confirmAddToPack() {
      this.$emit("confirmAddToPack");
    },
    confirmAddToGroupPack(){
      this.$emit("confirmAddToGroupPack");
    },
    emitViewContent(materials) {
      this.$emit("viewContent", materials);
    },
    showMore(item) {
      this.store.setCurrentEditItem(item);
      this.showMoreMenu = true;
      // console.log("showMore")
    },
    checkBackgroundImageLoaded(imageUrl) {
      if (imageUrl) {
        const tempImg = new Image();
        tempImg.src = imageUrl;
        tempImg.onload = () => {
          this.isLoadingNow = false;
          // console.log('背景图像已加载完成。');
        };
      }
    },
    getImgUrl(materials) {
      try {
        if (materials.imgBlobUrl && this.blobsas) {
          if (materials.imgBlobUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials?.imgBlobUrl + "?" + this.blobsas}`
            );
            return 'url("' + materials.imgBlobUrl + "?" + this.blobsas + '")';
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.uploader.id +
              "/" +
              materials.id +
              "/" +
              materials.imgBlobUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`);
            return 'url("' + finalUrl + '")';
          }
        } else if (!materials?.imgBlobUrl) {
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getTime(timestamp) {
      const date = new Date(timestamp * 1000);

      //const year = date.getFullYear();
      //const month = date.getMonth() + 1; // 月份从 0 开始，所以需要加 1
      //const day = date.getDate();
      // const hours = date.getHours();
      // const minutes = date.getMinutes();
      // const seconds = date.getSeconds();
      return date.toLocaleDateString();
    },
    truncatedIntro(intro) {
      if (intro) {
        const maxLength = 100;
        if (intro.length <= maxLength) {
          return intro;
        } else {
          return intro.substr(0, maxLength) + "...";
        }
      }
      return "";
    },
  },
};
</script>

<style lang="less">
.card-disable {
  .img-top,
  .card-textwrap > h5 {
    cursor: not-allowed !important;
  }
  &:hover {
    filter: unset !important;
  }
}
.card-item {
  @apply relative rounded-lg border border-gray-300 bg-white;
  // max-height: 450px;
  width: 100%;

  &:hover {
    @apply drop-shadow-xl;
  }

  .loaded {
    @apply animate-pulse;
    background: none !important;
    @apply bg-gray-500 !important;
  }

  .img-top {
    @apply rounded-t-lg cursor-pointer;
    height: 160px;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  }

  .uploader {
    @apply font-bold text-sky-500 underline;
  }

  .card-textwrap {
    @apply p-5 pt-3;

    h5 {
      @apply mb-2 text-2xl font-bold tracking-tight text-gray-900 cursor-pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .intro-text {
      // width: calc(~"18rem - 10px");
      @apply pb-10 pt-5 font-normal text-gray-700 text-sm break-words;
    }

    .hits-num {
      @apply absolute bottom-4 left-5 text-left text-xs text-gray-600;

      .eye-icon {
        @apply mr-1 text-gray-600;
        display: inline-block;
      }
    }

    .more-dropdown {
      top: 200px;
      @apply absolute -right-5 z-10 mt-2 w-auto origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5;

      .menubtn {
        @apply block cursor-pointer px-4 py-2 text-sm text-black;

        &:hover {
          @apply bg-slate-200 !important;
        }
        .copy-icon {
          display: inline-block;
          margin-right: 5px;

          path {
            fill: black !important;
          }
        }
        .edit-icon {
          display: inline-block;
          margin-right: 5px;

          * {
            fill: black;
          }
        }

        .del-icon {
          display: inline-block;
          margin-right: 5px;

          * {
            stroke: black;
          }
        }
      }
    }

    .more-btn {
      font-size: 28px;
      border-radius: 50%;
      line-height: 30px;
      position: absolute;
      top: 170px;
      right: 10px;
      cursor: pointer;
      text-align: center;
      width: 32px;
      height: 32px;

      * {
        position: relative;
        left: 2px;
        top: 1px;
        stroke: gray;
      }

      &:hover {
        @apply bg-slate-200;

        * {
          @apply stroke-sky-600 !important;
        }
      }
    }

    .badge-group {
      @apply absolute bottom-3 right-3 text-right;

      .badge {
        @apply mx-1 cursor-pointer rounded-full bg-yellow-400 px-2 py-1 text-sm font-semibold text-black drop-shadow-sm;
      }
    }

    .tag-group {
      @apply absolute bottom-4 right-3 text-right text-xs font-bold text-sky-500;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .tag {
        @apply mx-1;
      }
    }
  }
}
</style>
