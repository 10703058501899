export default {
   
        新增:'新增節點',
        編輯:'編輯節點',
        輸入文字: '輸入文字',
        確定:'確定',
        取消:'取消',
        請先選取節點:'請先選取節點!',
        不可刪除有子項目的節點:'不可刪除有子項目的節點!',
        新增失敗:'新增失敗，父節點已被刪除!',
        編輯失敗:'編輯失敗，所選節點已被刪除!',
        加入根節點:'加入根節點',
        左邊:'左邊',
        右邊:'右邊',
        已是最上面:'已是最上面!',
        已是最下面:'已是最下面!',
        圖片:'圖片',
        圖片過大提示字1:'所選圖片過大(請小於',
        圖片過大提示字2:'MB)，請重新選擇！',
        連結:'連結',
        連結網址:'連結網址',
        連結名稱:'連結名稱',
        輸入URL:'輸入URL...',
        輸入連結名稱:'輸入連結名稱...',
        註解:'註解',
        進階設定:'進階設定',
        線條顏色:'線條顏色',
        節點顏色:'節點顏色',
        preEditHint:'注意：目前的節點之前編輯者是',
        confirmUpdate:'，確定要修改嗎?',
        menu:{
          新增:'新增',
          刪除:'刪除',
          上移:'上移',
          下移:'下移',
          編輯:'編輯',
          複製:'複製',
          檢視圖片:'檢視圖片',
          最後更新:'最後更新',
        },
        imgEditor:{
          詢問離開動作:"詢問離開動作?",
          保存圖片:'保存圖片',
          捨棄變更:'捨棄變更',
          取消:'取消',
        }
      
}