<template>
  <div class="loader">
    <img src="../assets/spinner.gif" />
    <p>Loading...</p>
  </div>
</template>

<script>
export default {
    name:"loader-component"
}
</script>

<style lang="less">
.loader{
    *{  
        position: relative;
        left: 50%;
        transform: translate(-50%);
        padding-top: 30px;
    }
  
   
    p{  
        font-weight: bold;
        text-align: center;
        margin-left: 10px;
        color:gray;
    }
}

</style>