import { createI18n } from "vue-i18n";

import customZhCn from "./lang/zh-CN";
import customZhTw from "./lang/zh-TW";
import customEnUs from "./lang/en-US";

const navLang = localStorage.getItem("local") ? localStorage.getItem("local") : navigator.language;
const localLang = navLang === "zh-TW" || navLang === "zh-CN" || navLang === "en-US" ? navLang : false;
let lang = localLang || "en-US";

const i18n = createI18n({
  allowComposition:true,
  //legacy:false,
  locale: lang,
  messages: {
    "zh-CN": customZhCn,
    "zh-TW": customZhTw,
    "en-US": customEnUs,
  },
});

export default i18n;
