<template>
  <div class="card-review-item">
    <p class="item-num">{{ index + 1 }}</p>
    <div class="item-left">
      <div
        class="item-img"
        v-show="materials"
        v-lazy-background="getImgUrl(materials)"
        :class="{ loaded: isLoadingNow && materials.imgBlobUrl }"
        ref="item-img"
        :key="getImgUrl(materials)"
      ></div>
      <div class="badge-group">
        <span class="badge"
          ><span v-show="$i18n.locale == 'en-US'">Grade&nbsp;</span
          >{{ materials.grade
          }}<span v-show="$i18n.locale !== 'en-US'">年級</span></span
        >
        <span class="badge" v-show="materials.subject">{{
          materials.subject
        }}</span>
      </div>
      <div class="tag-group">
        <span
          v-for="(item, index) in materials.category"
          :key="index + materials.category + materials.id"
          class="tag"
          >#{{ item }}
        </span>
      </div>
    </div>

    <div class="item-content">
      <div class="main-type">
        {{ getContentTypeName(materials.type) }}
      </div>
      <p class="font-bold">{{ materials.title }}</p>

      <p class="text-xs mt-2">
        <span class="font-bold">簡介：</span>{{ materials.intro }}
      </p>
      <p class="text-gray-600 text-xs mt-2">
        {{ getTime(materials.timeStamp) }}・{{ $t("上傳者") }}：<span
          class="uploader"
          >{{ materials.uploader.name }}</span
        >
      </p>
      <p class="mt-1">
        <a :href="getBlobUrl(materials)" class="link">{{ $t("下載") }}</a>
      </p>
      <br />
    </div>
    <div class="right-btn-group">
        <div class="icon-wrap">
              <div v-show="materials.tempGroupAllowShow" class="check-icon">
                <svg-icon icon-class="check" />
              </div>
         </div>
         <div class="icon-wrap">
              <div v-show="!materials.tempGroupAllowShow" class="check-not-icon">
                <svg-icon icon-class="check" />
              </div>
         </div>

      <div
        class="approve-btn"
        @click="setApproveSingleReview(index, true)"
        v-show="!materials.tempGroupAllowShow"
      >
        同意
      </div>
      <div
        class="cancel-btn"
        @click="setApproveSingleReview(index, false)"
        v-show="materials.tempGroupAllowShow"
      >
        取消
      </div>
    </div>
  </div>
</template>

<script>
import profile from "../api/profile";
import SvgIcon from "../components/SvgIcon.vue";
export default {
  name: "card-review",
  components: {
    SvgIcon,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    materials: {
      type: Object,
      required: true,
    },
    blobsas: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadingNow: true,
    };
  },
  methods: {
    setApproveSingleReview(index, approve) {
      this.$emit("setApproveSingleReview", index, approve);
    },
    getContentTypeName(type) {
      const typeNames = {
        htex: this.$t("布題頁面"),
        audio: this.$t("音訊"),
        video: this.$t("影片"),
        back: this.$t("背景圖"),
        exam: this.$t("測驗卷"),
        tutorial: this.$t("教材"),
        plan: this.$t("教案"),
      };
      return typeNames[type] || type;
    },
    getTime(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString();
    },
    checkBackgroundImageLoaded(imageUrl) {
      if (imageUrl) {
        const tempImg = new Image();
        tempImg.src = imageUrl;
        tempImg.onload = () => {
          this.isLoadingNow = false;
          // console.log('背景图像已加载完成。');
        };
      }
    },
    getBlobUrl(materials) {
      if (materials.blobUrl.includes(profile.blobsasurl)) {
        return materials.blobUrl + "?" + this.blobsas;
      } else {
        const finalUrl =
          profile.blobsasurl +
          materials.uploader.id +
          "/" +
          materials.id +
          "/" +
          materials.blobUrl +
          "?" +
          this.blobsas;
        return finalUrl;
      }
    },
    getImgUrl(materials) {
      try {
        if (materials.imgBlobUrl && this.blobsas) {
          if (materials.imgBlobUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials?.imgBlobUrl + "?" + this.blobsas}`
            );
            return 'url("' + materials.imgBlobUrl + "?" + this.blobsas + '")';
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.uploader.id +
              "/" +
              materials.id +
              "/" +
              materials.imgBlobUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`);
            return 'url("' + finalUrl + '")';
          }
        } else if (!materials?.imgBlobUrl) {
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less">
.card-review-item {
  display: flex;
  @apply border-b border-gray-300;
  @apply py-3;
  .loaded {
    @apply animate-pulse;
    background: none !important;
    @apply bg-gray-500 !important;
  }
  .item-num {
    font-size: 12px;
    padding: 10px;
  }
  .item-left {
    flex: 1;
    .item-img {
      height: 100px;
      max-width: 150px;
      display: block;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      border-bottom: 1px solid rgba(62, 43, 43, 0.3);
    }
    .badge-group {
      @apply my-2;
      .badge {
        @apply mr-1  cursor-pointer rounded-full bg-yellow-400 px-2 py-1 text-sm font-semibold text-black;
      }
    }
  }

  .tag-group {
    @apply mt-1 text-xs font-bold text-sky-500;
    .tag {
      @apply mx-1;
    }
  }
  .link {
    @apply text-sky-600 underline  !important;
  }
  .item-content {
    flex: 2;
    width: 60%;
    padding: 10px;
    padding-top: 0px;
    .uploader {
      @apply cursor-pointer font-bold text-sky-500;
      text-decoration: underline;
    }
    .main-type {
      @apply mb-1 inline-block rounded-sm bg-purple-600 p-1  text-xs font-bold text-white;
    }
  }
  .right-btn-group{
    *{
        display: inline-block;
        text-align: right
        
    }
    .approve-btn,.cancel-btn{
        @apply -mr-2;
    }
   
    .icon-wrap {
      text-align: center;
      position: relative;
      padding-right: 5px;
    }
    .check-icon {
      @apply text-green-600;
    }
    .check-not-icon{
        @apply text-transparent;
    }
  }
 
}
</style>