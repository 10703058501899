import CryptoJS from 'crypto-js';

function getUUID() {
    var d = Date.now()
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now()
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
  }
  function encrypt(word, keyStr) {
    keyStr = keyStr ? keyStr : 'abcdefgabcdefg12';
    const key = CryptoJS.enc.Utf8.parse(keyStr);// Latin1 w8m31+Yy/Nw6thPsMpO5fg==
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {iv: key, mode: CryptoJS.mode.CTR, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
  }
  // 解密
  function decrypt(word, keyStr) {
    keyStr = keyStr ? keyStr : 'abcdefgabcdefg12';
    const key = CryptoJS.enc.Utf8.parse(keyStr);// Latin1 w8m31+Yy/Nw6thPsMpO5fg==
    const decrypt = CryptoJS.AES.decrypt(word, key, {iv: key, mode: CryptoJS.mode.CTR, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  }

  //排序
  function sortMaterials(val,materials){
    return materials.sort((a, b) => {
        return val == "title" ? a.title.localeCompare(b.title) : b.timeStamp - a.timeStamp;
      });
  }

  function checkSessionAccessTokenReady() {
    return new Promise((resolve, reject) => {
      let intervalId;
      let timeoutId;

      const intervalCallback = () => {
        const accessToken = sessionStorage.getItem("access_token");
        if (accessToken) {
          // access_token 存在，停止 setInterval 和 clearTimeout
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          resolve(accessToken); 
        } else {
          // access_token 不存在，可執行其他操作
          console.log("access_token 不存在");
        }
      };
      intervalId = setInterval(intervalCallback, 1000);

      timeoutId = setTimeout(() => {
        // 10 秒内仍未獲取到視為逾時
        clearInterval(intervalId);
        reject(new Error("等候授權逾時!"));
      }, 10000);
    });
  }

  function urlify(text) {
    if (text != undefined) {
      let urlRegex = /(https?:\/\/[^\s]+)/g
      return text.replace(urlRegex, function(url) {
        return '<a class="url-link" target="_blank" href="' + url + '">' + url + '</a>'
      })
    }
  }

  export const jsFn = {
    getUUID,encrypt,decrypt,sortMaterials,checkSessionAccessTokenReady,urlify

  }