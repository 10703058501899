<template>
  <div class="home-page div-center div-centerY">
    <img v-if="!isPC" class="ImgPC" src="../../assets/img/bean/bean_02.png" alt="">
    <div class="explain oneLine">      
      {{chooseBindingMethod[0]}}<span class="stress">{{chooseBindingMethod[1]}}</span>{{chooseBindingMethod[2]}}
      <img v-if="isPC" class="Img" src="../../assets/img/bean/bean_02_2.png" alt="">
    </div>
    <div v-if="!isPC" class="infoPC">
      <div class="tellMessage light">{{$t('AccountLogin.completeWithin30Minutes', { time: timerStore.formattedTime })}}</div>
      <div class="tellMessage">{{$t('AccountLogin.afterBindingLoginWithEduCloud')}}</div>
    </div>
    <div class="oneLine chooseArea">
      <div class="wayArea">
        <div>{{$t('AccountLogin.qrCodeBinding')}}</div>
        <!-- <img class="Img" src="../../assets/img/QR_code.png" alt=""> -->
        <div class="qr-code-container">
          <img class="img" :src="qrCodeDataUrl" alt="QR Code" />
          <div class="overlay" :style="{opacity:+ showQRcode ? 0 : 10}">
            {{$t('AccountLogin.qrCodeExpired')}}
          </div>
        </div>
        <div class="txtInfo">{{$t('AccountLogin.useHiTA5AppToScan')}}</div>
        <div class="sentCode sent" style="color: #409EFF;"  @click="lanSSE()">{{$t('AccountLogin.update')}}</div>
      </div>
      <div class="wayArea div-centerY">
        <div class="title">{{$t('AccountLogin.emailPhoneUserID')}}</div>
        <el-input class="input" v-model="teamModel.id" style="width: 240px" :placeholder="$t('AccountLogin.emailPhoneUserID')"  />
        <el-input class="input" v-model="teamModel.password" style="width: 240px" :placeholder="$t('AccountLogin.password')" />
        <div class="imgBtn div-center2" style="cursor: pointer;">
          <div class="txt" @click="goAccountBinding">{{$t('AccountLogin.bindNow')}}</div>
          <img src="../../assets/img/area-08.png">
        </div>
      </div>
      <div class="wayArea div-centerY">
        <div class="title">{{$t('AccountLogin.sms')}}</div>
        <div class="oneLine MsgDiv" >
          <div class="phonetxt"><el-input class="input" v-model="message.phone" style="width: 100%;" :placeholder="$t('AccountLogin.phoneNumber')" /></div>
          <div class="sentCode sent" v-if="!countCtrl" @click="clickToSentFun()">{{t('AccountLogin.sendVerificationCode')}}</div>
          <div class="sentCode wait" v-else>{{countMin}}</div>
        </div>
        <el-input class="input" v-model="message.code" style="width: 100%;" :disabled="isSend" :placeholder="$t('AccountLogin.verificationCode')" />
        <div class="imgBtn div-center2" :style="sendCtrl ? 'cursor: pointer' : 'cursor:no-drop;'">
          <div class="txt" @click="goMsgBinding">{{$t('AccountLogin.confirmVerificationCode')}}</div>
          <img src="../../assets/img/area-08.png">
        </div>
      </div>      
    </div>    
    <div v-if="isPC" class="tellMessage light">{{$t('AccountLogin.completeWithin30Minutes', { time: timerStore.formattedTime })}}</div>
    <div v-if="isPC" class="tellMessage">{{$t('AccountLogin.afterBindingLoginWithEduCloud')}}</div>
    <div class="backDiv" @click="goBack">{{$t('AccountLogin.back')}}</div>
  </div>
</template>

<script setup>
import { reactive,inject,ref,computed, onMounted, onUnmounted} from 'vue';//ref
import { useRouter } from 'vue-router'
import QRCode from 'qrcode'
import { post } from "@/api/http";
import option_gl from '@/static/regions/region_gl.json'
import profile from "../../api/profile";
import { ElMessage } from 'element-plus';
import {EventSourcePolyfill} from 'event-source-polyfill';
import { useTimerStore } from '../../stores/timer';
import { useGlobalMethods } from '@/utils/useGlobalMethods';

const { afterLoginRouter } = useGlobalMethods();
const timerStore = useTimerStore();

const optionsData = option_gl[0].children //縣市檔案
const serverLocation = "global";// 國際站設定
const clientID = profile.clientID[serverLocation];// clientID設定
const coreAPIUrl = profile.apiUrl[serverLocation];// API路由設定
const teamModel = reactive({id:'',password:''});// 醍摩豆帳號綁定
const message = reactive({phone:'',code:''});// 簡訊綁定
const qrCodeDataUrl = ref('')// QR-Code綁定
const qrCodeurl = ref('')
const gt_educloudtw = "educloudtw";
let sseSurvive = ref(true);
let isSend = ref(true);
let showQRcode =ref(true);//控制是否顯示QR Code
let isPC = ref(true);//判斷是否是PC

//字串組
const chooseBindingMethod = computed(() =>t('AccountLogin.chooseBindingMethod').split(','));

const t = inject('$t');//三國語系

//抓資料看是否是手機
function isMobileBrowser(){
  isPC.value =  sessionStorage.getItem("isPC") === 'true';
}

lanSSE();
function lanSSE() {
  //let apiUrl = profile.getQRCodeUrl()
  let apiUrl = "https://qrcode.teammodel.net";
  let es = new EventSourcePolyfill(apiUrl + '/service/sse', { headers: { 'X-Auth-Name': 'Account' } })
  //let _this = this
  //this.esState = es

  var closeSSE = function () {
    if (sseSurvive.value) {
      es.close()
      sseSurvive.value = false
      showQRcode .value = false
    }
  }

  es.addEventListener('open', function () {
    sseSurvive.value = true
  }, false)

  // 取得登入網址
  es.addEventListener('message', function (e) {
    if (e.data) {
      let esdata = JSON.parse(e.data)
      if (esdata.sid) {
        qrCodeurl.value = apiUrl + '/qrcode/qrcodelogin?sid=' + encodeURIComponent(esdata.sid, 'utf-8') + '&info=' + encodeURIComponent('TEAM Model', 'utf-8') + '&m=' + encodeURIComponent(t('AccountLogin.qrCodeLogin'), 'utf-8')
        generateQRCode();
      } else if (esdata.code) {
        closeSSE()
        let data =
        {
          grant_type: "authorization_code",
          client_id: clientID,
          code: esdata.code,
        }
        post(coreAPIUrl + '/oauth2/token', data).then(async res => {
          if (res.id_token) {// 驗證完成  開始綁定
            idTokenBinding(res);
          }

        })
        //_this.codeLogin(data.code)
      }
    }
  }, false)

  // 錯誤
  es.addEventListener('error', function () {
    closeSSE()
  }, false)

  // 設定五分鐘後關閉連接
  setTimeout(() => {
    closeSSE()
  }, 300000)
}

onMounted(() => {
  timerStore.loadTimer();
  isMobileBrowser();
  window.addEventListener('resize', isMobileBrowser);
});

onUnmounted(() => {
  if (timerStore.intervalId) {
    clearInterval(timerStore.intervalId);
  }
  window.removeEventListener('resize', isMobileBrowser);
});


// 產生qrCode
async function generateQRCode () {
  try {
    showQRcode.value = true;
    qrCodeDataUrl.value = await QRCode.toDataURL(qrCodeurl.value);
    
  } catch (error) {
    console.error('Failed to generate QR code:', error)
  }
}
// 取得學校資料
async function getSchoolData(school_id){
  let schdata = {
          code: school_id,
          fullData: true
        }
        await post(coreAPIUrl + '/service/SchoolData', schdata).then(schres => {
          sessionStorage.setItem("school_name", schres[0].name)//校名
          sessionStorage.setItem("shortCode", schres[0].shortCode)
          sessionStorage.setItem("schoolCode", schres[0].code)          

          //用cityId取縣市名
          let odItem = optionsData.find(od => od.code == schres[0].cityId)
          sessionStorage.setItem("region_name", odItem.name)//縣市名
          sessionStorage.setItem("cityId", schres[0].cityId)        
        }) 

}
// 已有醍摩豆id 未綁定 用帳號密碼綁定
function goAccountBinding() {
  if (teamModel.id !== "" && teamModel.password !== "") {
    // 先用帳密取id_token
    let data =
    {
      grant_type: "account",
      client_id: clientID,
      nonce: "0",      
      lang: "zh-tw",      
      account: teamModel.id,
      password: teamModel.password
    }
    post(coreAPIUrl + '/oauth2/login', data).then(async res => {
      if (res.error) {
        ElMessage({
          type: 'error',
          message: t('AccountLogin.incorrectAccountOrPassword'),
        })
      } else {
        idTokenBinding(res);
      }
    })
  } else {
    ElMessage({
      type: 'error',
      message: t('AccountLogin.enterAccountAndPassword'),
    })

  }
}
// 用id_token綁定教育雲帳號
function idTokenBinding(res) {
  sessionStorage.setItem("access_token", res.access_token);
  let data2 =
  {
    grant_type: gt_educloudtw,
    client_id: clientID,
    nonce: "0",
    open_code: sessionStorage.getItem("open_id"),    
    lang: "zh-tw",
    id_token: res.id_token
  }
  // 用id_token去綁定教育雲帳號
  post(coreAPIUrl + '/oauth2/login', data2).then(async res2 => {
    if (res2.error) {
     console.log("API錯誤：" + res2.message);
    }else{
      sessionStorage.setItem("id_token", res2.id_token)
      sessionStorage.setItem("open_name", res2.open_name)
      sessionStorage.setItem("edu_id_token", res2.edu_id_token)
      await getSchoolData(res2.school_id);
      await ElMessage({
        type: 'success',
        message: t('AccountLogin.bindingSuccessful'),
      })
      sessionStorage.removeItem("open_id");// 綁定完成清除open_id的Storage
      afterLoginRouter()
    
    }
  })
}

//路由
const routers = useRouter();
// 将 router 注入到 store 中
timerStore.$router = routers;
function goBack(){
  routers.back();
}

//傳送認證碼等待時間
let countMin = ref(t('AccountLogin.resend'));
let countCtrl = ref(false); //預設false 這是控制重發訊息的時間
let sendCtrl = ref(false); //預設false 這是控制確認驗證碼是否啟用
const min = 60;//倒數60秒
const countdown = ref(min);
let timer = null;

// 發送驗證碼程序
function clickToSentFun() {
  if (message.phone) {
    // 定义手机号码的正则表达式
    const phoneRegex = /^09\d{2}\d{3}\d{3}$/
    let isPhoneNumberValid = phoneRegex.test(message.phone)
    if (isPhoneNumberValid) {
      countCtrl.value = true;
      sendCtrl.value = true;
      countMin.value = t('AccountLogin.resend') + '（' + countdown.value + '）';
      sentMessageFun();
      timer = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--;
          countMin.value = t('AccountLogin.resend') + '（' + countdown.value + '）';
        } else {
          clearInterval(timer);
          countdown.value = min;
          countCtrl.value = false;
        }
      }, 1000);
    } else {
      ElMessage({
        type: 'error',
        message: t('AccountLogin.incorrectPhoneNumberFormat'),
      })
    }
  } else {
    ElMessage({
      type: 'error',
      message: t('AccountLogin.enterPhoneNumber'),
    })
  }
}
// 驗證碼綁定
function goMsgBinding() {
  if(sendCtrl.value){
    if (message.code) {
    // 驗證碼的正則
    const pinRegex = /^\d{6}$/
    let isPhoneNumberValid = pinRegex.test(message.code)
    if (isPhoneNumberValid) {
    // 先用手機跟驗證碼取id_token
    let data =
    {
      grant_type: "pin",
      client_id: clientID,
      nonce: "0",      
      lang: "zh-tw",
      pin_code: message.code,
      account: message.phone.substring(1, message.phone.length)
    }
    post(coreAPIUrl + '/oauth2/login', data).then(async res => {
      if (res.error) {
        ElMessage({
          type: 'error',
          message: t('AccountLogin.incorrectAccountOrVerificationCode'),
        })
      } else {
        idTokenBinding(res);
      }
    })   
    } else {
      ElMessage({
        type: 'error',
        message: t('AccountLogin.incorrectVerificationCodeFormat'),
      });
    }
  } else {
    ElMessage({
      type: 'error',
      message: t('AccountLogin.enterVerificationCode'),
    });
  }

  }else{
    return false;
  }  
}
// 發送驗證碼
function sentMessageFun() { //傳送手機認證碼的程式寫這<<<<<<<<<<<<<<<<<<<<  
  let data =
  {
    country: "886",
    lang: "zh-tw",
    to: message.phone.substring(1, message.phone.length)
  }
  post(coreAPIUrl + '/service/sendsms/pin', data).then(async res => {
    if (res.error) {
      console.log( t('AccountLogin.verificationCodeSendFailed')+'：' + res.error);
    } else {
      ElMessage({
        type: 'success',
        message:  t('AccountLogin.verificationCodeSent'),
      })
      isSend.value = false;
    }
  })
}

</script>

<style lang="less">

</style>
<style lang="less" scoped>
@fontColor :#424f55;//文字：深藍
@fontColor-light :#8da3a0;//文字：中藍
@stressColor :#f59f56;//強調色：橘色
@fontColor-gray1: #909399;
@fontColor-gray2: #b1b3b8;
@background-yellow:#F6EDE4;//背景：淡黃

.div-center{/*水平置中*/
  display: flex; 
  justify-content: center; 
  text-align:center;
  width:100%;
}
.div-center2{/*水平置中2，父框架需有display: flex;*/
    margin-left: auto;
    margin-right: auto;
}
.div-centerY{ /*垂直置中*/
  display: flex;    
  align-items: center;
}
.oneLine{ /*單行與折行顯示*/
  display: flex;
  flex-wrap: wrap; //元素折行呈現，元素空間夠的時候單行呈現，空間不夠則折行呈現
}
.home-page{
  margin-top: 50px;
  .ImgPC{
    height: 100px;
    margin-bottom: 20px;
  }
  .infoPC{
    margin: 10px 0 30px 0;
  }
  .explain{
    font-size: 18px;
    font-weight:bold;
    .stress{
      color: @stressColor;
    }
    .Img{
      height: 70px;
      margin-left: 50px;
      transform: translate(0%, -40%);
    }
  }
  .chooseArea{
    font-size: 16px;
    .wayArea{
      background: @background-yellow;
      padding: 30px;
      margin: 10px;
      width: 320px;
      border-radius: 40px;
      
      .qr-code-container {
        position: relative;
        display: inline-block;
        .Img{
          display: block;
          width: 180px;
          margin-top: 20px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.95); /* 白色半透明背景 */
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 10px;
          box-sizing: border-box;
          color: red; 
        }
      }
      .title{
        margin-bottom: 10px;
      }
      .input{
        margin: 10px 0;
        height: 50px;
      }
      .txtInfo{
        margin-top: 10px;
        font-size: 14px;
      }
      .MsgDiv{
        width: 100%;
        .phonetxt{
          flex: 1;
        }
        .sentCode{
          font-size: 14px;
          margin: 10px 0;
          font-weight:bold;            
          &.sent{
            width: 50px;
            color: #409EFF;
            cursor: pointer;//滑鼠變成小手
          }
          &.wait{
            width: 60px;
            color: @fontColor-gray2;
          }        
        }
      }
      
      .imgBtn{
        margin-top: 10px;
        position: relative;
        width:140px;
        height: 40px;
        margin-left: auto;
        font-size: 14px;
        //cursor: pointer;//滑鼠變成小手
        .txt {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white; 
          text-align: center;
        }
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
  .tellMessage{
    color: @fontColor-light ;
    margin-top: 10px;
    &.light{
      color: @fontColor;
    }    
  }
  .backDiv{
    margin-top: 30px;
    color: @fontColor ;
    cursor: pointer;//滑鼠變成小手
    margin-bottom: 70px;
  }
}
</style>
