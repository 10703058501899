<template>
  <div class="home-page" v-loading="loadingCtrl">
    <!-- <el-space direction="vertical" style="width: 100%;margin-left: auto;margin-right: auto;margin-top: 3%;"
      shadow="Always"></el-space> -->      
    <div class="customInfoDIV div-center">   
      <div class=" oneLine">
        <img class="imgCSS" :src="pictureUri">
        <div class="custominfo">
          <div class="name">{{open_name}}</div>
          <div class="accountID">{{$t('AccountInfo.teamModelAccount')}}：{{tmdid}}</div>
          <div class="school">{{school_name}}</div>
          <div class="otherInfo" v-show="isSowpurchaseMsg1">{{$t('AccountInfo.schoolNotInList')}}</div>
          <div class="otherInfo" v-show="isSowpurchaseMsg2">{{$t('AccountInfo.schoolAuthorizationFull')}}</div>
        </div>
        <div class="hiTeachInfo" >
          <div>
            <img class="imgDiv div-center2" src="../../assets/img/bean/bean_06.png">
            <div v-show="isShowHi"><a href="https://www.habook.com/zh-tw/faq.php?act=view&id=236" target="_blank">
              {{clickToLearnHiTeach[0]}}<hr>
              {{clickToLearnHiTeach[1]}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="oneLine titleDiv">
      <img src="../../assets/img/symbol_arrow2red.png" :style="'visibility:'+ (ApplicationData.length===0 ?  'hidden' :'')">      
      <div class="mainTitle"><u><b>{{ $t("AccountInfo.availableProducts") }}</b></u></div>
    </div>      
    <div class="area">  
      <div class="title oneLine" v-if="ApplicationData.length !==0">
        <div style="flex:1">{{ $t("AccountInfo.productName") }}</div>
        <div style="flex:2">{{ $t("AccountInfo.additionalItem") }}</div>
        <div style="width:200px">{{ $t("AccountInfo.usageTime") }}</div>
        <div style="width:200px">{{ $t("AccountInfo.state") }}</div>
      </div>
      <div class="content oneLine" v-if="ApplicationData.length ===0">
        <div class="nodata">{{ $t("AccountInfo.noAvailableItems") }}</div>
      </div>    
      <div class="content oneLine" v-else v-for="(data,i) in ApplicationData" :key="i">
        <div class="name">{{data.name}}</div>        
        <div class="dlc"><i v-html="data.info"></i></div>
        <div class="time">{{data.time}}</div>
        <div class="time">{{data.state}}</div>
        <hr style="width: 100%;">
      </div>
      <div v-if="ApplicationData.length !==0&&isShowApply" class="imgCss"  @click="applyClick">
        <div class="txt">{{ $t("AccountInfo.apply") }}</div>
        <img src="../../assets/img/area-08.png">
      </div>
    </div>
    <div class="info" @click="infoFun">
      {{ $t("AccountInfo.authorizationNote") }}
      <el-icon><QuestionFilled /></el-icon>
    </div>
    <img v-if="infoCtrl" @click="infoFun" class="infoPicture div-center2" src="../../assets/img/instructions.png">
    <div class="mainTitle"><u><b>{{ $t("AccountInfo.myAuthorizations") }}</b></u></div>
    <div class="area">
      <div class="title oneLine">
        <div style="flex:1;">{{ $t("AccountInfo.productName") }}</div>
        <div style="flex:2">{{ $t("AccountInfo.additionalItem") }}</div>
        <div style="width:200px">{{ $t("AccountInfo.usageTime") }}</div>
      </div>
      <div class="content oneLine" v-if="myProductData.length ===0">
        <div class="nodata">No Data</div>
      </div>
      <div class="content oneLine" v-else v-for="(data,i) in myProductData" :key="i">        
        <div class="name">{{data.name}}</div>
        <div class="dlc"><i v-html="data.info"></i></div>
        <div class="time">{{data.time}}</div>
        <hr style="width: 100%;">
      </div>
    </div>    
    <div  class="assist">
      <div class="box oneLine">
        <a href="https://www.habook.com/zh-tw/contact_us.php" target="_blank"><u>{{ $t("AccountInfo.customerSupport") }}</u></a>
        <img src="../../assets/img/man.png" >
        <img src="../../assets/img/woman.png" >
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive,ref,inject,onMounted,computed } from 'vue';
import { ElMessageBox } from 'element-plus';
import api from "@/api";
import jwtDecode from 'jwt-decode'
import { post } from "@/api/http";

import { useRouter } from 'vue-router'

const routers = useRouter() //路由

// do not use same name with ref
//  function routerskip(val) {//路由
//    routers.push(val)  
//  }
let infoCtrl = ref(false);//開啟授權說明
let isShowHi = ref(false);//是否顯示學習Hiteach
const pic = require("../../assets/img/bean/bean_02_2.png")
let pictureUri = ref(pic)
let tmdid = ref("")
let open_name = ref("")
let school_name = ref("")
let isSowpurchaseMsg1 = ref(false)
let isSowpurchaseMsg2 = ref(false)
let isShowApply = ref(true)

let loadingCtrl =ref(false);//載入畫面控制

const t = inject('$t');//三國語系

//字串組
const clickToLearnHiTeach = computed(() =>t('AccountInfo.clickToLearnHiTeach').split(','));

// 擴充項項目
const extensions = reactive([
    {
      Name: "AI智能終端",
      Key: "ezs"
    },
    {
      Name: "禁用硬體IRS",
      Key: "irs"
    },
    {
      Name: "USB錄影支援",
      Key: "hdcam"
    },
    {
      Name: "AI文句分析",
      Key: "wordanls"
    },
    {
      Name: "分組數",
      Key: "cligroup"
    },
    {
      Name: "議課人數",
      Key: "soknumber"
    },
    {
      Name: "IRS連線授權數",
      Key: "client_volume"
    },
    {
      Name: "蘇格拉底議課APP",
      Key: "sokapp"
    },
    {
      Name: "蘇格拉底影片",
      Key: "sokvdo"
    },
    {
      Name: "學校簡碼",
      Key: "schoolinfo"
    },
    {
      Name: "蘇格拉底桌面",
      Key: "sokdesk"
    },
    {
      Name: "電子學生證",
      Key: "dgistuid"
    },
    {
      Name: "智慧評分系統",
      Key: "scorsys"
    },
    {
      Name: "蘇格拉底小數據",
      Key: "soksdata"
    },
    {
      Name: "蘇格拉底報告",
      Key: "sokrpt"
    },
    {
      Name: "雲端診斷分析系統",
      Key: "cloudas"
    },
    {
      Name: "蘇格拉底語音轉寫",
      Key: "sokvtt"
    },
    {
      Name: "協作",
      Key: "cowork"
    },
    {
      Name: "AI GPT服務",
      Key: "aigpt"
    },
  ]);
//產品名稱
const nameOptions = reactive([
  { label: "HiTeach5縣市授權版", value: "VA67B6EZ" },
  { label: "IES5個人空間縣市授權版", value: "VAA7B6EY" }
]);
function infoFun(){
  infoCtrl.value = !infoCtrl.value;
}


let ApplicationData = reactive([]);//可申請授權資料


let myProductData= reactive([]);//個人授權資料 


function applyClick() {
  ElMessageBox.alert(t("AccountInfo.confirmApplication"), t("AccountInfo.inquiry"), {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: t("AccountInfo.yes"),
    callback: async (action) => {
      let t_data = jwtDecode(sessionStorage.getItem("id_token"));
      if (action === 'confirm') {        
        const promises = ApplicationData.map(async (item) => {
          console.log(item);
          let data = {
            tmid: t_data.sub,
            shortCode: sessionStorage.getItem("shortCode"),
            purchaseCode: item.id
          };
          try {
            debugger
            let bbAPIDomain = location.host === "localhost:5005" ? "https://bb-rc.teammodel.net" : "https://bb.teammodel.net" ;
            const res = await post(bbAPIDomain + "/bbauthapi/add-purchase-order", data);
            if (res.errCode !== "") {
              console.error(res.error);
            }
          } catch (e) {
            console.error(e);
          }
        });
        // Promise.all解決非同步問題
        await Promise.all(promises);
        await getListData();
        isShowApply.value = false;

        // myProductData.push(...ApplicationData);
        // ApplicationData.splice(0, ApplicationData.length);
      }
    },
  });
}
onMounted(() => {
  //以後要檢查登入要打開
  if (sessionStorage.getItem("id_token")) {// 檢查有無登入資料
    let t_data = jwtDecode(sessionStorage.getItem("id_token"));
    //debugger
    if (t_data.picture) { pictureUri.value = t_data.picture; }

    tmdid.value = t_data.sub;
    school_name.value = sessionStorage.getItem("school_name")
    open_name.value = sessionStorage.getItem("open_name")
    
    //purchaseMsg.value = 

    getListData();
  } else {
    routers.push("/").then(() => { window.location.reload(); })
  } 
  
  //getListData();
  
}) 
// 取得並設定資料
async function getListData() {  
  loadingCtrl.value = true;
  let t_data = jwtDecode(sessionStorage.getItem("id_token"));
  
  let data = {
    cityId: sessionStorage.getItem("cityId"),
    shortCode: sessionStorage.getItem("shortCode"),
    tmid: t_data.sub
  };  

  try {
    const res = await api.main.getPurchaseData(data)    
    if (res) {
      ApplicationData.splice(0, ApplicationData.length);
      myProductData.splice(0, myProductData.length);
      if (res.data.yes.length > 0) {
        res.data.yes.forEach((element) => {
          let item = {
            id:element.id,
            name: nameOptions.find(no => no.value == element.name).label,
            info: getContent(element.content, element.name),
            time: getTime(element.startDate) + "~" + getTime(element.endDate)
          }          
          myProductData.push(item);                 
           // 檢查有無hiteach           
          if(element.name === "VA67B6EZ"){
            isShowHi.value = true;
          }
        })
      }
      if (res.data.no.length > 0) {
        res.data.no.forEach((element) => {
          let item = {
            id: element.id,
            name: nameOptions.find(no => no.value == element.name).label,
            info: getContent(element.content, element.name),
            time: getTime(element.startDate) + "~" + getTime(element.endDate),
            state: element.state == 1 ? t('AccountInfo.notApplied') : element.state == 2 ? t('AccountInfo.appliedNotAuthorized') : ""
          }
          ApplicationData.push(item);          
          // 只要有一個未申請就可以按申請
          isShowApply.value = element.state == 1 ? true : false;

        })
      }
      if(!res.data.isInList){
        isSowpurchaseMsg1 .value = true;
      }
      if(res.data.isFull){
        isSowpurchaseMsg2 .value = true;
      }
      loadingCtrl.value = false;
    }
    //debugger
    console.log(res)
  } catch (err) {
    console.log(err);
    loadingCtrl.value = false;
  }
}
// 取得日期
function getTime(timestamp) {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString();
}
// 組合擴充項
function getContent(Contentarr, name) {
  let exstr = "";
  if (name === "VA67B6EZ") {// 如果是HiTeach5縣市授權版 組合擴充項文字
    Contentarr.forEach((apItem, index) => {
      if (index === Contentarr.length - 1) {
        let exItem = extensions.find(ex => ex.Key == apItem)
        exstr += exItem.Name
      }
      else {
        let exItem = extensions.find(ex => ex.Key == apItem)
        exstr += exItem.Name + "<br/>"
      }
    });
  }

  if (name === "VAA7B6EY") {// 如果是IES5個人空間縣市授權版 放容量
    exstr = Contentarr[0] + "G";
  }
  return exstr;
}
    
// function quit() {
//   debugger
//   localStorage.clear();
//   routers.push('/QrLogin') 
// }

</script>

<style lang="less">

</style>
<style scoped lang="less">
@fontColor :#424f55;//文字：深藍
@fontColor-light :#8da3a0 ;//文字：中藍
@stressColor :#f59f56;//強調色：橘色
@fontColor-gray1: #909399;
@fontColor-gray2: #b1b3b8;
@other-blue:#70a6b2;//其他輔助色：水藍

:deep(.my-label) {
  background: var(--el-color-success-light-9) !important;
}
:deep(.my-content) {
  background: var(--el-color-danger-light-9);
}

.oneLine{ /*單行與折行顯示*/
  display: flex;
  flex-wrap: wrap;
}
.div-center{/*水平置中*/
  display: flex; 
  justify-content: center; 
  text-align:center;
  width:100%;
}
.div-center2{/*水平置中2，父框架需有display: flex;*/
  margin-left: auto;
  margin-right: auto;
}
.oneLine{ /*單行與折行顯示*/
  display: flex;
  flex-wrap: wrap; //元素折行呈現，元素空間夠的時候單行呈現，空間不夠則折行呈現
}

.home-page{
  padding: 10px 70px 20px !important;
  .customInfoDIV{
    position: relative;//父元素設置為相對定位
    margin:30px 0px;
    .imgCSS{
      width: 100px;
      height: fit-content;
      border-radius: 50%;
    }
    .custominfo{
      margin-left: 20px;
      text-align: left;
      line-height: 25px;
      .name{
        font-size: 20px;
        text-decoration: underline;
        font-weight:bold;
        color: @fontColor;
      }
      .accountID{
        margin-top: 5px;
        color:@fontColor-gray1;
        font-weight:bold;
      }
      .school{
        color:@other-blue;
        font-weight:bold;
      }
      .otherInfo{
        color:@fontColor-gray2;
        font-style:oblique;
      }
    }
  }
  .hiTeachInfo{
    position: absolute;
    display: flex;
    right: 0px;
    color: @stressColor;
    .imgDiv{
      width: 100px;
    }
  }
  .titleDiv{
    position: relative;//父元素設置為相對定位
    left: -60px;
    img{
      margin-top: 15px;
      width: 50px;
      margin-right: 10px ;
    }
  }
  .info{
    color: #8da3a0;
    margin: 10px 20px 30px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;//滑鼠變成小手
  }
  .infoPicture{
    margin-bottom: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), /* 深陰影 */
                0 1px 3px rgba(0, 0, 0, 0.08); /* 淺陰影 */
  }
  .mainTitle{
    font-size:16px;
    margin: 10px;
  }
  .area{
    background: #F6EDE4;
    padding: 20px 30px;
    border-radius: 40px;
    .title{
      background: white;
      border-radius: 40px;
      padding: 5px 20px;
    }
    .content{
      padding: 10px 20px;
      .name{
        flex:1;  
      }
      .dlc{
        flex:2;
        color: #8da3a0;
      }
      .time{
        width:200px;
        color: #8da3a0;
        margin-bottom: 5px;
      }
      .nodata{
        color: #8da3a0;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .imgCss{
      margin-top: 10px;
      position: relative;
      width:70px;
      height: 30px;
      margin-left: auto;
      cursor: pointer;//滑鼠變成小手
      .txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white; 
        text-align: center;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .assist{  
    margin-top: 30px;
    margin-bottom: 100px;
    display: flex;
    .box{
      margin-left: auto;
      display: flex;
      align-items: flex-end;
      img{
        height: 50px;
      }
    }
  }
}
</style>
