<template>
  <div class="editManager-modal">
    <div class="main-form">
      <div class="text-center text-lg font-medium text-gray-900">
        {{ "設定管理員" }}
      </div>
      <div class="member-wra-scroll">
        <div
          class="member-wrap"
          v-for="(member, index) in groupMembers"
          :key="'member' + member.id"
          @click="setSingleManager(index, !member.isManager)"
        >
          <p class="num">{{ index + 1 }}</p>
          <img :src="member.picture" alt="" />
          <div class="name">
            {{ member.name }}
            <p class="creator" v-show="member.id == groupCreator.id">
              *群組創立者(永久管理員)
            </p>
            <p class="creator" v-show="member.id == currentLoginAccount.id">
              *管理員(不可移除自己)
            </p>
          </div>
          <div class="icon-wrap">
            <div v-show="member.isManager" class="check-icon">
              <svg-icon icon-class="check" />
            </div>
          </div>
        </div>
      </div>
      <div class="normal ml-auto mt-5 p-4 pt-0">
        <button class="left-btn" @click="confirm()">
          {{ $t("確定") }}
        </button>
        <button class="right-btn" @click="close()">{{ $t("取消") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../components/SvgIcon.vue";
export default {
  name: "editmanager-modal",
  props: {
    groupMembers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    groupCreator: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isCurrentAccountManager:{
        type:Boolean,
        default:function(){
            return false
        }
    },
    currentLoginAccount:{
        type:Object,
        default:function(){
            return {};
        }
    }
  },
  methods: {
    setSingleManager(index, status) {
      this.$parent.setSingleManager(index, status);
    },
    confirm() {
      this.$emit("confirm");
    },
    close() {
      this.$emit("close");
    },
  },
  components: {
    SvgIcon,
  },
};
</script>

<style lang="less">
.editManager-modal {
  z-index: 999;
  @apply fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-800/50;

  .main-form {
    @apply relative  flex flex-col  rounded-lg bg-white pt-4;
    min-width: 40%;
    max-height: 90vh;
    .scroll-wrap {
      @apply px-8 py-0;
      width: 100%;
      height: 100%;
      min-height: 260px;
      overflow: auto;
      .upload-item {
        @apply my-3;
        input[type="text"],
        textarea {
          @apply mt-2 w-full rounded-md px-5 py-2 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6;
        }
        textarea {
          min-height: 120px;
          max-height: 180px;
          overflow: auto;
        }
      }
      .title {
        @apply font-bold;
      }
    }

    .member-wra-scroll {
      max-height: 585px;
      overflow: auto;
    }

    .member-wrap {
      display: flex;
      align-items: center;
      padding-right: 20px;
      border-bottom: 1px solid rgba(128, 128, 128, 0.2);
      &:hover {
        @apply bg-slate-200;
      }
      @apply py-1;
      .creator {
        @apply text-sky-500 text-xs;
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .num {
        @apply text-sm text-gray-400;
        width: 40px;
        text-align: center;
      }

      .name {
        flex: 1;
        @apply m-4 font-bold;
        width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .icon-wrap {
        text-align: center;
        position: relative;
        padding-right: 10px;
      }

      .check-icon {
        @apply text-green-600;
      }
    }
    .normal {
      .left-btn {
        @apply mr-2 rounded border border-sky-500 bg-sky-500 px-4 py-1 font-bold text-white;

        &:hover {
          @apply bg-sky-600;
        }
      }

      .right-btn {
        @apply rounded border border-gray-500 bg-transparent px-4 py-1 font-semibold text-gray-700;

        &:hover {
          @apply bg-sky-100;
        }
      }
    }
  }
}
</style>
