<template>
  <div class="pack-review-item">
    <div class="flex pb-5">
      <p class="item-num">{{ index + 1 }}</p>

      <div class="item-left">
        <div
          class="item-img"
          :style="{
            'background-size': checkImgStyle(materials) ? 'contain' : 'cover',
            'background-color': checkImgStyle(materials) ? '#fef9c3' : 'unset',
          }"
          v-show="materials && materials.imgUrl != ''"
          v-lazy-background="getImgUrl(materials)"
          :class="{ loaded: materials.isLoadingNow && materials.imgUrl }"
          ref="imgTop"
        ></div>
        <div
          class="img-compose"
          v-show="materials && materials.imgUrl == ''"
          :class="{ 'grid-cols-2': materials.materialsList.length == 2 }"
          @click="emitViewContent(materials)"
        >
          <div
            class="img-puzze"
            :class="{
              'col-span-2 row-span-1':
                index == 0 && materials.materialsList.length != 2,
              loaded: item.isLoadingNow,
            }"
            v-for="(item, index) in materials.materialsList"
            v-lazy-background="getImgListItemUrl(item)"
            v-show="index < 3"
            :key="index"
          ></div>
        </div>
        <div class="badge-group">
          <span class="badge" v-show="materials.grade"
            ><span v-show="$i18n.locale == 'en-US'">Grade&nbsp;</span
            >{{ materials.grade
            }}<span v-show="$i18n.locale !== 'en-US'">年級</span></span
          >
          <span class="badge" v-show="materials.subject">{{
            materials.subject
          }}</span>
        </div>
        <div class="tag-group">
          <span
            v-for="(item, index) in materials.category"
            :key="index + materials.category + materials.id"
            class="tag"
            >#{{ item }}
          </span>
        </div>
      </div>
      <div class="item-content">
        <p class="font-bold">{{ materials.title }}</p>
        <p class="text-xs mt-2">
          <span class="font-bold">簡介：</span>{{ materials.intro }}
        </p>
        <p class="text-gray-600 text-xs mt-2">
          {{ getTime(materials.timestamp) }}・{{ $t("上傳者") }}：<span
            class="uploader"
            >{{ materials.packCreator.name }}</span
          >
        </p>
      </div>
      <div class="right-btn-group">
        <div class="icon-wrap">
          <div v-show="materials.tempGroupAllowShow" class="check-icon">
            <svg-icon icon-class="check" />
          </div>
        </div>
        <div class="icon-wrap">
          <div v-show="!materials.tempGroupAllowShow" class="check-not-icon">
            <svg-icon icon-class="check" />
          </div>
        </div>

        <div
          class="approve-btn"
          @click="setApproveSinglePackReview(index, true)"
          v-show="!materials.tempGroupAllowShow"
        >
          同意
        </div>
        <div
          class="cancel-btn"
          @click="setApproveSinglePackReview(index, false)"
          v-show="materials.tempGroupAllowShow"
        >
          取消
        </div>
        <div class="arrow-btn" @click="showList=!showList">
          <svg-icon icon-class="arrow-down" class="arrow-icon"  v-show="!showList"/>
          <svg-icon
        class="arrow-icon arrow-icon-up text-purple-600"
        icon-class="arrow-down"
        v-show="showList"
      />
        </div>
      </div>
    </div>

    <div
      class="inner-card-review-item" v-show="showList"
      v-for="(item, subindex) in materials.materialsList"
      :key="item.id"
    >
      <p class="item-num">{{ subindex + 1 }}</p>
      <div class="item-left">
        <div
          class="item-img"
          v-show="item"
          v-lazy-background="getInnerImgUrl(item)"
          :class="{ loaded: item.isLoadingNow && item.imgBlobUrl }"
          ref="item-img"
          :key="getInnerImgUrl(item)"
        ></div>
        <div class="badge-group">
          <span class="badge"
            ><span v-show="$i18n.locale == 'en-US'">Grade&nbsp;</span
            >{{ item.grade
            }}<span v-show="$i18n.locale !== 'en-US'">年級</span></span
          >
          <span class="badge" v-show="item.subject">{{ item.subject }}</span>
        </div>
        <div class="tag-group">
          <span
            v-for="(subitem, index) in item.category"
            :key="index + item.category + item.id"
            class="tag"
            >#{{ subitem }}
          </span>
        </div>
      </div>

      <div class="item-content">
        <div class="main-type">
          {{ getContentTypeName(item.type) }}
        </div>
        <p class="font-bold">{{ item.title }}</p>

        <p class="text-xs mt-2">
          <span class="font-bold">簡介：</span>{{ item.intro }}
        </p>
        <p class="text-gray-600 text-xs mt-2">
          {{ getTime(item.timeStamp) }}・{{ $t("上傳者") }}：<span
            class="uploader"
            >{{ item.uploader.name }}</span
          >
        </p>
        <p class="mt-1">
          <a :href="getBlobUrl(item)" class="link">{{ $t("下載") }}</a>
        </p>
        <br />
      </div>
    </div>
    
  </div>
</template>

<script>
import profile from "../api/profile";
import SvgIcon from "../components/SvgIcon.vue";
export default {
  name: "pack-review",
  components: {
    SvgIcon,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    materials: {
      type: Object,
      required: true,
    },
    blobsas: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showList: false,
    };
  },
  methods: {
    setApproveSinglePackReview(index, approve) {
      this.$emit("setApproveSinglePackReview", index, approve);
    },
    getBlobUrl(materials) {
      if (materials.blobUrl.includes(profile.blobsasurl)) {
        return materials.blobUrl + "?" + this.blobsas;
      } else {
        const finalUrl =
          profile.blobsasurl +
          materials.uploader.id +
          "/" +
          materials.id +
          "/" +
          materials.blobUrl +
          "?" +
          this.blobsas;
        return finalUrl;
      }
    },
    getContentTypeName(type) {
      const typeNames = {
        htex: this.$t("布題頁面"),
        audio: this.$t("音訊"),
        video: this.$t("影片"),
        back: this.$t("背景圖"),
        exam: this.$t("測驗卷"),
        tutorial: this.$t("教材"),
        plan: this.$t("教案"),
      };
      return typeNames[type] || type;
    },
    getTime(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString();
    },
    checkBackgroundImageLoaded(imageUrl, materials) {
      try {
        if (imageUrl && materials) {
          //某個有問題資料庫的圖片
          if (!imageUrl.includes("292A56BE-66C3-4A8D-A812-AE266C30AEFB.jpg")) {
            const tempImg = new Image();
            tempImg.src = imageUrl;
            tempImg.onload = () => {
              if (materials?.isLoadingNow) materials.isLoadingNow = false;
              // console.log('背景图像已加载完成。');
            };
            tempImg.onerror = (img) => {
              console.log(img);
              console.log("圖片載入失敗");
            };
          } else {
            // console.log(materials,'有問題的圖片')
          }
        }
      } catch (e) {
        console.log("圖片載入失敗");
      }
    },
    checkImgStyle(materials) {
      // console.log(materials.imgUrl.slice(0, 4) == "/img");
      return (
        materials.imgUrl.slice(0, 4) == "/img" ||
        materials.imgUrl.slice(0, 5) == "data:"
      );
    },

    getInnerImgUrl(materials) {
      try {
        if (materials.imgBlobUrl && this.blobsas) {
          if (materials.imgBlobUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials?.imgBlobUrl + "?" + this.blobsas}`
            );
            return 'url("' + materials.imgBlobUrl + "?" + this.blobsas + '")';
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.uploader.id +
              "/" +
              materials.id +
              "/" +
              materials.imgBlobUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`);
            return 'url("' + finalUrl + '")';
          }
        } else if (!materials?.imgBlobUrl) {
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getImgListItemUrl(materials) {
      // console.log(materials);
      try {
        if (materials?.imgBlobUrl && this.blobsas) {
          if (materials?.imgBlobUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials?.imgBlobUrl + "?" + this.blobsas}`,
              materials
            );
            return 'url("' + materials.imgBlobUrl + "?" + this.blobsas + '")';
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.uploader.id +
              "/" +
              materials.id +
              "/" +
              materials.imgBlobUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`, materials);
            return 'url("' + finalUrl + '")';
          }
        } else if (!materials?.imgBlobUrl) {
          materials.isLoadingNow = false;
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getImgUrl(materials) {
      // console.log(materials.imgUrl.slice(0, 5), "aass");
      try {
        if (
          materials.imgUrl &&
          this.blobsas &&
          materials.imgUrl.slice(0, 4) != "/img" &&
          materials.imgUrl.slice(0, 5) != "data:"
        ) {
          if (materials.imgUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials?.imgUrl + "?" + this.blobsas}`
            );
            return 'url("' + materials.imgUrl + "?" + this.blobsas + '")';
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.packCreator.id +
              "/" +
              "packImgs" +
              "/" +
              materials.imgUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`);
            return 'url("' + finalUrl + '")';
          }
        } else if (materials.imgUrl.slice(0, 4) == "/img") {
          materials.isLoadingNow = false;
          return `url("https://${location.host}${materials.imgUrl}")`;
        } else if (materials.imgUrl.slice(0, 5) == "data:") {
          materials.isLoadingNow = false;
          return `url("${materials.imgUrl}")`;
        } else if (!materials?.imgUrl) {
          materials.isLoadingNow = false;
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less">
.pack-review-item {
  @apply border-b border-gray-300 py-3;
 
  .item-num {
    font-size: 12px;
    padding: 10px;
  }
  .item-left {
    flex: 1;
    .item-img {
      height: 100px;
      max-width: 150px;
      display: block;
      background-size: cover;
      background-position: center !important;
      background-repeat: no-repeat !important;
      border-bottom: 1px solid rgba(62, 43, 43, 0.3);
    }
    .img-compose {
      @apply grid rounded-t-lg cursor-pointer overflow-hidden;
      height: 100px;
      max-width: 150px;

      .loaded {
        @apply animate-pulse;
        background: none !important;
        @apply bg-gray-500 !important;
      }
      .img-puzze {
        background-size: cover;
        background-position: center !important;
        background-repeat: no-repeat !important;
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
      }
    }
    .badge-group {
      @apply my-2;
      .badge {
        @apply mr-1  cursor-pointer rounded-full bg-yellow-400 px-2 py-1 text-sm font-semibold text-black;
      }
    }
  }

  .tag-group {
    @apply mt-1 text-xs font-bold text-sky-500;
    .tag {
      @apply mx-1;
    }
  }
  .item-content {
    flex: 2;
    width: 60%;
    padding: 10px;
    padding-top: 0px;
    .uploader {
      @apply font-bold text-sky-500;
      text-decoration: underline;
    }
    .main-type {
      @apply mb-1 inline-block rounded-sm bg-purple-600 p-1  text-xs font-bold text-white;
    }
  }
  .right-btn-group {
    position: relative;
    * {
      display: inline-block;
      text-align: right;
    }
    .approve-btn,
    .cancel-btn {
      @apply -mr-2;
    }

    .icon-wrap {
      text-align: center;
      position: relative;
      padding-right: 5px;
    }
    .check-icon {
      @apply text-green-600;
    }
    .check-not-icon {
      @apply text-transparent;
    }
  }
  .arrow-btn {
    .arrow-icon {
      position: absolute;
      right: 2px;
      bottom:-12px;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .arrow-icon-up{
    bottom:-12px !important;
    transition: 1s !important;
    transform: scaleY(-1) !important;
  }
  .inner-card-review-item {
    display: flex;
    padding: 30px;
    @apply bg-slate-200;
    border-top: 1px solid rgba(128, 128, 128, 0.2);
    .item-num{
        background-color: #fff;
        display: inline-block;
        height: 30px;
        width:30px;
        line-height: 14px;
        text-align: center;
        border-radius: 50%;
        margin-right: 10px;
    }
    .link {
      @apply text-sky-600 underline  !important;
    }
  }
}
</style>