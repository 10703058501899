/*eslint-disable*/
import mdTestText from "./mdTestText";
//處理markdown轉換套件
const remark = require("remark");
const remarkParse = require("remark-parse");


export default {
  removeMarkdownFences(mdText) {  //移除字串中包含 ``` 的行
    return mdText.replace(/^.*```.*$/gm, '').trim();
  },
  fixHeading(mdText) {
    //修理井字號 第一次出現的要是##
    let lines = mdText.split("\n");
    let adjustedLines = [];
    let foundFirstLevelHeading = false;
    let noTitle = true;

    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];

      if (line.startsWith("# ") && !foundFirstLevelHeading) {
        foundFirstLevelHeading = true;
        noTitle = false;
      } else if (foundFirstLevelHeading && /^#{3,}/.test(line)) {
        line = line.replace(/^#{3,}/, "##");
        foundFirstLevelHeading = false; // Reset after the first replacement
      } else if (line.startsWith("## ")) {
        foundFirstLevelHeading = false;
      }

      adjustedLines.push(line);
    }

    //如果都沒有標題，把第一個-開頭的標題換成#
    if (noTitle) {
      for (let i = 0; i < adjustedLines.length; i++) {
        if (adjustedLines[i].startsWith("- ")) {
          adjustedLines[i] = adjustedLines[i].replace("- ", "# ");
          break;
        }
      }
    }

    return adjustedLines.join("\n");
  },
  fixNumToList(mdText) {
    //去掉數字.後面的空格
    // 去掉數字.後面的空格 並把數字開頭項目加入項目符號 -
    let lines = mdText.split("\n");
    let adjustedLines = lines.map((line) => {
      // 保留原始縮排
      let leadingSpaces = line.match(/^\s*/)[0];
      let trimmedLine = line.trim();

      // 匹配 "數字. " 並去掉空格
      trimmedLine = trimmedLine.replace(/(\d+)\. /g, "$1.");

      // 把數字開頭的項目加入項目符號 -
      if (/^\d+\./.test(trimmedLine)) {
        trimmedLine = "- " + trimmedLine;
      }

      return leadingSpaces + trimmedLine;
    });

    return adjustedLines.join("\n");
  },
  removeEmptyLinesInLists(markdownText) {
    //移除##下連續兩個子項中間的空行，避免空行後形成新列表 ，詳如測試案例3
    const lines = markdownText.split("\n");
    let result = [];

    let insideList = false;
    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];

      if (line.trim().startsWith("-") || line.trim().startsWith("*") || line.trim().startsWith("1.")) {
        // 如果以列表标记开头，则将其视为列表项
        if (!insideList) {
          // 如果不在列表中，则开始新的列表
          insideList = true;
          result.push(line);
        } else {
          // 如果在列表中，追加到上一个列表项的末尾（去除空行）
          result[result.length - 1] += "\n" + line;
        }
      } else if (line.trim() === "" && insideList) {
        // 如果是空行且在列表内部，跳过空行
        continue;
      } else {
        // 非列表项内容，直接添加到结果中
        result.push(line);
        insideList = false; // 离开列表
      }
    }

    return result.join("\n");
  },
  fixIndent(input) {
    //##下面同一級--調整為子項
    let lines = input.trim().split("\n");

    // 初始化變數
    let result = [];
    let insideSection = false; // 標記是否在 ## 標題節點內部
    let indentStack = [0]; // 追蹤當前縮進級別的堆疊

    // 遍歷每一行
    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];

      if (line.startsWith("##")) {
        // 處理 ## 標題節點
        result.push(line);
        insideSection = true; // 進入這個節點內部
      } else if (insideSection && (line.trim().startsWith("-") || line.trim().startsWith("*"))) {
        // 處理 ## 標題節點下的列表項目
        let currentIndentLevel = line.search(/\S/);
        if (currentIndentLevel > indentStack[indentStack.length - 1]) {
          // 如果當前縮進級別大於堆疊頂元素，說明是子項
          indentStack.push(currentIndentLevel);
        } else {
          // 否則，調整堆疊直到找到正確的縮進級別
          while (currentIndentLevel < indentStack[indentStack.length - 1]) {
            indentStack.pop();
          }
        }
        result.push(" ".repeat(indentStack.length * 2) + line.trim());
      } else if (insideSection && line.trim() === "") {
        // 遇到空行，停止節點內部的處理
        result.push(line);
        insideSection = false;
      } else {
        // 預設情況下直接添加該行
        result.push(line);
      }
    }
    // 將處理後的文本行合併並返回
    return result.join("\n");
  },
  preConvertMDHeadingToList(mdText) {
    const lines = mdText.split("\n");
    let result = [];
    let currentIndent = 0;
    let isFirstHeading = true;

    lines.forEach((line, index) => {
      if (line.trim() === "") {
        result.push("");
        return;
      }

      const headingMatch = line.match(/^(#+)\s*(.*)/);
      const listMatch = line.match(/^(\s*)-\s*(.*)/);

      if (headingMatch) {
        const level = headingMatch[1].length;
        const content = headingMatch[2];

        if (isFirstHeading) {
          result.push("# " + content); // Keep the first heading line as '#' style
          isFirstHeading = false;
        } else {
          if (level > 1) {
            currentIndent = level - 2; // Adjust indent based on heading level
          } else {
            currentIndent = 0; // Reset indent for higher level headings
          }
          result.push({ indent: currentIndent, text: content });
        }
      } else if (listMatch) {
        const indent = listMatch[1].length / 2; // Assuming each level of list is indented by 2 spaces
        const content = listMatch[2];
        currentIndent = indent;
        result.push({ indent: currentIndent, text: content });
      } else {
        result.push({ indent: currentIndent, text: line.trim() });
      }
    });

    let output = "";
    result.forEach((item) => {
      if (item === "") {
        output += "\n";
      } else if (typeof item === "string") {
        output += item + "\n";
      } else {
        output += "  ".repeat(item.indent) + "- " + item.text + "\n";
      }
    });

    return output.trim();
  },

  removeIndentation(mdtext) {
    const lines = mdtext.split("\n");
    // 找到最小的非零缩进级别
    const minIndent = Math.min(...lines.filter((line) => line.trim().length > 0).map((line) => line.match(/^\s*/)[0].length));

    // 移除每行的最小缩进
    const unindentedLines = lines.map((line) => line.slice(minIndent));
    return unindentedLines.join("\n");
  },
  mdToJsMind(mdtext, vm) {
    let finalmdtext = "";
    const lineColorArr = ["red", "orange", "green", "#0000ff", "brown", "purple", "#0000ff", "purple"];
    const nodeColorArr = ["rgb(255, 191, 215)", "rgb(255, 238, 139)", "rgb(211, 255, 139)", "rgb(139, 243, 255)", "rgb(255, 191, 215)", "rgb(255, 238, 139)", "rgb(211, 255, 139)", "rgb(139, 243, 255)"];
    let branchIndex = 0;

    try {
      // 移除缩进
      finalmdtext = this.testMDFormatByJest(mdtext);

      // 解析 Markdown
      const ast = remark()
        .use(remarkParse)
        .parse(finalmdtext);

      const mindData = {
        meta: {
          name: "",
          author: "",
          version: "",
        },
        format: "node_array",
        data: [],
      };

      function addNode(parentId, topic, isRoot = false, isFirstLevel = false, parentLineColor = null, parentBgColor = null, index = 0, childrenLength = 0) {
        const nodeId = isRoot ? "root" : vm.genUUID();
        let finalNote = "";
        if (topic.length > 20 && topic.length < 100) {
          finalNote = topic;
        } else if (topic >= 100) {
          finalNote = topic.slice(0, 97) + "...";
        } else if (topic <= 20) {
          finalNote = "";
        }

        const node = {
          id: nodeId,
          parentid: parentId ? parentId : "",
          isroot: isRoot ? true : false,
          topic: `<p>${topic.length > 20 ? topic.slice(0, 17) + "..." : topic}</p>`,
          direction: childrenLength!==0 && index < childrenLength/2 ? "right" : "left",
          nodeText: topic,
          nodeImg: "",
          nodeLink: "",
          note: finalNote,
        };

        if (!isRoot) {
          if (isFirstLevel) {
            const lineColorIndex = branchIndex % lineColorArr.length; //從配好的色組挑選
            const nodeColorIndex = branchIndex % nodeColorArr.length;
            node.lineColor = lineColorArr[lineColorIndex];
            node["background-color"] = nodeColorArr[nodeColorIndex];
            branchIndex++;
          } else {
            node.lineColor = parentLineColor;
            node["background-color"] = parentBgColor;
          }
        }
        if(isFirstLevel){
          node.direction=="right"? mindData.data.push(node):mindData.data.unshift(node);
        }else{
          mindData.data.push(node);
        }
       
        return nodeId;
      }

      function traverseAst(parentId, children, level = 1, parentLineColor = null, parentBgColor = null) {
        children.forEach((child, index) => {
          if (child.type === "listItem" && child.children && child.children.length > 0) {
            let topicNode = child.children.find((n) => n.type === "paragraph");
            if (topicNode && topicNode.children && topicNode.children.length > 0) {
              let topic = topicNode.children
                .map((n) => {
                  return n.type === "strong" ? n.children.map((n) => n.value) : n.value;
                })
                .join("");
              const isFirstLevel = level === 1;
              const nodeId = addNode(parentId, topic, false, isFirstLevel, parentLineColor, parentBgColor, index, children.length);
              

              const currentLineColor = mindData.data.find((node) => node.id === nodeId).lineColor;
              const currentBgColor = mindData.data.find((node) => node.id === nodeId)["background-color"];

              let sublist = child.children.find((n) => n.type === "list");
              if (sublist) {
                traverseAst(nodeId, sublist.children, level + 1, currentLineColor, currentBgColor);
              }
            }
          }
        });
      }

      const rootNode = ast.children.find((n) => n.type === "heading" && n.depth === 1);
      if (rootNode && rootNode.children && rootNode.children.length > 0) {
        const rootTopic = rootNode.children.map((n) => n.value).join("");
        addNode(null, rootTopic, true);
        const list = ast.children.find((n) => n.type === "list");
        if (list) {
          traverseAst("root", list.children);
        }
      }
      if (mindData?.data.length === 0) {
        vm.$Message.info(vm.$t("webirs.mindmap.markDownNoData"));
        return "";
      } else {
        return mindData;
      }
    } catch (error) {
      vm.$Message.warning(vm.$t("webirs.mindmap.markDownErr"));
      return null;
    }
  },
  jsMindToMd(jmNode, vm) {
    function traverse(node, depth = 0) {
      let mdText = "";
      if (depth === 0) {
        mdText += `# ${node.nodeText}\n\n`;
      } else {
        mdText += node.nodeText ? `${"  ".repeat(depth - 1)}- ${node.nodeText}\n` : "";
      }

      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          mdText += traverse(child, depth + 1);
        });
      }
      return mdText;
    }

    return traverse(jmNode.data);
  },
  testMDFormatByJest(currentMdText) {
    let step0 = this.removeMarkdownFences(currentMdText)
    let step1 = this.fixHeading(step0);
    let step2 = this.fixNumToList(step1);
    let step3 = this.removeEmptyLinesInLists(step2);
    let step4 = this.fixIndent(step3);
    let step5 = this.preConvertMDHeadingToList(step4);
    let finalResult = this.removeIndentation(step5);
    return finalResult;
  },
  //測試案例與輸出
  testMDFormat() {
    let currentMdText = mdTestText.mdtest11;
    let result = this.testMDFormatByJest(currentMdText);
    console.log(result);
    return currentMdText;
  },
};
