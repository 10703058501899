import mindmap from "./mindmap";
import konvaCanvas from "./konvaCanvas";
export default {
  konvaCanvas,
  mindmap,
  "header": {
    "digitalLearningOffice": "{region} Government Education Bureau",
    "authorizationPlatform": "HiTeach Authorization and Activity Platform",
    "cityInformation": "{region} City Information",
    "softwareIntroduction": "HiTeach Software Introduction",
    "learningResources": "Learning Resources",
    "latestNews": "Latest News",
    "contactUs": "Contact Us",
    "login": "Login"
  },
  "eventPage": {
    applyCheck:'Apply/Check',
    authorization:'Authorization',
    entry1:'Unified County/City HiTeach Procurement Authorization Entry',
    getAuthorization:'Enter Here to Apply for Authorization',
    check:'Register/Check',
    event:'Event',
    competition:'County/City X HiTeach Competition Event',
    registration:'Enter Here to Apply for Registration',
    eventPreparation: "Event Preparation in Progress",
    notification: "Notification"
  },
  "AccountInfo": {
    "availableProducts": "Available Products for Application",
    "noAvailableItems": "No Items Available for Application",
    "apply": "Apply",
    "authorizationNote": "※If HiTeach dates overlap, the overlapping time will be added to the end!",
    "myAuthorizations": "My Authorizations",
    "customerSupport": "Customer Support",
    "productName": "Product Name",
    "usageTime": "Usage Time",
    "state": "State",
    additionalItem: 'Additional Item',
    "confirmApplication": "Are you sure you want to apply?",
    "inquiry": "Inquiry",
    "yes": "Yes",
    teamModelAccount: 'TeamModel Account ID',
    schoolNotInList: 'School is not on the unified procurement list',
    schoolAuthorizationFull: 'School authorization is full',
    clickToLearnHiTeach: 'Not familiar with HiTeach yet?, Click here to learn!',
    notApplied: 'Not Applied',
    appliedNotAuthorized: 'Application has been accepted'
  },
  "AccountLogin":{
    sendVerificationCode: 'Send Verification Code',
    resend: 'Resend',
    haveTeamModelAccount: 'I have a, TeamModel account',
    noTeamModelAccount: "I don't have a, TeamModel account",
    chooseMethodToProceed: 'Please choose any method to proceed to the next step',
    bindExistingTeamModelAccount: 'Go to the page to bind an existing TeamModel account',
    registerAndBindNewAccount: 'Register a new TeamModel account and bind with Education Cloud account',
    completeWithin30Minutes: 'Please complete this stage within {time} minutes. If expired, you will need to log in again',
    back: 'Back',
    chooseBindingMethod: 'Please choose , Any method ,to bind your existing TeamModel account',
    qrCodeBinding: 'QR-Code',
    qrCodeExpired: 'Your QR Code has expired, please click the update button below',
    update: 'Update',
    useHiTA5AppToScan: 'Please use HiTA5 APP to scan the code',
    emailPhoneUserID: 'TeamModel Account',
    password: 'Password',
    bindNow: 'Bind Now',
    sms: 'SMS',
    phoneNumber: 'Phone Number',
    verificationCode: 'Verification Code',
    confirmVerificationCode: 'Confirm Verification Code',
    sendingSMS: 'Sending SMS...',
    registerNewAccountPrompt: 'Would you like to register a new TeamModel account?',
    confirm: 'Confirm',
    cancel: 'Cancel',
    bindingSuccessful: 'Binding Successful',
    qrCodeLogin: 'QRCode Login',
    incorrectAccountOrPassword: 'Incorrect account or password',
    enterAccountAndPassword: 'Please enter account and password',
    incorrectPhoneNumberFormat: 'Incorrect phone number format',
    enterPhoneNumber: 'Please enter phone number',
    incorrectAccountOrVerificationCode: 'Incorrect account or verification code',
    incorrectVerificationCodeFormat: 'Incorrect verification code format',
    enterVerificationCode: 'Please enter verification code',
    verificationCodeSendFailed: 'Failed to send verification code',
    verificationCodeSent: 'Verification code sent',
    afterBindingLoginWithEduCloud: 'After binding, you can log in directly with your Education Cloud account next time!',
  }
};
