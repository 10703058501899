export default {
  新增: "新增节点",
  編輯: "编辑节点",
  輸入文字: "输入文字",
  確定: "确定",
  取消: "取消",
  請先選取節點: "请先选取节点!",
  不可刪除有子項目的節點: "不可刪除有子項目的節點!",
  新增失敗: "新增失败，父节点已被删除!",
  編輯失敗: "编辑失败，所选节点已被删除!",
  加入根節點: "加入根节点",
  左邊: "左边",
  右邊: "右边",
  已是最上面: "已是最上面!",
  已是最下面: "已是最下面!",
  圖片: "图片",
  圖片過大提示字1: "所选图片过大(请小于",
  圖片過大提示字2: "MB)，请重新选择！",
  連結: "连结",
  連結網址: "连结网址",
  連結名稱: "连结名称",
  輸入URL: "输入URL...",
  輸入連結名稱: "输入连结名称...",
  註解: "注解",
  進階設定: "进阶设置",
  線條顏色: "线条颜色",
  節點顏色: "节点颜色",
  preEditHint:'注意：目前的节点之前编辑者是',
  confirmUpdate:'，确定要修改吗?',
  menu: {
    新增: "新增",
    刪除: "删除",
    上移: "上移",
    下移: "下移",
    編輯: "编辑",
    複製: "复制",
    檢視圖片: "检视图片",
    最後更新: "最后更新",
  },
  imgEditor: {
    詢問離開動作: "询问离开动作?",
    保存圖片: "保存图片",
    捨棄變更: "舍弃变更",
    取消: "取消",
  },
};
