<template>
  <div class="reportmsg-form">
    <div class="main-form">
      <div class="text-gray-900 font-medium text-lg text-center">
        {{ $t("reportmsg-form.檢舉教材表單") }}
      </div>
      <div class="scroll-wrap">
        <div class="upload-item">
          <p class="title">{{ $t("reportmsg-form.檢舉教材名稱") }}：{{ store.currentEditItem.title }}</p>
        </div>
        <div class="upload-item">
          <p class="title">{{ $t("reportmsg-form.檢舉原因") }}：</p>
          <SelectMenu
            :category="cateReason"
            :defaultSelect="$t('reportmsg-form.錯誤或無法使用的檔案格式')"
            ref="reasonSelect"
          />
        </div>
        <div class="upload-item">
          <label>
            <p class="title">{{ $t("reportmsg-form.描述") }}：</p>
            <textarea
              :placeholder="$t('reportmsg-form.敘明原因提示字')"
              v-model="editNote"
            ></textarea>
          </label>
        </div>
      </div>

      <div class="ml-auto mt-5 normal p-4 pt-0">
        <button class="left-btn" v-show="editNote!=''" @click="confirm()">{{ $t("reportmsg-form.送出") }}</button>
        <button class="right-btn" @click="close()">{{ $t("reportmsg-form.取消") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "../stores/mainStore";
import SelectMenu from "@/components/SelectMenu.vue";
export default {
  name: "reportmsg-form",
  components: {
    SelectMenu,
  },
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      editNote: "",
      cateReason: [
      this.$t("reportmsg-form.錯誤或無法使用的檔案格式"),
      this.$t("reportmsg-form.有害或誤導性的內容"),
      this.$t("reportmsg-form.侵權內容"),
      this.$t("reportmsg-form.其他原因")
      ],
    };
  },
  methods: {
    confirm() {
      let localeProfile = JSON.parse(localStorage.getItem("profile"));
      if (this.$refs.reasonSelect) {
        // console.log(
        //   this.cateReason.indexOf(this.$refs.reasonSelect.currentSelect)
        // );
        let createReportData = {
          materialId: this.store.currentEditItem.id,
          materialType:this.store.currentEditItem.type,
          reasonCode: this.cateReason.indexOf(
            this.$refs.reasonSelect.currentSelect
          ),
          applicant: {
            id: localeProfile.id,
            name: localeProfile.name,
          },
          note: this.editNote,
        };
        console.log(createReportData);
        this.$emit("confirm", createReportData);
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less">
.reportmsg-form {
  z-index: 999;
  @apply flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800/50;

  .main-form {
    @apply bg-white  rounded-lg relative  flex flex-col pt-4;
    min-width: 40%;
    max-height: 90vh;
    .scroll-wrap {
      @apply px-8 py-0;
      width: 100%;
      height: 100%;
      min-height: 260px;
      overflow: auto;
      .upload-item {
        @apply my-3;
        input[type="text"],
        textarea {
          @apply ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6 w-full py-2 px-5 rounded-md mt-2;
        }
        textarea {
          min-height: 120px;
          max-height: 180px;
          overflow: auto;
        }
      }
      .title {
        @apply font-bold;
      }
    }
    .normal {
      .left-btn {
        @apply text-white font-bold border py-1 px-4 rounded mr-2 bg-sky-500 border-sky-500;

        &:hover {
          @apply bg-sky-600;
        }
      }

      .right-btn {
        @apply bg-transparent text-gray-700 font-semibold py-1 px-4 border border-gray-500 rounded;

        &:hover {
          @apply bg-sky-100;
        }
      }
    }
  }
}
</style>