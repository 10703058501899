<template>
  <div class="text-picker">
    <div class="slider_box">
      <p class="menu-title">
        {{ $t('konvaCanvas.字體大小') }}：<b class="textsize">{{ $parent.textSize }}</b>
      </p>
      <Slider v-model="$parent.textSize" :step="1" @on-change="size" :min="20" :max="72"></Slider>
    </div>
    <div class="ColorPicker_box ">
      <p class="menu-title" style="padding-left:5px">{{ $t('konvaCanvas.顏色') }}</p>

      <div class="q-gutter-sm" style="padding:10px 0 2px 0;">
        <div class="color-btn" :class="{ 'select-color': $parent.textColor == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index < 5" @click="chioceColor(item)" />
      </div>
      <div class="q-gutter-sm" style="padding:2px 0;">
        <div class="color-btn" :class="{ 'select-color':  $parent.textColor == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index >= 5" @click="chioceColor(item)" />
      </div>
      <!--<q-color v-model="hex" no-header no-footer default-view="palette" class="my-picker" @change="ss" @input="cc" :palette="['#2d8cf0', '#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'textSetting',

  data() {
    return {
      button4: 'brush',
      hex: '#FF00FF',
      standard: 2,

      colorArr: [
        { val: 'red', color: 'red' },
        { val: 'orange', color: 'orange' },
        { val: 'yellow', color: 'yellow' },
        { val: 'cyan', color: 'cyan' },
        { val: '#e0e0e0', color: '#e0e0e0' },
        { val: 'brown', color: 'brown' },
        { val: 'purple', color: 'purple' },
        { val: 'green', color: 'green' },
        { val: '#0000ff', color: '#0000ff' },
        { val: 'black', color: 'black' }
      ]
    }
  },

  methods: {
    size: function(e) {
      this.$parent.textSize = e
      
    },
    chioceColor: function(item) {
      this.$parent.textColor = item.val
    }
  }
}
</script>

<style lang="less">
@import '../../assets/color.less';
.textSetting-tool {
  background-color: #fff;
  position: absolute;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1005;
  // transition: 0.1s;
  .text-picker {
    .slider_box {
      width: 100%;
      padding: 5px;

      .textsize {
        color: @light-color;
      }
      .ivu-slider-wrap {
        height: 12px !important;
        border-radius: 10px !important;
      }
      .ivu-slider-bar {
        height: 12px !important;
        border-radius: 10px !important;
        background: @light-color !important;
      }
      .ivu-slider-button {
        width: 16px;
        height: 16px;
        position: relative;
        border: 2px solid @light-color;
        top: 2px;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .color-btn {
      height: 36px !important;
      width: 36px !important;
      border: 2px solid white;
      margin: 8px;
      display: inline-block;
      box-shadow: 0px 0px 2px gray;
      border-radius: 50%;
      cursor: pointer;
    }
    .select-color {
      border: 0px solid rgba(223, 223, 223, 0);
      box-shadow: 0px 0px 2px gray;
    }
  }
}
</style>
