<template>
  <div class="home-page">    
    <div class="between backDiv">
      <div class="">
        <span class="cursorPointer" @click="goToUrl('Activity_Info')" >活動主頁 </span>
        <span>/ 參賽數據</span>
      </div>
      <div @click="goToUrl('Activity_Info')" class="cursorPointer PageBack oneLine">
        <div class="div-centerY2  backTxt">
          返回
        </div>
        <img class="backImg" src="../../assets/img/bean/bean_02.png" alt="">
      </div>
    </div>
    
    <div class="div-center">
      <div>      
        <div class="titleDiv oneLine">
          <img class="iconImg " src="../../assets/img/traffic_ballon.png" alt="">
          2024 全國閱讀好小子學藝競賽
          <span class="stressColor">活動公告</span>
        </div>
        <div class="oneLine cascaderDiv">
          <div class="cascader1">
            報名組別：
            <el-cascader v-model="signUpGroup" :options="signUpGroupOptions" class="selectCss" @change="signUpGroupFun" />
          </div>
          <div class="cascader2">
            顯示範圍：
            <el-cascader v-model="showData" :options="showDataOptions" style="width: 300px;" class="selectCss" @change="showDataFun" />
          </div>
        </div>
        <div class="between infoArea">
          <div class="infoNow stressColor" style="visibility:hidden;">
            <span class="rank">活動名次：2</span>
            全體總均分：86分
          </div>
          <div class="joinButtonArea" >
            <div :class="'joinButton  whiteColor div-center div-centerY '+(PageStatus===0 ? 'onClick' :'') "  >
              <div class="txt div-center">
                匯出
                <el-icon class="txt div-centerY2"><ArrowRightBold /></el-icon>
              </div> 
            </div>
          </div>
        </div>
        <div class="tableDiv div-centerY" v-for="(data,i) in FractionData" :key="i">
          <!-- <img class="buttonImg " v-if="i>0" :src="srcUrl(i)" alt=""> -->
          <div class="title oneLine">
            <img class="titleIconImg " :src="srcUrl(i)" alt="">
            {{data.title}}
          </div>
          <div class="tableCss">
            <el-table :data="data.data">
              <el-table-column prop="group" label="組別" width="100" />
              <el-table-column prop="rank" label="名次" width="100" />
              <el-table-column prop="class" label="班級"  width="100" />
              <el-table-column prop="name" label="姓名"  width="100" />
              <el-table-column prop="absenceRate" label="缺考率"  width="100" />
              <el-table-column prop="passRate" label="及格率"  width="100" />
            </el-table>
            <!-- <el-auto-resizer>
              <template #default="{ height, width }">
                <el-table-v2 
                  :columns="columns"
                  :data="data.data"
                  :width="width"
                  :height="height"
                  @column-sort="onSort"
                  fixed
                />
              </template>
            </el-auto-resizer> -->
          </div>
          
        </div>
      </div>
      <!-- <img class="buttonImg " v-if="i>0" src="../../assets/img/bean/bean_02.png" alt=""> -->
    </div>
    <dir class="footerDiv div-center">
      指導單位：新竹縣政府教育局<br>
      主辦單位：臺灣科技領導與教學科技發展協會、臺灣讀寫教學研究學會、中華教育創新發展學會<br>
      支持單位：全球醍摩豆智慧教育研究院、小貓頭鷹智慧聯盟校<br>
    </dir>
    <!-- <div class="div-center">
      <img class="buttonImg " src="../../assets/img/bean/bean_08.png" alt="">
    </div> -->
  </div>
</template>

<script setup>
import { ref,reactive } from 'vue'//inject,reactive,ref, h
import { useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
// import { ElMessageBox } from 'element-plus';
// import profile from "../api/profile";
// import {Base64} from 'js-base64'

useHead({
  title: 'Your Page Title - Activity_Data',
  meta: [
    { name: 'description', content: 'Your page description - Activity_Data' },
    { property: 'og:title', content: 'Your OG Title - Activity_Data' },
    { property: 'og:description', content: 'Your OG Description - Activity_Data' },
    // { property: 'og:image', content: 'https://example.com/your-image.jpg' },
    // { property: 'og:url', content: 'https://example.com/your-page' },
  ],
})

let signUpGroup = ref([]);//加入班級資訊_報名組別
let showData = ref([]);//選擇顯示範圍


//加入班級的相關資訊↓↓

const signUpGroupOptions = reactive([//加入班級頁面的data
  {
    value: 'Group1',
    label: '低年級組',    
  },
  {
    value: 'Group2',
    label: '中年級組',    
  },
  {
    value: 'Group3',
    label: '高年級組',    
  },
]);
const signUpGroupFun = (value) => {
  console.log(value)
}
//加入班級的相關資訊↑↑

//選擇顯示範圍↓↓
const showDataFun = (value) => {
  console.log(value)
}
const showDataOptions = reactive([//加入班級頁面的data
  {
    value: 'viewAll',
    label: '選擇全部',
  },
  {
    value: 'stageChoose',
    label: '階段選擇',
    children: [
      {
        value: 'stage1',
        label: '班級閱讀熱身賽',
        children: [
          {
            value: 'stage1_0',
            label: '選擇全部',
          },
          {
            value: 'stage1_1',
            label: '評量1',
          },
          {
            value: 'stage1_2',
            label: '評量2',
          },
          {
            value: 'stage1_3',
            label: '評量3',
          },
        ],
      },
      {
        value: 'stage2',
        label: '班級閱讀決賽',
        children: [
          {
            value: 'stage2_0',
            label: '選擇全部',
          },
          {
            value: 'stage2_1',
            label: '評量1',
          },
          {
            value: 'stage2_2',
            label: '評量2',
          },
        ],
      },
      {
        value: 'stage3',
        label: '評審閱卷',
      },
      {
        value: 'stage4',
        label: '班級代表挑戰賽',
        children: [
          {
            value: 'stage4_0',
            label: '選擇全部',
          },
          {
            value: 'stage4_1',
            label: '評量1',
          },
          {
            value: 'stage4_2',
            label: '評量2',
          },
          {
            value: 'stage4_3',
            label: '評量3',
          },
        ],
      },
    ],
  },
]);


//選擇顯示範圍↑↑

//表格資料
/*
const columns = ref([
  // { key: 'name', dataKey: 'name', title: '活動名稱',width:400,
  //   cellRenderer: ({ rowData }) => h(
  //     'div',
  //     { class: 'nameColor' }, // 添加 class
  //     rowData.name
  //   ),
  // },
  { key: 'group', dataKey: 'group', title: '組別', width: 100 },
  { key: 'rank', dataKey: 'rank', title: '名次', width: 100 },
  { key: 'class', dataKey: 'class', title: '班級', width: 100 },
  { key: 'name', dataKey: 'name', title: '姓名', width: 100 },
  { key: 'absenceRate', dataKey: 'absenceRate', title: '缺考率', width: 100 },
  { key: 'passRate', dataKey: 'passRate', title: '及格率', width: 100,
    // cellRenderer: ({ rowData }) => {
    //   const passRate = parseFloat(rowData.passRate);
    //   let className = '';
    //   if (passRate >= 90) {
    //     className = 'stressColor';
    //   } else if (passRate < 60) {
    //     className = 'lightColor';
    //   }
    //   return h('span', { class: className }, rowData.passRate);
    // } 
  },
  { key: 'avgScore', dataKey: 'avgScore', title: '總均分', width: 100 },
]);*/
let FractionData = reactive([
  {
    title:'班級閱讀熱身賽',
    data:[//表格資料
      { group: '國小組', rank: '第一名', class: '1年2班', name: '學生A', absenceRate: '0%', passRate: '100%', avgScore: '92.56' },
      { group: '國中組', rank: '第二名', class: '1年3班', name: '學生B', absenceRate: '2%', passRate: '87%', avgScore: '85.91' },
      { group: '高中組', rank: '第三名', class: '1年6班', name: '學生C', absenceRate: '5%', passRate: '55%', avgScore: '77.53' },
    ],
  },
  {
    title:'班級閱讀決賽',
    data:[//表格資料
      { group: '國小組', rank: '第一名', class: '1年2班', name: '學生A', absenceRate: '0%', passRate: '100%', avgScore: '92.56' },
      { group: '國中組', rank: '第二名', class: '1年3班', name: '學生B', absenceRate: '2%', passRate: '87%', avgScore: '85.91' },
      { group: '高中組', rank: '第三名', class: '1年6班', name: '學生C', absenceRate: '5%', passRate: '55%', avgScore: '77.53' },
    ]
  },
  {
    title:'班級閱讀決賽-評量1',
    data:[//表格資料
      { group: '國小組', rank: '第一名', class: '1年2班', name: '學生A', absenceRate: '0%', passRate: '100%', avgScore: '92.56' },
      { group: '國中組', rank: '第二名', class: '1年3班', name: '學生B', absenceRate: '2%', passRate: '87%', avgScore: '85.91' },
      { group: '高中組', rank: '第三名', class: '1年6班', name: '學生C', absenceRate: '5%', passRate: '55%', avgScore: '77.53' },
    ]
  }
]);

//src路徑
function srcUrl(index){
  index += 1 ;
  if(index>8)index = 0;
  return require(`../../assets/img/bean/bean_0${index}.png`);
}

//路由器
const routers = useRouter();
function goToUrl(){//url
  routers.push("/Activity_Info");
}


</script>

<style lang="less" scoped>
@fontColor :#424f55;//文字：深藍
@fontColor-light :#8da3a0 ;//文字：中藍
@stressColor :#f59f56;//強調色：橘色
@fontColor-gray1: #909399;
@fontColor-gray2: #b1b3b8;
@other-blue:#70a6b2;//其他輔助色：水藍
@background-gray:#eff4f7;//背景：淡灰(footer顏色)

.div-center{/*水平置中*/
  display: flex; 
  justify-content: center; 
  text-align:center;
  width:100%;
}
.div-centerY{ /*垂直置中*/
  display: flex;    
  align-items: center;
  &.use{
    flex-direction: column;  //有時可能需要在加這個屬性
  }
}
.div-centerY2{ /*垂直置中*/
  margin-top: auto;
  margin-bottom: auto;
}
.oneLine{ /*單行與折行顯示*/
  display: flex;
  flex-wrap: wrap; //元素折行呈現，元素空間夠的時候單行呈現，空間不夠則折行呈現
}
.between{ /*子元素分左右兩邊*/
  display: flex;
  justify-content: space-between;
}
.cursorPointer{
  cursor: pointer;//滑鼠變成小手
}

.stressColor{
  color: @stressColor;
}


.home-page {
  position: relative;//父元素設置為相對定位
  padding: unset !important;
  
  .PageBack{
    color:@fontColor-gray1;
  }
  .backDiv{
    padding: 10px 50px 20px;
    .backTxt{
      margin-right: 20px;
      font-weight:bold;
    }
    .backImg{
      width: 50px;
    }
  }  
  .buttonImg{
    width: 100px;
    // right: 50px;
    margin-bottom: 20px;
  }
  
  
  .cascaderDiv{
    width: 100%;
    @media (max-width: 770px) {
      .cascader2{
        margin-top: 20px;
      }
    }
    @media (min-width: 770px) {
      .cascader2{
        margin-left: 30px;
      }
    }
    @media (max-width: 1000px) {
      margin-top: 20px;
    }
  }
  .titleDiv{
    font-weight:bold;//粗體
    font-size: 30px;
    margin-bottom: 30px;
    text-align:left;
    margin-top: 20px;
    .iconImg{
      width: 50px;
      margin-right: 20px;
    }
  }
  .infoArea{
    margin-top: 10px;
    .infoNow{
      margin-bottom: 40px;
      font-weight:bold;//粗體
      text-align: left;
      .rank{
        margin-right: 20px;
      }
    }
    .joinButtonArea{
      .joinButton{
        align-items: center;
        border-radius: 50px;//圓角
        font-weight:bold;
        width: 100px;
        height: 30px;
        background: @fontColor-gray2;
        cursor: pointer;//滑鼠變成小手
      }
    }
  }
  .tableDiv{
    width: 100%;    
    .title{
      font-weight:bold;//粗體
      font-size: 25px;
      margin-bottom: 10px;
      margin-top: 10px;
      color: @other-blue;
      margin-right: auto;
      .titleIconImg{
        width: 60px;
        margin-right: 10px;
      }
    }
    .tableCss{
      // width: 100%;   
      // height: 40vh;
      margin-bottom: 100px;
    }
    .otherBlueColor{
      color:@other-blue;
    }
    .lightColor{
      color: @fontColor-light; 
    }
    ::v-deep .redColor{
      color:red;
    }
    .detail-button:hover {
      cursor: pointer; /* 滑鼠移入變成手形 */
      text-decoration: underline; /* 可選：增加下劃線效果 */
    }
  }
  .footerDiv{
    background-color: @background-gray;
    padding:20px 0 ;
    margin: unset;
  }
}
</style>
