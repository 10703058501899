import { defineStore } from 'pinia';

export const useMindMapStore = defineStore('mindMap', {
    state: () => ({
        mindMapBlobsasurl: "https://teammodeltest.blob.core.windows.net/mindmap/",
        mindMapBlobContainerClient:"", 
        mindMapBlobSas: "",
    }),
    actions: {
        setMindMapBlobContainerClient(blobClient) {
            this.mindMapBlobContainerClient = blobClient;
        },

        setMindMapBlobSas(sas) {
            this.mindMapBlobSas = sas;
        }
    }
})