<template>
  <div class="setting-page">
    設定頁
    <div class="mt-6 text-3xl font-bold">{{ $t("語系") }}</div>
    
  </div>
</template>

<script>

export default {
  name: "setting-page",
 
 
  mounted() {
    
   
  },
  methods: {
    
    // 開分頁顯示個人詳細資訊
  },
};
</script>

<style lang="less">
.setting-page {
  @apply p-16;

  
}
</style>
