<template>
  <div class="creategroup-form">
    <div class="main-form">
      <div class="text-gray-900 font-medium text-lg text-center">
        {{ "創建群組" }}
      </div>
      <div class="scroll-wrap">
        <div class="upload-item">
          <label>
            <p class="title">{{ "群組名" }}</p>
            <input type="text" v-model="editTitle" placeholder="必填" />
          </label>
        </div>
        <div class="upload-item">
          <label>
            <p class="title">{{ "群組介紹" }}</p>
            <textarea v-model="editIntro" placeholder="必填" />
          </label>
        </div>
        <div class="upload-item">
            <label>
              <input type="checkbox" v-model="isUploadToReview" />
              <span class="title">{{ "群組加入教材需管理員審查" }}</span>
            </label>
          </div>
        <div class="upload-item">
          <label class="title"
            >群組縮圖(必要)：
            <input
              id="inputfile"
              type="file"
              accept="image/gif, image/jpeg, image/png"
              @change="imagefileChangeHandler"
              required
            />
          </label>
          <img
            class="img-preview"
            v-show="editImgPreview"
            :src="editImgPreview"
            alt=""
          />
        </div>
      </div>
      <div class="ml-auto mt-5 normal p-4 pt-0">
        <button
          class="left-btn"
          v-if="editTitle && editIntro && editImgPreview"
          @click="confirm()"
        >
          {{ $t("確定") }}
        </button>
        <button class="right-btn" @click="close()">
          {{ $t("取消") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "creategroup-form",
  data() {
    return {
      editTitle: "",
      editIntro: "",
      editImgPreview: "",
      imageFile: "",
      isUploadToReview:false
    };
  },
  methods: {
    imagefileChangeHandler(e) {
      this.imageFile = Array.from(e.target.files);
      if (this.imageFile) {
        this.editImgPreview = URL.createObjectURL(this.imageFile[0]);
      }
    },
    confirm() {
      let data = {
        groupName: this.editTitle,
        groupIntro: this.editIntro,
        groupImgFile: this.imageFile,
        isUploadToReview:this.isUploadToReview
      };
      console.log();
      this.$emit("confirm", data);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less">
.creategroup-form {
  z-index: 999;
  @apply flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800/50;

  .main-form {
    min-width: 40%;
    max-height: 90vh;
    .scroll-wrap {
      @apply p-8 pb-20;
      width: 100%;
      max-height: 85vh;
      overflow: auto;
    }

    @apply bg-white  rounded-lg relative  flex flex-col pt-4;
    .img-preview {
      margin-top: 10px;
      max-height: 200px;
      max-width: 200px;
    }
    .upload-item {
      @apply my-3;
      input[type="text"],
      textarea {
        @apply ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6 w-full py-2 px-5 rounded-md mt-2;
      }
      textarea {
        max-height: 180px;
        overflow: auto;
      }
    }
    .title {
      @apply font-bold;
    }
  }

  .normal {
    .left-btn {
      @apply text-white font-bold border py-1 px-4 rounded mr-2 bg-sky-500 border-sky-500;

      &:hover {
        @apply bg-sky-600;
      }
    }

    .right-btn {
      @apply bg-transparent text-gray-700 font-semibold py-1 px-4 border border-gray-500 rounded;

      &:hover {
        @apply bg-sky-100;
      }
    }
  }
}
</style>