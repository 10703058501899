<template>
  <div class="testpage">
    <div class="grid-wrap">
      <div class="mock-area">
        <h1 class="title">測試首頁-Mock Uploader</h1>
        <!-- @click="counterStore.increment()" -->
        <div class="upload-item">
          <label class="title">
            標題*<br />
            <input type="text" v-model="title" required />
          </label>
        </div>
        <div class="upload-item">
          <label class="title">
            <p>介紹</p>
            <textarea v-model="intro"></textarea>
          </label>
        </div>
        <div class="upload-item">
          <label class="title"
            >教材*
            <input
              id="inputfile"
              type="file"
              @change="fileChangeHandler"
              required
            />
          </label>
        </div>
        <div class="upload-item">
          <label class="title"
            >教材縮圖*
            <input
              id="inputfile"
              type="file"
              @change="imagefileChangeHandler"
              required
            />
          </label>
        </div>

        <form class="title">
          類型*<br />
          <label class="title"
            ><input
              type="radio"
              name="location"
              v-model="pickedType"
              value="htex"
            />
            htex &nbsp;</label
          >
          <label class="title">
            <input
              type="radio"
              name="location"
              v-model="pickedType"
              value="audio"
            />
            聲音 &nbsp;</label
          >
          <label class="title"
            ><input
              type="radio"
              name="location"
              v-model="pickedType"
              value="video"
            />
            影片 &nbsp;
          </label>
          <label class="title">
            <input
              type="radio"
              name="location"
              v-model="pickedType"
              value="back"
            />
            背景圖 &nbsp;
          </label>
          <br />
          <label class="title">
            <input
              type="radio"
              name="location"
              v-model="pickedType"
              value="exam"
            />
            測驗券 &nbsp;
          </label>
          <label class="title">
            <input
              type="radio"
              name="location"
              v-model="pickedType"
              value="tutorial"
            />
            教材 &nbsp;
          </label>
          <label class="title">
            <input
              type="radio"
              name="location"
              v-model="pickedType"
              value="plan"
            />
            教案 &nbsp;
          </label>
        </form>

        <div class="upload-item">
          <p class="title">學校資訊- 實際非主動輸入，由HT自動給定</p>
          <br />
          <label class="title">
            －schoolId:<input type="text" v-model="schoolId" /></label
          ><br /><br />
          <label class="title">
            －schoolName:<input type="text" v-model="schoolName"
          /></label>
        </div>

        <div class="upload-item">
          <p class="title">群組(非必要：沒有選群組，就上傳到個人頁面)</p>
          <SelectMenu
            :category="cateGroup"
            :defaultSelect="'未指定'"
            ref="groupSelect"
          />
        </div>

        <div class="upload-item">
          <p class="title">年級*</p>
          <SelectMenu
            :category="cateGrade"
            :defaultSelect="'未指定'"
            ref="gradeSelect"
          />
        </div>

        <div class="upload-item">
          <p class="title">科目*</p>
          <SelectMenu
            :category="cateSubject"
            :defaultSelect="'未指定'"
            ref="subjectSelect"
          />
        </div>

        <div class="upload-item">
          <p class="title">教材語言*</p>
          <SelectMenu
            :category="cateLang"
            :defaultSelect="'未指定'"
            ref="langSelect"
          />
        </div>

        <div class="upload-item">
          <label class="title">
            自訂標籤(請用逗號分隔,ex:"二元一次方程式,三角函數")<br />
            <input type="text" v-model="category" />
          </label>
        </div>
        <h1 class="title">創用CC授權方式</h1>
        <form class="title">
          1.你這作品的改作，能否再次被分享？*<br />
          <label class="title"
            ><input
              type="radio"
              name="location"
              v-model="ccShareAgainType"
              :value="1"
            />
            是 &nbsp;</label
          >
          <label class="title">
            <input
              type="radio"
              name="location"
              v-model="ccShareAgainType"
              :value="2"
            />
            否 &nbsp;</label
          >
          <label class="title"
            ><input
              type="radio"
              name="location"
              v-model="ccShareAgainType"
              :value="3"
            />
            可以，只要別人以相同授權方式分享 &nbsp;
          </label>
        </form>
        <form class="title">
          2.是否允許你的著作被使用於商業目的？*<br />
          <label class="title"
            ><input
              type="radio"
              name="location"
              v-model="ccShareCommercial"
              :value="true"
            />
            是 &nbsp;</label
          >
          <label class="title">
            <input
              type="radio"
              name="location"
              v-model="ccShareCommercial"
              :value="false"
            />
            否 &nbsp;</label
          >
        </form>
        <!-- {{  counterStore.count }} -->
        <div
          class="upload-btn"
          v-show="validToUpload()"
          @click="uploadAndSave()"
        >
          上傳
        </div>
      </div>
      <div class="card-wrap mock-myupload">
        <Card
          v-for="item in materialsByMe"
          :key="item.id"
          :materials="item"
          :blobsas="blobsas"
          @viewContent="viewContent(item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";

import Card from "@/components/Card.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import { useCounterStore } from "@/stores/counter";
import { useMainStore } from "@/stores/mainStore";
import { jsFn } from "@/utils/jsFn";

const { BlobServiceClient } = require("@azure/storage-blob");

export default {
  //pinia 在optional api 中引入
  setup() {
    const counterStore = useCounterStore();
    const store = useMainStore();
    return { counterStore, store };
  },
  name: "test-page",
  data() {
    return {
      blobsasurl: "https://teammodeltest.blob.core.windows.net/ht-community/",
      blobServiceClient: "",
      containerClient: "",
      blobsas: "",
      materialFile: "",
      imageFile: "",
      title: "",
      intro: "",
      pickedType: "htex",
      profile: "",
      materialsByMe: "",
      cateGrade: [
        "未指定",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      cateSubject: ["未指定", "國文", "英文", "數學", "社會", "自然", "綜合"],
      cateLang: ["未指定", "tw", "cn", "en", "other"],
      category: "",
      schoolId: "",
      schoolName: "",
      cateGroup: [],
      groups: [],
      ccShareAgainType: 3,
      ccShareCommercial: false,
    };
  },
  components: {
    Card,
    SelectMenu,
  },
  async mounted() {
    let that = this;
    this.profile = await JSON.parse(localStorage.getItem("profile"));
    console.log("當前語系:" + that.$i18n.locale);
    this.store.setCurrentPage("testPage");
    jsFn.checkSessionAccessTokenReady().then(() => {
      this.getSas();
      setTimeout(() => {
        this.getMaterialsByMe();
        this.getGroupsIattend();
      }, 1000);
    });
  },
  methods: {
    getGroupsIattend() {
      api.main
        .getGroupsIattend({ tmid: this.profile.id })
        .then((res) => {
          this.groups = res.data;
          this.groups.forEach((item) => {
            this.cateGroup.push({
              name: item.groupName,
              id: item.id,
            });
          });

          this.cateGroup.unshift("未指定");
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validToUpload() {
      return (
        this.materialFile && this.title && this.imageFile && this.imageFile
      );
    },
    async uploadAndSave() {
      let groupIndex=this.groups.findIndex(item=>item.id==this.$refs.groupSelect.currentSelect.id)
      let uuid = jsFn.getUUID();
      let materialFileInfo = {
        title: this.title,
        type: this.pickedType,
        uploader: this.profile,
        intro: this.intro,
        id: uuid,
        school: {
          id: this.schoolId,
          name: this.schoolName,
        },
        groupAllowShow:this.groups[groupIndex]?.isUploadToReview?!this.groups[groupIndex].isUploadToReview:true,
        grade:
          this.$refs.gradeSelect.currentSelect == "未指定"
            ? 0
            : Number(this.$refs.gradeSelect.currentSelect),
        subject:
          this.$refs.subjectSelect.currentSelect == "未指定"
            ? ""
            : this.$refs.subjectSelect.currentSelect,
        language:
          this.$refs.langSelect.currentSelect == "未指定"
            ? ""
            : this.$refs.langSelect.currentSelect,
        category: this.category.split(","),
        groupId:
          this.$refs.groupSelect.currentSelect == "未指定"
            ? ""
            : this.$refs.groupSelect.currentSelect.id,
        ccShareAgainType: this.ccShareAgainType,
        ccShareCommercial: this.ccShareCommercial,
      };
      // console.log(materialFileInfo);
      try {
        const materialRes = await this.uploadBlob(uuid, this.materialFile);
        console.log("上傳教材成功", materialRes + "?" + this.blobsas);
        materialFileInfo.blobUrl = materialRes;
        if (this.imageFile) {
          const imageRes = await this.uploadBlob(uuid, this.imageFile);
          console.log("上傳縮圖成功", imageRes + "?" + this.blobsas);
          materialFileInfo.imgBlobUrl = imageRes;
        }

        const writeDBRes = await api.main.createMaterial(materialFileInfo);
        console.log(writeDBRes);
      } catch (err) {
        console.log(err);
      }
    },
    getSas() {
      api.main.getSas().then((res) => {
        console.log(res, "sas");
        this.blobsas = res;
        this.store.setBlobSas(res);
        this.initblob();
      });
    },
    getMaterialsByMe() {
      api.main.getMaterialsByUserId({ tmid: this.profile.id }).then((res) => {
        console.log(res.data, "getMaterials");
        this.materialsByMe = res.data;
      });
    },
    uploadBlob(uuid, targetFile) {
      return new Promise((r, j) => {
        let targetname = `${this.profile.id}/${uuid}/` + targetFile[0].name;
        let blockBlobClient =
          this.containerClient.getBlockBlobClient(targetname);
        blockBlobClient.uploadBrowserData(targetFile[0]).then(
          () => {
            // let url = decodeURIComponent(res._response.request.url);
            // url = url.substring(0, url.lastIndexOf("?"));
            r(targetFile[0].name); //僅傳檔名即可由本地拼裝
          },
          (err) => {
            j(err);
          }
        );
      });
    },
    fileChangeHandler(e) {
      this.materialFile = Array.from(e.target.files);
    },
    imagefileChangeHandler(e) {
      this.imageFile = Array.from(e.target.files);
      console.log(this.imageFile);
    },

    initblob() {
      if (this.blobsasurl != "") {
        this.blobServiceClient = new BlobServiceClient(
          this.blobsasurl + "?" + this.blobsas
        );
        this.containerClient = this.blobServiceClient.getContainerClient("");
        console.log(this.containerClient, "containerClient");
        this.store.setBlobContainerClient(this.containerClient);
      }
    },
    viewContent(item) {
      this.store.viewContent(item);
    },
  },
};
</script>

<style lang="less">
.testpage {
  .grid-wrap {
    @apply grid grid-cols-1  md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2;
  }
  .mock-area {
    @apply col-span-1 md:col-span-1 m-10  rounded-md bg-slate-100 shadow-lg;
    overflow: auto;
    max-height: 85vh;
    @apply p-10;
    position: relative;
    .upload-item {
      @apply my-10;
    }
    .title {
      @apply font-bold;
    }

    .upload-btn {
      @apply mt-10 inline-block cursor-pointer rounded-md bg-sky-500 px-4 py-2 text-sm font-bold text-white;
      &:hover {
        @apply bg-sky-600;
      }
    }
  }
  .mock-myupload {
    @apply col-span-1 md:col-span-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-8 !important;
    max-height: calc(~"100vh - 48px");
    overflow: auto;
    @apply p-5;
  }
}
</style>
