<template>
  <div class="addImgBox" :class="{ 'no-mask': currentTab == 'tab2' }">
    <div class="addImgBox-card">
      <div class="close-btn" @click="closeAddImgBox()">
        <svg-icon icon-class="close" class="close-icon" />
      </div>

      <Tabs v-model="currentTab">
        <TabPane :label="$t('konvaCanvas.檔案圖片')" name="tab1">
          <label class="img_upload-btn" @click="addImgMode">
            <svg-icon class="img_upload-icon" iconClass="add_img" />
            <input @click="resetAddImageUploader()" ref="addImageUploader" type="file" accept="image/png, image/gif, image/x-png,image/jpeg" @change="addImgObj" />
          </label>
          <div class="img_upload-text">{{ $t('konvaCanvas.選取圖片作為物件') }}</div>
        </TabPane>
        <!--協作不提供學生換背景功能-->
        <!-- <TabPane :label="$t('konvaCanvas.背景設置')" name="tab2" >
          <div class="bgColors-wrap">
            <div @click="setBgColor(item)" class="bgColors-item" v-for="(item, index) in bgColors" :style="{ 'background-color': item }" :key="index + 'c'"></div>
            <input type="color" v-model="freecolor" />
          </div>

          <label class="img_upload-btn" @click="addImgMode">
            <svg-icon class="imgbgc-icon" iconClass="add_imgbgc" />
            <input @click="resetAddImageUploader()" ref="addImageBgcUploader" type="file" accept="image/png, image/gif, image/x-png,image/jpeg" @change="addImgBgc" />
          </label>
          <div class="img_upload-text">{{ $t('konvaCanvas.選取圖片作為背景') }}</div>
        </TabPane> -->
      </Tabs>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Konva from 'konva'
import SvgIcon from "../SvgIcon.vue";
import { useMindMapStore } from "@/stores/mindMapStore";
export default {
  name: 'AddImgBox',
  components: {SvgIcon},
  setup() {
    const mindMapStore = useMindMapStore()
    return { mindMapStore }
  },
  data() {
    return {
      currentTab: 'tab1',
      bgColors: ['#ffcdd2', '#BBDEFB', '#C8E6C9', '#FFF9C4', '#ffffff'],
      freecolor: '#C9C9C9'
    }
  },
  watch: {
    freecolor(value) {
      this.setBgColor(value)
    }
  },
  methods: {
    genUUID() {
            let d = Date.now()
            //Performance.now() 亞毫秒級的時間戳記
            if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
                d += performance.now()
            }
            return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                //xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
                let r = (d + Math.random() * 16) % 16 | 0
                d = Math.floor(d / 16)
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
            })
    },
    resetAddImageUploader() {
      this.$refs.addImageUploader.value = ''
      // this.$refs.addImageBgcUploader.value = '' //協作不提供學生換背景功能
    },
    addImgMode() {
      this.$parent.select()
    },
    closeAddImgBox() {
      this.$parent.showAddImgBox = false
    },
    getViewCenter() {
      // 获取舞台的宽度和高度
      let stage = this.$parent.stage
      let stageWidth = stage.width();
      let stageHeight = stage.height();

      // 获取舞台的偏移量
      let offsetX = stage.x();
      let offsetY = stage.y();
      let scale=stage.scaleX()

      // 计算舞台的中心点坐标
      let centerX = (stageWidth / 2 - offsetX) / scale;
      let centerY = (stageHeight / 2 - offsetY) / scale;
      return { x: centerX, y: centerY };
    },
    dataURLtoFile(dataurl, filename) {
            const arr = dataurl.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], filename, { type: mime })
    },
    //上傳本地所選檔案圖片
    async addImgObj(e) {
      let that = this

      let stage = this.$parent.stage
      let layer = this.$parent.layer

      let times = new Date().getTime()
      let fileName = e.target.files[0].name
      let compressFile

      let fileReader = new FileReader()
      fileReader.readAsDataURL(e.target.files[0])
      fileReader.onload = e => {
        let imageObj = new Image()
        imageObj.setAttribute('crossOrigin', 'Anonymous')
        imageObj.src = e.target.result
        imageObj.onload = async function() {
          console.log(imageObj.width, imageObj.height)
          let ratio = that.$parent.calScaleRatio(imageObj.width, imageObj.height, 1024, 1024).ratio

          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')

          const originalWidth = imageObj.width
          const originalHeight = imageObj.height

          const canvasWidth = originalWidth * ratio
          const canvasHeight = originalHeight * ratio

          canvas.width = canvasWidth
          canvas.height = canvasHeight

          context.drawImage(imageObj, 0, 0, canvasWidth, canvasHeight)
          console.log(canvasWidth, canvasHeight, '壓縮後的圖片長寬')
          compressFile = await that.dataURLtoFile(canvas.toDataURL('images/jpeg', 1), fileName)

          let size = parseFloat(compressFile.size / 1000000).toFixed(4)
          if (size <= 3) {
            that.$q.loading.show()
            await that.$parent.konvaCanvasUploadFile(compressFile).then(res => {
              that.$q.loading.hide()
              that.$q.loading.show()
              let fileUrl = res.url
              let currentIndex=layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length-1 : 1
              // console.log(currentIndex,'currentIndex圖片')
              // console.log(fileUrl)
              // console.log(stage)
              Konva.Image.fromURL(fileUrl + '?' + that.mindMapStore.mindMapBlobSas, function(image) {
                that.$q.loading.hide()
                console.log(image)
                let scaleRatio = image.width() > stage.width() ? stage.width() / image.width() / 2 : 0.8
                // image is Konva.Image instance
                image.setAttrs({
                  uuid: that.genUUID(),
                  x: that.getViewCenter().x- Math.floor(Math.random() * 100),
                  y: that.getViewCenter().y- Math.floor(Math.random() * 100),
                  width: image.width() * scaleRatio,
                  height: image.height() * scaleRatio,
                  draggable: true,
                  src: fileUrl,
                  index: currentIndex
                })

                // stage.find('Transformer').destroy()
                let tr = new Konva.Transformer({
                  anchorStroke: '#00a6ff',
                  anchorFill: '#fff',
                  anchorSize: 12,
                  anchorCornerRadius: 5,
                  anchorStrokeWidth: 2,
                  borderStroke: '#6ac9fc',
                  borderStrokeWidth: 2,
                  borderDash: [3, 3],
                  padding: 10,
                  name: 'default'
                })
                stage.find('Transformer').forEach(function(ele, i) {
                  if (ele.attrs.name == 'default') {
                    ele.destroy()
                  }
                })
                layer.add(tr)
                tr.nodes([image])

                that.$parent.addMenuBtnToTr(tr, image) 

                layer.add(image)
                layer.draw()

                 //儲存undo歷史
                 that.$parent.undoHistory.push({ action: 'delete', item: JSON.parse(JSON.stringify(image)) })
                 //新增前一動應提供該物件的初始化Attr
                 that.$parent.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(image)) })
                 that.$parent.isModified = true
              })
            })
          } else {
            that.$q.loading.hide()
            that.$Message.warning(that.$t('konvaCanvas.所選圖片過大'))
          }
          that.$parent.showAddImgBox = false
        }
      }
    },
    //上傳本地所選背景圖片
    async addImgBgc(e) {
      let that = this
      let layer = this.$parent.layer
      let stage = this.$parent.stage

      let times = new Date().getTime()
      let fileName = e.target.files[0].name
      let compressFile

      let fileReader = new FileReader()
      fileReader.readAsDataURL(e.target.files[0])
      that.$q.loading.show()
      fileReader.onload = e => {
        let imageObj = new Image()
        imageObj.setAttribute('crossOrigin', 'Anonymous')
        imageObj.src = e.target.result
        imageObj.onload = async function() {
          console.log(imageObj.width, imageObj.height)
          let ratio = that.$parent.calScaleRatio(imageObj.width, imageObj.height, layer.width(), layer.height()).ratio
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          const originalWidth = imageObj.width
          const originalHeight = imageObj.height
          const canvasWidth = originalWidth * ratio
          const canvasHeight = originalHeight * ratio
          canvas.width = canvasWidth
          canvas.height = canvasHeight
          context.drawImage(imageObj, 0, 0, canvasWidth, canvasHeight)
          compressFile = await that.dataURLtoFile(canvas.toDataURL('images/jpeg', 1), fileName)

          let size = parseFloat(compressFile.size / 1000000).toFixed(4)
          console.log(size, 'MB,壓縮後的圖片大小')
          if (size <= 3) {
            layer.find('Image').forEach(function(ele, i) {
              if (ele.attrs.name == 'boardPdfBg') {
                ele.destroy()
              }
            })
            layer.find('Rect').forEach(function(ele, i) {
              if (ele.attrs.name == 'bgRect') {
                ele.destroy()
              }
            })
            await that.$parent.konvaCanvasUploadFile(compressFile).then(res => {
              that.$q.loading.hide()
              that.$q.loading.show()
              let fileUrl = res.url
              // console.log(fileUrl)
              // console.log(stage)
              Konva.Image.fromURL(fileUrl + '?' + that.mindMapStore.mindMapBlobSas, function(image) {
                that.$q.loading.hide()
                // image is Konva.Image instance
                console.log(that.$parent.relativScaleRatio)
                image.setAttrs({
                  uuid: that.genUUID(),
                  x: (layer.width() - image.width()) / 2 / that.$parent.relativScaleRatio.ratio,
                  y: (layer.height() - image.height()) / 2 / that.$parent.relativScaleRatio.ratio,
                  width: image.width() / that.$parent.relativScaleRatio.ratio,
                  height: image.height() / that.$parent.relativScaleRatio.ratio,
                  // image: imageObj,
                  src: fileUrl,
                  name: 'boardPdfBg'
                })
                layer.add(image)
                image.moveToBottom()
                that.$parent.addWhiteRectBg()
                layer.draw()
                
              })
            })
          } else {
            that.$Message.warning(that.$t('konvaCanvas.所選圖片過大'))
            that.$q.loading.hide()
          }

          that.$parent.showAddImgBox = false
        }
      }
    },
    setBgColor(color) {
      let layer = this.$parent.layer
      let finalx, finaly

      layer.find('Image').forEach(function(ele, i) {
        if (ele.attrs.name == 'boardPdfBg') {
          ele.destroy()
        }
      })
      layer.find('Rect').forEach(function(ele, i) {
        if (ele.attrs.name == 'bgRect') {
          ele.destroy()
        }
      })
      let background = new Konva.Rect({
        uuid: this.genUUID(),
        x: 0,
        y: this.$parent.relativScaleRatio.screenOffsetY / this.$parent.relativScaleRatio.ratio,
        fill: color,
        width: (layer.width() * 1) / this.$parent.relativScaleRatio.ratio,
        height: (layer.height() * 1) / this.$parent.relativScaleRatio.ratio,
        illLinearGradientStartPoint: { x: 0, y: 0 },
        listening: false,
        name: 'bgRect'
      })
      layer.add(background)
      background.moveToBottom()
      layer.draw()
    
    }
  }
}
</script>

<style lang="less">
@import '../../assets/color.less';

.ivu-tabs-nav-container:focus .ivu-tabs-tab-focused,
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: @light-color !important;
  &:hover {
    color: @light-color !important;
  }
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: @light-color !important;
}
.ivu-tabs-ink-bar {
  background-color: @light-color !important;
}
.no-mask {
  background-color: rgba(0, 0, 0, 0) !important;
}
.addImgBox {
  position: absolute;
  display: block;
  width: 100%;
  background-color: @editbtn-shadow;
  // border-radius: 5px;
  transition: 0.5s;
  color: black;
  height: 100%;
  z-index: 1003;
  .close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: @darken-gray;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100;
    .close-icon {
      font-size: 16px;
    }
  }
  .addImgBox-card {
    top: 15%;
    width: 50%;
    min-height: 350px;
    transition: 0.5s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: @nomal-bgc;
    font-weight: bolder;
    padding: 10px 20px 20px 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 102;
    text-align: center;
    .divide-wrap {
      background-color: @nomal-bgc;
      padding: 10px 0px;
      .divide-line {
        background-color: @nomal-bgc;
        border-bottom: 0.5px solid rgb(192, 192, 192);
      }
    }
    @media screen and (max-width: 768px) {
      input[type='color'] {
        cursor: pointer;
        display: inline-block;
        position: relative;
        top: -11px !important;
        -webkit-appearance: none;
        border: none;
        height: 30px !important;
        width: 30px !important;
        margin: 0px 10px;
      }
      input[type='color']::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      input[type='color']::-webkit-color-swatch {
        border: none;
      }
      .bgColors-item {
        display: inline-block;
        height: 30px !important;
        width: 30px !important;
        margin: 0px 10px;
        cursor: pointer;
        border: 1px solid @darken-gray;
        &:hover {
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        }
      }
    }
    .bgColors-wrap {
      position: relative;
      display: block;
      height: auto;

      input[type='color'] {
        cursor: pointer;
        display: inline-block;
        position: relative;
        top: -15px;
        -webkit-appearance: none;
        border: none;
        height: 40px;
        width: 40px;
        margin: 0px 10px;
      }
      input[type='color']::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      input[type='color']::-webkit-color-swatch {
        border: none;
      }
      .bgColors-item {
        display: inline-block;
        height: 40px;
        width: 40px;
        margin: 0px 10px;
        cursor: pointer;
        border: 1px solid @darken-gray;
        &:hover {
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        }
      }
    }
    .img_upload-text {
      margin-top: 20px;
    }
    .img_upload-btn {
      display: inline-block;
      border-radius: 50%;
      background-color: #fff;
      height: 120px !important;
      width: 120px !important;
      cursor: pointer;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
      margin-top: 30px;
      .img_upload-icon {
        font-size: 80px;
        color: @text-color !important;
        position: relative;
        top: 20px;
        left: 2px;
      }
      .imgbgc-icon {
        font-size: 80px;
        color: @text-color !important;
        position: relative;
        top: 20px;
        left: 0px;
      }
      input[type='file'] {
        display: none;
      }
      &:hover {
        background-color: @light-color;
        .img_upload-icon,
        .imgbgc-icon {
          color: white !important;
        }
      }
    }
  }
}
</style>
