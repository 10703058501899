<template>
  <div class="home-page">    
    <div class="between backDiv">
      <div>
        <span class="cursorPointer" @click="ActivityMainPage" >活動主頁 </span>
        <span>/ 活動列表</span>
      </div>
      <div @click="ActivityMainPage" class="cursorPointer PageBack oneLine">
        <div class="div-centerY2  backTxt">
          返回
        </div>
        <img class="backImg" src="../../assets/img/bean/bean_02.png" alt="">
      </div>
    </div>
    <div class="div-center">
      <img class="img " src="../../assets/img/bean/bean_08.png" alt="">
    </div>
    <div class="div-centerY div-center">
      <div class="tableDiv">
        <el-auto-resizer>
          <template #default="{ height, width }">
            <el-table-v2 
              :columns="columns"
              :data="ActivityArry"
              :width="width"
              :height="height"
              @column-sort="onSort"
              fixed
            />
          </template>
        </el-auto-resizer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref,reactive, h, } from 'vue'//inject,reactive,ref
import { useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
// import { ElMessageBox } from 'element-plus';
// import profile from "../api/profile";
// import {Base64} from 'js-base64'

useHead({
  title: 'HiTeach授權活動平台 - 活動列表',
  meta: [    
    { name: 'description', content: 'HiTeach授權活動平台 - 活動列表說明' },
    { property: 'og:title', content: 'HiTeach授權活動平台 - 活動列表' },
    { property: 'og:description', content: 'HiTeach授權活動平台 - 活動列表說明' },
    // { property: 'og:image', content: 'https://example.com/your-image.jpg' },
    // { property: 'og:url', content: 'https://example.com/your-page' },
  ],
})

//表格資料
const columns = ref([
  { key: 'name', dataKey: 'name', title: '活動名稱',width:400,
    cellRenderer: ({ rowData }) => h(
      'div',
      { class: 'nameColor' }, // 添加 class
      rowData.name
    ),
  },
  { key: 'date', dataKey: 'date', title: '時程', width: 150 },
  { key: 'admin', dataKey: 'admin', title: '管理員', width: 100 },
  {
    key: 'url', 
    dataKey: 'url', 
    title: '活動內容', 
    width: 200,
    cellRenderer: ({ rowData }) => h(
      'el-button',
      {
        class: 'urlColor cursorPointer',
        type: 'primary',
        size: 'small',
        onClick: () => goToUrl(rowData.url)
      },
      '詳細內容'
    ),
  },
  {
    key: 'status',
    dataKey: 'status',
    title: '活動狀態',
    width: 150,
    cellRenderer: ({ rowData }) => {
      const statusInfo = getStatusText(rowData.status);
      return h(
        'span',
        { style: { color: statusInfo.color } }, // 動態設置顏色
        statusInfo.txt
      );
    },
  },
]);
/*status狀態
  報名中 ING
  已報名 Already
  報名截止 Closed
  活動已結束 Ended
*/
let ActivityArry = reactive([//表格資料
  {
    // name: '台灣文化藏寶趣',
    name: '台灣文化藏寶趣',
    date: '6/1~6/30',
    admin: '張老師',
    url: '想拿的值',
    status: 'ING',
  },
  {
    name: '閩南語大冒險',
    date: '7/1~7/30',
    admin: '王老師',
    url: '想拿的值2',
    status: 'Already',
  },
  {
    name: '阿美族的文化傳承',
    date: '8/1~8/30',
    admin: '陳老師',
    url: '想拿的值3',
    status: 'Closed',
  },
]);


//路由器
const routers = useRouter();

function routerskip(val) {
  routers.push(val);
}
function ActivityMainPage(){
  routerskip("/HomePage_Activity");
} 


function goToUrl(){//url
  routerskip("/Activity_Info");
}

function getStatusText(status) {
  switch (status) {
    case 'ING':
      return {txt:'開放報名',color:'#F56C6C'};
    case 'Already':
      return  {txt:'已報名',color:'#409EFF'};
    case 'Closed':
      return  {txt:'報名截止',color:'#909399'};
    case 'Ended':
      return  {txt:'活動已結束',color:'#909399'};
    default:
      return status;
  }
}

</script>

<style lang="less" scoped>
@fontColor :#424f55;//文字：深藍
@fontColor-light :#8da3a0 ;//文字：中藍
@stressColor :#f59f56;//強調色：橘色
@fontColor-gray1: #909399;
@fontColor-gray2: #b1b3b8;
@other-blue:#70a6b2;//其他輔助色：水藍
.div-center{/*水平置中*/
  display: flex; 
  justify-content: center; 
  text-align:center;
  width:100%;
}
.div-centerY{ /*垂直置中*/
  display: flex;    
  align-items: center;
  flex-direction: column;  //有時可能需要在加這個屬性
}
.div-centerY2{ /*垂直置中*/
  margin-top: auto;
  margin-bottom: auto;
}
.oneLine{ /*單行與折行顯示*/
  display: flex;
  flex-wrap: wrap; //元素折行呈現，元素空間夠的時候單行呈現，空間不夠則折行呈現
  flex-wrap: nowrap; //元素單行呈現  
}
.between{ /*子元素分左右兩邊*/
  display: flex;
  justify-content: space-between;
}
.cursorPointer{
  cursor: pointer;//滑鼠變成小手
}
.home-page {
  position: relative;//父元素設置為相對定位
  .PageBack{
    color:@fontColor-gray1;
  }
  .backDiv{
    .backTxt{
      margin-right: 20px;
      font-weight:bold;
    }
    .backImg{
      width: 50px;
    }
  }  
  .img{
    position: absolute;//子元素設置為絕對定位
    width: 150px;
    bottom: 20px;
    // right: 50px;
    z-index: 100;
  }
  .tableDiv{
    margin-top: 30px;
    width: 90%;
    max-width: 1200px;
    height: 45vh;
    .nameColor{
      color:@other-blue;
    }
    .urlColor {
      color: @stressColor; 
    }
    .statusColor{
      color: @fontColor-light; 
    }
    .detail-button:hover {
      cursor: pointer; /* 滑鼠移入變成手形 */
      text-decoration: underline; /* 可選：增加下劃線效果 */
    }
  }
}
</style>
