<template>
  <div class="cardPack-item">
    <div
      class="img-top"
      :style="{
        'background-size': checkImgStyle(materials) ? 'contain' : 'cover',
        'background-color': checkImgStyle(materials) ? '#fef9c3' : 'unset',
      }"
      v-show="materials && materials.imgUrl != ''"
      v-lazy-background="getImgUrl(materials)"
      :class="{ loaded: materials.isLoadingNow && materials.imgUrl }"
      ref="imgTop"
      @click="emitViewContent(materials)"
    ></div>
    <div
      class="img-compose"
      v-show="materials && materials.imgUrl == ''"
      :class="{ 'grid-cols-2': materials.materialsList.length == 2 }"
      @click="emitViewContent(materials)"
    >
      <div 
        class="img-puzze"
        :class="{
          'col-span-2 row-span-1':
            index == 0 && materials.materialsList.length != 2,
          loaded: item.isLoadingNow,
        }"
        v-for="(item, index) in materials.materialsList"
        v-lazy-background="getImgListItemUrl(item)"
        v-show="index < 3"
        :key="index"
      ></div>
    </div>
    <div class="card-textwrap">
      <h5 @click="emitViewContent(materials)">{{ materials.title }}</h5>
      <p>(可秀:{{ materials.groupAllowShow }})</p>
      <p class="text-xs">
        <a href="#" class="uploader">{{ materials.packCreator.name }}</a
        ><span class="text-gray-500">・{{ getTime(materials.timestamp) }}</span>
      </p>
      <p class="intro-text">{{ truncatedIntro(materials.intro) }}</p>
      <div ref="moreDropdown" v-show="!materials.hidden">
        <div
          class="more-btn"
          v-show="option.allowEdit"
          @click="showMore(materials)"
        >
          <svg-icon icon-class="more" />
        </div>
        <!-- more dropdown -->
        <div class="more-dropdown" v-show="showMoreMenu">
          <div
            class="menubtn"
            v-show="option.allowMoveOut"
            @click="moveOutFromGroup()"
          >
            <svg-icon icon-class="file-right" class="edit-icon" />{{
              "移出群組"
            }}
          </div>
          <div
            class="menubtn"
            @click="openSelectedGroupForm('move')"
            v-show="option.allowMoveIn"
          >
            <svg-icon icon-class="file-left" class="edit-icon" />{{
              "移入群組"
            }}
          </div>
          <div
            class="menubtn"
            @click="openSelectedGroupForm('copy')"
            v-show="option.allowCopy"
          >
            <svg-icon icon-class="copy" class="copy-icon" />{{ "複製到群組" }}
          </div>
          <div class="menubtn" @click="openEditMaterialForm(materials)">
            <svg-icon icon-class="edit" class="edit-icon" />{{ $t("編輯") }}
          </div>
          <div class="menubtn" @click="confirmDelete()">
            <svg-icon icon-class="delete" class="del-icon" />{{ $t("刪除") }}
          </div>
        </div>
      </div>
      <div class="badge-group">
        
        <span class="badge" v-if="materials.grade"> {{ materials.grade }}年級</span>
        <span class="badge" v-if="materials.subject"> {{ materials.subject }}</span>
        <span class="badge"> 資源包</span>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
import profile from "../api/profile";
import { useMainStore } from "../stores/mainStore";
export default {
  components: { SvgIcon },
  name: "cardPack-item",
  props: {
    materials: {
      type: Object,
      required: true,
    },
    blobsas: {
      type: String,
      required: true,
    },
    option: {
      type: Object,
      required: false,
      default: function () {
        return {
          allowEdit: false,
          allowMoveIn: true,
          allowMoveOut: false,
          allowCopy: true,
          allowRemoveFromPack: false,
        };
      },
    },
  },
  setup() {
    const store = useMainStore();
    return { store };
  },
  mounted() {
    document.addEventListener("click", this.closeMenu);
    if (this.materials)
      this.materials.materialsList.forEach((item) => {
        item.isLoadingNow = true;
      });
  },
  data() {
    return {
      showMoreMenu: false,
      containStyle: false,
    };
  },
  methods: {
    randemColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    moveOutFromGroup() {
      this.$emit("moveOutFromGroup");
      this.showMoreMenu = false;
    },
    openSelectedGroupForm(mode) {
      this.store.setCurrentEditMaterialGroupMode(mode);
      this.$emit("openSelectedGroupForm",'Pack');
      this.showMoreMenu = false;
    },
    openEditMaterialForm() {
      this.$emit("openEditPackForm");
    },
    confirmDelete() {
      this.$emit("confirmDelete");
    },
    closeMenu(event) {
      // 檢查點擊事件是否在選單內部
      if (this.$refs.moreDropdown) {
        if (!this.$refs.moreDropdown.contains(event.target)) {
          this.showMoreMenu = false;
        }
      }
    },
    emitViewContent(materials) {
      this.$emit("viewContent", materials);
      console.log(materials);
    },
    showMore(item) {
      this.store.setCurrentEditItem(item);
      this.showMoreMenu = true;
      // console.log("showMore")
    },
    checkBackgroundImageLoaded(imageUrl, materials) {
      try {
        if (imageUrl && materials) {
          //某個有問題資料庫的圖片
          if (!imageUrl.includes("292A56BE-66C3-4A8D-A812-AE266C30AEFB.jpg")) {
            const tempImg = new Image();
            tempImg.src = imageUrl;
            tempImg.onload = () => {
              if (materials?.isLoadingNow) materials.isLoadingNow = false;
              // console.log('背景图像已加载完成。');
            };
            tempImg.onerror = (img) => {
              console.log(img);
              console.log("圖片載入失敗");
            };
          }
          else{
            // console.log(materials,'有問題的圖片')
          }
        }
      } catch (e) {
        console.log("圖片載入失敗");
      }
    },
    checkImgStyle(materials) {
      // console.log(materials.imgUrl.slice(0, 4) == "/img");
      return (
        materials.imgUrl.slice(0, 4) == "/img" ||
        materials.imgUrl.slice(0, 5) == "data:"
      );
    },
    getImgListItemUrl(materials) {
      // console.log(materials);
      try {
        if (materials?.imgBlobUrl && this.blobsas) {
          if (materials?.imgBlobUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials?.imgBlobUrl + "?" + this.blobsas}`,
              materials
            );
            return 'url("' + materials.imgBlobUrl + "?" + this.blobsas + '")';
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.uploader.id +
              "/" +
              materials.id +
              "/" +
              materials.imgBlobUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`, materials);
            return 'url("' + finalUrl + '")';
          }
        } else if (!materials?.imgBlobUrl) {
          materials.isLoadingNow = false;
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getImgUrl(materials) {
      // console.log(materials.imgUrl.slice(0, 5), "aass");
      try {
        if (
          materials.imgUrl &&
          this.blobsas &&
          materials.imgUrl.slice(0, 4) != "/img" &&
          materials.imgUrl.slice(0, 5) != "data:"
        ) {
          if (materials.imgUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials?.imgUrl + "?" + this.blobsas}`
            );
            return 'url("' + materials.imgUrl + "?" + this.blobsas + '")';
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.packCreator.id +
              "/" +
              "packImgs" +
              "/" +
              materials.imgUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`);
            return 'url("' + finalUrl + '")';
          }
        } else if (materials.imgUrl.slice(0, 4) == "/img") {
          materials.isLoadingNow = false;
          return `url("https://${location.host}${materials.imgUrl}")`;
        } else if (materials.imgUrl.slice(0, 5) == "data:") {
          materials.isLoadingNow = false;
          return `url("${materials.imgUrl}")`;
        } else if (!materials?.imgUrl) {
          materials.isLoadingNow = false;
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getTime(timestamp) {
      const date = new Date(timestamp * 1000);

      //const year = date.getFullYear();
      //const month = date.getMonth() + 1; // 月份从 0 开始，所以需要加 1
      //const day = date.getDate();
      // const hours = date.getHours();
      // const minutes = date.getMinutes();
      // const seconds = date.getSeconds();
      return date.toLocaleDateString();
    },
    truncatedIntro(intro) {
      if (intro) {
        const maxLength = 100;
        if (intro.length <= maxLength) {
          return intro;
        } else {
          return intro.substr(0, maxLength) + "...";
        }
      }
      return "";
    },
  },
};
</script>

<style lang="less">
.cardPack-item {
  @apply relative rounded-lg border border-gray-300 bg-white;
  // max-height: 450px;
  width: 100%;

  &:hover {
    @apply drop-shadow-xl;
  }

  .loaded {
    @apply animate-pulse;
    background: none !important;
    @apply bg-gray-500 !important;
  }

  .img-top {
    @apply rounded-t-lg cursor-pointer;
    height: 160px;
    width: 100%;
    background-size: cover;
    background-position: center !important;
    background-repeat: no-repeat !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  }
  .img-compose {
    @apply grid rounded-t-lg cursor-pointer overflow-hidden;
    height: 160px;

    .loaded {
      @apply animate-pulse;
      background: none !important;
      @apply bg-gray-500 !important;
    }
    .img-puzze {
      background-size: cover;
      background-position: center !important;
      background-repeat: no-repeat !important;
      border-bottom: 1px solid rgba(128, 128, 128, 0.3);
    }
  }

  .uploader {
    @apply font-bold text-sky-500 underline;
  }

  .card-textwrap {
    @apply p-5 pt-3;

    h5 {
      @apply mb-2 text-2xl font-bold tracking-tight text-gray-900 cursor-pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .intro-text {
      // width: calc(~"18rem - 10px");
      @apply pb-10 pt-5 font-normal text-gray-700 text-sm break-words;
    }

    .hits-num {
      @apply absolute bottom-4 left-5 text-left text-xs text-gray-600;

      .eye-icon {
        @apply mr-1 text-gray-600;
        display: inline-block;
      }
    }

    .more-dropdown {
      top: 200px;
      @apply absolute -right-5 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5;

      .menubtn {
        @apply block cursor-pointer px-4 py-2 text-sm text-black;

        &:hover {
          @apply bg-slate-200 !important;
        }
        .copy-icon {
          display: inline-block;
          margin-right: 5px;

          path {
            fill: black !important;
          }
        }
        .edit-icon {
          display: inline-block;
          margin-right: 5px;

          * {
            fill: black;
          }
        }

        .del-icon {
          display: inline-block;
          margin-right: 5px;

          * {
            stroke: black;
          }
        }
      }
    }

    .more-btn {
      font-size: 28px;
      border-radius: 50%;
      line-height: 30px;
      position: absolute;
      top: 170px;
      right: 10px;
      cursor: pointer;
      text-align: center;
      width: 32px;
      height: 32px;

      * {
        position: relative;
        left: 2px;
        top: 1px;
        stroke: gray;
      }

      &:hover {
        @apply bg-slate-200;

        * {
          @apply stroke-sky-600 !important;
        }
      }
    }

    .badge-group {
      @apply absolute bottom-3 right-3 text-right;

      .badge {
        @apply mx-1 cursor-pointer rounded-full bg-purple-300 px-2 py-1 text-sm font-semibold text-black drop-shadow-sm;
      }
    }

    .tag-group {
      @apply absolute bottom-4 right-3 text-right text-xs font-bold text-sky-500;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .tag {
        @apply mx-1;
      }
    }
  }
}
</style>
