<template>
  <div class="editmaterial-form">
    <div class="main-form">
      <div class="text-gray-900 font-medium text-lg text-center">
        {{ $t("editmaterial-form.編輯教材資訊") }}
      </div>
      <div class="scroll-wrap">
        <div class="upload-item">
          <label>
            <p class="title">{{ $t("editmaterial-form.標題") }}</p>
            <input type="text" v-model="editTitle" />
          </label>
        </div>
        <div class="upload-item">
          <label>
            <p class="title">{{ $t("editmaterial-form.介紹") }}</p>
            <textarea v-model="editIntro"></textarea>
          </label>
        </div>
        <div class="upload-item">
          <p class="title">{{ $t("editmaterial-form.年級") }}</p>
          <SelectMenu
            :category="cateGrade"
            :defaultSelect="$t('editmaterial-form.未指定')"
            ref="gradeSelect"
          />
        </div>

        <div class="upload-item">
          <p class="title">{{ $t("editmaterial-form.科目") }}</p>
          <SelectMenu
            :category="cateSubject"
            :defaultSelect="$t('editmaterial-form.未指定')"
            ref="subjectSelect"
          />
        </div>

        <div class="upload-item">
          <p class="title">{{ $t("editmaterial-form.授課語系") }}</p>
          <SelectMenu
            :category="cateLang"
            :defaultSelect="$t('editmaterial-form.未指定')"
            ref="langSelect"
          />
        </div>

        <div class="upload-item">
          <label>
            <p class="title">{{ $t("editmaterial-form.自訂標籤") }}</p>
            <input type="text" v-model="category" />
            <p class="text-xs text-gray-500 pt-2">
              {{ $t("editmaterial-form.標籤提示字") }}
            </p>
          </label>
        </div>
        <h1 class="title">創用CC授權方式</h1>
        <form class="title upload-item">
          1.你這作品的改作，能否再次被分享？*<br />
          <label class="title"
            ><input
              type="radio"
              name="location"
              v-model="ccShareAgainType"
              :value="1"
            />
            是 &nbsp;</label
          >
          <label class="title">
            <input
              type="radio"
              name="location"
              v-model="ccShareAgainType"
              :value="2"
            />
            否 &nbsp;</label
          >
          <label class="title"
            ><input
              type="radio"
              name="location"
              v-model="ccShareAgainType"
              :value="3"
            />
            可以，只要別人以相同授權方式分享 &nbsp;
          </label>
        </form>
        <form class="title upload-item">
          2.是否允許你的著作被使用於商業目的？*<br />
          <label class="title"
            ><input
              type="radio"
              name="location"
              v-model="ccShareCommercial"
              :value="true"
            />
            是 &nbsp;</label
          >
          <label class="title">
            <input
              type="radio"
              name="location"
              v-model="ccShareCommercial"
              :value="false"
            />
            否 &nbsp;</label
          >
        </form>
      </div>
      <div class="ml-auto mt-5 normal p-4 pt-0">
        <button class="left-btn" v-if="checkIsChange" @click="confirmUpdate()">
          {{ $t("確定") }}
        </button>
        <button class="right-btn" @click="close()">
          {{ $t("取消") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "../stores/mainStore";
import SelectMenu from "@/components/SelectMenu.vue";
export default {
  name: "editmaterial-form",
  mounted() {
    this.ccShareAgainType=this.store.currentEditItem.ccShareAgainType;
    this.ccShareCommercial=this.store.currentEditItem.ccShareCommercial;
    this.editTitle = this.store.currentEditItem.title;
    this.editIntro = this.store.currentEditItem.intro;
    this.$refs.gradeSelect.currentSelect =
      this.store.currentEditItem.grade == 0
        ? this.$t("editmaterial-form.未指定")
        : this.store.currentEditItem.grade;
    this.$refs.subjectSelect.currentSelect =
      this.store.currentEditItem.subject == 0
        ? this.$t("editmaterial-form.未指定")
        : this.store.currentEditItem.subject;
    this.$refs.langSelect.currentSelect =
      this.store.currentEditItem.language == 0
        ? this.$t("editmaterial-form.未指定")
        : this.store.currentEditItem.language;
    if (this.store.currentEditItem.category.length > 0) {
      this.category = this.store.currentEditItem.category;
    }
  },
  data() {
    return {
      editTitle: "",
      editIntro: "",
      cateGrade: [
        this.$t("editmaterial-form.未指定"),
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      cateSubject: [
        this.$t("editmaterial-form.未指定"),
        "國文",
        "英文",
        "數學",
        "社會",
        "自然",
        "綜合",
      ],
      cateLang: [
        this.$t("editmaterial-form.未指定"),
        "tw",
        "cn",
        "en",
        "other",
      ],
      category: "",
      ccShareAgainType:"",
      ccShareCommercial:""
    };
  },
  components: {
    SelectMenu,
  },
  setup() {
    const store = useMainStore();
    return { store };
  },
  computed: {
    checkIsChange: function () {
      let grade, subject, language;
      if (this.$refs.gradeSelect) {
        grade =
          this.$refs.gradeSelect.currentSelect ==
          this.$t("editmaterial-form.未指定")
            ? 0
            : Number(this.$refs.gradeSelect.currentSelect);
        subject =
          this.$refs.subjectSelect.currentSelect ==
          this.$t("editmaterial-form.未指定")
            ? ""
            : this.$refs.subjectSelect.currentSelect;
        language =
          this.$refs.langSelect.currentSelect ==
          this.$t("editmaterial-form.未指定")
            ? ""
            : this.$refs.langSelect.currentSelect;
      }
      return (
        this.editTitle !== this.store.currentEditItem.title ||
        this.editIntro !== this.store.currentEditItem.intro ||
        grade !== this.store.currentEditItem.grade ||
        subject !== this.store.currentEditItem.subject ||
        language !== this.store.currentEditItem.language ||
        this.category != this.store.currentEditItem.category.join()||
        this.ccShareCommercial!=this.store.currentEditItem.ccShareCommercial||
        this.ccShareAgainType!=this.store.currentEditItem.ccShareAgainType
      );
    },
  },
  methods: {
    confirmUpdate() {
      let updateData = {
        id: this.store.currentEditItem.id,
        title: this.editTitle,
        type: this.store.currentEditItem.type,
        intro: this.editIntro,
        grade:
          this.$refs.gradeSelect.currentSelect ==
          this.$t("editmaterial-form.未指定")
            ? 0
            : Number(this.$refs.gradeSelect.currentSelect),
        subject:
          this.$refs.subjectSelect.currentSelect ==
          this.$t("editmaterial-form.未指定")
            ? ""
            : this.$refs.subjectSelect.currentSelect,
        language:
          this.$refs.langSelect.currentSelect ==
          this.$t("editmaterial-form.未指定")
            ? ""
            : this.$refs.langSelect.currentSelect,
        category: this.category.toString().split(","),
        ccShareCommercial:this.ccShareCommercial,
        ccShareAgainType:this.ccShareAgainType
      };
      this.$emit("confirm", updateData);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less">
.editmaterial-form {
  z-index: 999;
  @apply flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800/50;

  .main-form {
    min-width: 40%;
    max-height: 90vh;
    .scroll-wrap {
      @apply p-8 pb-20;
      width: 100%;
      max-height: 85vh;
      overflow: auto;
    }

    @apply bg-white  rounded-lg relative  flex flex-col pt-4;

    .upload-item {
      @apply my-3;
      input[type="text"],
      textarea {
        @apply ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6 w-full py-2 px-5 rounded-md mt-2;
      }
      textarea {
        max-height: 180px;
        overflow: auto;
      }
    }
    .title {
      @apply font-bold;
    }
  }

  .normal {
    .left-btn {
      @apply text-white font-bold border py-1 px-4 rounded mr-2 bg-sky-500 border-sky-500;

      &:hover {
        @apply bg-sky-600;
      }
    }

    .right-btn {
      @apply bg-transparent text-gray-700 font-semibold py-1 px-4 border border-gray-500 rounded;

      &:hover {
        @apply bg-sky-100;
      }
    }
  }
}
</style>