<!--eslint-disable-->
<template>
    <div class="baseKonva-canvas">
        <!--詢問離開動作-->
        <q-dialog v-model="isQueryLogout">
            <q-card class="textinput-qcard">
                <div class="textinput-card">
                    <p style="font-size: 16px">{{ $t('mindmap.imgEditor.詢問離開動作') }}</p>
                    <br />
                    <q-card-actions align="right">
                        <q-btn no-caps flat :label="$t('mindmap.imgEditor.保存圖片')" color="primary"
                            @click="saveImgForNode()" />
                        <q-btn no-caps flat :label="$t('mindmap.imgEditor.捨棄變更')" color="primary" @click="logout()" />
                        <q-btn no-caps flat :label="$t('mindmap.imgEditor.取消')" color="primary"
                            @click="isQueryLogout = false" />
                    </q-card-actions>
                </div>
            </q-card>
        </q-dialog>

        <!--圖片工具箱選單-->
        <AddImgBox v-show="showAddImgBox" ref="addImgBox" />
        <!--文字工具箱選單-->
        <AddTextBox v-show="showAddTextBox" ref="addTextBox" />
        <!--文字點按編輯選單-->
        <div class="textSetting-tool"
            v-show="!showAddTextBox && isEditingTextNow == true && (TextSettingToolpos.x != 0) & (TextSettingToolpos.y != 0)"
            :style="{ left: TextSettingToolpos.x + 'px', top: TextSettingToolpos.y + 'px' }">
            <TextSetting />
        </div>
        <!--工具箱-->
        <div class="controls control-layerToTop-btn" @click="moveToTop()"
            v-show="toggleBtnOn == 'select' && currentSelectElement != ''">
            <svg-icon iconClass="moveTop" class="moveTop-icon" />
        </div>
        <div class="controls control-layerToBottom-btn" @click="moveToBottom()"
            v-show="toggleBtnOn == 'select' && currentSelectElement != ''">
            <svg-icon iconClass="moveBottom" class="moveBottom-icon" />
        </div>
        <div class="toolbox">
            <!--選擇工具-->
            <div class="controls" @click="select()"
                :class="{ 'controls-on': toggleBtnOn == 'select' || toggleBtnOn == 'text' }">
                <svg-icon iconClass="select" />
            </div>
            <!--筆形工具-->
            <div class="controls" @click="addDraw()" :class="{ 'controls-on': toggleBtnOn == 'pen' }">
                <Poptip v-model="penPopVisible" placement="left-start" trigger="click">
                    <svg-icon :iconClass="penIconType" />
                    <template #content>
                        <PenSetting ref="penSetting" />
                    </template>
                </Poptip>
            </div>

            <!--圖形工具-->
            <div class="controls" @click="addShape()" :class="{ 'controls-on': toggleBtnOn == 'shape' }">
                <Poptip v-model="shapePopVisible" content="content" placement="left-start" trigger="click">
                    <span v-if="shapeState.shapeFill == false">
                        <svg-icon
                            v-if="shapeState.shapeType == 'long-arrow-alt-right' && shapeState.shapeIconType != 'shapes'"
                            icon-class="add_arrow2" />
                        <q-icon
                            v-else-if="shapeState.shapeIconType == 'trending_flat' || shapeState.shapeIconType == 'remove'"
                            :name="shapeState.shapeIconType" style="transform: rotate(-45deg)" class="shapebtn-icon" />
                        <q-icon v-else-if="shapeState.shapeType == 'ellipse'" style="transform: scaleX(1.2);"
                            :name="shapeState.shapeIconType" class="shapebtn-icon" />
                        <q-icon
                            v-if="shapeState.shapeType != 'long-arrow-alt-right' && shapeState.shapeType != 'ellipse' && shapeState.shapeType != 'line'"
                            :name="shapeState.shapeIconType" class="shapebtn-icon" />
                    </span>
                    <svg-icon v-if="shapeState.shapeFill == true" :icon-class="shapeState.shapeIconType" />
                    <template #content>
                        <ShapeSetting ref="shapeSetting" />
                    </template>
                </Poptip>
            </div>
            <!--文字工具-->
            <div class="controls" @click="openTextbox()">
                <svg-icon iconClass="textNote" class="textNote-icon" />
            </div>
            <!--圖片百寶箱工具-->
            <div class="controls" @click="openImgBox()">
                <svg-icon iconClass="add_img" class="imgbgc-icon addimg_icon" />
            </div>
            <!--橡皮擦工具-->
            <div class="controls" @click="deleteObj()" :class="{ 'controls-on': toggleBtnOn == 'eraser' }">
                <Poptip v-model="eraserPopVisible" content="content" placement="left-start" trigger="click"
                    class="eraserPop">
                    <svg-icon iconClass="eraser" />
                    <template #content>
                        <div class="controls" @click="deleteObj()" :class="{ 'controls-on': toggleBtnOn == 'eraser' }">
                            <svg-icon iconClass="eraser" />
                        </div>
                        <!--垃圾桶清空畫布工具-->
                        <div class="controls" @click="stageClear()"><svg-icon iconClass="trash" /></div>
                    </template>
                </Poptip>
            </div>

            <!--回上一步-->
            <div class="controls" @click="doUndo()">
                <svg-icon iconClass="undo" />
            </div>

            <!--放大鏡工具-->
            <div class="controls" @click="zoomPanMode()" :class="{ 'controls-on': toggleBtnOn == 'scale' }">
                <Poptip v-model="zoomPopVisible" content="content" placement="left-start" trigger="click">
                    <svg-icon iconClass="scale_hand" class="scale_hand_icon" />

                    <template #content>
                        <div class="zoomPop" @click="setBarMode">
                            <div>
                                <span class="controls zoomToDefault-btn" @click="zoomToDefault">
                                    <p>1:1</p>
                                </span>
                            </div>
                            <div class="slider_box">
                                <p class="menu-title">
                                    {{ $t('konvaCanvas["比例"]') }}：<i class="textsize">{{ zoomSize }}</i>
                                </p>
                                <Slider v-model="zoomSize" @on-input="setZoomSize" @on-change="setBarMode" :min="1"
                                    :step="0.2" :max="4" show-tip="never"></Slider>
                            </div>
                        </div>
                    </template>
                </Poptip>
            </div>
        </div>

        <!--Ｃowork也可以logout-->
        <div class="controls btn-logout" @click="logoutui()">
            <svg-icon iconClass="log_out" class="logout-icon" />
        </div>
        <!--畫布本體-->
        <div id="container" />

        <div class="obj-menu" v-show="showObjMenu" :style="{ left: objMenuPos.x + 'px', top: objMenuPos.y + 'px' }">
            <p v-show="isCurrentTexObj()" @click="editTextObj()">{{ $t('konvaCanvas["編輯文字便利貼"]') }}</p>
            <p @click="copyPasteTextObjByObjMenu()">{{ $t('konvaCanvas.複製') }}</p>
            <p @click="deleteObjByObjMenu()">{{ $t('konvaCanvas.刪除') }}</p>
            <p @click="moveToTopByObjMenu()">{{ $t('konvaCanvas.移到最上層') }}</p>
            <p @click="moveToBottomByObjMenu()">{{ $t('konvaCanvas.移到最下層') }}</p>
            <p v-show="isCurrentTextURL()" @click="openURL_NewTab()">{{ $t('konvaCanvas["openURL_NewTab"]') }}
            </p>
        </div>
    </div>
</template>


<script>
/* eslint-disable */
import Konva from 'konva'
import '../assets/icons'
import AddImgBox from './KonvaCanvasComponent/AddImgBox.vue'
import AddTextBox from './KonvaCanvasComponent/AddTextBox.vue'
import TextSetting from './KonvaCanvasComponent/TextSetting.vue'
import PenSetting from './KonvaCanvasComponent/PenSetting.vue'
import ShapeSetting from './KonvaCanvasComponent/ShapeSetting.vue'
import SvgIcon from "./SvgIcon.vue";
import { QBtn, QCard, QCardActions, QDialog, QIcon, Loading } from 'quasar'
import { provide, reactive, inject } from 'vue';
import { useMindMapStore } from "@/stores/mindMapStore";


function debuglog(...theArgs) {
    //console.log(Date(), theArgs)
}
export default {
    name: 'MindMapImgEditor',
    setup() {
        const showImgEditor = inject('showImgEditor')
        const inputImgFileBeforeOpenCanvas = inject('inputImgFileBeforeOpenCanvas')
        const mindMapUploadFile = inject('mindMapUploadFile')
        const currentImgUrl=inject('currentImgUrl')
        const mindMapInfo = reactive({
            showImgEditor,
            inputImgFileBeforeOpenCanvas,
            mindMapUploadFile,
            currentImgUrl
        });

        const mindMapStore = useMindMapStore()


        const shapeState = reactive({
            shapeType: 'long-arrow-alt-right',
            shapeIconType: 'add_arrow2',
            shapeFill: false,
            shapeColor: 'transparent',
            shapeBorderColor: 'red',
        });

        const updateShapeType = (value) => {
            shapeState.shapeType = value.shapeType;
            shapeState.shapeIconType = value.shapeIconType;
            shapeState.shapeFill = value.shapeFill;
            shapeState.shapeColor = value.shapeColor;
            shapeState.shapeBorderColor = value.shapeBorderColor;
        };

        const updateShapeColor = (value) => {
            shapeState.shapeBorderColor = value.shapeBorderColor;
            shapeState.shapeColor = value.shapeColor;
        };

       

        provide('shapeState', shapeState);
        provide('updateShapeType', updateShapeType);
        provide('updateShapeColor', updateShapeColor);

        return { shapeState,  mindMapInfo, mindMapStore };
    },

    data() {
        return {
            toggleBtnOn: 'select',
            stage: '',
            layer: '',
            isPaint: false, //畫筆是否生效
            lastLine: {}, //線條
            oldonex: '',
            oldoney: '',
            newPoints: [],
            currentSelectElement: '',
            showAddImgBox: false,
            showAddTextBox: false,

            isEditingTextNow: false,
            currentEditTextNode: '',
            TextSettingToolpos: {
                x: 0,
                y: 0
            },
            zoomPopVisible: false,
            penPopVisible: false,
            shapePopVisible: false,
            eraserPopVisible: false,
            zoomSize: 1,
            zoomType: 'bar',
            penIconType: 'pencil-alt',
            shapeIconType: 'add_arrow2',
            shapeType: 'long-arrow-alt-right',

            shapeColor: 'transparent',
            shapeBorderColor: 'red',
            upagatekey: 0,
            relativScaleRatio: { ratio: 1, screenOffsetX: 0, screenOffsetY: 0 },
            blobSas_read: '',
            isSendByMe: false,
            currentSelectItem: '',
            currentDeleteItem: '',
            hostStageWidth: '', //以ht 或 cc 傳來的螢幕大小，所有的裝置以此為標準縮放
            hostStageHeight: '',
            currentDragElement: [], //htexJSON中的文字,連結等 v-html中可以設為drag的元素
            complexStyle: [], //v-html 無法再渲染時設定多變數的inline style
            poslinkInfo: [], //給konva 的段落位置參數
            isShowHint: true,
            isOpenObjLink: false,
            isOpenMultiLink: false, //一個物件可能會有多個連結
            isOpenPlayerLink: false,
            currentPlayerHtml: '',
            currentPlayerTimeout: '',
            currentObjLink: '',
            currentMultiLinks: [],

            //存放undo動作
            undoHistory: [],
            doUndoIndex: 0,
            undoInitLayer: '',
            isModified: false,
            isQueryLogout: false,
            preScale: 1,
            showObjMenu: false,
            objMenuPos: { x: 0, y: 0 },
            currentMenuObj: {},
            isRenderHTEX: false,
            webIRSLayerSize: { height: 0, width: 0 },
            myFinalSendCoworkAction: { uuid: "", action: "" },
            finalCoworkAction: { uuid: "", action: "" },
            currentEditor: {
                seat: '',
                name: ''
            },
            showNameLabel: true,
            coworkEraserMode: "eraseLine", //刪除筆刷或物件
            undoDeleteHistory: [],  //存放我自己殺掉的undoDeleteHistory 
            undoDeleteByOtherHistory: [], //存放別人叫我殺掉的undoDeleteByOtherHistory
            drawHistory: [],
            isDrawOut: false,
            isSelectTouchingNow: false, //目前我是不是在touch 觸控板
            teacherLargeH: 0,
            teacherLargeW: 0,
            isCoworkStageScaleHT: true, //確認,是不是走StageScale的HT
            coworkBgRatio: 1, //舊版Cowork流程使用
            coworkBgScreenOffsetX: 0, //舊版Cowork流程使用
            coworkBgScreenOffsetY: 0, //舊版Cowork流程使用
        }
    },

    components: {
        AddImgBox,
        AddTextBox,
        TextSetting,
        PenSetting,
        ShapeSetting,
        SvgIcon,
        QBtn,
        QCard,
        QCardActions,
        QDialog,
        QIcon,
        Loading
    },
    async mounted(event) {

        Konva.pixelRatio = 1
        Konva.hitOnDragEnabled = true
        Konva.captureTouchEventsEnabled = true

        let that = this
        this.isModified = false


        let gesture = ['touchstart', 'touchmove', 'touchend', 'touchcancel']
        let containerDom = document.getElementById('container')


        this.stage = await new Konva.Stage({
            container: 'container',
            width: window.innerWidth,
            height: window.innerHeight,
        })



        if (this.stage != '')
            gesture.forEach(ges => {
                containerDom.addEventListener(ges, e => {
                    e.preventDefault()
                    this.stage.setPointersPositions(e)
                })
            })
        this.layer = await new Konva.Layer()

        this.stage.add(this.layer)
        this.select()

        //konva警告的手動校正
        if (event) {
            this.stage.setPointersPositions(event)
        }
        //讀取使用者的相片資料

        if (this.mindMapInfo?.inputImgFileBeforeOpenCanvas&&this.mindMapInfo?.inputImgFileBeforeOpenCanvas != '') {
            let bgSrc = this.mindMapInfo.inputImgFileBeforeOpenCanvas
            that.$q.loading.show()
            Konva.Image.fromURL(bgSrc, function (image) {
                let bgRatio = that.calScaleRatio(image.width(), image.height(), that.layer.width(), that.layer.height()).ratio
                let bgScreenOffsetX = that.calScaleRatio(image.width(), image.height(), that.layer.width(), that.layer.height()).screenOffsetX
                let bgScreenOffsetY = that.calScaleRatio(image.width(), image.height(), that.layer.width(), that.layer.height()).screenOffsetY
                image.setAttrs({
                    uuid: that.genUUID(),
                    x: -bgScreenOffsetX,
                    y: -bgScreenOffsetY,
                    width: image.width() * bgRatio,
                    height: image.height() * bgRatio,
                    index: 0,
                    src: bgSrc,
                    name: 'boardPdfBg'
                })

                that.layer.add(image)
                image.moveToBottom()
                that.addWhiteRectBg()
                that.layer.draw()
                that.$q.loading.hide()
            })
        } else {
            this.$q.loading.show()
            this.addWhiteRectBg()
            this.$q.loading.hide()
        }

    },


    methods: {
        genUUID() {
            let d = Date.now()
            //Performance.now() 亞毫秒級的時間戳記
            if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
                d += performance.now()
            }
            return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                //xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
                let r = (d + Math.random() * 16) % 16 | 0
                d = Math.floor(d / 16)
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
            })
        },
        moveHtexToTop() {
            let that = this
            let items = []

            const bgRect = this.layer.children.find(item => item.attrs.name === 'bgRect');
            const boardPdfBg = this.layer.children.find(item => item.attrs.name === 'boardPdfBg');

            if (boardPdfBg && !bgRect) {
                boardPdfBg.moveToBottom();
            } else if (bgRect) {
                bgRect.moveToBottom();
            }

            this.layer.children.forEach((item, index) => {
                if (item.attrs.name == 'HtexMask') {
                    that.layer.children.splice(index, 1)
                    items.push(item)
                }

            })
            items.forEach(item => {
                that.layer.children.push(item)
            })
            this.layer.draw()

        },
        importAll(r, target) {
            r.keys().forEach(key => target.push({ pathLong: r(key), pathShort: key }))
        },
        closePlayer() {
            this.isOpenPlayerLink = false
            let media = document.getElementsByClassName('media'),
                i = media.length
            while (i--) {
                media[i].pause()
                media[i].currentTime = 0
            }
            clearTimeout(this.currentPlayerTimeout)
            this.currentPlayerTimeout = ''
        },
        playerMedia() {
            this.currentPlayerTimeout = setTimeout(() => {
                let media = document.getElementsByClassName('media')
                media[0].play()
            }, 1000)
        },
        openCurrentSelectLink(link) {
            //console.log(link)
            if (link.urlType == 'link' || link.urlType == 'file') {
                this.currentObjLink = link.url
                this.openCurrentObjLink()
            }
            if (link.urlType == 'audio') {
                this.currentObjLink = link.url
                this.isOpenPlayerLink = true
                this.currentPlayerHtml = `<audio controls class="media">
          <source src="${this.currentObjLink}"  type="audio/mp3" />
          <p>Your browser doesn't support HTML5 audio. Here is a <a href="${this.currentObjLink}">>link to the audio</a> instead.</p>
          </audio>`
                this.playerMedia()
            }
            if (link.urlType == 'video') {
                this.currentObjLink = link.url
                this.isOpenPlayerLink = true
                this.currentPlayerHtml = `<video controls class="media">
          <source src="${this.currentObjLink}" type="video/mp4" />
          <p>Your browser doesn't support HTML5 audio. Here is a <a href="${this.currentObjLink}">>link to the video</a> instead.</p>
          </video>`
                this.playerMedia()
            }
            this.isOpenMultiLink = false
        },
        openCurrentObjLink() {
            window.open(this.currentObjLink, '_blank')
            this.isOpenObjLink = false
            this.currentObjLink = ''
        },
        async saveImgForNode() {
            this.removeTransformer()
            this.removeTextTransformer()
            let url = this.stage.toDataURL({ mimeType: 'image/jpeg', quality: 0.9 })


            let imgFile = this.dataURLtoFile(url, `${'匿名'}-node-img.jpg`)

            let link = await this.mindMapInfo.mindMapUploadFile(imgFile)
            this.mindMapInfo.currentImgUrl = link.url + '?' + this.mindMapStore.mindMapBlobSas
            this.logout()

        },

        //send Img需先將圖片轉換成file
        dataURLtoFile(dataurl, filename) {
            const arr = dataurl.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], filename, { type: mime })
        },
        addWhiteRectBg() {
            let ratio = this.relativScaleRatio.ratio ? this.relativScaleRatio.ratio : 1
            let background = new Konva.Rect({
                uuid: this.genUUID(),
                x: 0,
                y: this.relativScaleRatio.screenOffsetY / ratio,
                fill: 'white',
                width: window.innerWidth / ratio,
                height: window.innerHeight / ratio,
                illLinearGradientStartPoint: { x: 0, y: 0 },
                listening: false,
                index: 0,
                name: 'bgRect',
                draggable: false
            })

            this.layer.add(background)
            background.moveToBottom()
            this.stage.on('dragmove', () => {
                background.absolutePosition({ x: 0, y: 0 })
            })
        },

        addKonvaSingleImg(target, parent, recoverScale = false) { //recoverScale需再*一個倍率
            let that = this
            if (this.blobSas_read == '' && this.$mobileirs.hostmode != 'CC') this.blobSas_read = this.$mobileirs.rdReadOnlyBlobSasToken
            let finallink = target.attrs.src.includes(this.blobSas_read) || target.attrs?.fromLocal ? target.attrs.src : target.attrs.src + '?' + this.blobSas_read

            let imageObj = new Image()
            imageObj.setAttribute('crossOrigin', 'Anonymous')
            imageObj.src = finallink
            imageObj.onload = function () {
                Konva.Image.fromURL(finallink, async function (image) {
                    image.setAttrs({
                        ...target.attrs,
                        parent: parent
                    })

                    parent.add(image)
                    // image.zIndex(target.attrs.index > parent.children.length - 1 ? parent.children.length - 1 : target.attrs.index)
                    if (target.attrs.name == 'boardPdfBg') {
                        image.moveToBottom()
                    } else {
                        if (image.zIndex() == 0) image.zIndex(1)
                    }

                    that.layer.batchDraw()
                })
            }
        },
        //接收到單一物件針對uuid與動作去更新
        updateStageObj(targetAction) {
            // console.log('單一物件渲染!', targetAction)
            if (this.isCoworkStageScaleHT) {
                this.stage.find("Label").forEach((obj) => {
                    if (obj.attrs.name == "namelabel") {
                        obj.remove()
                    }
                });

                this.currentEditor.seat = targetAction?.fromInfo?.seat ? targetAction?.fromInfo?.seat : ""
                this.currentEditor.name = targetAction?.fromInfo?.name ? targetAction?.fromInfo?.name : ""

                let that = this
                let childrenIndex = this.layer.children.findIndex(x => x.attrs.uuid == JSON.parse(targetAction.item).attrs.uuid)
                if (targetAction.action == 'add' || targetAction.action == 'undoDelete') {
                    let normalClassName = ['Line', 'Circle', 'Rect', 'Star', 'Text', 'Path', 'Ellipse', 'RegularPolygon']
                    if (normalClassName.includes(JSON.parse(targetAction.item).className)) {
                        let KonvaItem = new Konva[JSON.parse(targetAction.item).className](JSON.parse(targetAction.item).attrs)

                        //如果傳來的文字物件沒有寬度就給300
                        if (JSON.parse(targetAction.item).className == 'Text') {
                            if (!JSON.parse(targetAction.item).attrs?.width) {
                                KonvaItem.width(300)
                            }
                        }

                        this.layer.add(KonvaItem)
                        if (JSON.parse(targetAction.item).className == 'Rect' && KonvaItem.attrs.name == 'bgRect') {
                            KonvaItem.moveToBottom()
                        }

                        this.layer.batchDraw()
                    } else if (JSON.parse(targetAction.item).className == 'Label') {
                        //文字便利貼
                        let label = new Konva.Label(JSON.parse(targetAction.item).attrs)
                        JSON.parse(targetAction.item).children.forEach(item => {
                            if (item.className == 'Tag') {
                                let tag = new Konva.Tag(item.attrs)

                                label.add(tag)
                            }
                            if (item.className == 'Text') {
                                let text = new Konva.Text(item.attrs)
                                //如果傳來的文字物件沒有寬度就給200
                                if (!item.attrs?.width || !item.attrs?.height) {
                                    text.width(200)
                                    text.height(200)
                                }
                                label.add(text)
                            }
                        })

                        this.layer.add(label)
                        this.layer.batchDraw()
                    } else if (JSON.parse(targetAction.item).className == 'Image') {
                        this.addKonvaSingleImg(JSON.parse(targetAction.item), this.layer)
                    } else if (JSON.parse(targetAction.item).className == 'Group') {
                        let KonvaGroup = new Konva[JSON.parse(targetAction.item).className](JSON.parse(targetAction.item).attrs)

                        JSON.parse(targetAction.item).children.forEach(child => {
                            let normalClassName = ['Line', 'Circle', 'Rect', 'Star', 'Text', 'Path', 'Ellipse', 'RegularPolygon']
                            if (normalClassName.includes(child.className)) {
                                let item = new Konva[child.className]({
                                    parent: KonvaGroup,
                                    ...child.attrs
                                })
                                KonvaGroup.add(item)
                            }
                            if (child.className == 'Image') {
                                this.addKonvaSingleImg(child, KonvaGroup)
                            }
                        })

                        this.layer.add(KonvaGroup)
                        this.layer.batchDraw()
                    }
                } else if (targetAction.action == 'update') {
                    // console.log('更新物件', targetAction)
                    let targetItem = typeof targetAction.item === 'object' ? targetAction.item : JSON.parse(targetAction.item)

                    let childrenIndex = this.layer.children.findIndex(x => x.attrs.uuid == targetItem.attrs.uuid)

                    if (childrenIndex != -1) {
                        this.layer.children[childrenIndex].setAttrs(targetItem.attrs)
                        if (!targetItem.attrs.hasOwnProperty('x')) this.layer.children[childrenIndex].setAttrs({ x: 0 })
                        if (!targetItem.attrs.hasOwnProperty('y')) this.layer.children[childrenIndex].setAttrs({ y: 0 })
                        if (!targetItem.attrs.hasOwnProperty('rotation')) this.layer.children[childrenIndex].setAttrs({ rotation: 0 })
                        if (!targetItem.attrs.hasOwnProperty('scaleX')) this.layer.children[childrenIndex].setAttrs({ scaleX: 1 })
                        if (!targetItem.attrs.hasOwnProperty('scaleY')) this.layer.children[childrenIndex].setAttrs({ scaleY: 1 })


                        if (this.layer.children[childrenIndex].className == 'Label' || this.layer.children[childrenIndex].className == 'Group') {
                            this.layer.children[childrenIndex].children.forEach((item, i) => {
                                item.setAttrs(targetItem.children[i].attrs)
                            })
                        }
                        if (!this.isSelectTouchingNow) { //touch觸控不立刻渲染白板
                            this.layer.batchDraw()
                        }

                        // console.log(this.layer)
                    }
                    //複合物件點擊目標待修
                } else if (targetAction.action == 'delete') {

                    if (childrenIndex != -1) {
                        this.layer.children[childrenIndex].remove()
                        this.layer.draw()
                    }
                } else if (targetAction.action == 'backZIndex') {

                    if (childrenIndex != -1) {
                        this.layer.children[childrenIndex].zIndex(targetAction.Index)
                        this.layer.batchDraw()
                    }
                } else if (targetAction.action == 'moveToTop') {
                    if (childrenIndex != -1) {
                        this.layer.children[childrenIndex].setAttrs(
                            {
                                index: this.layer.children.length
                            }
                        )
                    }

                }
                else if (targetAction.action == 'moveToBottom') {
                    if (childrenIndex != -1) {
                        this.layer.children[childrenIndex].setAttrs(
                            {
                                index: 0
                            }
                        )
                    }
                }
                this.refreshLayerIndex(false)
            }
        },
        refreshLayerIndex(showMsg) {
            if (showMsg) this.$Message.info(this.$t('konvaCanvas.重新整理圖層'))
            console.log(this.layer, "整理圖層")
            this.layer.children.forEach((item, index) => {
                if (item.className == 'Transformer')
                    item.destroy()

            })
            this.layer.children.sort(function (a, b) {
                let indexA = a.attrs.index
                let indexB = b.attrs.index

                if (indexA < indexB) {
                    return -1
                }
                if (indexA > indexB) {
                    return 1
                }
                return 0
            })
            this.moveHtexToTop()
        },

        //接收CC傳來 Konva LayerInfo 分析渲染，同時也接收WebIRS對內點開筆記的初始化二次編輯渲染
        konvaCanvasUploadJsonData(data) {
            let times = new Date().getTime()
            return new Promise((r, j) => {
                let promises = []
                let targetfilename = this.$mobileirs.hostmodeFilePath.slice(1) + localStorage.getItem('bindingNumber') + (this.$mobileirs.HiGroup ? '/Groups/' : '/Clients/') + (this.$mobileirs.HiGroup ? this.$mobileirs.HiGroupID : this.$mobileirs.teammodelId) + '/Task/' + times + 'JsonData.json'
                let blockBlobClient = this.$mobileirs.containerClient.getBlockBlobClient(targetfilename)
                promises.push(blockBlobClient.uploadBrowserData(JSON.stringify(data)))

                Promise.all(promises).then(
                    res => {
                        //console.log('上傳歷程資料到blob konvaCanvasUploadJsonData', this.$mobileirs.blobsasurl + '/' + targetfilename + '?' + this.$mobileirs.rdReadOnlyBlobSasToken)
                        r({
                            url: this.$mobileirs.blobsasurl + '/' + targetfilename + '?' + this.$mobileirs.rdReadOnlyBlobSasToken
                        })
                    },
                    err => {
                        j(err)
                    }
                )
            })
        },
        konvaCanvasUploadFile(currentFile) {
            //直接先對接到mindMapInfo 
            return this.mindMapInfo.mindMapUploadFile(currentFile)
        },
        isShowHintNow() {
            setTimeout(() => {
                this.isShowHint = false
            }, 10000)
            return this.isShowHint
        },
        calScaleRatio(imgTargetWidth, imgTargetHeight, bgWidth, bgHeight) {
            let ratio = 0
            if (imgTargetWidth > bgWidth && imgTargetHeight < bgHeight) {
                ratio = bgWidth / imgTargetWidth
            } else if (imgTargetWidth < bgWidth && imgTargetHeight > bgHeight) {
                ratio = bgHeight / imgTargetHeight
            } else if (imgTargetWidth < bgWidth && imgTargetHeight < bgHeight) {
                ratio = bgWidth / imgTargetWidth < bgHeight / imgTargetHeight ? bgWidth / imgTargetWidth : bgHeight / imgTargetHeight
            } else {
                //1.判斷要縮哪一邊
                let imgWidthMinusCanvasWidth = imgTargetWidth - bgWidth
                let imgHeightMinusCanvasHeight = imgTargetHeight - bgHeight
                //console.log(imgWidthMinusCanvasWidth,imgHeightMinusCanvasHeight)
                if (imgWidthMinusCanvasWidth > imgHeightMinusCanvasHeight) ratio = bgWidth / imgTargetWidth
                else {
                    ratio = bgHeight / imgTargetHeight
                }
            }
            let screenOffsetX = (imgTargetWidth * ratio - bgWidth) / 2 //整體置中與白邊的左右差距
            let screenOffsetY = (imgTargetHeight * ratio - bgHeight) / 2 //整體置中與白邊的上下差距

            let output = {
                ratio: ratio,
                screenOffsetX: screenOffsetX,
                screenOffsetY: screenOffsetY
            }

            return output
        },
        getRelativePointerPosition(node) {
            /*白板缩放后 重新获取新的坐标未知*/
            if (node) {
                let transform = node.getAbsoluteTransform().copy() // 函数将返回相对于传递的节点的指针位置
                transform.invert() // 为了检测相对位置，需要进行逆变换
                let pos = node.getStage().getPointerPosition() // 获取指针(如鼠标或触摸)位置
                return transform.point(pos) // now we find relative point
            }
        },
        removeStageEvents() {
            this.stage.draggable(false)
            document.body.style.cursor = 'default'
            this.stage.off('mouseup touchend')
            this.stage.off('mousedown touchstart click tap dragstart')
            this.stage.off('mousemove touchmove')
            this.removeTransformer()
        },
        removeTargetEvents(target) {
            target.off('dblclick dbltap click tap')
        },
        removeTextTransformer() {
            this.isEditingTextNow = false
            this.stage.find('Transformer').forEach(ele => {
                if (ele.attrs.name == 'defaultTextTr') {
                    ele.destroy()
                }
            })
        },
        removeTransformer() {
            this.stage.find('Transformer').forEach(ele => {
                if (ele.attrs.name == 'default') {
                    ele.destroy()
                }
            })
        },
        hideAllMenu() {
            this.zoomPopVisible = false
            this.penPopVisible = false
            this.shapePopVisible = false
            this.eraserPopVisible = false
        },
        addShape() {
            let that = this
            this.toggleBtnOn = 'shape'
            this.removeStageEvents()
            this.removeTextTransformer()
            this.setGroupChildDraggable(false)
            let stage = this.stage
            let layer = this.layer
            let shapeType = this.shapeState.shapeType
            let shapeBorderColor = this.shapeState.shapeBorderColor
            let shapeColor = this.shapeState.shapeColor
            let rect // 各种图形的容器
            let currentArrow
            let start = []
            let end = [] // 记录绘制图形的起始点坐标、结束点坐标
            let lastClientX
            let lastClientY
            let beginClientX
            let beginClientY

            const pos = stage.getPointerPosition()

            this.stage.on('mouseup touchend', e => {
                that.isPaint = false
                that.moveHtexToTop()
                //儲存undo歷史
                this.undoHistory.push({ action: 'delete', item: JSON.parse(JSON.stringify(layer.children[layer.children.length - 1])) })
                //新增前一動應提供該物件的初始化Attr
                this.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(layer.children[layer.children.length - 1])) })
                this.isModified = true

                if (e.type == 'touchend') {
                    lastClientX = stage.getPointerPosition().x
                    lastClientY = stage.getPointerPosition().y
                } else {
                    lastClientX = e.evt.offsetX
                    lastClientY = e.evt.offsetY
                }

                start = []
                end = []
            })
            this.stage.on('mousedown touchstart', e => {
                that.removeTargetEvents(e.target)
                that.hideAllMenu()
                that.isPaint = true
                that.layer.children.forEach(item => {
                    item.draggable(false)
                })
                let currentIndex = that.layer?.children ? JSON.parse(JSON.stringify(that.layer?.children)).length - 1 : 1
                console.log('shapeBorderColor:' + shapeBorderColor, 'fill:' + shapeColor, "899")
                if (shapeType == 'line') {
                    // 直线
                    rect = new Konva.Line({
                        uuid: this.genUUID(),
                        fill: shapeBorderColor,
                        stroke: shapeBorderColor,
                        strokeWidth: 5,
                        hitStrokeWidth: 30,
                        closed: true,
                        globalCompositeOperation: 'source-over',
                        points: [that.getRelativePointerPosition(stage).x, that.getRelativePointerPosition(stage).y],
                        name: 'line',
                        lineCap: 'round',
                        lineJoin: 'round',
                        index: currentIndex
                    })
                    layer.add(rect)
                } else if (shapeType == 'long-arrow-alt-right') {
                    currentArrow = new Konva.Line({
                        uuid: this.genUUID(),
                        x: that.getRelativePointerPosition(stage).x,
                        y: that.getRelativePointerPosition(stage).y,
                        rotation: 180,
                        offsetX: 0,
                        offsetY: 0,
                        // points: [0, 0, 3.25695, -22.371525, 3.25695, -22.371525, 9.6, -19.2, 0, -38.4, -9.6, -19.2, -3.25695, -22.371525],
                        points: [0, 0, 6.25695, -22.371525, 6.25695, -22.371525, 13.6, -19.2, 0, -38.4, -13.6, -19.2, -6.25695, -22.371525],
                        // fill: 'red',
                        fill: shapeBorderColor,
                        stroke: shapeBorderColor,
                        strokeWidth: 1,
                        hitStrokeWidth: 30,
                        shadowBlur: 0,
                        closed: true,
                        lineCap: 'round',
                        lineJoin: 'round',
                        name: 'arrowline',
                        draggable: true,
                        index: that.layer?.children ? that.layer?.children.length : 1
                    })
                } else if (shapeType == 'square') {
                    // 矩形
                    rect = new Konva.Rect({
                        uuid: this.genUUID(),
                        x: that.getRelativePointerPosition(stage).x - 10,
                        y: that.getRelativePointerPosition(stage).y - 10,
                        width: 10,
                        height: 10,
                        fill: shapeColor,
                        stroke: shapeBorderColor,
                        strokeWidth: 4,
                        name: 'rect',
                        index: that.layer?.children ? that.layer?.children.length : 1
                    })
                } else if (shapeType == 'circle') {
                    // 正圆
                    rect = new Konva.Circle({
                        uuid: this.genUUID(),
                        x: that.getRelativePointerPosition(stage).x - 5,
                        y: that.getRelativePointerPosition(stage).y - 5,
                        // radius: 50,
                        radius: 5,
                        fill: shapeColor,
                        stroke: shapeBorderColor,
                        strokeWidth: 4,
                        name: 'circle',
                        index: that.layer?.children ? that.layer?.children.length : 1
                    })
                } else if (shapeType == 'ellipse') {
                    // 椭圆
                    rect = new Konva.Ellipse({
                        uuid: this.genUUID(),
                        x: that.getRelativePointerPosition(stage).x - 5,
                        y: that.getRelativePointerPosition(stage).y - 5,
                        // radius: 50,
                        radiusX: 5,
                        radiusY: 5,
                        fill: shapeColor,
                        stroke: shapeBorderColor,
                        strokeWidth: 4,
                        name: 'ellipse',
                        index: that.layer?.children ? that.layer?.children.length : 1
                    })
                } else if (shapeType == 'campground') {
                    // 三角形
                    rect = new Konva.RegularPolygon({
                        uuid: this.genUUID(),
                        x: that.getRelativePointerPosition(stage).x - 10,
                        y: that.getRelativePointerPosition(stage).y - 5,
                        sides: 3,
                        radius: 10,
                        fill: shapeColor,
                        stroke: shapeBorderColor,
                        strokeWidth: 4,
                        name: 'campground',
                        index: currentIndex
                    })
                } else if (shapeType == 'star') {
                    // 五角星
                    rect = new Konva.Star({
                        uuid: this.genUUID(),
                        x: that.getRelativePointerPosition(stage).x - 7,
                        y: that.getRelativePointerPosition(stage).y - 7,
                        numPoints: 5,
                        innerRadius: 4,
                        outerRadius: 8,
                        fill: shapeColor,
                        stroke: shapeBorderColor,
                        strokeWidth: 4,
                        name: 'star',
                        index: currentIndex
                    })
                }

                layer.draw()
            })
            stage.on('mousemove touchmove', function (e) {
                // 直线的
                if (!that.isPaint) {
                    return
                }
                const pos = stage.getPointerPosition()

                if (shapeType == 'long-arrow-alt-right') {
                    const newPoints = currentArrow.points()
                    const deltaX = that.getRelativePointerPosition(stage).x - currentArrow.x()
                    const deltaY = that.getRelativePointerPosition(stage).y - currentArrow.y()

                    let newArrowHeight = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2))
                    if (newArrowHeight < 38.4) newArrowHeight = 38.4
                    const newRotation = (Math.atan2(deltaX, -deltaY) * 180) / Math.PI
                    newPoints[1] = newArrowHeight - 38.4
                    currentArrow.offsetY(newArrowHeight - 38.4)
                    currentArrow.points(newPoints)
                    currentArrow.rotation(newRotation)
                    //   console.log(currentArrow)
                    layer.add(currentArrow)
                    layer.batchDraw()
                }
                document.body.classList.remove('cursor-fdj')
                if (!rect) {
                    return
                }
                let name = rect.attrs.name
                if (name == 'line') {
                    let newPoints
                    if (shapeType == 'line') {
                        if (rect.points().length > 4) {
                            newPoints = rect
                                .points()
                                .slice(0, 2)
                                .concat([that.getRelativePointerPosition(stage).x, that.getRelativePointerPosition(stage).y])
                        } else {
                            newPoints = rect.points().concat([that.getRelativePointerPosition(stage).x, that.getRelativePointerPosition(stage).y])
                        }
                    }
                    rect.points(newPoints)
                } else if (name == 'rect') {
                    // 矩形
                    if (start.length < 1) {
                        start = [pos.x + 10, pos.y + 10]
                    }
                    let newPoints = end.concat([pos.x + 10, pos.y + 10]).slice(-2)

                    rect.width(newPoints[0] - start[0] + 10)
                    rect.height(newPoints[1] - start[1] + 10)
                } else if (name === 'circle') {
                    // 正圆
                    if (start.length < 1) {
                        start = [pos.x + 5, pos.y + 5]
                    }
                    let newPoints = end.concat([pos.x + 5, pos.y + 5]).slice(-2)
                    let radiusX = newPoints[0] - start[0] + 5
                    let radiusY = newPoints[1] - start[1] + 5

                    if (radiusX < 10 || radiusY < 10) {
                        return
                    }
                    rect.radius(newPoints[0] - start[0] + 5)
                    // rect.radiusY(newPoints[1] - start[1] + 5);
                } else if (name === 'ellipse') {
                    // 椭圆
                    if (start.length < 1) {
                        start = [pos.x + 5, pos.y + 5]
                    }
                    let newPoints = end.concat([pos.x + 5, pos.y + 5]).slice(-2)
                    let radiusX = newPoints[0] - start[0] + 5
                    let radiusY = newPoints[1] - start[1] + 5
                    if (radiusX < 10 || radiusY < 10) {
                        return
                    }
                    rect.radiusX(newPoints[0] - start[0] + 5)
                    rect.radiusY(newPoints[1] - start[1] + 5)
                } else if (name == 'campground') {
                    // 三角形&多边形
                    if (start.length < 1) {
                        start = [pos.x - 10, pos.y - 5]
                    }
                    let newPoints = end.concat([pos.x, pos.y]).slice(-2)
                    let radiusX = newPoints[0] - start[0] + 5
                    rect.radius(newPoints[0] - start[0])
                } else if (name == 'star') {
                    // 五角星
                    if (start.length < 1) {
                        start = [pos.x - 4, pos.y - 4]
                    }
                    let newPoints = end.concat([pos.x, pos.y]).slice(-2)
                    rect.innerRadius(newPoints[0] - start[0])
                    rect.outerRadius((newPoints[0] - start[0]) * 2)
                }
                layer.add(rect)
                layer.draw()
            })
        },
        setGroupChildDraggable(value) {
            this.stage.find('Group').forEach(function (ele, i) {
                ele.children.forEach(child => {
                    child.draggable(value)
                })
            })
        },
        addDraw() {
            let that = this
            this.toggleBtnOn = 'pen'
            this.removeStageEvents()
            this.removeTextTransformer()
            this.setGroupChildDraggable(false)

            this.stage.on('mouseup touchend', e => {
                this.drawHistory = []
                that.isPaint = false

                let newElement = that.layer.children[that.layer.children.length - 1]

                if (newElement) {
                    //儲存undo歷史
                    this.undoHistory.push({ action: 'delete', item: JSON.parse(JSON.stringify(newElement)) })
                    //新增前一動應提供該物件的初始化Attr
                    this.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(newElement)) })
                }
                this.isModified = true
            })
            this.stage.on('mousedown touchstart', e => {
                this.isDrawOut = this.drawHistory.length > 0 && this.drawHistory[this.drawHistory.length - 1] === 'mouseDown';
                this.drawHistory.push('mouseDown')
                that.removeTargetEvents(e.target)
                that.hideAllMenu()
                that.isPaint = true
                that.layer.children.forEach(item => {
                    item.draggable(false)
                    if (item.attrs?.name == 'HtexGroup') {
                        item.children.forEach((child) => {
                            child.draggable(false);
                        });
                    }
                    if (item.attrs.penType == 'magic') {
                        item.destroy()
                    }
                })
                let finalWidth = this.$refs.penSetting.penType !== 'pencil-alt' ? this.$refs.penSetting.strokeSize * 5 : this.$refs.penSetting.strokeSize * 3
                let currentIndex = that.layer?.children ? JSON.parse(JSON.stringify(that.layer?.children)).length - 1 : 1

                that.lastLine = that.isDrawOut ? that.lastLine : new Konva.Line({
                    uuid: this.genUUID(),
                    stroke: this.$refs.penSetting.strokeColor,
                    strokeWidth: finalWidth,
                    hitStrokeWidth: finalWidth < 50 ? 50 : finalWidth,
                    lineCap: 'round',
                    lineJoin: 'round',
                    opacity: this.$refs.penSetting.opacity,
                    globalCompositeOperation: 'source-over',
                    points: [that.getRelativePointerPosition(that.stage).x, that.getRelativePointerPosition(that.stage).y],
                    name: 'brush',
                    closed: false,
                    shadowBlur: 1,
                    penType: this.$refs.penSetting.penType,
                    shadowColor: 'gray',
                    tension: 0.5,
                    index: currentIndex
                })
                if (that.isPaint) {
                    that.layer.add(that.lastLine)
                }
            })
            this.stage.on('mousemove touchmove', () => {
                // 直线的
                if (!that.isPaint) {
                    return
                } else {
                    let onex = that.getRelativePointerPosition(that.stage).x
                    let oney = that.getRelativePointerPosition(that.stage).y

                    if (that.oldonex == '' && that.oldoney == '') {
                        that.oldonex = onex
                        that.oldoney = oney
                        that.newPoints = that.lastLine.points().concat([onex, oney])
                    } else if (that.oldonex != onex || that.oldoney != oney) {
                        that.oldonex = onex
                        that.oldoney = oney
                        that.newPoints = that.lastLine.points().concat([onex, oney])
                    }
                    that.lastLine.points(that.newPoints)
                    // console.log(that.newPoints)

                    that.layer.batchDraw()
                }
            })
        },
        saveUndoUpdate() {
            //儲存undo歷史
            if (this.currentSelectElement !== '') {
                // console.log('偵測滑鼠點按物件放開', this.currentSelectElement.attrs.x, this.currentSelectElement.attrs.y)
                //選中物件調整細節判斷
                if (this.currentSelectElement.className == 'Rect' && this.currentSelectElement?.parent?.className == 'Transformer') {
                    this.currentSelectElement = this.currentSelectElement.parent._nodes[0]
                    // console.log(this.currentSelectElement, '調整縮放時選中的元素')
                }
                if ((this.currentSelectElement.className == 'Text' && this.currentSelectElement?.parent?.className == 'Label') || (this.currentSelectElement.className == 'Image' && this.currentSelectElement?.parent.attrs.name == 'groupFile')) {
                    this.currentSelectElement = this.currentSelectElement.parent
                }
                if (this.currentSelectElement?.parent?.attrs.name == 'HtexGroup') this.currentSelectElement = this.currentSelectElement.parent

                let finalhistoryItem = this.undoHistory[this.undoHistory.length - 1]
                if (!this.currentSelectElement.attrs.hasOwnProperty('x')) this.currentSelectElement.attrs.x = 0
                if (!this.currentSelectElement.attrs.hasOwnProperty('y')) this.currentSelectElement.attrs.y = 0
                if (!this.currentSelectElement.attrs.hasOwnProperty('rotation')) this.currentSelectElement.attrs.rotation = 0
                if (!this.currentSelectElement.attrs.hasOwnProperty('scaleX')) this.currentSelectElement.attrs.scaleX = 1
                if (!this.currentSelectElement.attrs.hasOwnProperty('scaleY')) this.currentSelectElement.attrs.scaleY = 1

                //針對Group儲存位置資料缺失定位
                if (this.currentSelectElement.attrs.name == 'HtexGroup') {
                    if (finalhistoryItem?.action != 'update') {
                        this.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(this.currentSelectElement)) })
                        this.isModified = true
                    } else {
                        let finalhistoryItemAttr = JSON.parse(this.undoHistory[this.undoHistory.length - 1].item)
                        // console.log(this.currentSelectElement.attrs.x, finalhistoryItemAttr.attrs.x)
                        // console.log(this.currentSelectElement.attrs.y, finalhistoryItemAttr.attrs.y)
                        // console.log(this.currentSelectElement.attrs.rotation, finalhistoryItemAttr.attrs.rotation)
                        // console.log(this.currentSelectElement.attrs.scaleX, finalhistoryItemAttr.attrs.scaleX)
                        //  console.log(this.currentSelectElement.attrs.uuid==finalhistoryItemAttr.attrs.uuid)
                        if (this.currentSelectElement.attrs.uuid == finalhistoryItemAttr.attrs.uuid) {
                            if (!finalhistoryItemAttr.attrs.hasOwnProperty('x')) finalhistoryItemAttr.attrs.x = 0
                            if (!finalhistoryItemAttr.attrs.hasOwnProperty('y')) finalhistoryItemAttr.attrs.y = 0
                            if (!finalhistoryItemAttr.attrs.hasOwnProperty('rotation')) finalhistoryItemAttr.attrs.rotation = 0
                            if (!finalhistoryItemAttr.attrs.hasOwnProperty('scaleX')) finalhistoryItemAttr.attrs.scaleX = 1
                            if (!finalhistoryItemAttr.attrs.hasOwnProperty('scaleY')) finalhistoryItemAttr.attrs.scaleY = 1
                            if (this.currentSelectElement.attrs.x != finalhistoryItemAttr.attrs.x || this.currentSelectElement.attrs.y != finalhistoryItemAttr.attrs.y) {
                                this.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(this.currentSelectElement)) })
                                this.isModified = true
                            }
                        } else {
                            this.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(this.currentSelectElement)) })
                            this.isModified = true
                        }
                    }
                } else {
                    if (finalhistoryItem?.action != 'update') {
                        this.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(this.currentSelectElement)) })
                        this.isModified = true
                    } else {
                        let finalhistoryItemAttr = JSON.parse(this.undoHistory[this.undoHistory.length - 1].item)
                        // console.log(this.currentSelectElement.attrs.x, finalhistoryItemAttr.attrs.x)
                        // console.log(this.currentSelectElement.attrs.y, finalhistoryItemAttr.attrs.y)
                        // console.log(this.currentSelectElement.attrs.rotation, finalhistoryItemAttr.attrs.rotation)
                        // console.log(this.currentSelectElement.attrs.scaleX, finalhistoryItemAttr.attrs.scaleX)
                        if (this.currentSelectElement.attrs.x != 0 && this.currentSelectElement.attrs.y != 0) {
                            //判斷位移是否相同
                            if (this.currentSelectElement.attrs.x != finalhistoryItemAttr.attrs.x || this.currentSelectElement.attrs.y != finalhistoryItemAttr.attrs.y) {
                                this.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(this.currentSelectElement)) })
                                this.isModified = true
                            }
                        }
                    }
                }
                // console.log('目前undo動作歷史', this.undoHistory)
                // console.log('目前undo物件', this.currentSelectElement.attrs)
            }
        },
        //編輯選中的文字便利貼
        isCurrentTexObj() {
            let target = this.currentSelectElement
            // console.log(this.currentSelectElement,"this.currentSelectElement")
            return target.attrs?.name == 'pastText' || target.attrs?.name == 'text' || target.attrs?.name == 'pastTextContent'
        },
        isCurrentTextURL() {
            if (this.isCurrentTexObj()) {
                let URL = this.currentSelectElement.className == "Label" ? this.currentSelectElement?.children[1]?.attrs?.text : this.currentSelectElement?.attrs?.text
                return URL ? this.checkURLinText(URL) != null : false
            } else return false
        },
        openURL_NewTab() {
            let URL = this.currentSelectElement.className == "Label" ? this.currentSelectElement?.children[1]?.attrs?.text : this.currentSelectElement?.attrs?.text
            if (this.checkURLinText(URL)) {
                window.open(this.fixURL(this.checkURLinText(URL)), '_blank');
            }
        },
        fixURL(url) {
            // 使用正則表達式檢查URL是否以"http://"或"https://"開頭
            if (!/^https?:\/\//i.test(url)) {
                // 如果沒有，添加"http://"到URL的開頭
                url = "http://" + url;
            }
            return url;
        },

        checkURLinText(text) {
            const pattern = /(http|https):\/\/[^\s]+|www\.[^\s]+/;

            // 使用正則表達式進行匹配
            const match = text.match(pattern);

            // 如果找到了匹配，則取得匹配的字串值
            const matchedValue = match ? match[0] : null;

            return matchedValue
        },
        editTextObj() {
            let that = this
            let target = this.currentSelectElement
            if (target.attrs.name == 'pastText') {
                target.children.forEach(item => {
                    if (item.attrs.name == 'pastTextContent') {
                        target = item
                    }
                })
            }
            if (target.attrs.name == 'text' && that.toggleBtnOn == 'select') {
                debuglog('進入編輯文字')
                // that.editText(target)
                that.isEditingTextNow = true
                that.openTextbox()
                that.currentEditTextNode = target
                that.$refs.addTextBox.currentAddTextValue = target.attrs.text
                that.$refs.addTextBox.textSize = target.attrs.fontSize
                that.$refs.addTextBox.currentStickerColor = 'transparent'
                that.$refs.addTextBox.textColor = target.attrs.fill
            } else if (target.attrs.name == 'pastTextContent' && that.toggleBtnOn == 'select') {
                console.log(target)
                that.isEditingTextNow = true
                that.openTextbox()
                that.currentEditTextNode = target.parent
                that.$refs.addTextBox.currentAddTextValue = target.attrs.text
                that.$refs.addTextBox.currentStickerColor = target.parent.children[0].attrs.fill
                that.$refs.addTextBox.textSize = target.attrs.fontSize
                that.$refs.addTextBox.textColor = target.attrs.fill
            }
        },
        //選取物件及其拉伸變形的行為定義
        select() {
            let that = this
            this.toggleBtnOn = 'select'
            this.removeStageEvents()
            document.body.style.cursor = 'pointer'
            //konva變形奇怪的地方，點到目標Transform的上下節點，初次加入會把非目標單行英文的node文字變形，變形完設定為1
            this.stage.find('Text').forEach(function (ele, i) {
                ele.on('transformend', function () {
                    ele.scale({ x: 1, y: 1 })
                    that.layer.batchDraw()
                })
            })
            this.stage.on('mouseup touchend', e => {
                // that.removeTargetEvents(e.target)
                that.saveUndoUpdate()
                if (e.type != 'mouseup') {
                    that.isSelectTouchingNow = false
                }

            })
            this.stage.on('mousedown touchstart click tap dragstart', e => {
                if (e.type == 'touchstart') {
                    that.isSelectTouchingNow = true
                }

                that.hideAllMenu()
                let target = e.target
                let stage = that.stage
                let layer = that.layer
                if (!that.isRenderHTEX && target?.parent?.attrs.name == 'HtexGroup') {
                    // console.log('第三種點擊', target)
                    document.body.style.cursor = 'pointer'
                    target.draggable(false)
                    // target.parent.draggable(true)
                }
                //響應無限複製
                if (target.attrs?.copy == true) {
                    that.removeTargetEvents(target)
                    target.draggable(false)
                    target.on('click', function (e) {
                        that.currentMenuObj = target
                        let copyObj = that.currentMenuObj.clone({
                            uuid: this.genUUID(),
                            x: isNaN(that.currentMenuObj.attrs?.x) ? -Math.floor(Math.random() * 40) : that.currentMenuObj.attrs.x - Math.floor(Math.random() * 40),
                            y: isNaN(that.currentMenuObj.attrs?.y) ? -Math.floor(Math.random() * 40) : that.currentMenuObj.attrs.y - Math.floor(Math.random() * 40),
                            index: that.layer.children.length - 1,
                            copy: false, //無限複製
                        })

                        if (that.currentMenuObj.attrs.name == 'HtexGroup')
                            copyObj.children.forEach(child => {
                                child.attrs.parent = child.parent
                            })
                        //儲存undo歷史
                        that.undoHistory.push({ action: 'delete', item: JSON.parse(JSON.stringify(copyObj)) })
                        that.layer.add(copyObj)
                        let uuid = copyObj.attrs.uuid


                        that.myFinalSendCoworkAction = { action: 'add', uuid: uuid }
                        that.layer.batchDraw()
                        that.stage.find('Transformer').forEach(function (ele, i) {
                            if (ele.attrs.name == 'default') {
                                ele.destroy()
                            }
                        })
                        that.currentMenuObj = ''
                    })



                }
                //vue3環境好像無法用target==stage判斷，改用target?.className==undefined 去判斷
                if (target?.className == undefined || target.attrs.name == 'boardPdfBg' || target.attrs.name == 'bgRect' || target.attrs.name == 'namelabel') {
                    that.showObjMenu = false
                    that.currentSelectElement = ''
                    that.removeTransformer()
                    that.removeTextTransformer()
                } else if (target.attrs.name !== 'objBtn' && !target.attrs?.copy) { //無限複製
                    if (that.toggleBtnOn == 'select') {
                        that.currentSelectElement = target
                        that.saveUndoUpdate()
                        that.removeTargetEvents(target)
                        target.on('dblclick dbltap click tap', function (e) {
                            if (target.attrs.hasOwnProperty('links') || target.attrs.hasOwnProperty('filelink')) {
                                debuglog('單擊或雙擊的物件連結', target.attrs?.filelink, target.attrs?.filelinkType, target.attrs?.links)

                                if (target.attrs.hasOwnProperty('links') || target.attrs.hasOwnProperty('filelink')) {
                                    if (target.attrs?.filelinkType == 'audio' && target.attrs?.links.length == 1) {
                                        //偵測到單一個聲音連結
                                        that.openCurrentSelectLink({
                                            url: target.attrs?.filelink,
                                            urlType: target.attrs?.filelinkType,
                                            urlShortName: target.parent.children[1].attrs.text
                                        })
                                    }
                                    if (target.attrs?.filelinkType == 'audio' && target.attrs?.links.length > 1) {
                                        //偵測到單一個聲音連結，並挾帶連結
                                        that.currentMultiLinks = JSON.parse(JSON.stringify(target.attrs?.links))
                                        that.currentMultiLinks.forEach(link => {
                                            if (link.action == 'ppaction://media') {
                                                link.url = target.attrs?.filelink
                                                    ; (link.urlType = target.attrs?.filelinkType), (link.urlShortName = target.parent.children[1].attrs.text)
                                            } else {
                                                link.urlType = 'link'
                                                link.urlShortName = link.url
                                            }
                                        })
                                        //過濾連結是否有效
                                        that.currentMultiLinks.forEach((link, index) => {
                                            if (!that.validURL(link.url)) that.currentMultiLinks.splice(index, 1)
                                        })
                                        //過濾後僅偵測到單一個聲音連結
                                        if (that.currentMultiLinks.length == 1) that.openCurrentSelectLink(that.currentMultiLinks[0])
                                        //偵測到多個有效連結
                                        if (that.currentMultiLinks.length > 1) that.isOpenMultiLink = true
                                    }

                                    if ((target.attrs?.filelinkType == 'file' && target.attrs?.links.length == 1) || (target.attrs?.filelinkType == 'video' && target.attrs?.links.length == 1)) {
                                        //length為1可能有兩種情況:第一個link可能有url(一般link),可能沒有(file,video)
                                        if (!target.attrs?.links[0]?.url || !that.validURL(target.attrs?.links[0]?.url)) {
                                            const textIndex = target.parent.children.findIndex(item => item.className == 'Text')
                                            that.currentObjLink = {
                                                url: target.attrs?.filelink,
                                                urlType: target.attrs?.filelinkType,
                                                urlShortName: target.parent.children[textIndex].attrs.text
                                            }
                                            target.attrs?.filelinkType == 'file' ? (that.isOpenObjLink = true) : that.openCurrentSelectLink(that.currentObjLink)
                                        } else if (that.validURL(target.attrs?.links[0]?.url) || target.attrs?.links[0]?.action == 'ppaction://media') {
                                            that.currentMultiLinks = []
                                            const textIndex = target.parent.children.findIndex(item => item.className == 'Text')
                                            that.currentMultiLinks.push({
                                                url: target.attrs?.filelink,
                                                urlType: target.attrs?.filelinkType,
                                                urlShortName: target.parent.children[textIndex].attrs.text
                                            })
                                            if (target.attrs?.links[0]?.action == 'ppaction://hlinkpres') {
                                                that.currentMultiLinks.push({
                                                    url: target.attrs?.links[0]?.url,
                                                    urlType: 'link',
                                                    urlShortName: target.attrs?.links[0]?.url
                                                })
                                            }
                                            if (target.attrs?.links[0]?.action == 'ppaction://media') {
                                                that.currentMultiLinks.push({
                                                    url: that.$mobileirs.currentHtexFilePath + '/' + target.attrs?.links[0]?.url + '?' + that.blobSas_read,
                                                    urlType: 'audio',
                                                    urlShortName: target.attrs?.links[0]?.url
                                                })
                                            }
                                            that.isOpenMultiLink = true
                                        }
                                    }

                                    if ((target.attrs?.filelinkType == 'file' && target.attrs?.links.length > 1) || (target.attrs?.filelinkType == 'video' && target.attrs?.links.length > 1)) {
                                        //length為>1可能第一個就不是自己，自己先push
                                        const textIndex = target.parent.children.findIndex(item => item.className == 'Text')
                                        that.currentMultiLinks = []
                                        that.currentMultiLinks.push({
                                            url: target.attrs?.filelink,
                                            urlType: target.attrs?.filelinkType,
                                            urlShortName: target.parent.children[textIndex].attrs.text
                                        })
                                        target.attrs?.links.forEach(link => {
                                            if (link?.action == 'ppaction://hlinkpres' && that.validURL(link?.url)) {
                                                that.currentMultiLinks.push({
                                                    url: link?.url,
                                                    urlType: 'link',
                                                    urlShortName: link?.url
                                                })
                                            }
                                            if (link?.action == 'ppaction://media') {
                                                that.currentMultiLinks.push({
                                                    url: that.$mobileirs.currentHtexFilePath + '/' + link?.url + '?' + that.blobSas_read,
                                                    urlType: 'audio',
                                                    urlShortName: link?.url
                                                })
                                            }
                                        })
                                        that.isOpenMultiLink = true
                                    }
                                    if (!target.attrs?.filelinkType && target.attrs?.links.length >= 1) {
                                        //物件類夾帶檔案
                                        that.currentMultiLinks = []
                                        target.attrs?.links.forEach(link => {
                                            if (link?.action == 'ppaction://hlinkpres' && that.validURL(link?.url)) {
                                                that.currentMultiLinks.push({
                                                    url: link?.url,
                                                    urlType: 'link',
                                                    urlShortName: link?.url
                                                })
                                            }
                                            if (link?.action == 'ppaction://media') {
                                                that.currentMultiLinks.push({
                                                    url: that.$mobileirs.currentHtexFilePath + '/' + link?.url + '?' + that.blobSas_read,
                                                    urlType: 'audio',
                                                    urlShortName: link?.url
                                                })
                                            }
                                        })
                                        if (that.currentMultiLinks.length == 1) {
                                            that.currentObjLink = that.currentMultiLinks[0]
                                            that.currentMultiLinks[0].urlType == 'audio' ? that.openCurrentSelectLink(that.currentObjLink) : (that.isOpenObjLink = true)
                                        }
                                        //偵測到多個有效連結
                                        if (that.currentMultiLinks.length > 1) that.isOpenMultiLink = true
                                    }
                                }
                            }
                            if (e.type == 'dblclick' || e.type == 'dbltap') {
                                if (target.attrs.name == 'text' && that.toggleBtnOn == 'select') {
                                    debuglog('進入編輯文字')
                                    // that.editText(target)
                                    that.isEditingTextNow = true
                                    that.openTextbox()
                                    that.currentEditTextNode = target
                                    that.$refs.addTextBox.currentAddTextValue = target.attrs.text
                                    that.$refs.addTextBox.textSize = target.attrs.fontSize
                                    that.$refs.addTextBox.currentStickerColor = 'transparent'
                                    that.$refs.addTextBox.textColor = target.attrs.fill
                                } else if (target.attrs.name == 'pastTextContent' && that.toggleBtnOn == 'select') {
                                    that.isEditingTextNow = true
                                    that.openTextbox()
                                    that.currentEditTextNode = target.parent
                                    that.$refs.addTextBox.currentAddTextValue = target.attrs.text
                                    that.$refs.addTextBox.currentStickerColor = target.parent.children[0].attrs.fill
                                    that.$refs.addTextBox.textSize = target.attrs.fontSize
                                    that.$refs.addTextBox.textColor = target.attrs.fill
                                }
                            }
                        })

                        target.on('transform', () => {
                            // with enabled anchors we can only change scaleX
                            // so we don't need to reset height
                            // just width
                            if (target.attrs.name == 'text') {
                                target.setAttrs({
                                    width: Math.max(target.width() * target.scaleX(), 20),
                                    height: Math.max(target.height() * target.scaleY(), 20),
                                    scaleX: 1,
                                    scaleY: 1
                                })
                            }
                        })
                        target.on('click tap touchstart', function (e) {

                            if (that.toggleBtnOn == 'select') {
                                document.body.style.cursor = 'pointer'
                                that.removeTransformer()
                                that.removeTextTransformer()
                                let tr = new Konva.Transformer({
                                    anchorStroke: '#00a6ff',
                                    anchorFill: '#fff',
                                    anchorSize: 12,
                                    anchorCornerRadius: 5,
                                    anchorStrokeWidth: 2,
                                    borderStroke: '#6ac9fc',
                                    borderStrokeWidth: 2,
                                    borderDash: [3, 3],
                                    padding: 10,
                                    name: 'default'
                                })

                                if (!that.isRenderHTEX && target.className != 'text' && target.attrs?.name != 'pastTextContent' && target.attrs?.name != 'pastImgContent' && target?.parent?.attrs?.name != 'HtexGroup' && target.attrs?.name != 'HtexGroup' && target.attrs?.name != 'webpage') {
                                    // console.log('第一種點擊', target)
                                    if (!target.attrs?.lock == true) {
                                        document.body.style.cursor = 'pointer'
                                        target.draggable(true)
                                    } else {
                                        target.draggable(false)
                                    }
                                }
                                if (!that.isRenderHTEX && target.attrs.name !== 'boardPdfBg' && target.attrs.name !== 'pastTextContent' && target.attrs.name !== 'pastImgContent' && target.attrs.name != 'pastTextPickName' && target.className != 'text' && target?.parent?.attrs?.name != 'HtexGroup' && target.attrs?.name != 'webpage') {
                                    // console.log('第二種點擊', target)
                                    if (!target.attrs?.lock == true) {
                                        document.body.style.cursor = 'pointer'
                                        layer.add(tr)
                                        tr.nodes([this])
                                        that.addMenuBtnToTr(tr, this)
                                        target.draggable(true)
                                    } else {
                                        target.draggable(false)
                                    }
                                    layer.batchDraw()
                                } else if ((!that.isRenderHTEX && target?.parent?.attrs?.name == 'HtexGroup') || (target.attrs.name == 'pastTextContent' && target.className != 'text') || (target.attrs.name == 'pastTextPickName' && target.className != 'text') || target.attrs.name == 'pastImgContent' || target.attrs.name == 'webpage') {
                                    // console.log('第三種點擊', target)
                                    document.body.style.cursor = 'pointer'
                                    target.draggable(false)
                                    if (!target.parent.attrs?.lock) {
                                        target.parent.draggable(true)
                                        layer.add(tr)
                                        tr.nodes([this.parent])
                                        that.addMenuBtnToTr(tr, this.parent)
                                        layer.draw()
                                    } else {
                                        target.parent.draggable(false)
                                    }
                                } else {
                                    that.showObjMenu = false
                                    tr.nodes([])
                                    layer.batchDraw()
                                    target.draggable(false)
                                }
                                //文字便利貼物件行為
                                if (this.parent && target.attrs.name == 'pastTextContent') {
                                    this.parent.on('transform', function (e) {
                                        let tempText = e.target.children[1]
                                        e.target.children[1].destroy()
                                        e.target.add(
                                            new Konva.Text({
                                                uuid: that.genUUID(),
                                                text: tempText.attrs.text,
                                                fontSize: tempText.attrs.fontSize,
                                                fill: tempText.attrs.fill,
                                                padding: 20,
                                                lineHeight: 1.5,
                                                width: Math.abs(tempText.attrs.width * e.target.attrs.scaleX) > layer.width() ? layer.width() : Math.abs(tempText.attrs.width * e.target.attrs.scaleX),
                                                height: tempText.attrs.height == 'auto' ? (Math.abs(200 * e.target.attrs.scaleY) > layer.height() ? layer.height() : Math.abs(200 * e.target.attrs.scaleY)) : Math.abs(tempText.attrs.height * e.target.attrs.scaleY) > layer.height() ? layer.height() : Math.abs(tempText.attrs.height * e.target.attrs.scaleY),
                                                name: 'pastTextContent',
                                                draggable: false,
                                                listening: true
                                            })
                                        )
                                        e.target.setAttrs({ scaleX: 1, scaleY: 1 })
                                        layer.batchDraw()
                                    })
                                }
                            }
                        })
                        //konva變形奇怪的地方，點到目標Transform的上下節點，初次加入會把非目標單行英文的node文字變形，變形完設定為1
                        stage.find('Text').forEach(function (ele) {
                            ele.on('transformend', function () {
                                this.scale({ x: 1, y: 1 })
                                layer.batchDraw()
                            })
                        })
                    }
                }
            })
        },
        deleteLineInCowork() {
            let that = this
            let stage = this.stage
            let layer = this.layer
            this.toggleBtnOn = 'eraser'
            this.coworkEraserMode = 'eraseLine'
            this.removeStageEvents()
            this.removeTransformer()
            stage.on('mousedown touchstart', e => {

                that.removeTargetEvents(e.target)
                that.removeTransformer()
                let target = e.target
                that.hideAllMenu()

                //可刪除Line但非直線與箭頭
                if (target !== stage && target.attrs.name !== 'boardPdfBg' && target.attrs.name !== 'bgRect' && target.attrs.name !== 'line' && target.attrs.name !== 'arrowline' && target.className == 'Line') {

                    if (target.className == 'Text' && target?.parent?.className == 'Label' || target?.parent?.attrs?.name == 'groupFile') {
                        //協作刪除時點到便利貼文字就刪除整個便利貼
                        that.currentDeleteItem = target.parent
                    } else if (target.attrs?.parent?.attrs?.name == 'HtexGroup') {
                        that.currentDeleteItem = target.attrs.parent
                    } else {
                        that.currentDeleteItem = target
                    }

                    //儲存undo歷史
                    that.undoDeleteHistory.push({ action: 'add', item: JSON.parse(JSON.stringify(that.currentDeleteItem)) })
                    that.undoHistory.push({ action: 'add', item: JSON.parse(JSON.stringify(that.currentDeleteItem)) })
                    that.isModified = true
                    if (that.currentDeleteItem.attrs?.lock == true) that.$Message.warning({ content: `${that.$t('konvaCanvas.刪除鎖定物件提示字')}`, duration: 5, closable: true })

                    //不可直接用remove 因某些物件是參照parent 相關欄位缺失konva內部偶發報錯，需把該實體明確找出來殺掉
                    let konvaObjIndex = layer.children.findIndex(konvaItem => konvaItem.attrs.uuid === that.currentDeleteItem.attrs.uuid)
                    layer.children.splice(konvaObjIndex, 1)
                    layer.batchDraw()
                }
            })


            this.showObjMenu = false
        },
        deleteObjInCowork() {
            let that = this
            let stage = this.stage
            let layer = this.layer
            this.toggleBtnOn = 'eraser'
            this.coworkEraserMode = 'eraseObj'
            this.removeStageEvents()
            this.removeTransformer()
            stage.on('mousedown touchstart', e => {

                that.removeTargetEvents(e.target)
                that.removeTransformer()
                let target = e.target
                that.hideAllMenu()


                if (target !== stage && target.attrs.name !== 'boardPdfBg' && target.attrs.name !== 'bgRect' && target.attrs.name !== 'namelabel') {

                    if (target.className == 'Text' && target?.parent?.className == 'Label' || target?.parent?.attrs?.name == 'groupFile' || target.attrs?.name == 'webpage') {
                        //協作刪除時點到便利貼文字就刪除整個便利貼
                        that.currentDeleteItem = target.parent
                    } else if (target.attrs?.parent?.attrs?.name == 'HtexGroup') {
                        that.currentDeleteItem = target.attrs.parent
                    } else if (target.className == 'Line' && target.attrs.name == 'arrowline' || target.className == 'Line' && target.attrs.name == 'line' || target.className !== 'Line') {
                        //如果是線條只能殺直線或箭頭
                        that.currentDeleteItem = target
                    }
                    else return

                    //儲存undo歷史
                    that.undoDeleteHistory.push({ action: 'add', item: JSON.parse(JSON.stringify(that.currentDeleteItem)) })
                    that.undoHistory.push({ action: 'add', item: JSON.parse(JSON.stringify(that.currentDeleteItem)) })
                    that.isModified = true
                    if (that.currentDeleteItem.attrs?.lock == true) that.$Message.warning({ content: `${that.$t('konvaCanvas.刪除鎖定物件提示字')}`, duration: 5, closable: true })

                    //不可直接用remove 因某些物件是參照parent 相關欄位缺失konva內部偶發報錯，需把該實體明確找出來殺掉
                    let konvaObjIndex = layer.children.findIndex(konvaItem => konvaItem.attrs.uuid === that.currentDeleteItem.attrs.uuid)
                    layer.children.splice(konvaObjIndex, 1)
                    layer.batchDraw()
                }
            })

            this.showObjMenu = false
        },
        deleteObj() {
            let that = this
            let stage = this.stage
            let layer = this.layer
            this.toggleBtnOn = 'eraser'
            this.removeStageEvents()
            this.removeTransformer()
            stage.on('mousedown touchstart', e => {

                that.removeTargetEvents(e.target)
                that.removeTransformer()
                let target = e.target
                that.hideAllMenu()

                if (target !== stage && target.attrs.name !== 'boardPdfBg' && target.attrs.name !== 'bgRect') {

                    if (target.className == 'Text' && target?.parent?.className == 'Label' || target?.parent?.attrs?.name == 'groupFile' || target.attrs?.name == 'webpage') {
                        //協作刪除時點到便利貼文字就刪除整個便利貼
                        that.currentDeleteItem = target.parent
                    } else if (target.attrs?.parent?.attrs?.name == 'HtexGroup') {
                        that.currentDeleteItem = target.attrs.parent
                    } else {
                        that.currentDeleteItem = target
                    }

                    //儲存undo歷史
                    that.undoHistory.push({ action: 'add', item: JSON.parse(JSON.stringify(that.currentDeleteItem)) })
                    that.isModified = true
                    if (that.currentDeleteItem.attrs?.lock == true) that.$Message.warning({ content: `${that.$t('konvaCanvas.刪除鎖定物件提示字')}`, duration: 5, closable: true })

                    //不可直接用remove 因某些物件是參照parent 相關欄位缺失konva內部偶發報錯，需把該實體明確找出來殺掉
                    let konvaObjIndex = layer.children.findIndex(konvaItem => konvaItem.attrs.uuid === that.currentDeleteItem.attrs.uuid)
                    layer.children.splice(konvaObjIndex, 1)
                    layer.batchDraw()
                }
            })

            this.showObjMenu = false
        },
        stageClear() {
            let layer = this.layer
            if (layer.children[0].attrs.name === 'boardPdfBg' || layer.children[0].attrs.name === 'bgRect') {
                //最下面第一層可能是底圖加白底，第二層才是PDF的背景
                if (layer.children[1].attrs.name === 'boardPdfBg') {
                    layer.children.length = 2
                } else {
                    layer.children.length = 1
                }
            } else {
                layer.children.length = 0
            }
            layer.draw()
            setTimeout(() => {
                this.select()
                this.eraserPopVisible = false
            })
        },
        openImgBox() {
            this.showAddImgBox = true
        },
        openTextbox() {
            this.showAddTextBox = true
            this.$refs.addTextBox.currentAddTextValue = ''
            this.currentEditTextNode = ''
        },
        doUndo() {
            if (this.undoHistory == '') {
                alert(this.$t('konvaCanvas["目前沒有動作可復原"]'))
                return
            }

            this.removeTransformer()
            this.removeTextTransformer()
            this.doUndoIndex = this.undoHistory.length - 1
            if (this.undoHistory != '') this.updateStageObj(this.undoHistory[this.undoHistory.length - 1])
            this.undoHistory.pop()
        },
        zoomPanMode() {
            this.showObjMenu = false
            this.zoomPopVisible = true
            this.toggleBtnOn = 'scale'
            let stage = this.stage

            let that = this
            let beginClientX, beginClientY, lastClientX, lastClientY
            let panning = false //平移
            this.removeStageEvents()
            this.removeTextTransformer()



            stage.on('mouseup touchend', e => {
                panning = false
                if (Math.pow(beginClientX - stage.getPointerPosition().x, 2) + Math.pow(beginClientY - stage.getPointerPosition().y, 2) < 4) {
                    that.clickStageToZoom()
                }
                if (e.type == 'touchend') {
                    lastClientX = stage.getPointerPosition().x
                    lastClientY = stage.getPointerPosition().y
                } else {
                    lastClientX = e.evt.offsetX
                    lastClientY = e.evt.offsetY
                }
            })
            stage.on('mousedown touchstart', e => {
                that.hideAllMenu()
                that.removeTargetEvents(e.target)
                document.body.classList.add('cursor-fdj')
                panning = true
                stage.draggable(true)
                if (e.type == 'touchstart') {
                    beginClientX = lastClientX = stage.getPointerPosition().x //touch在舞台 x y 一般event 似乎回傳為空
                    beginClientY = lastClientY = stage.getPointerPosition().y
                } else {
                    beginClientX = lastClientX = e.evt.offsetX
                    beginClientY = lastClientY = e.evt.offsetY
                }
                that.layer.children.forEach(item => {
                    item.draggable(false)
                })
            })
            stage.on('mousemove touchmove', function (e) {
                panning = true
                document.body.classList.remove('cursor-fdj')
                document.body.style.cursor = 'grab'
                if (panning == true && e.type == 'touchmove') {
                    // Update the last X and Y values
                    lastClientX = e.evt.x
                    lastClientY = e.evt.y
                } else {
                    if (panning == true && e.type == 'mousemove') {
                        // Update the last X and Y values
                        lastClientX = e.evt.offsetX
                        lastClientY = e.evt.offsetY
                    }
                }
            })
        },
        clickStageToZoom() {
            if (this.toggleBtnOn == 'scale') {
                //alert('111111')
                if (this.zoomSize < 4) {
                    this.zoomSize += 0.3

                    this.zoomType = 'click'
                    this.setZoomSize(this.zoomSize)
                }
            }
        },
        setZoomSize(value, event) {
            if (this.isCoworkStageScaleHT) {
                this.zoomSize = value;

                // 取得舞台和圖層
                let stage = this.stage;
                let layer = this.layer;

                // 禁用所有圖層中的物件的拖曳功能
                layer.children.forEach(item => {
                    item.draggable(false);
                });

                // 設定縮放倍率
                let zoom = this.zoomSize * this.relativScaleRatio.ratio;

                // 取得滑鼠指標位置
                let pointer = stage.getPointerPosition();

                if (pointer && this.zoomType == 'click') {
                    // 計算滑鼠點擊位置相對於舞台的位置
                    let mousePointTo = {
                        x: (pointer.x - stage.x()) / stage.scaleX(),
                        y: (pointer.y - stage.y()) / stage.scaleY()
                    };
                    // 計算新的位置
                    let newPos = {
                        x: pointer.x - mousePointTo.x * zoom,
                        y: pointer.y - mousePointTo.y * zoom
                    };
                    // 設定新的位置和縮放倍率
                    stage.position(newPos);
                    stage.scale({ x: zoom, y: zoom });
                } else {
                    // 調整舞台的位置和縮放倍率
                    stage.absolutePosition({
                        x: (-stage.width() / 2) * (zoom - 1),
                        y: (-stage.height() / 2) * (zoom - 1)
                    });
                    stage.position({
                        x: (-stage.width() / 2) * (zoom - 1),
                        y: (-stage.height() / 2) * (zoom - 1)
                    });
                    stage.scale({ x: zoom, y: zoom });
                }

                // 延遲設置背景圖形不可拖曳
                setTimeout(() => {
                    layer.children.forEach(item => {
                        if (item.attrs.name == 'bgRect') {
                            item.setAttrs({ draggable: false });
                        }
                    });
                    layer.batchDraw();
                });
            }
        },
        setBarMode() {
            this.zoomType = 'bar'
            this.layer.children.forEach(item => {
                if (item.attrs.name == 'bgRect') {
                    item.setAttrs({
                        x: 0,
                        y: 0,
                        draggable: false
                    })
                }
            })
            this.layer.batchDraw()
        },
        zoomToDefault() {
            this.zoomType = 'default'
            this.setZoomSize(1)
        },
        logoutui() {
            this.isQueryLogout = true
        },
        logout() {
            //離開時記得把底圖清掉
            this.mindMapInfo.inputImgFileBeforeOpenCanvas = ''
            this.mindMapInfo.showImgEditor = false

        },
        validURL(str) {
            var pattern = new RegExp(
                '^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/@[-a-z\\d%_.,~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=!-]*)?', // query string
                // '(\\#[-a-z\\d_]*)?$'
                'i'
            ) // fragment locator
            return !!pattern.test(str)
        },
        moveToTop() {
            if (this.currentSelectElement.className == 'Rect' && this.currentSelectElement.parent.className == 'Transformer') {
                this.currentSelectElement = that.currentSelectElement.parent._nodes[0]
                // console.log(this.currentSelectElement, '調整縮放時選中的元素')
            }
            if (this.currentSelectElement.className == 'Text' && this.currentSelectElement.parent.className == 'Label' || this.currentSelectElement.attrs.name == 'webpage' && this.currentSelectElement.parent.attrs.name == 'webpageGroup') {
                this.currentSelectElement = this.currentSelectElement.parent
            }

            let childrenIndex = this.layer.children.findIndex(x => x.attrs.uuid == this.currentSelectElement.attrs.uuid)
            this.undoHistory.push({ action: 'backZIndex', item: JSON.parse(JSON.stringify(this.currentSelectElement)), Index: childrenIndex })
            this.currentSelectElement.moveToTop()
            this.currentSelectElement.setAttrs(
                {
                    index: this.layer.children.length
                }
            )
            let uuid = this.currentSelectElement.attrs.uuid
            let position = { x: 0, y: 0 }


            this.myFinalSendCoworkAction = { action: 'update', uuid: this.currentSelectElement.attrs.uuid }
            this.layer.batchDraw()
        },
        moveToBottom() {
            if (this.currentSelectElement.className == 'Rect' && this.currentSelectElement.parent.className == 'Transformer') {
                this.currentSelectElement = that.currentSelectElement.parent._nodes[0]
                // console.log(this.currentSelectElement, '調整縮放時選中的元素')
            }
            if (this.currentSelectElement.className == 'Text' && this.currentSelectElement.parent.className == 'Label' || this.currentSelectElement.attrs.name == 'webpage' && this.currentSelectElement.parent.attrs.name == 'webpageGroup') {
                this.currentSelectElement = this.currentSelectElement.parent
            }
            let childrenIndex = this.layer.children.findIndex(x => x.attrs.uuid == this.currentSelectElement.attrs.uuid)
            this.undoHistory.push({ action: 'backZIndex', item: JSON.parse(JSON.stringify(this.currentSelectElement)), Index: childrenIndex })
            this.currentSelectElement.moveToBottom()
            this.currentSelectElement.moveUp()
            this.currentSelectElement.setAttrs(
                {
                    index: 0
                }
            )
            this.setTwoBackObjIndex()


            this.myFinalSendCoworkAction = { action: 'update', uuid: this.currentSelectElement.attrs.uuid }
            this.layer.batchDraw()

        },
        //選取物件時,在變形外框加入子選單按鈕
        addMenuBtnToTr(tr, trNode) {
            this.currentMenuObj = trNode
            let that = this
            const stage = this.stage
            const objbtnbg = new Konva.Circle({
                name: 'objBtn',
                fill: '#d8d8d8',
                radius: 12,
                x: tr.findOne('.top-right').position().x + 30,
                y: tr.findOne('.top-right').position().y + 12
            })
            this.removeTargetEvents(objbtnbg)
            tr.add(objbtnbg)
            const objbtn = new Konva.Path({
                x: tr.findOne('.top-right').position().x + 18,
                y: tr.findOne('.top-right').position().y,
                fill: '#141414',
                data: `<path d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z" fill="#292D32"/>`,
                name: 'objBtn',
                listening: false
            })
            tr.add(objbtn)
            function updatePos() {
                if (tr?.findOne('.top-right')?.position()?.x) {
                    objbtnbg.position({ x: tr.findOne('.top-right').position().x + 30, y: tr.findOne('.top-right').position().y + 12 })
                    objbtn.position({ x: tr.findOne('.top-right').position().x + 18, y: tr.findOne('.top-right').position().y })
                }
            }
            trNode.on('transform', updatePos)
            objbtnbg.on('mouseenter', e => {
                stage.container().style.cursor = 'pointer'
            })

            objbtnbg.on('mouseleave', e => {
                stage.container().style.cursor = 'default'
            })

            objbtnbg.on('click touchstart', e => {
                // console.log('click bg', e.evt.clientX)
                // console.log(trNode)

                that.showObjMenu = true

                let touch, menuX, menuY

                if (e.type == 'touchstart') {
                    touch = e.evt.touches[0] || e.evt.changedTouches[0]
                    menuX = touch.pageX - 60
                    menuY = touch.pageY + 20
                } else {
                    menuX = e.evt.clientX - 60
                    menuY = e.evt.clientY + 20
                }
                that.objMenuPos = { x: menuX, y: menuY }
            })

        },

        deleteObjByObjMenu() {
            //儲存undo歷史
            this.undoDeleteHistory.push({ action: 'add', item: JSON.parse(JSON.stringify(this.currentMenuObj)) })
            this.undoHistory.push({ action: 'add', item: JSON.parse(JSON.stringify(this.currentMenuObj)) })
            this.currentMenuObj.destroy()
            let uuid = this.currentMenuObj.attrs.uuid



            this.myFinalSendCoworkAction = { action: 'delete', uuid: uuid }
            this.stage.find('Transformer').forEach(item => {
                item.destroy()
            })
            this.layer.batchDraw()
            this.showObjMenu = false
        },

        copyPasteTextObjByObjMenu() {
            let copyObj = this.currentMenuObj.clone({
                uuid: this.genUUID(),
                x: isNaN(this.currentMenuObj.attrs?.x) ? -Math.floor(Math.random() * 40) : this.currentMenuObj.attrs.x - Math.floor(Math.random() * 40),
                y: isNaN(this.currentMenuObj.attrs?.y) ? -Math.floor(Math.random() * 40) : this.currentMenuObj.attrs.y - Math.floor(Math.random() * 40),
                index: this.layer.children.length - 1
            })

            if (this.currentMenuObj.attrs.name == 'HtexGroup')
                copyObj.children.forEach(child => {
                    child.attrs.parent = child.parent
                })
            //儲存undo歷史
            this.undoHistory.push({ action: 'delete', item: JSON.parse(JSON.stringify(copyObj)) })
            this.layer.add(copyObj)
            let uuid = copyObj.attrs.uuid



            this.myFinalSendCoworkAction = { action: 'add', uuid: uuid }
            this.layer.batchDraw()

            this.showObjMenu = false
            this.stage.find('Transformer').forEach(function (ele, i) {
                if (ele.attrs.name == 'default') {
                    ele.destroy()
                }
            })
        },
        moveToTopByObjMenu() {
            let childrenIndex = this.layer.children.findIndex(x => x.attrs.uuid == this.currentMenuObj.attrs.uuid)
            this.undoHistory.push({ action: 'backZIndex', item: JSON.parse(JSON.stringify(this.currentMenuObj)), Index: childrenIndex })
            this.currentMenuObj.moveToTop()
            this.currentMenuObj.setAttrs(
                {
                    index: this.layer.children.length
                }
            )
            let uuid = this.currentMenuObj.attrs.uuid
            let position = { x: 0, y: 0 }

            this.myFinalSendCoworkAction = { action: 'update', uuid: uuid }
            this.layer.batchDraw()
        },
        moveToBottomByObjMenu() {
            let childrenIndex = this.layer.children.findIndex(x => x.attrs.uuid == this.currentMenuObj.attrs.uuid)
            this.undoHistory.push({ action: 'backZIndex', item: JSON.parse(JSON.stringify(this.currentMenuObj)), Index: childrenIndex })
            this.currentMenuObj.moveToBottom()
            this.currentMenuObj.moveUp()
            this.currentMenuObj.setAttrs(
                {
                    index: 0
                }
            )
            this.setTwoBackObjIndex()
            let uuid = this.currentMenuObj.attrs.uuid
            let position = { x: 0, y: 0 }


            this.myFinalSendCoworkAction = { action: 'update', uuid: uuid }
            this.layer.batchDraw()
        },
        setTwoBackObjIndex() {
            const bgRect = this.layer.children.find(item => item.attrs.name === 'bgRect');
            const boardPdfBg = this.layer.children.find(item => item.attrs.name === 'boardPdfBg');
            if (boardPdfBg && !bgRect) {
                boardPdfBg.moveToBottom();
            } else if (boardPdfBg && bgRect) {
                boardPdfBg.moveToBottom();
                bgRect.moveToBottom();
            }
        },
        setSendByMe() {
            this.currentEditor.seat = 'me'
            this.currentEditor.name = ''
        },

    },
    beforeDestroy() {
        this.stage.destroy()
        this.stage.destroyChildren()
    }
}
</script>

<style lang="less">
@import '../assets/color.less';
@import '../assets/baseKonvaCanvas.less';


.textinput-qcard {
  position: relative;
  top: -20%;

  @media screen and (max-width: 320px) {
    top: 0%;
  }
}

.textinput-card {
  padding: 10px 20px;
  min-width: 350px;
  background-color: white;

  p {
    font-weight: 600;
    font-size: 20px;
  }

  .textinput {
    border-radius: 5px;
    border: 2px solid @light-color;
    color: @text-color;
    margin-top: 10px;
    width: 100%;

    @media screen and (max-width: 320px) {
      width: auto;
    }

    min-height: 100px;
    font-size: 20px;
    padding: 10px;
  }

}


.cursor-fdj {
    cursor: zoom-in !important;
}

.current-editor {
    color: rgb(255, 255, 255);
    font-size: 10px;
    background-color: #000000;
    display: inline-block;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 1px 5px;
    z-index: 9999;
}
</style>