<template>
  <label class="search-bar">
    <input :placeholder="placeholder?placeholder:$t('搜尋')" v-model="search" />
    <div class="delete-btn" v-show="search.length !== 0" @click="resetSearch()">
      <svg-icon icon-class="close" />
    </div>
    <button
      type="submit"
      class="search-btn"
      @click="searchContent()"
      :class="{ 'dis-search': search.length == 0 }"
    >
      <svg-icon icon-class="search" class="search-icon" />
    </button>
  </label>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
import { useMainStore } from "../stores/mainStore";
import { inject } from "vue";

export default {
  components: { SvgIcon },
  name: "search-bar",
  props: {
        placeholder: {
            type: String,
           
        }
    },
  setup() {
    const store = useMainStore();
    const searchContentAtHome = inject("searchContent", null); //從Home注入
    const searchContentAtCate = inject("searchContentAtCate", null); //從Cate注入

    return { store, searchContentAtHome, searchContentAtCate };
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    resetSearch() {
      this.search = "";
      this.searchContent();
    },
    searchContent() {
      if (
        this.store.currentPage == "" ||
        this.store.currentPage == "management"
      ) {
        this.searchContentAtHome(this.search);
      } else if (this.store.currentPage == "reported") {
        this.$emit("searchReport",this.search)
      } else {
        this.searchContentAtCate(this.search);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search-bar {
  @apply relative flex max-w-2xl items-center rounded-lg bg-white ring-1 ring-black ring-opacity-5;
  input {
    @apply w-full h-10 px-4 py-2 text-gray-800 text-xl rounded-lg;
    outline: none;
    &:focus {
      @apply rounded-lg border-2 border-purple-500;
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
    }
  }

  .search-btn {
    @apply flex h-10 w-12 cursor-pointer items-center justify-center rounded-r-lg bg-purple-500 text-white;

    &:active,
    &:hover {
      @apply bg-purple-600 !important;
    }
    .search-icon {
      font-size: 20px;
    }
  }
  .delete-btn {
    @apply absolute mr-2 mt-0 cursor-pointer;
    color: gray;
    right: 50px;
  }
}
</style>
