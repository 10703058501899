<template>
  <div class="selectedgroup-form">
    <div class="main-form">
      <div class="text-gray-900 font-medium text-lg text-center">
        {{ listType=="group"?"選擇群組":listType=='groupPack'?"選擇群組資源包":"選擇我的資源包" }}
      </div>
      <div class="scroll-wrap">
        <div class="upload-item">
          <SelectMenu
            :category="groupsNamelist"
            :defaultSelect="'未指定'"
            ref="groupsNamelistSelect"
          />
        </div>
      </div>
      <div class="ml-auto mt-5 normal p-4 pt-0">
        <button class="left-btn" v-if="checkShowConfirm" @click="confirm()">
          {{ $t("確定") }}
        </button>
        <button class="right-btn" @click="close()">
          {{ $t("取消") }}
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import SelectMenu from "./SelectMenu.vue";
import { watch } from "vue";
export default {
  name: "selectedgroup-form",
  props: {
   listType: {
      type: String,
      required: true,
   },
    groupsNamelist: {
      type: Array,
      required: true,
      default: function () {
        return ["未指定"];
      },
    },
  },
  data() {
    return {
      checkShowConfirm: false,
    };
  },
  components: {
    SelectMenu,
  },
  mounted() {
    watch(
      () => this.$refs.groupsNamelistSelect.currentSelect,
      async (newVal) => {
        // console.log(newVal);
        if (newVal) {
          this.checkShowConfirm = newVal != "未指定";
        }
      }
    );
  },
  methods: {
    confirm() {
      let data = {
        groupName: this.$refs.groupsNamelistSelect.currentSelect,
      };
      this.$emit("confirm", data);

    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
  
  <style lang="less">
.selectedgroup-form {
  z-index: 999;
  @apply flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800/50;

  .main-form {
    min-width: 40%;
    max-height: 90vh;
    .scroll-wrap {
      @apply pb-16;
    }

    @apply bg-white  rounded-lg relative  flex flex-col pt-4;

    .upload-item {
      @apply my-3 px-5;
      position: absolute;
      display: block;
      height: 40px;
      width: 100%;
      z-index: 100;
      input[type="text"],
      textarea {
        @apply ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6 w-full py-2 px-5 rounded-md mt-2;
      }
      textarea {
        max-height: 180px;
        overflow: auto;
      }
    }
    .title {
      @apply font-bold;
    }
  }

  .normal {
    .left-btn {
      @apply text-white font-bold border py-1 px-4 rounded mr-2 bg-sky-500 border-sky-500;

      &:hover {
        @apply bg-sky-600;
      }
    }

    .right-btn {
      @apply bg-transparent text-gray-700 font-semibold py-1 px-4 border border-gray-500 rounded;

      &:hover {
        @apply bg-sky-100;
      }
    }
  }
}
</style>