export default {
  blobsasurl:"https://teammodeltest.blob.core.windows.net/ht-community/",
  apiUrl: {
    global: "https://api2.teammodel.net",
    //global: "https://ies5-rc.teammodel.net",
    cn: "https://api2.teammodel.cn",
    globalrc: "https://api2-rc.teammodel.net",
    cnrc: "https://api2-rc.teammodel.cn",
  },
  accountUrl: {
    global: "https://account.teammodel.net",
    cn: "https://account.teammodel.cn",
    globalrc: "https://account-rc.teammodel.net",
  },
  clientID: {
    global: "4a6fd5c8-dfe2-4aef-ad9c-d5cb04488ae3",
    cn: "06113516-efe3-43ce-ba7e-6b9fcf8776db",
  },
  clientsecret: {
    global: "qWN8Q~GO.rZp4zRsbOJrVGVNuI9M4b-1lqcp2beQ",
    cn: " UfHu3_~Qe9Ps1w~r-lICqCtNjoo7dH4Jp8",
  },
  htCommunityUrl: {
    global: "https://HTCommunity.teammodel.net",
    // cn: "https://account.teammodel.cn",
    globalrc: "https://htcommunity-rc.teammodel.net",
  },
  ies5ApiUrl: {
    global: "https://www.teammodel.net",    
    globalrc: "https://ies5-rc.teammodel.net",
    globaltest: "https://test.teammodel.net",
  },
};
