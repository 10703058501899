<template>
  <div class="group-profile">
    <SelectedGroupForm
      v-if="showSelectedPackForm"
      @confirm="moveToPack"
      @close="showSelectedPackForm = false"
      :groupsNamelist="packslist"
      :listType="'pack'"
    />
    <SelectedGroupForm
      v-if="showSelectedGroupPackForm"
      @confirm="moveToGroupPack"
      @close="showSelectedGroupPackForm = false"
      :groupsNamelist="groupPackslist"
      :listType="'groupPack'"
    />
    <CreatePackForm
      v-if="showCreatePackForm"
      @close="closeCreatePackForm"
      @confirm="createOrUpdatePack"
      :packFormMode="packFormMode"
      :packPageMode="'group'"
      :materials="materialsForEditPack"
      :blobsas="blobsas"
    />
    <Modal
      v-if="showConfirmDeletePackModal"
      :option="deleteModalOptionForPack"
      @close="showConfirmDeletePackModal = false"
      @confirm="deletePack()"
    />
    <GroupInvitationForm
      v-show="showGroupInvitationForm"
      :groupMembers="currentGroupProfile.groupMembers"
      @close="showGroupInvitationForm = false"
      @confirm="sendInvitations"
    />
    <EditManagerModal
      v-show="showEditManagerModal"
      :groupMembers="currentGroupProfile.groupMembers"
      :groupCreator="currentGroupProfile.groupCreator"
      :isCurrentAccountManager="isCurrentAccountManager"
      :currentLoginAccount="currentLoginAccount"
      @close="showEditManagerModal = false"
      @confirm="saveGroupManagersSetting"
    />
    <EditMaterialForm
      v-if="showEditMaterialForm"
      @close="closeEditMaterialForm"
      @confirm="updateMaterial"
    />
    <Modal
      v-if="showConfirmDeleteMemberModal"
      :option="deleteModalOption"
      @close="closeDeleteMemberModal"
      @confirm="saveGroupMembersSetting"
    />
    <Modal
      v-if="showConfirmLeaveModal"
      :option="leaveModalOption"
      @close="showConfirmLeaveModal = false"
      @confirm="leaveGroup()"
    />
    <Modal
      v-if="showConfirmDeleteModal"
      :option="deleteMaterialModalOption"
      @close="showConfirmDeleteModal = false"
      @confirm="deleteMaterial()"
    />
    <div class="userinfo-card">
      <div class="left-part">
        <div
          class="profile-img"
          :style="{ background: getGroupImgUrl(currentGroupProfile) }"
        ></div>
      </div>
      <div class="right-part">
        <div class="ml-5 text-3xl font-bold">
          {{ currentGroupProfile.groupName }}
        </div>
        <p class="ml-5">
          {{ $t("簡介") }}:
          <span v-html="urlify(currentGroupProfile.groupIntro)" />
        </p>
        <p class="ml-5">
          {{ "群組加入教材需管理員審查" }}:
          {{ currentGroupProfile.isUploadToReview }}
        </p>
        <p class="ml-5">
          {{ "群組開放是否延續審查" }}:
          {{ currentGroupProfile.isStayOldReview}}
        </p>
        <div class="ml-5 btn-group" v-show="isLogin">
          <p
            class="normal-btn"
            @click="addWaitingList()"
            v-show="!isCurrentAccountMember && !isWaitingMember"
          >
            加入群組
          </p>
          <p class="normal-btn" v-show="isWaitingMember">等候同意加入...</p>
          <p
            class="normal-btn"
            v-show="isCurrentAccountMember && !isCurrentAccountManager"
            @click="showConfirmLeaveModal = true"
          >
            離開群組
          </p>
          <p
            class="normal-btn"
            v-show="isCurrentAccountManager"
            @click="openInvitationForm()"
          >
            發送邀請
          </p>
        </div>
      </div>
    </div>
    <div class="tab-wrap">
      <span
        class="tab"
        :class="{ 'tab-light': currentTabId == 0 }"
        @click="setTab(0)"
        >群組資源({{ uploadNum }})</span
      >
      <span
        class="tab"
        :class="{ 'tab-light': currentTabId == 3 }"
        @click="setTab(3)"
        >群組資源包({{ uploadPackNum }})</span
      >
      <span
        class="tab"
        :class="{ 'tab-light': currentTabId == 4 }"
        @click="setTab(4)"
        v-show="
          (isLogin && currentGroupProfile.isUploadToReview) ||
          (isLogin && currentGroupProfile.isStayOldReview)
        "
        >待審資源(包)</span
      >
      <span
        class="tab"
        :class="{ 'tab-light': currentTabId == 1 }"
        @click="setTab(1)"
        v-show="isLogin"
        >組員管理</span
      ><span
        class="tab"
        :class="{ 'tab-light': currentTabId == 2 }"
        @click="setTab(2)"
        v-show="isLogin"
        >群組設定</span
      >
    </div>
    <div v-show="currentTabId == 0" class="card-wrap">
      <Card
        v-for="item in gMaterials"
        v-show="showNotHiddenReview(item)"
        :key="item.id"
        :materials="item"
        :blobsas="blobsas"
        :option="checkCardOption(item)"
        @viewContent="viewContent(item)"
        @moveOutFromGroup="moveOutFromGroup"
        @confirmDelete="confirmDelete"
        @openEditMaterialForm="openEditMaterialForm"
        @confirmAddToPack="confirmAddToPack()"
        @confirmAddToGroupPack="confirmAddToGroupPack()"
      />
      <div class="text-md ml-5 mt-5 font-bold" v-show="!gMaterials.length">
        {{ $t("暫無資料") }}
      </div>
    </div>

    <div v-show="currentTabId == 1" class="member-management">
      <div class="member-section" v-show="currentGroupProfile.waitingMembers &&
            currentGroupProfile.waitingMembers != ''">
        <p class="title">等候加入</p>
        <div
          class="btn-top"
          v-show="
            isCurrentAccountManager &&
            currentGroupProfile.waitingMembers &&
            currentGroupProfile.waitingMembers != ''
          "
        >
          <div class="btn-inner-right">
            <div
              class="approve-btn approve-all mr-2"
              @click="setApproveAllWaitings(true)"
            >
              全部同意
            </div>
            <div
              class="cancel-btn approve-all mr-5"
              @click="setApproveAllWaitings(false)"
            >
              全部取消
            </div>
            <div
              @click="saveWaitingMembersSetting()"
              class="approve-all approve-btn"
              v-show="currentTabId == 1"
            >
              儲存
            </div>
          </div>
        </div>

        <div class="member-wra-scroll">
          <div
            class="member-wrap"
            v-for="(member, index) in currentGroupProfile.waitingMembers"
            :key="'member' + member.id"
          >
            <p class="num">{{ index + 1 }}</p>
            <img :src="member.picture" alt="" />
            <p class="name">{{ member.name }}</p>

            <div class="icon-wrap">
              <div v-show="member.approve" class="check-icon">
                <svg-icon icon-class="check" />
              </div>
            </div>

            <div
              class="approve-btn"
              @click="setApproveSingleWaiting(index, true)"
              v-show="!member.approve && isCurrentAccountManager"
            >
              同意
            </div>
            <div
              class="cancel-btn"
              @click="setApproveSingleWaiting(index, false)"
              v-show="member.approve && isCurrentAccountManager"
            >
              取消
            </div>
          </div>
        </div>
      </div>
      <div class="member-section">
        <p class="title">目前組員</p>
        <div class="btn-top" v-show="isCurrentAccountManager">
          <div class="btn-inner-right">
            <div
              class="cancel-btn approve-all mr-5"
              @click="setRemoveAllGroupMembers(false)"
            >
              全部取消
            </div>
            <div
              @click="showConfirmDeleteMemberModal = true"
              class="approve-all approve-btn"
              v-show="currentTabId == 1"
            >
              儲存
            </div>
          </div>
        </div>
        <div class="member-wra-scroll">
          <div
            class="member-wrap"
            v-for="(member, index) in currentGroupProfile.groupMembers"
            :key="'member' + member.id"
          >
            <p class="num">{{ index + 1 }}</p>
            <img :src="member.picture" alt="" />
            <p class="name">{{ member.name }}</p>
            <p
              class="creator"
              v-show="member.id == currentGroupProfile.groupCreator.id"
            >
              群組創立者
            </p>
            <p
              class="creator"
              v-show="
                member.isManager &&
                member.id !== currentGroupProfile.groupCreator.id
              "
            >
              管理員
            </p>
            <div class="icon-wrap">
              <div v-show="member.remove" class="remove-icon">
                <svg-icon icon-class="close" />
              </div>
            </div>
            <div
              class="remove-btn"
              @click="setRemoveSingleGroupMember(index, true)"
              v-show="
                isCurrentAccountManager &&
                !(
                  member.id == currentLoginAccount.id && isCurrentAccountManager
                ) &&
                !member.remove &&
                !member.isManager
              "
            >
              移除
            </div>
            <div
              class="cancel-btn"
              @click="setRemoveSingleGroupMember(index, false)"
              v-show="
                isCurrentAccountManager &&
                !(
                  member.id == currentLoginAccount.id && isCurrentAccountManager
                ) &&
                member.remove == true
              "
            >
              取消
            </div>
          </div>
        </div>
      </div>
      <div class="member-section">
        <p class="title">管理員</p>
        <div class="btn-top" v-show="isCurrentAccountManager">
          <div class="btn-inner-right">
            <div
              @click="showEditManagerModal = true"
              class="approve-all approve-btn"
              v-show="currentTabId == 1"
            >
              設置管理員
            </div>
          </div>
        </div>
        <div
          class="member-wrap"
          v-for="(member, index) in currentGroupProfile.groupManagers"
          :key="'manager' + member.id"
        >
          <p class="num">{{ index + 1 }}</p>
          <img :src="member.picture" alt="" />
          <p class="name">{{ member.name }}</p>
          <p
            class="creator"
            v-show="member.id == currentGroupProfile.groupCreator.id"
          >
            群組創立者
          </p>
        </div>
      </div>
    </div>
    <!--更新群組資訊或之後群組審批可能會一次擴充欄位儲存-->
    <div v-show="currentTabId == 2" class="group-updInfo">
      <div class="main-form">
        <div class="px-8 font-bold">更新群組資訊</div>
        <div class="scroll-wrap">
          <div class="upload-item">
            <label>
              <p class="title">{{ "群組名" }}</p>
              <input type="text" v-model="editTitle" placeholder="必填" />
            </label>
          </div>
          <div class="upload-item">
            <label>
              <p class="title">{{ "群組介紹" }}</p>
              <textarea v-model="editIntro" placeholder="必填" />
            </label>
          </div>
          <div class="upload-item">
            <label>
              <input type="checkbox" v-model="isUploadToReview" />
              <span class="title">{{ "群組加入教材需管理員審查" }}</span>
            </label>
          </div>
          <div class="upload-item" v-show="showAskStayOldReview">
            <label>
              <input type="checkbox" v-model="isStayOldReview" />
              <span class="title">{{
                "之前未審查完成者需要保留繼續審查(需要請打勾，不勾則直接公開)"
              }}</span>
            </label>
          </div>
          <div class="upload-item">
            <label class="title"
              >群組縮圖(必要)：
              <input
                id="inputfile"
                type="file"
                accept="image/gif, image/jpeg, image/png"
                @change="imagefileChangeHandler"
                required
              />
            </label>
            <img
              class="img-preview"
              v-show="editImgPreview"
              :src="editImgPreview"
              alt=""
            />
          </div>
        </div>
        <div class="ml-auto mt-5 normal p-4 pt-0">
          <button class="left-btn" @click="updateGroupInfo()">
            {{ "儲存" }}
          </button>
        </div>
      </div>
    </div>
    <div v-show="currentTabId == 3" class="pack-area">
      <div class="pack-wrap" v-show="isCurrentAccountMember">
        <div class="pack-ball" @click="openCreatePackForm()">
          <div>
            <p class="plus-icon">+</p>
            <p class="font-bold">創建資源包</p>
          </div>
        </div>
      </div>
      <CardPack
        v-for="item in packs"
        v-show="showNotHiddenReview(item)"
        :key="item.id + item.imgUrl"
        :materials="item"
        :option="checkPackCardOption(item)"
        :blobsas="blobsas"
        @viewContent="viewPackContent(item)"
        @moveOutFromGroup="moveOutPackFromGroup"
        @confirmDelete="showConfirmDeletePackModal = true"
        @openEditPackForm="openEditPackForm"
      />
    </div>
    <div v-show="currentTabId == 4" class="review-management">
      <div class="material-section">
        <div class="m-5 mb-2 text-xl font-bold">待審資源</div>
        <div class="btn-top">
          <div class="btn-inner-right">
            <div
              class="approve-btn approve-all mr-2"
              @click="setApproveAllMaterialsReview(true)"
            >
              全部同意
            </div>
            <div
              class="cancel-btn approve-all mr-5"
              @click="setApproveAllMaterialsReview(false)"
            >
              全部取消
            </div>
            <div
              @click="saveMaterialReviewSetting()"
              class="approve-all approve-btn"
              v-show="currentTabId == 4"
            >
              儲存
            </div>
          </div>
        </div>
        <div class="material-scroll-wrap">
          <CardReview
            v-for="(item, index) in reviewMaterials"
            :key="item.id"
            :materials="item"
            :index="index"
            :blobsas="blobsas"
            @setApproveSingleReview="setApproveSingleReview"
          />
        </div>
      </div>
      <div class="material-section">
        <div class="m-5 mb-2 text-xl font-bold">待審資源包</div>
        <div class="btn-top">
          <div class="btn-inner-right">
            <div
              class="approve-btn approve-all mr-2"
              @click="setApproveAllPacksReview(true)"
            >
              全部同意
            </div>
            <div
              class="cancel-btn approve-all mr-5"
              @click="setApproveAllPacksReview(false)"
            >
              全部取消
            </div>
            <div
              @click="savePackReviewSetting()"
              class="approve-all approve-btn"
              v-show="currentTabId == 4"
            >
              儲存
            </div>
          </div>
        </div>
        <div class="material-scroll-wrap">
          <PackReview
            v-for="(item, index) in reviewPacks"
            :materials="item"
            :key="item.id"
            :index="index"
            :blobsas="blobsas"
            @setApproveSinglePackReview="setApproveSinglePackReview"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "../stores/mainStore";
import Card from "@/components/Card.vue";
import CardPack from "@/components/CardPack.vue";
import SvgIcon from "../components/SvgIcon.vue";
import EditManagerModal from "../components/EditManagerModal.vue";
import GroupInvitationForm from "@/components/GroupInvitationForm.vue";
import EditMaterialForm from "@/components/EditMaterialForm.vue";
import CreatePackForm from "@/components/CreatePackForm.vue";
import SelectedGroupForm from "@/components/SelectedGroupForm.vue";
import CardReview from "@/components/CardReview.vue";
import PackReview from "@/components/PackReview.vue";
import Modal from "@/components/Modal.vue";
import { jsFn } from "@/utils/jsFn";
import jwt_decode from "jwt-decode";
import api from "@/api";
import profile from "../api/profile";
import { inject, watch } from "vue";

const { BlobServiceClient } = require("@azure/storage-blob");

export default {
  name: "group-profile",
  setup() {
    const store = useMainStore();
    const mountMsg = inject("mountMsg"); //從App注入
    return { store, mountMsg };
  },
  data() {
    return {
      //"blob刪除使用"
      blobsasurl: "https://teammodeltest.blob.core.windows.net/ht-community/",
      blobServiceClient: "",
      containerClient: "",

      isLogin: false,
      currentLoginAccount: {},
      isCurrentAccountManager: false,
      isCurrentAccountMember: false,
      isWaitingMember: false,
      currentGroupProfile: "",
      blobsas: "",
      gMaterials: [],
      uploadNum: 0,
      uploadPackNum: 0,
      currentTabId: 0,
      deleteMaterialModalOption: {
        title: "刪除提示",
        btnStyle: "danger",
        desc: "確定刪除這份教材嗎? 刪除後將無法復原!",
        showCloseIconBtn: false,
        showEditItemTitle: true,
      },
      deleteModalOption: {
        title: "移除組員提示",
        btnStyle: "danger",
        desc: "確定移除這些組員嗎? 移除後將無法復原，須個人自行再次加入",
        showCloseIconBtn: false,
        showEditItemTitle: false,
      },
      deleteModalOptionForPack: {
        title: "刪除提示",
        btnStyle: "danger",
        desc: "確定刪除這份資源包嗎? 刪除後將無法復原!",
        showCloseIconBtn: false,
      },
      leaveModalOption: {
        title: "離開提示",
        btnStyle: "danger",
        desc: "確定離開嗎?離開後所有的教材將由群組管理員管理，後續可再次申請加入!",
        showCloseIconBtn: false,
        showEditItemTitle: false,
      },
      showConfirmDeleteMemberModal: false,
      showConfirmDeletePackModal: false,
      showEditManagerModal: false,
      showGroupInvitationForm: false,
      showConfirmLeaveModal: false,
      showCreatePackForm: false,
      packFormMode: "edit",
      cardOption: {
        pageName: "group-profile",
        allowEdit: false,
        allowMoveIn: false,
        allowMoveOut: false,
        allowAddInMyPack: false,
        allowAddInGroupPack: false,
        allowCopy: false,
        allowRemoveFromPack: false,
      },
      showConfirmDeleteModal: false,
      showEditMaterialForm: false,
      editTitle: "",
      editIntro: "",
      isUploadToReview: false,
      editImgPreview: "",
      imageFile: "",
      packs: [],
      materialsForEditPack: [],
      showSelectedPackForm: false,
      showSelectedGroupPackForm: false,
      packslist: [],
      packsOriginal: [],
      groupPackslist: [],
      groupPacksOriginal: [],
      reviewMaterials: [],
      reviewPacks: [],
      isStayOldReview: false, //舊有審查需維持
      showAskStayOldReview: false,
    };
  },
  components: {
    Card,
    Modal,
    SvgIcon,
    EditManagerModal,
    GroupInvitationForm,
    EditMaterialForm,
    CardPack,
    CreatePackForm,
    SelectedGroupForm,
    CardReview,
    PackReview,
  },
  async mounted() {
    watch(
      () => this.isUploadToReview,
      (newvalue, oldvalue) => {
        console.log(newvalue, oldvalue);
        this.showAskStayOldReview = newvalue == false && oldvalue == true;
      }
    );
    jsFn.checkSessionAccessTokenReady().then(async () => {
      this.blobsas =
        this.store.blobsas !== ""
          ? this.store.blobsas
          : await api.main.getSas();

      if (this.store.currentGroupProfile) {
        this.currentGroupProfile = this.store.currentGroupProfile;
        this.Init();
        this.saveLocal(this.currentGroupProfile);
      } else if (sessionStorage.getItem("currentViewGroupProfile")) {
        const deSavedContent = jsFn.decrypt(
          sessionStorage.getItem("currentViewGroupProfile"),
          "123"
        );
        const getSavedContent = JSON.parse(deSavedContent);
        if (this.$route.params.id === getSavedContent.id) {
          this.currentGroupProfile = getSavedContent;
          this.Init();
        }
      } else {
        //沒有本地也沒有點擊，通常是直接給網址的時候，需要直接用api要資料，部分資料仍需要查詢取得 如 組長, 組員名單
        await this.refreshGroupInfo();
      }
      this.initblob();
    });
  },
  methods: {
    showNotHiddenReview(item) {
      if (this.currentGroupProfile.isStayOldReview == false)
        return (
          (!item.hidden &&
            this.currentGroupProfile.isUploadToReview == false) ||
          (!item.hidden &&
            this.currentGroupProfile.isUploadToReview == true &&
            item.groupAllowShow == true)
        );
      else {
        return (
          (!item.hidden &&
            this.currentGroupProfile.isUploadToReview == false &&
            item.groupAllowShow == true) ||
          (!item.hidden &&
            this.currentGroupProfile.isStayOldReview == true &&
            item.groupAllowShow == true)
        );
      }
    },
    urlify(text) {
      return jsFn.urlify(text);
    },
    checkPackCardOption(item) {
      return {
        allowEdit:
          item.packCreator.id === this.currentLoginAccount.id ||
          this.isCurrentAccountManager,
        allowMoveOut:
          item.packCreator.id === this.currentLoginAccount.id ||
          this.isCurrentAccountManager,
        allowRemoveFromPack: false,
      };
    },
    checkCardOption(item) {
      return {
        pageName: "group-profile",
        allowEdit:
          item.uploader.id === this.currentLoginAccount.id ||
          this.isCurrentAccountManager,
        allowMoveOut:
          item.uploader.id === this.currentLoginAccount.id ||
          this.isCurrentAccountManager,
        allowAddInMyPack: this.isLogin,
        allowAddInGroupPack:
          item.uploader.id === this.currentLoginAccount.id ||
          this.isCurrentAccountMember,
        allowRemoveFromPack: false,
      };
    },
    confirmAddToPack() {
      console.log("加入我的資源包!");
      this.showSelectedPackForm = true;
    },
    confirmAddToGroupPack() {
      console.log("加入群組的資源包!");
      this.showSelectedGroupPackForm = true;
    },
    confirmDelete() {
      this.showConfirmDeleteModal = true;
    },
    openEditMaterialForm() {
      this.showEditMaterialForm = true;
    },
    openInvitationForm() {
      this.showGroupInvitationForm = true;
    },
    async getGroupInfo() {
      try {
        const response = await api.main.getGroupInfoById({
          gid: this.$route.params.id,
        });
        return response.data;
      } catch (error) {
        console.error("獲取群組信息時出錯：", error);
        throw error;
      }
    },
    async updateMaterial(data) {
      try {
        await api.main.updateMaterialById(data);
        this.closeEditMaterialForm();
        const res = await api.main.getMaterialsByGroupId({
          gid: this.currentGroupProfile.id,
        });
        this.gMaterials = res.data;
        this.gMaterials.forEach((item) => {
          item.tempGroupAllowShow = false;
        });
        this.uploadNum = this.gMaterials.filter((item) =>
          this.showNotHiddenReview(item)
        ).length;
        this.mountMsg("更新成功!");
      } catch (err) {
        console.log(err);
      }
    },
    closeEditMaterialForm() {
      this.showEditMaterialForm = false;
    },
    closeDeleteMemberModal() {
      this.showConfirmDeleteMemberModal = false;
    },
    setSingleManager(index, status) {
      if (
        this.currentGroupProfile.groupMembers[index].id !==
          this.currentGroupProfile.groupCreator.id &&
        this.currentGroupProfile.groupMembers[index].id !==
          this.currentLoginAccount.id
      )
        this.currentGroupProfile.groupMembers[index].isManager = status;
    },
    setRemoveAllGroupMembers(status) {
      this.currentGroupProfile.groupMembers.forEach(
        (member) => (member.remove = status)
      );
    },
    setRemoveSingleGroupMember(index, status) {
      this.currentGroupProfile.groupMembers[index].remove = status;
    },
    setApproveAllMaterialsReview(status) {
      this.reviewMaterials.forEach((item) => {
        item.tempGroupAllowShow = status;
      });
    },
    setApproveAllPacksReview(status) {
      this.reviewPacks.forEach((item) => {
        item.tempGroupAllowShow = status;
      });
    },
    setApproveSinglePackReview(index, status) {
      console.log(index);
      this.reviewPacks[index].tempGroupAllowShow = status;
    },
    setApproveSingleReview(index, status) {
      console.log(index);
      this.reviewMaterials[index].tempGroupAllowShow = status;
    },
    setApproveAllWaitings(status) {
      this.currentGroupProfile.waitingMembers.forEach(
        (member) => (member.approve = status)
      );
    },
    setApproveSingleWaiting(index, status) {
      console.log(index);
      this.currentGroupProfile.waitingMembers[index].approve = status;
    },

    async leaveGroup() {
      let deleteGroupMembers = [];
      deleteGroupMembers.push(this.currentLoginAccount);
      console.log(this.currentLoginAccount);
      try {
        await api.main.deleteGroupMembers({
          gid: this.currentGroupProfile.id,
          deleteGroupMembers: deleteGroupMembers,
        });
      } catch (error) {
        console.error("Error leaveGroup:", error);
      }
      this.showConfirmLeaveModal = false;
      this.$router.push({
        name: "Profile",
        params: {
          id: this.currentLoginAccount.id,
        },
      });
    },
    async saveMaterialReviewSetting() {
      try {
        console.log("儲存");
        let materials = this.reviewMaterials.filter(
          (item) => item.tempGroupAllowShow
        );
        materials.forEach((item) => {
          item.groupAllowShow = item.tempGroupAllowShow;
        });

        await api.main.updateMaterials(materials);

        const res = await api.main.getMaterialsByGroupId({
          gid: this.currentGroupProfile.id,
        });

        this.gMaterials = res.data;
        this.gMaterials.forEach((item) => {
          item.tempGroupAllowShow = false;
        });

        this.uploadNum = this.gMaterials.filter((item) =>
          this.showNotHiddenReview(item)
        ).length;

        this.reviewMaterials = this.gMaterials.filter(
          (item) => !this.showNotHiddenReview(item)
        );

        this.mountMsg("更新成功!");
      } catch (error) {
        console.error("Error saving material review setting:", error);
      }
    },
    async savePackReviewSetting() {
      try {
        console.log("儲存");
        let updatePacks = this.reviewPacks.filter(
          (item) => item.tempGroupAllowShow
        );
        updatePacks.forEach((item) => {
          item.groupAllowShow = item.tempGroupAllowShow;
        });

        await api.main.updatePacks(updatePacks);

        const res = await api.main.getResourcePackByGroupId({
          gid: this.currentGroupProfile.id,
        });

        this.packs = res.data;
        this.packs.forEach((item) => {
          item.tempGroupAllowShow = false;
        });

        this.uploadPackNum = this.packs.filter((item) =>
          this.showNotHiddenReview(item)
        ).length;

        this.reviewPacks = this.packs.filter(
          (item) => !this.showNotHiddenReview(item)
        );

        this.mountMsg("更新成功!");
      } catch (error) {
        console.error("Error saving material review setting:", error);
      }
    },
    async moveOutFromGroup() {
      try {
        let updateData = {
          id: this.store.currentEditItem.id,
          type: this.store.currentEditItem.type,
          groupId: "",
        };
        await api.main.updateMaterialById(updateData);
        const res = await api.main.getMaterialsByGroupId({
          gid: this.currentGroupProfile.id,
        });
        this.gMaterials = res.data;
        this.gMaterials.forEach((item) => {
          item.tempGroupAllowShow = false;
        });
        this.uploadNum = this.gMaterials.filter((item) =>
          this.showNotHiddenReview(item)
        ).length;

        this.mountMsg("更新成功!");
      } catch (error) {
        console.error("Error moveOutFromGroup:", error);
      }
    },
    async moveOutPackFromGroup() {
      try {
        let updateData = {
          id: this.store.currentEditItem.id,
          groupId: "",
        };
        await api.main.updateResourcePackInfoById(updateData);

        const res = await api.main.getResourcePackByGroupId({
          gid: this.currentGroupProfile.id,
        });
        this.packs = res.data;
        this.uploadPackNum = this.packs.filter((item) =>
          this.showNotHiddenReview(item)
        ).length;
        this.mountMsg("更新成功!");
      } catch (error) {
        console.error("Error moveOutFromGroup:", error);
      }
    },

    async saveWaitingMembersSetting() {
      try {
        let deleteWaitingMembers =
          this.currentGroupProfile.waitingMembers.filter(
            (member) => member.approve == true
          );

        await api.main.deleteGroupMembers({
          gid: this.currentGroupProfile.id,
          deleteWaitingMembers: deleteWaitingMembers,
        });

        await api.main.updateGroupInfoById({
          gid: this.currentGroupProfile.id,
          groupMembers: deleteWaitingMembers,
        });

        await this.refreshGroupInfo();
      } catch (error) {
        console.error("Error saving waiting members setting:", error);
      }
    },

    async saveGroupMembersSetting() {
      try {
        let deleteGroupMembers = this.currentGroupProfile.groupMembers.filter(
          (member) => member.remove == true
        );

        await api.main.deleteGroupMembers({
          gid: this.currentGroupProfile.id,
          deleteGroupMembers: deleteGroupMembers,
        });

        await this.refreshGroupInfo();
        this.showConfirmDeleteMemberModal = false;
      } catch (error) {
        console.error("Error saving group members setting:", error);
      }
    },

    async saveGroupManagersSetting() {
      try {
        const addGroupManagers = this.currentGroupProfile.groupMembers.filter(
          (member) => member.isManager == true
        );

        const deleteGroupManagers =
          this.currentGroupProfile.groupMembers.filter(
            (member) => member.isManager == false
          );

        await api.main.updateGroupInfoById({
          gid: this.currentGroupProfile.id,
          groupManagers: addGroupManagers,
        });

        await api.main.deleteGroupMembers({
          gid: this.currentGroupProfile.id,
          deleteGroupManagers: deleteGroupManagers,
        });

        await this.refreshGroupInfo();
        this.showEditManagerModal = false;
      } catch (error) {
        console.error("Error saving group managers setting:", error);
      }
    },

    async refreshGroupInfo() {
      try {
        this.currentGroupProfile = await this.getGroupInfo();
        this.Init();
        this.saveLocal(this.currentGroupProfile);
      } catch (error) {
        console.error("刷新群組信息時出錯：", error);
      }
    },
    async getPacksLoginAcountCreated() {
      const res = await api.main.getResourcePacksIcreated({
        tmid: this.currentLoginAccount.id,
      });
      this.packsOriginal = res.data;

      this.packslist = res.data
        .filter((item) => item.groupId == "")
        .map((item) => ({
          name: item.title,
          id: item.id,
        }));
    },
    async getGroupPacksLoginAcountCreated() {},
    Init() {
      this.checkIsLogin();
      this.checkAllowEditGroup();
      this.checkInGroup();
      this.checkInWaitingMembers();
      this.getPacksLoginAcountCreated();

      //暫存紀錄是否同意加入
      if (this.currentGroupProfile.waitingMembers)
        this.currentGroupProfile.waitingMembers.forEach((member) => {
          member.approve = false;
        });

      //暫存紀錄是否移除
      if (this.currentGroupProfile.groupMembers)
        this.currentGroupProfile.groupMembers.forEach((member) => {
          member.remove = false;
          if (
            this.currentGroupProfile.groupManagers.findIndex(
              (item) => item.id == member.id
            ) != -1
          ) {
            member.isManager = true;
          } else {
            member.isManager = false;
          }
        });
      api.main
        .getMaterialsByGroupId({ gid: this.currentGroupProfile.id })
        .then((res) => {
          console.log(res);
          this.gMaterials = res.data;
          this.gMaterials.forEach((item) => {
            item.tempGroupAllowShow = false;
          });
          this.uploadNum = this.gMaterials.filter((item) =>
            this.showNotHiddenReview(item)
          ).length;
          this.reviewMaterials = this.gMaterials.filter(
            (item) => !this.showNotHiddenReview(item)
          );
        });

      api.main
        .getResourcePackByGroupId({ gid: this.currentGroupProfile.id })
        .then((res) => {
          this.packs = res.data;
          this.uploadPackNum = this.packs.filter((item) =>
            this.showNotHiddenReview(item)
          ).length;
          this.groupPacksOriginal = res.data;
          this.groupPackslist = res.data.map((item) => ({
            name: item.title,
            id: item.id,
          }));
          this.reviewPacks = this.packs.filter(
            (item) => !this.showNotHiddenReview(item)
          );
          this.reviewPacks.forEach((item) => {
            item.tempGroupAllowShow = false;
          });
          console.log(this.groupPackslist, "this.groupPackslist");
        });

      this.editTitle = this.currentGroupProfile.groupName;
      this.editIntro = this.currentGroupProfile.groupIntro;
      this.groupImgUrl = this.currentGroupProfile.groupImgURL;
      this.isUploadToReview = this.currentGroupProfile.isUploadToReview;
      this.isStayOldReview=this.currentGroupProfile.isStayOldReview
    },
    checkIsLogin() {
      const idToken = sessionStorage.getItem("id_token");
      if (idToken) {
        const decoded = jwt_decode(idToken);
        this.currentLoginAccount = {
          id: decoded.sub,
          name: decoded.name,
          picture: decoded.picture,
        };
        this.isLogin = true;
      }
    },
    async addWaitingList() {
      try {
        console.log("申請加入群組");
        const waitingMembers = [this.currentLoginAccount];

        const data = {
          gid: this.currentGroupProfile.id,
          waitingMembers: waitingMembers,
        };

        await api.main.updateGroupInfoById(data);

        const groupInfoResponse = await api.main.getGroupInfoById({
          gid: this.$route.params.id,
        });
        this.currentGroupProfile = groupInfoResponse.data;
        this.Init();
      } catch (error) {
        console.error("Error adding to waiting list:", error);
      }
    },
    setTab(id) {
      this.currentTabId = id;
    },
    checkInWaitingMembers() {
      const idToken = sessionStorage.getItem("id_token");
      if (idToken && this.currentGroupProfile?.waitingMembers) {
        const decoded = jwt_decode(idToken);
        const mIdx = this.currentGroupProfile?.waitingMembers.findIndex(
          (manager) => manager.id == decoded.sub
        );
        this.isWaitingMember = mIdx != -1;
      }
    },
    checkInGroup() {
      const idToken = sessionStorage.getItem("id_token");
      if (idToken) {
        const decoded = jwt_decode(idToken);
        const mIdx = this.currentGroupProfile.groupMembers.findIndex(
          (manager) => manager.id == decoded.sub
        );
        this.isCurrentAccountMember = mIdx != -1;
      }
    },
    checkAllowEditGroup() {
      const idToken = sessionStorage.getItem("id_token");
      if (idToken) {
        const decoded = jwt_decode(idToken);
        const managerIndex = this.currentGroupProfile.groupManagers.findIndex(
          (manager) => manager.id == decoded.sub
        );
        this.isCurrentAccountManager = managerIndex != -1;
      }
    },
    checkBackgroundImageLoaded(imageUrl) {
      const tempImg = new Image();
      tempImg.src = imageUrl;
      tempImg.onload = () => {
        this.isLoadingNow = false;
        // console.log('背景图像已加载完成。');
      };
    },
    getGroupImgUrl(group) {
      try {
        if (group?.groupImgURL && this.blobsas) {
          //console.log(group);
          const finalUrl =
            profile.blobsasurl +
            group.groupCreator.id +
            "/groupImgs/" +
            group.groupImgURL +
            "?" +
            this.blobsas;
          //console.log(finalUrl);

          this.checkBackgroundImageLoaded(`${finalUrl}`);
          return 'url("' + finalUrl + '")';
        } else if (!group?.groupImgURL) {
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
    saveLocal(item) {
      const savedContent = JSON.parse(JSON.stringify(item));
      delete savedContent.pk;
      delete savedContent.ttl;
      // // delete savedContent.hidden;
      // delete savedContent.reported;
      console.log(savedContent);

      //初步加密教材到本地
      const encSavedContent = jsFn.encrypt(JSON.stringify(savedContent), "123");
      sessionStorage.setItem("currentViewGroupProfile", encSavedContent);
    },
    viewContent(item) {
      this.store.viewContent(item);
    },
    async sendInvitations(toUserIds) {
      const data = {
        title: "群組邀請通知",
        text: "邀請您加入群組:" + this.currentGroupProfile.groupName,
        importance: "important",
        attachlink: "",
        routelink: "/group/" + this.currentGroupProfile.id,
        fromUser: {
          id: this.currentLoginAccount.id,
          name: this.currentLoginAccount.name,
        },
        toUserIds: toUserIds,
        msgImg: this.currentLoginAccount.picture,
      };
      console.log(toUserIds,this.currentGroupProfile,data,)
      // try {
      //   await api.main.createMessagesByToIds(data);
      //   this.showGroupInvitationForm = false;
      //   this.mountMsg("發送成功！");
      // } catch (err) {
      //   console.log(err);
      // }
    },
    async deleteBlobFolder() {
      const folderName = `${this.currentLoginAccount.id}/${this.store.currentEditItem.id}`; // 資料夾名稱

      const containerClient = this.store.blobContainerClient;

      let iterator = containerClient
        .listBlobsFlat({ prefix: folderName })
        .byPage();
      let response = await iterator.next();

      while (!response.done) {
        for (const blob of response.value.segment.blobItems) {
          // 刪除資料夾中的每個 Blob
          await containerClient.deleteBlob(blob.name);
          console.log(`已刪除 Blob：${blob.name}`);
        }

        response = await iterator.next();
      }
      console.log(`已刪除資料夾：${folderName}`);
    },
    initblob() {
      if (this.blobsasurl != "") {
        this.blobServiceClient = new BlobServiceClient(
          this.blobsasurl + "?" + this.blobsas
        );
        this.containerClient = this.blobServiceClient.getContainerClient("");
        console.log(this.containerClient, "containerClient");
        this.store.setBlobContainerClient(this.containerClient);
      }
    },
    async deleteMaterial() {
      console.log(this.getGroupImgUrl(this.currentGroupProfile));

      const groupImgUrl = this.currentGroupProfile.groupImgURL
        ? profile.blobsasurl +
          this.currentGroupProfile.groupCreator.id +
          "/groupImgs/" +
          this.currentGroupProfile.groupImgURL
        : "";

      try {
        console.log(this.store.currentEditItem);

        await api.main.deleteMaterialById({
          id: this.store.currentEditItem.id,
          type: this.store.currentEditItem.type,
        });

        await this.deleteBlobFolder();

        this.showConfirmDeleteModal = false;
        this.mountMsg("刪除成功!");

        const res = await api.main.getMaterialsByGroupId({
          gid: this.currentGroupProfile.id,
        });
        this.gMaterials = res.data;
        this.gMaterials.forEach((item) => {
          item.tempGroupAllowShow = false;
        });
        this.uploadNum = this.gMaterials.filter((item) =>
          this.showNotHiddenReview(item)
        ).length;

        await api.main.createMessage({
          title: "群組將您的教材刪除通知",
          text: `被刪除教材名稱:${this.store.currentEditItem.title}`,
          importance: "important",
          fromUser: {
            id: this.currentGroupProfile.groupName,
            name: this.currentGroupProfile.groupName,
          },
          toUserId: this.store.currentEditItem.uploader.id,
          msgImg: groupImgUrl,
        });
      } catch (err) {
        console.log(err);
      }
    },
    imagefileChangeHandler(e) {
      this.imageFile = Array.from(e.target.files);
      if (this.imageFile) {
        this.editImgPreview = URL.createObjectURL(this.imageFile[0]);
      }
    },
    uploadImgBlob(targetFile, folderName) {
      return new Promise((r, j) => {
        let targetname =
          this.currentGroupProfile.groupCreator.id +
          `/${folderName}/` +
          targetFile[0].name;
        let blockBlobClient =
          this.containerClient.getBlockBlobClient(targetname);
        blockBlobClient.uploadBrowserData(targetFile[0]).then(
          () => {
            // let url = decodeURIComponent(res._response.request.url);
            // url = url.substring(0, url.lastIndexOf("?"));
            r(targetFile[0].name); //僅傳檔名即可由本地拼裝
          },
          (err) => {
            j(err);
          }
        );
      });
    },
    async updateGroupInfo() {
      try {
        let materialRes = this.imageFile
          ? await this.uploadImgBlob(this.imageFile, "groupImgs")
          : this.groupImgURL;
        console.log("上傳教材成功", materialRes + "?" + this.blobsas);
        console.log(materialRes);

        let data = {
          gid: this.currentGroupProfile.id,
          groupName: this.editTitle,
          groupIntro: this.editIntro,
          groupImgURL: materialRes,
          isUploadToReview: this.isUploadToReview,
          isStayOldReview: this.isStayOldReview,
        };
        await api.main.updateGroupInfoById(data);

        const groupInfoResponse = await api.main.getGroupInfoById({
          gid: this.$route.params.id,
        });
        this.currentGroupProfile = groupInfoResponse.data;
        this.editTitle = this.currentGroupProfile.groupName;
        this.editIntro = this.currentGroupProfile.groupIntro;
        this.groupImgUrl = this.currentGroupProfile.groupImgURL;
        this.currentTabId = 0;
        this.isUploadToReview = this.currentGroupProfile.isUploadToReview;
        this.isStayOldReview=this.currentGroupProfile.isStayOldReview
        this.Init();
      } catch (err) {
        console.log("updateGroupInfo err:" + err);
      }
    },
    viewPackContent(item) {
      this.store.viewPackContent(item);
    },
    async deletePack() {
      try {
        await api.main.deleteResourcePackById({
          id: this.store.currentEditItem.id,
        });
        this.mountMsg("刪除成功!");
        this.showConfirmDeletePackModal = false;
        const res = await api.main.getResourcePackByGroupId({
          gid: this.currentGroupProfile.id,
        });
        this.packs = res.data;
        this.uploadPackNum = this.packs.filter((item) =>
          this.showNotHiddenReview(item)
        ).length;
      } catch (err) {
        console.log(err); // 處理錯誤情況
      }
    },
    async getMaterialsForPack() {
      const res = await api.main.getMaterialsByUserId({
        tmid: this.currentLoginAccount.id,
      });
      const materialsByMe = res.data;
      console.log(materialsByMe);

      this.materialsForEditPack = this.gMaterials.concat(materialsByMe);
    },
    async openEditPackForm() {
      await this.getMaterialsForPack();
      this.showCreatePackForm = true;
      this.packFormMode = "edit";
    },

    closeCreatePackForm() {
      this.showCreatePackForm = false;
      this.packFormMode = "edit";
    },
    createOrUpdatePack(formData) {
      if (this.packFormMode == "create") this.createPack(formData);
      else this.updatePack(formData);
    },
    async openCreatePackForm() {
      await this.getMaterialsForPack();
      if(this.materialsForEditPack.length!==0){
        this.showCreatePackForm = true;
        this.packFormMode = "create";
      }else{
        this.mountMsg("請先至少使用HiTeach上傳一個資源，才可創建資源包!")
      }
    },
    async createPack(formData) {
      console.log(formData);

      const packCreatorData = {
        id: this.currentLoginAccount.id,
        name: this.currentLoginAccount.name,
        picture: this.currentLoginAccount.picture,
      };

      let data = {
        packCreator: packCreatorData,
        title: formData.title,
        intro: formData.intro,
        imgUrl:
          formData.pickImgType === 0
            ? await this.uploadImgBlob(formData.imgUrl, "packImgs")
            : formData.imgUrl,
        materialsList: formData.materialsList,
        groupId: this.currentGroupProfile.id,
        grade: formData.grade,
        subject: formData.subject,
        language: formData.language,
        category: formData.category,
        groupAllowShow: !this.currentGroupProfile.isUploadToReview,
      };

      try {
        await api.main.createResourcePack(data);
        this.closeCreatePackForm();
        const res = await api.main.getResourcePackByGroupId({
          gid: this.currentGroupProfile.id,
        });
        this.packs = res.data;
        this.uploadPackNum = this.packs.filter((item) =>
          this.showNotHiddenReview(item)
        ).length;
        this.mountMsg("更新成功!");
      } catch (err) {
        console.log(err);
      }
    },
    async updatePack(formData) {
      console.log(formData);

      const commonData = {
        id: this.store.currentEditItem.id,
        finalEditor: {
          id: this.currentLoginAccount.id,
          name: this.currentLoginAccount.name,
          picture: this.currentLoginAccount.picture,
        },
        title: formData.title,
        intro: formData.intro,
        materialsList: formData.materialsList,
        grade: formData.grade,
        subject: formData.subject,
        language: formData.language,
        category: formData.category,
      };

      try {
        if (formData.pickImgType === 0) {
          let materialRes = await this.uploadImgBlob(
            formData.imgUrl,
            "packImgs"
          );
          console.log("上傳教材成功", materialRes + "?" + this.blobsas);

          const dataWithImgUrl = {
            ...commonData,
            imgUrl: materialRes,
          };

          await this.updatePackInfo(dataWithImgUrl);
        } else if (formData.pickImgType === 1 || formData.pickImgType === 2) {
          const dataWithImgUrl = {
            ...commonData,
            imgUrl: formData.imgUrl,
          };

          await this.updatePackInfo(dataWithImgUrl);
        } else if (formData.pickImgType === 3) {
          await this.updatePackInfo(commonData);
        }

        this.closeCreatePackForm();
      } catch (err) {
        console.log(err);
      }
    },
    async updatePackInfo(data) {
      try {
        await api.main.updateResourcePackInfoById(data);
        const res = await api.main.getResourcePackByGroupId({
          gid: this.currentGroupProfile.id,
        });
        this.packs = res.data;
        this.uploadPackNum = this.packs.filter((item) =>
          this.showNotHiddenReview(item)
        ).length;
        this.mountMsg("更新成功!");
      } catch (err) {
        console.log(err);
      }
    },
    async updateMaterialsList(packList, formData, originalList) {
      const index = originalList.findIndex(
        (item) => item.id === formData.groupName.id
      );
      const updateMaterials = originalList[index].materialsList;
      const mId = updateMaterials.findIndex(
        (item) => item.id === this.store.currentEditItem.id
      );

      if (mId === -1) {
        updateMaterials.push(this.store.currentEditItem);
      }

      const data = {
        id: originalList[index].id,
        materialsList: updateMaterials,
      };

      try {
        await api.main.updateResourcePackInfoById(data);
        this.mountMsg("更新成功!");
        packList =
          packList === this.packsOriginal
            ? this.showSelectedPackForm
            : this.showSelectedGroupPackForm;
        packList = false;
      } catch (err) {
        console.log(err);
      }
    },
    async moveToPack(formData) {
      console.log(formData);
      const index = this.packsOriginal.findIndex(
        (item) => item.id === formData.groupName.id
      );
      console.log(index);
      let updateMaterials = this.packsOriginal[index].materialsList;
      const mId = updateMaterials.findIndex(
        (item) => item.id === this.store.currentEditItem.id
      );
      if (mId == -1) {
        updateMaterials.push(this.store.currentEditItem);
      }

      const data = {
        id: this.packsOriginal[index].id,
        materialsList: updateMaterials,
      };
      console.log(data);
      try {
        await api.main.updateResourcePackInfoById(data);
        this.mountMsg("更新成功!");
        this.showSelectedPackForm = false;
      } catch (err) {
        console.log(err);
      }
    },
    async moveToGroupPack(formData) {
      console.log(formData);
      const index = this.groupPacksOriginal.findIndex(
        (item) => item.id === formData.groupName.id
      );
      console.log(index);
      let updateMaterials = this.groupPacksOriginal[index].materialsList;
      const mId = updateMaterials.findIndex(
        (item) => item.id === this.store.currentEditItem.id
      );
      if (mId == -1) {
        updateMaterials.push(this.store.currentEditItem);
      }

      const data = {
        id: this.groupPacksOriginal[index].id,
        materialsList: updateMaterials,
      };
      console.log(data);
      try {
        await api.main.updateResourcePackInfoById(data);
        this.mountMsg("更新成功!");
        this.showSelectedGroupPackForm = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less">
.group-profile {
  @apply p-10;

  .userinfo-card {
    background-color: #fff;
    @apply flex rounded-md p-5 mb-5;

    .btn-group {
      .normal-btn {
        @apply inline-block cursor-pointer text-white text-center border my-2 px-4 rounded mr-2 bg-sky-500 border-sky-500 text-sm;

        &:hover {
          @apply bg-sky-600;
        }
      }
    }

    .left-part {
      .profile-img {
        @apply rounded-md;
        height: 100px;
        width: 100px;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
      }
    }
  }

  .tab-wrap {
    position: relative;
    @apply mb-5 text-xl font-bold;

    .tab {
      @apply mr-5 text-gray-500 cursor-pointer;
    }

    .tab-light {
      @apply text-black !important;
    }
  }
  @media (max-width: theme("screens.lg")) {
    .btn-top {
      position: relative !important;
      height: 20px !important;

      .btn-inner-right {
        position: absolute;
        right: 0px;
        top: -30px;
      }
    }
  }

  .btn-top {
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .pack-area {
    @apply my-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8;
    .pack-wrap {
      text-align: center;
      position: relative !important;

      .loaded {
        @apply animate-pulse !important;
        // background: none !important;
        @apply bg-gray-500 !important;
        img {
          visibility: hidden;
        }
      }
      .pack-ball {
        display: flex;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中 */
        /* 其他样式 */
        @apply bg-sky-200 border border-sky-400;
        position: relative !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        height: 100%;
        width: 100%;
        font-size: 16px;
        line-height: 36px;
        vertical-align: baseline;
        min-height: 100px;
        @media (min-width: theme("screens.lg")) {
          min-height: 300px;
        }
        cursor: pointer;
        border-radius: 10px;
        text-align: center !important;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        .plus-icon {
          font-size: 50px;
        }

        &:hover {
          @apply bg-sky-300 drop-shadow-xl;
          opacity: 0.8;
        }
      }
    }
  }
  .approve-all {
    white-space: nowrap;

    //display: block !important;
  }

  .approve-btn {
    @apply inline-block cursor-pointer text-white text-center border  px-4 rounded bg-sky-500 border-sky-500 text-sm;
    white-space: nowrap;

    &:hover {
      @apply bg-sky-600;
    }
  }

  .cancel-btn {
    @apply bg-transparent cursor-pointer text-gray-700 font-semibold px-4 border border-gray-500 rounded inline-block text-sm;
    white-space: nowrap;

    &:hover {
      @apply bg-sky-100;
    }
  }

  .remove-btn {
    @apply bg-red-600 cursor-pointer text-white font-semibold px-4 border border-red-600 rounded inline-block text-sm;
    white-space: nowrap;

    &:hover {
      @apply bg-red-700;
    }
  }
  .member-management {
    @apply grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-8;
    position: relative;

    .title {
      @apply font-bold text-lg my-5;
      padding: 0px 20px;
    }

    .member-section {
      position: relative;
      background-color: #fff;
    }

    .icon-wrap {
      text-align: center;
      position: relative;
      padding-right: 10px;
    }

    .check-icon {
      @apply text-green-600;
    }

    .remove-icon {
      @apply text-red-600;
    }

    .member-wra-scroll {
      max-height: 560px;
      overflow: auto;
    }

    .member-wrap {
      display: flex;
      align-items: center;
      padding-right: 20px;
      border-bottom: 1px solid rgba(128, 128, 128, 0.2);
      @apply py-2;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .num {
        @apply text-gray-400 text-sm;
        width: 40px;
        text-align: center;
      }
      .creator {
        @apply text-sky-500 text-xs;
      }
      .name {
        flex: 1;
        @apply font-bold m-4;
        width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  //和創建群組相同表單
  .group-updInfo {
    .main-form {
      min-width: 40%;
      max-height: 90vh;
      .scroll-wrap {
        @apply px-8 pb-20;
        width: 100%;
        max-height: 85vh;
        overflow: auto;
      }

      @apply bg-white  rounded-lg relative  flex flex-col pt-4;
      .img-preview {
        margin-top: 10px;
        max-height: 200px;
        max-width: 200px;
      }
      .upload-item {
        @apply my-3;
        input[type="text"],
        textarea {
          @apply ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6 w-full py-2 px-5 rounded-md mt-2;
        }
        textarea {
          max-height: 180px;
          overflow: auto;
        }
      }
      .title {
        @apply font-bold;
      }
    }

    .normal {
      .left-btn {
        @apply text-white font-bold border py-1 px-4 rounded mr-2 bg-sky-500 border-sky-500;

        &:hover {
          @apply bg-sky-600;
        }
      }

      .right-btn {
        @apply bg-transparent text-gray-700 font-semibold py-1 px-4 border border-gray-500 rounded;

        &:hover {
          @apply bg-sky-100;
        }
      }
    }
  }
  //待審資源管理
  .review-management {
    @apply grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8;
    .material-section {
      background-color: #fff;
      position: relative;
      @media (max-width: theme("screens.lg")) {
        .btn-top {
          position: relative !important;
          height: 20px !important;

          .btn-inner-right {
            position: absolute;
            right: 0px;
            top: -30px;
          }
        }
      }

      .btn-top {
        position: absolute;
        right: 20px;
        top: 15px;
      }
      .material-scroll-wrap {
        @apply p-5;
        max-height: 55vh;
        overflow: auto;
      }
    }
  }
}
</style>