//請勿變更測試案例的縮排！
export default {
  mdtest1: `# American History

## Colonial Period
  - Jamestown Colony
      - Native Americans
      - Native Americans
        - Arrival of the Pilgrims
        - Massachusetts Bay Colony
        - Indentured Servitude
        - Bacon's Rebellion

## Revolutionary War
  - Declaration of Independence
  - Battle of Saratoga
  - French Involvement
    - Battle of Yorktown
    - Treaty of Paris

## Early Republic
- Constitution Drafted
- Federalists vs. Anti-Federalists
- President Washington
- President Jefferson
- War of 1812

## Civil War
- Southern Secession
- President Lincoln
- Emancipation Proclamation
- Battle of Gettysburg
- Lee's Surrender

## Modern History
- World War I
- The Great Depression
- World War II
- Civil Rights Movement
- The Cold War

`,

  mdtest2: `# 中国历史
    
- 夏朝

- 商朝

##### 甲骨文
    - 小周

## 周朝
    - 小周

## 周朝2號
    - 小周

### 西周

### 东周

#### 春秋时期

##### 孔子
    `,

  mdtest3: `# JavaScript 知识点

## 基础概念
- 数据类型
  - 基本类型：字符串、数字、布尔值、null、undefined
  - 引用类型：对象、数组、函数、日期

- 变量与常量
  - 变量声明（var、let、const）
  - 变量提升与作用域
  - 常量声明（const）

## 数据结构
- 数组
  - 增删改查操作
  - 遍历方法（for 循环、forEach、map、filter 等）

- 对象
  - 属性与方法
  - 对象字面量与构造函数
  - 原型与原型链

- Map 和 Set
  - 使用场景与操作

## 函数与闭包
- 函数声明与表达式
- 参数传递（默认参数、剩余参数）
- 闭包的概念与应用

## 异步编程
- 回调函数
- Promise 对象
- async/await

## ES6+ 新特性
- 箭头函数
- 模板字符串
- 解构赋值
- 类与继承
- 模块化（import/export）

## 浏览器相关
- DOM 操作
- 事件处理
- AJAX 与 Fetch API

## 工具与框架
- npm/yarn
- 框架（如React、Vue）
- 调试工具（如Chrome 开发者工具）

## 性能优化
- 代码优化技巧
- 加载优化与资源管理

## 安全性考虑
- XSS 与 CSRF 攻击
- 数据加密与传输安全

## 测试与调试
- 单元测试
- 调试技巧

## 未来发展
- ECMAScript 规范与版本更新
- WebAssembly 的应用与前景
`,
  mdtest4: `# 北宋文化成就

## 文學
### 詩詞
#### 蘇軾
#### 歐陽修
#### 王安石
### 散文
#### 歐陽修
#### 曾鞏
#### 蘇轍

## 繪畫
### 山水畫
#### 范寬
#### 郭熙
### 人物畫
#### 張擇端
##### 清明上河圖
### 花鳥畫
#### 黃荃
#### 徐熙
`,

  mdtest5: `# 安全性考虑
  - CSRF（跨站请求伪造）
  - **XSS（跨站脚本攻击）**
    - 什么是 XSS：XSS 攻击是指恶意攻击者在目标网站上注入恶意脚本，目的是在用户的浏览器中执行恶意代码。
    - XSS 类型：反射型 XSS、存储型 XSS、DOM 型 XSS。
    - 防范措施：
      - 输入验证和输出编码：对用户输入的数据进行验证，并在输出时进行编码。
      - 使用安全的 API：避免使用 innerHTML、document.write 等容易引发 XSS 的 API，使用 textContent、innerText 等替代。
      - Content Security Policy (CSP)：通过设置 CSP 头，限制可以加载的资源来源，防止外部恶意脚本的注入。`,

  mdtest6: `# 在烘手機下探索科學樂趣

## 1. 主題
- 探索科學樂趣

## 2. 烘手機的科學原理
- 氣流原理
  - 烘手機的工作原理
  - 氣流速度測試
- 加熱原理
  - 加熱元件介紹
  - 溫度變化實驗

## 3. 實驗設計
- 實驗目的
  - 理解烘手機的運作
  - 探索物理現象
- 實驗材料
  - 烘手機
  - 風速計
  - 溫度計
  - 不同材質的物品
- 實驗步驟
  - 準備實驗材料
  - 設置實驗環境
  - 測量並記錄數據
  - 分析實驗結果

## 4. 教學應用
- 激發學生興趣
  - 與生活相關的科學
  - 動手操作的實驗
- 教學方法
  - 問題導向學習
  - 小組合作
  - 結果討論與分享

## 5. 結論
- 科學實驗的重要性
- 透過實驗培養學生的探索精神與問題解決能力

## 6. 延伸活動
- 不同烘手機品牌的比較
- 探索其他家用電器的科學原理
- 科學展覽或競賽
`,

  //開頭不是井字號
  mdtest7: `- 數位轉型
    
- 核心元素：
    - 數位技術
    - 組織文化轉型
    - 數據驅動決策
    - 顧客體驗優化
    `,

  //包含數字項目但無縮排測試1
  mdtest8: `# 數位轉型
- 核心元素：
    - 數位技術
    - 組織文化轉型
    - 數據驅動決策
    - 顧客體驗優化

- 階段：
    1. 認知與覺醒：
        - 技術發展的重要性
        - 數位轉型的潛在價值
        - 全面洞悉數位趨勢

    2. 規劃與策略：
        - 數位戰略制定
        - 資源與投資規劃
        - 領導力與人才培養

    3. 執行與實施：
        - 數位基礎建設建置
        - 整合現有系統與流程
        - 創新實驗與快速迭代

    4. 監測與優化：
        - 數據分析與追蹤
        - 關鍵績效指標評估
        - 持續改進與優化

- 關鍵指標：
    - 新數位技術的採用率
    - 數位能力的提升程度
    - 數據資源的整合與利用
    - 顧客滿意度與忠誠度提升程度
    `,

  //包含數字項目但無縮排測試2
  mdtest9: `# 數位轉型

## 核心元素
- 組織變革
- 技術整合
- 數據分析
- 創新思維

## 階段
1. 了解與規劃
2. 實施與執行
3. 優化與改善

## 關鍵指標
- 數位成熟能力提升率
- 數位解決方案採用率
- 數據驅動程度
- 創新產品與服務比例
    `,

  mdtest10: `# 數位轉型心智圖
    
## 核心元素
- 技術基礎
- 組織變革
- 數位文化
- 數據分析
- 創新思維

## 階段
- 規劃與策略
- 執行與實施
- 監控與評估
- 持續改進

## 關鍵指標
- 數位化程度
- 效能提升
- 顧客體驗
- 營收增長
- 創新能力
    `,

  mdtest11: "```markdowndsdsd\n# 水的冰點與沸點\n## 水的物理性質\n### 1. 常見液體\n### 2. 短缺與長缺\n## 冰點\n### 1. 定義\n### 2. 溫度\n### 3. 影響因素\n### 4. 水結冰過程\n## 沸點\n### 1. 定義\n### 2. 溫度\n### 3. 影響因素\n### 4. 水沸騰過程\n## 冰點與沸點比較\n### 1. 相同點\n### 2. 不同點\n### 3. 溫度範圍\n## 實驗與觀察\n### 1. 准備材料\n### 2. 實驗過程\n### 3. 數據分析\n## 應用與實際生活\n### 1. 天氣預報\n### 2. 烹飪\n### 3. 水資源管理\n```",
};
