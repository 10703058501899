<template>
  <div class="pen-setting">
    <div class="slider_box">
      <p class="menu-title" style="margin-left:-5px">{{ $t("konvaCanvas.畫筆") }}</p>
      <RadioGroup v-model="penType" type="button" style="padding: 10px 0; margin-left:-30%">
        <Radio label="pencil-alt" :title="$t('konvaCanvas.通用畫筆')" :style="penType == 'pencil-alt' ? 'color:' + strokeColor : 'color:#72767c'"><svg-icon iconClass="pencil-alt" style="font-size: 24px" /> </Radio>
        <Radio label="highlighter" :title="$t('konvaCanvas.高光筆')" :style="penType == 'highlighter' ? 'color:' + strokeColor : 'color:#72767c '"><svg-icon iconClass="highlighter" style="font-size: 24px"/></Radio>
        <!-- <Radio label="magic" :title="$t('konvaCanvas.鐳射筆')" :style="penType == 'magic' ? 'color:' + strokeColor : 'color:#72767c '"><svg-icon iconClass="magic" style="font-size: 24px"/></Radio> -->
      </RadioGroup>
    </div>
    <div class="slider_box">
      <p class="menu-title" style="margin-left:-5px">
        {{ $t("konvaCanvas.大小") }}：<span class="text-size">{{ strokeSize }}</span>
      </p>
      <Slider v-model="strokeSize" @on-change="setSize" :min="1" :step="1" :max="10" show-tip="never"></Slider>
    </div>
    <div class="ColorPicker_box">
      <p class="menu-title">{{ $t("konvaCanvas.顏色") }}</p>
      <div class="q-gutter-sm" style="padding: 10px 0 2px 0">
        <div class="color-btn" :class="{ 'select-color': strokeColor == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index < 5" @click="setColor(item)" />
      </div>
      <div class="q-gutter-sm" style="padding: 2px 0">
        <div class="color-btn" :class="{ 'select-color': strokeColor == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index >= 5" @click="setColor(item)" />
      </div>
      <!--<q-color v-model="hex" no-header no-footer default-view="palette" class="my-picker" @change="ss" @input="cc" :palette="['#2d8cf0', '#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
    </div>
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon.vue";
export default {
  name: "PenSetting",
  components: { SvgIcon },
  data() {
    return {
      penType: "pencil-alt",
      strokeSize: 1,
      strokeColor: "red",
      opacity: 1,
      colorArr: [
        { val: "red", color: "red" },
        { val: "orange", color: "orange" },
        { val: "yellow", color: "yellow" },
        { val: "cyan", color: "cyan" },
        { val: "#e0e0e0", color: "#e0e0e0" },
        { val: "brown", color: "brown" },
        { val: "purple", color: "purple" },
        { val: "green", color: "green" },
        { val: "#0000ff", color: "#0000ff" },
        { val: "black", color: "black" },
      ],
    };
  },
  watch: {
    penType(value) {
      this.$parent.$parent.penIconType = value;
      if (value == "pencil-alt") {
        this.setColor({ val: "red", color: "red" });
        this.setSize(1);
        this.opacity = 1;
      } else if (value == "highlighter") {
        this.setColor({ val: "orange", color: "orange" });
        this.setSize(4);
        this.opacity = 0.5;
      } else if (value == "magic") {
        this.setColor({ val: "#0000ff", color: "#0000ff" });
        this.setSize(2);
        this.opacity = 0.5;
      }
    },
  },
  methods: {
    setSize(value) {
      this.strokeSize = value;
    },
    setColor(item) {
      this.strokeColor = item.val;
    },
  },
};
</script>

<style lang="less">
@import "../../assets/color.less";

.ivu-slider-button {
  width: 16px;
  height: 16px;
  position: relative;
  top: -1px;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
}

.pen-setting .ivu-radio-group-button .ivu-radio-wrapper-checked,
.ivu-radio-group-button .ivu-radio-wrapper:hover {
  color: #0000ff;
}
.pen-setting .ivu-radio-group-button .ivu-radio-wrapper {
  padding: 5px 20px 0 0 !important;
  background: none;
  position: relative;
  left: -15px;
}
.pen-setting .ivu-transfer-no-max-height {
  transform-origin: center top;
  position: absolute;
  will-change: top, left;
  top: 32px;
  left: 3px !important;
}
.pen-setting .ivu-color-picker-saturation-wrapper {
  padding-bottom: 0% !important;
}
.pen-setting .ivu-color-picker-picker-hue-slider {
  height: 0px !important;
}
.pen-setting {
  
  .slider_box {
    width: 190px;
    padding: 5px;

    .ivu-slider-wrap {
      height: 12px !important;
      border-radius: 10px !important;
    }
    .ivu-slider-bar {
      height: 12px !important;
      border-radius: 10px !important;
      background: @light-color;
    }
    .ivu-slider-button {
      width: 16px;
      height: 16px;
      position: relative;
      border: 2px solid @light-color;
      top: 2px;
      transition: all 0.2s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .color-btn {
    height: 24px;
    width: 24px;
    border: 2px solid white;
    margin: 8px;
    display: inline-block;
    box-shadow: 0px 0px 2px gray;
    border-radius: 50%;
  }
  .menu-title {
    color: @text-color;
    font-weight: bolder;
    text-align: left;
    font-size: 14px;
    .text-size {
      color: @light-color;
    }
  }

  .secondary_title {
    text-align: left;
  }

  .select-color {
    border: 0px solid rgba(223, 223, 223, 0);
    box-shadow: 0px 0px 2px gray;
  }
}
</style>
