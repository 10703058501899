<template>
  <div class="management-view">
    <Modal
      v-if="showConfirmDeleteModal"
      :option="deleteModalOption"
      @close="closeModal"
      @confirm="deleteMaterial()"
    />
    <EditMaterialForm
      v-if="showEditMaterialForm"
      @close="closeEditMaterialForm"
      @confirm="updateMaterial"
    />
    <div class="mb-5 text-3xl font-bold">
      {{ $t("所有上傳管理") }} ({{ materials.length }})
    </div>
    <div class="flex-wrap">
      <div class="search-wrap">
        <SearchBar class="search" />
      </div>
      <div class="select-wrap">
        <span class="select-title">{{ $t("類型") }}</span>
        <SelectMenu
          class="select-item"
          :category="cateType"
          :defaultSelect="$t('全部')"
          ref="selectType"
        />
        <span class="select-title">{{ $t("年級") }}</span>
        <SelectMenu
          class="select-item"
          :category="cateOne"
          :defaultSelect="$t('全部')"
          ref="selectOne"
        />
        <span class="select-title">{{ $t("科目") }}</span>
        <SelectMenu
          class="select-item"
          :category="cateTwo"
          :defaultSelect="$t('全部')"
          ref="selectTwo"
        />
      </div>
    </div>

    <div class="listcard-wrap">
      <div v-for="(item, index) in displayedItems" :key="index + item.title">
        <div class="list-card">
          <div
            class="card-img"
            :class="{
              loaded: isLoadingImgNow && item.imgBlobUrl,
              'image-noscroll': !item.imgBlobUrl,
            }"
          >
            <img :src="getImgUrl(item)" alt="" draggable="false" />
          </div>
          <div class="card-detail">
            <p class="text-3xl font-bold">{{ item.title }}</p>
            <p>
              {{ getTime(item.timeStamp) }}・{{$t("上傳者")}}: {{ item.uploader.name }}
            </p>
            <p>{{$t("類型")}}：{{ item.type }}</p>
            <p>{{$t("簡介")}}：{{ item.intro }}</p>
            <p>{{$t("附件")}}：<a class="link" :href="getBlobUrl(item)">link</a></p>
            <p>{{$t("點擊數")}}：{{ item.hits }}</p>
          </div>
          <div class="card-detail">
            <p>
                {{$t("年級")}}：{{ item.grade }}・{{$t("科目")}}：{{ item.subject }}・{{$t("授課語系")}}：{{
                item.language
              }}
            </p>
            <p>
                {{$t("標籤")}}：<span v-show="item.category.length > 0">{{
                item.category
              }}</span>
            </p>

            <p>{{$t("隱藏")}}：{{ item.hidden }}</p>
            <p>{{"groupId"}}：{{ item.groupId }}</p>
            <p>{{"最後編輯者"}}：{{ item.finalEditor }}</p>
            <p>{{"更新時間"}}：{{ getTime(item.updateTimeStamp) }}</p>
          </div>
          <div class="card-btngroup">
            <div class="btn-edit" @click="openEditMaterialForm(item)">{{$t("編輯")}}</div>
            <div class="btn-delete" @click="confirmDelete(item)">{{$t("刪除")}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-md ml-5 mt-5 font-bold" v-show="!materials.length&&!isLoading">
      {{ $t("暫無資料") }}
    </div>
    <Loader v-show="isLoading" />

    <!--Pagenation-->
    <Pagination :materials="materials" @displayedItems="setDisplayedItems" />

    <!-- 
    {{ materials }} -->
  </div>
</template>

<script>
import api from "@/api";
import { inject, provide, getCurrentInstance, watch } from "vue";
import { useMainStore } from "../stores/mainStore";
import { jsFn } from "@/utils/jsFn";
import profile from "../api/profile";
import Modal from "@/components/Modal.vue";
import SearchBar from "@/components/SearchBar.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import EditMaterialForm from "@/components/EditMaterialForm.vue";
import Pagination from "@/components/Pagination.vue";
import Loader from "@/components/Loader.vue";

const { BlobServiceClient } = require("@azure/storage-blob");

export default {
  name: "Management-view",
  data() {
    return {
      //"blob刪除使用"
      blobsasurl: "https://teammodeltest.blob.core.windows.net/ht-community/",
      blobServiceClient: "",
      containerClient: "",

      currentPage: 1,
      itemsPerPage: 10,
      displayedItems: [],

      materials: [],
      orinMaterials: [],
      blobsas: "",
      isLoadingImgNow: true,
      showConfirmDeleteModal: false,
      showEditMaterialForm: false,
      deleteModalOption: {
        title: this.$t("刪除提示"),
        btnStyle: "danger",
        desc: this.$t("刪除提示內文"),
        showCloseIconBtn: false,
      },
      cateOne: [
        this.$t("全部"),
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      cateTwo: [
        this.$t("全部"),
        "國文",
        "英文",
        "數學",
        "社會",
        "自然",
        "綜合",
      ],
      cateType: [this.$t("全部"), "htex", "audio", "video", "back", "exam"],
      selectAll: this.$t("全部"),
      isLoading:true,
    };
  },

  components: {
    Modal,
    EditMaterialForm,
    SearchBar,
    SelectMenu,
    Pagination,Loader
  },
  setup() {
    const store = useMainStore();
    const mountMsg = inject("mountMsg"); //從App注入
    const that = getCurrentInstance().ctx;
    const searchContent = (text) => {
      if (text == "") {
        that.getAllMaterialsForManager();
      } else {
        that.getMaterialsBySearch(text);
      }
    };
    provide("searchContent", searchContent);

    return { store, mountMsg };
  },
  mounted() {
    this.store.setCurrentPage("management");
    jsFn
      .checkSessionAccessTokenReady()
      .then(async () => {
        this.blobsas =
          this.store.blobsas !== ""
            ? this.store.blobsas
            : await api.main.getSas();
        this.getAllMaterialsForManager();
        this.initblob();
      })
      .catch((err) => {
        console.log(err); //未取到access_tokenå
      });

    //vue3 watch寫法
    watch(
      () => this.store.currentPage,
      () => {
        this.resetSelect()
      }
    );
    watch(
      () => this.$refs.selectType.currentSelect,
      () => {
        this.updateFilterMaterials();
      }
    );
    watch(
      () => this.$refs.selectOne.currentSelect,
      () => {
        this.updateFilterMaterials();
      }
    );
    watch(
      () => this.$refs.selectTwo.currentSelect,
      () => {
        this.updateFilterMaterials();
      }
    );
  },
  methods: {
    resetSelect(){
        this.$refs.selectOne.currentSelect = this.selectAll;
        this.$refs.selectTwo.currentSelect = this.selectAll;
        this.$refs.selectType.currentSelect = this.selectAll;
    },
    updateFilterMaterials() {
      let selectType = this.$refs.selectType?.currentSelect;
      let selectOne = this.$refs.selectOne?.currentSelect;
      let selectTwo = this.$refs.selectTwo?.currentSelect;

      this.materials = this.orinMaterials.filter(
        (item) =>
          (selectOne != this.selectAll ? item.grade == selectOne : true) &&
          (selectTwo != this.selectAll ? item.subject == selectTwo : true) &&
          (selectType != this.selectAll ? item.type == selectType : true)
      );
    },
    getMaterialsBySearch(text) {
      api.main
        .getMaterialsBySearch({ searchText: text, type: "" })
        .then((res) => {
          this.materials = res.data;
        });
    },
    updateMaterial(data) {
      api.main
        .updateMaterialById(data)
        .then((res) => {
          console.log(res);
          this.closeEditMaterialForm();
          this.getAllMaterialsForManager()
          this.mountMsg(this.$t("更新成功"));
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeEditMaterialForm() {
      this.showEditMaterialForm = false;
    },
    openEditMaterialForm(item) {
      this.store.setCurrentEditItem(item);
      this.showEditMaterialForm = true;
    },
    checkBackgroundImageLoaded(imageUrl) {
      if (imageUrl) {
        const tempImg = new Image();
        tempImg.src = imageUrl;
        tempImg.onload = () => {
          this.isLoadingImgNow = false;
          // console.log('背景图像已加载完成。');
        };
      }
    },
    getImgUrl(materials) {
      try {
        if (materials.imgBlobUrl && this.blobsas) {
          if (materials.imgBlobUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials?.imgBlobUrl + "?" + this.blobsas}`
            );
            return materials.imgBlobUrl + "?" + this.blobsas;
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.uploader.id +
              "/" +
              materials.id +
              "/" +
              materials.imgBlobUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`);
            return finalUrl;
          }
        } else if (!materials?.imgBlobUrl) {
          return "https://" + location.host + "/no-image.jpg";
        }
      } catch (err) {
        console.log(err);
      }
    },
    getBlobUrl(materials) {
      if (materials.blobUrl.includes(profile.blobsasurl)) {
        return materials.blobUrl + "?" + this.blobsas;
      } else {
        const finalUrl =
          profile.blobsasurl +
          materials.uploader.id +
          "/" +
          materials.id +
          "/" +
          materials.blobUrl +
          "?" +
          this.blobsas;
        return finalUrl;
      }
    },
    getTime(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString();
    },
    getAllMaterialsForManager() {
      api.main.getAllMaterials({ orderBy: "time" }).then((res) => {
        this.materials = res.data;
        this.orinMaterials = res.data;
      }).then(()=>{
        this.updateFilterMaterials()
        this.isLoading=false
      });
    },
    confirmDelete(item) {
      this.showConfirmDeleteModal = true;
      this.store.setCurrentEditItem(item);
    },
    closeModal() {
      console.log("close");
      this.showConfirmDeleteModal = false;
    },
    deleteMaterial() {
      console.log(this.store.currentEditItem);
      api.main
        .deleteMaterialById({
          id: this.store.currentEditItem.id,
          type: this.store.currentEditItem.type,
        })
        .then(async () => {
          await this.deleteBlobFolder();
          this.showConfirmDeleteModal = false;
          this.mountMsg(this.$t("刪除成功"));
          await this.getAllMaterialsForManager();
          this.resetSelect()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initblob() {
      if (this.blobsasurl != "") {
        this.blobServiceClient = new BlobServiceClient(
          this.blobsasurl + "?" + this.blobsas
        );
        this.containerClient = this.blobServiceClient.getContainerClient("");
        console.log(this.containerClient, "containerClient");
        this.store.setBlobContainerClient(this.containerClient);
      }
    },
    async deleteBlobFolder() {
      const folderName = `${this.store.currentEditItem.uploader.id}/${this.store.currentEditItem.id}`; // 資料夾名稱

      const containerClient = this.store.blobContainerClient;

      let iterator = containerClient
        .listBlobsFlat({ prefix: folderName })
        .byPage();
      let response = await iterator.next();

      while (!response.done) {
        for (const blob of response.value.segment.blobItems) {
          // 刪除資料夾中的每個 Blob
          await containerClient.deleteBlob(blob.name);
          console.log(`已刪除 Blob：${blob.name}`);
        }

        response = await iterator.next();
      }
      console.log(`已刪除資料夾：${folderName}`);
    },
    setDisplayedItems(data) {
      this.displayedItems = data;
    //   console.log(this.displayedItems);
    },
  },
};
</script>

<style lang="less">
.management-view {
  @apply p-10;
  @media (min-width: theme("screens.lg")) {
    .flex-wrap {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(128, 128, 128, 0.2);
      margin-bottom: 20px;
    }
    .search-wrap {
      @apply mb-5;
      flex: 10;
      width: 100%;
    }
    .select-wrap {
      flex: 10;
      text-align: right;
      margin-top: 15px;
      .select-title {
        @apply mr-2 text-sm  font-semibold;
        display: inline-block;
      }
      .select-item {
        @apply mr-2;
        display: inline-block;
        width: 160px;
      }
    }
  }
  @media (max-width: theme("screens.lg")) {
    .search-wrap {
      display: block;
    }
    .select-wrap {
      display: block;
      margin: 20px;
      .select-title {
        @apply mr-2 text-sm  font-semibold;
        display: inline-block;
      }
      .select-item {
        @apply mr-2;
        width: 40%;
        display: inline-block;
      }
    }
  }

  .listcard-wrap {
    @apply grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-3;
  }
  .list-card {
    @apply bg-white p-4;
    display: flex;
    flex-wrap: wrap;
   
    .link {
      @apply text-sky-500;
      text-decoration: underline;
    }
    .card-img {
      flex: 2;
    }
    .card-detail {
      @apply text-gray-700;
      padding-left: 10px;
      flex: 10;
    }
    .card-btngroup {
      flex: 2;
      text-align: right;
      .btn-edit {
        @apply text-sky-500 hover:text-sky-700;
        cursor: pointer;

        display: inline-block;
        margin-right: 10px;
      }
      .btn-delete {
        color: red;
        cursor: pointer;
        &:hover {
          color: darken(red, 10%);
        }
        display: inline-block;
      }
    }
  }
  @media (max-width: theme("screens.md")) {
    .list-card {
      display: block !important;
    }
    .card-img,
    .card-detail,
    .card-btngroup {
      width: 100%;
    }
  }
}
</style>