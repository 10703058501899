<template>
  <div class="sort-block">
    <div class="sort">
      <div>{{ $t("排序") }}</div>
      <div class="sort-btn" @click="setSort('timeNew')" :class="{ 'ligt-btn': sort == 'timeNew' }">{{ $t("時間") }}</div>
      <!-- 
    <div class="sort-btn" @click="setSort('timeOld')" :class="{ 'ligt-btn': sort == 'timeOld' }">時間從舊到新</div>
     -->
      <div class="sort-btn" @click="setSort('title')" :class="{ 'ligt-btn': sort == 'title' }">{{ $t("標題") }}</div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "../stores/mainStore";
import { watch } from "vue";
export default {
  name: "sort-component",
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      sort: "timeNew",
    };
  },
  mounted() {
    this.sort = this.store.currentSortBy;
    watch(
      () => this.store.currentPage,
      () => {
        this.sort = "timeNew";
      }
    );
  },
  methods: {
    setSort(way) {
      this.store.setSortBy(way);
      this.sort = this.store.currentSortBy;
    },
  },
};
</script>

<style lang="less">
.sort-block {
  position: relative;
  height: 60px;

  .sort {
    @media (max-width: theme("screens.lg")) {
      @apply absolute left-5;
    }
    @media (min-width: theme("screens.lg")) {
      @apply absolute right-0;
    }

    @apply my-2 flex text-sm  font-semibold;
    line-height: 30px;
    .sort-btn {
      @apply mx-2 cursor-pointer rounded-md px-2 text-gray-500;
      border: 1px solid rgba(128, 128, 128, 0.5);
    }
    .ligt-btn {
      @apply bg-sky-200  font-semibold text-black !important;
    }
  }
}
</style>
