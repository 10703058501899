import { createApp } from "vue";
import App from "../../App.vue";
import SvgIcon from '../../components/SvgIcon.vue';// svg component

const app = createApp(App);

// register globally
app.component('svg-icon', SvgIcon);

const req = require.context('./svg', false, /\.svg$/);
const requireAll = (requireContext) => requireContext.keys().map(requireContext);
requireAll(req);
