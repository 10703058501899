<template>
    <div class="modal_component">
        <div class="modal relative">
            <div class="text-gray-900 font-medium text-lg text-center">{{ option.title }}</div>
            <svg v-show="option.showCloseIconBtn"
                class="absolute right-5 top-4 fill-current text-gray-700 w-6 h-6 cursor-pointer" @click="close()"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>

            <!-- <hr> -->
            <div class="modal-text"> <span class="modal-text font-bold" v-show="option.showEditItemTitle">{{ store.currentEditItem.title }}</span><br>{{ option.desc }}</div>
            <!-- <hr> -->
            <div class="ml-auto mt-5" :class="option.btnStyle">
                <button class="left-btn" @click="confirm()">
                   {{ $t("確定") }}
                </button>
                <button class="right-btn " @click="close()">
                     {{ $t("取消") }}
                </button>
            </div>

        </div>
    </div>
</template>

<script>
import { useMainStore } from "../stores/mainStore";
export default {
    name: "modal_component",
    props: {
        option: {
            type: Object,
            default: function () {
                return {
                    title: "title",
                    btnStyle: "normal",
                    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utlabore et dolore magna aliqua.",
                    showCloseIconBtn: true,
                    showEditItemTitle:true
                };
            }
        }
    },
    setup() {
        const store = useMainStore();
        return { store };
    },
    methods: {
        confirm() {
            this.$emit('confirm')
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="less">
.modal_component {
    z-index: 999;
    @apply flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800/50;

    .modal {
        @apply bg-white rounded-lg relative -top-20 flex flex-col p-4;
        min-width: 25%;

        .modal-text {
            @apply mt-5 text-center !important;
        }

        .normal {
            .left-btn {
                @apply text-white font-bold border py-1 px-4 rounded mr-2 bg-sky-500 border-sky-500;

                &:hover {
                    @apply bg-sky-600;
                }
            }

            .right-btn {
                @apply bg-transparent text-gray-700 font-semibold py-1 px-4 border border-gray-500 rounded;

                &:hover {
                    @apply bg-sky-100;
                }
            }
        }

        .danger {
            .left-btn {
                @apply text-white font-bold border py-1 px-4 rounded mr-2 bg-red-500 border-red-500;

                &:hover {
                    @apply bg-red-600;
                }
            }

            .right-btn {
                @apply bg-transparent text-gray-700 font-semibold py-1 px-4 border border-gray-500 rounded;

                &:hover {
                    @apply bg-sky-100;
                }
            }
        }



    }
}
</style>