<template>
    <div class="mindmap-home">

        <div class="actions" v-show="showActionMenu"
            :style="{ left: actionMenuPos.x + 'px', top: actionMenuPos.y + 'px' }">
            <div class="action-item" @click="openShareLinkBox(currentMindMap)">共享</div>
            <div class="action-item" @click="openComfirmDelete(currentMindMap)">刪除</div>
            <div class="action-item">詳細資料</div>
        </div>
        <div v-show="showShareLinkBox" class="shareCard-wrap">
            <div class="shareCard">
                <div class="close-btn"> <svg-icon iconClass="close" @click="closeShareLinkBox()"></svg-icon></div>
                <div class="shareCard-title">{{ currentMindMap.mindmapName }}</div>
                <div v-show="!currentMindMap.openCowork" @click="initOpenCowork()" class="temp-btn">發布共享</div>
                <div v-show="currentMindMap.openCowork" class="url-wrap"><span class="url-title">URL：</span>{{
                currentMindMapURL }}</div>
                <div v-show="currentMindMap.openCowork" class="temp-btn" @click="closeOpenCowork()">關閉共享</div>
            </div>
        </div>
        <div v-show="showComfirmDelete" class="shareCard-wrap">
            <div class="shareCard">
                <div class="close-btn"> <svg-icon iconClass="close" @click="closeComfirmDelete()"></svg-icon></div>
                <div class="shareCard-title">{{ currentMindMap.mindmapName }}</div>
                <div>確定要刪除此心智圖嗎？</div>
                <div class="temp-btn danger-btn" @click="deleteMindMap(currentMindMap.id)">確定</div>
                <div class="temp-btn gray-btn" @click="closeComfirmDelete()">取消</div>
            </div>
        </div>
        <div class="flex-bar">
            <div class="left">
                <div class="title">MindMap Cloud</div>
            </div>
            <div class="right">
                <div @click="loginTMID()" class="temp-btn" v-if="!isLogin">loginTMID</div>
                <div @click="logout()" class="temp-btn" v-if="isLogin">logout</div>
            </div>
        </div>
        <div v-show="!isLogin" style="margin: 50px 30px 0 30px ">Hi, 請先登入～</div>
        <div v-show="!isLogin" v-lazy-background="mockImg" alt="" class="mock-img" />
        <!--listView-->
        <div class="mindmap-list" v-show="isLogin && currentView == 'list'">
            <div class="top-flex">
                <div class="left">
                    <div class="title">我創建的檔案</div>
                </div>
                <div class="right">
                    <!--搜尋-->
                    <div class="search-wrap">
                        <label class="search-bar">
                            <input :placeholder="'搜尋'" v-model="search" />
                            <div class="delete-btn" v-show="search.length !== 0" @click="resetSearch()">
                                <svg-icon icon-class="close" />
                            </div>
                            <button type="submit" class="search-btn" @click="searchMindMaps()" :disabled=" search.length == 0">
                                <svg-icon icon-class="search" class="search-icon" />
                            </button>
                        </label>
                    </div>
                    <!--搜尋End-->
                    <div class="view-btn" :class="{ 'active-btn': currentView == 'list' }" @click="setView('list')">
                        <svg-icon icon-class="list" />
                    </div>
                    <div class="view-btn" :class="{ 'active-btn': currentView == 'grid' }" @click="setView('grid')">
                        <svg-icon icon-class="grid" />
                    </div>
                    <div @click="createMindMap()" class="temp-btn">+ 新開心智圖</div>
                </div>
            </div>
            <div class="list-wrap">
                <table v-if="mindmapList.length > 0">
                    <thead>
                        <tr>
                            <th @click="getMyMindMapListBySort('mindmapName')">主題名稱<span
                                    v-show="currentSortField == 'mindmapName'">{{ currentSortOrder == 'asc' ? '↓' :
                '↑' }}</span></th>
                            <th @click="getMyMindMapListBySort('openCowork')">共享<span
                                    v-show="currentSortField == 'openCowork'">{{ currentSortOrder == 'asc' ? '↓' :
                '↑' }}</span></th>
                            <th @click="getMyMindMapListBySort('updateTime')">更新時間<span
                                    v-show="currentSortField == 'updateTime'">{{ currentSortOrder == 'asc' ? '↓' :
                '↑' }}</span></th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="mindmap in mindmapList" :key="mindmap.id">
                            <td @click="openMindMapByID(mindmap)" class="topic">
                                {{ mindmap.mindmapName }}
                            </td>
                            <!-- <td>{{ mindmap.id }}</td> -->
                            <td><svg-icon v-show="mindmap.openCowork" iconClass="group"></svg-icon></td>
                            <!-- <td>{{ mindmap.fluidContainerId }}</td> -->
                            <!-- <td>{{ getTime(mindmap.createTime) }}</td> -->
                            <td>{{ getTime(mindmap.updateTime) }}</td>
                            <td>
                                <div class="more-btn" @click="openActionMenu(mindmap, $event)"><svg-icon
                                        iconClass="more"></svg-icon></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-md ml-0 mt-5 font-bold" v-else>
                    {{ `暫無資料` }}
                </div>
            </div>
            <div class="page-btn-wrap" v-if="mindmapList.length > 0">
                <div v-for="pageBtn in pageBtns" :key="pageBtn" class="page-btn"
                    :class="{ 'page-btn-active': pageBtn == currentPageNum }" @click="getMyMindMapListByPage(pageBtn)">
                    {{ pageBtn }}</div>
            </div>

        </div>
        <!--gridView，部分樣式沿用教師上傳教材網Taiwind-->
        <div class="mindmap-grid" v-show="isLogin && currentView == 'grid'">
            <div class="top-flex">
                <div class="left">
                    <div class="title">我創建的檔案</div>
                </div>
                <div class="right">
                    <!--搜尋-->
                    <div class="search-wrap">
                        <label class="search-bar">
                            <input :placeholder="'搜尋'" v-model="search" />
                            <div class="delete-btn" v-show="search.length !== 0" @click="resetSearch()">
                                <svg-icon icon-class="close" />
                            </div>
                            <button type="submit" class="search-btn" @click="searchMindMaps()" :disabled=" search.length == 0">
                                <svg-icon icon-class="search" class="search-icon" />
                            </button>
                        </label>
                    </div>
                    <!--搜尋End-->
                    <div class="view-btn" @click="setView('list')" :class="{ 'active-btn': currentView == 'list' }">
                        <svg-icon icon-class="list" />
                    </div>
                    <div class="view-btn" @click="setView('grid')" :class="{ 'active-btn': currentView == 'grid' }">
                        <svg-icon icon-class="grid" />
                    </div>
                    <div @click="createMindMap()" class="temp-btn">+ 新開心智圖</div>
                </div>
            </div>
            <div class="mindmapcard-wrap" v-if="mindmapList.length > 0">
                <div class="card-item" v-for="mindmap in mindmapList" :key="mindmap.id">
                    <div class="img-top" v-lazy-background="getMindMapImgUrl(mindmap)" />
                    <div class="card-textwrap">
                        <h5 @click="openMindMapByID(mindmap)">{{ mindmap.mindmapName }}</h5>
                        <p class="update-time">{{ getTime(mindmap.updateTime) }}</p>
                    </div>
                    <div class="more-btn" @click="openActionMenu(mindmap, $event)">
                        <svg-icon icon-class="more" />
                    </div>
                    <p class="share" v-show="mindmap.openCowork"><svg-icon icon-class="group" /></p>
                </div>
            </div>
            <div class="text-md ml-8 mt-8 font-bold" v-else>
                {{ "暫無資料" }}
            </div>
            <div class="page-btn-wrap" v-if="mindmapList.length > 0">
                <div v-for="pageBtn in pageBtns" :key="pageBtn" class="page-btn"
                    :class="{ 'page-btn-active': pageBtn == currentPageNum }" @click="getMyMindMapListByPage(pageBtn)">
                    {{ pageBtn }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { SharedMap } from "fluid-framework";
import api from "@/api";
import { onMounted, onUnmounted, ref, reactive, inject, computed } from 'vue'
import jwt_decode from "jwt-decode";
import { useMainStore } from "../stores/mainStore";
import { useRouter } from 'vue-router';
import SvgIcon from "../components/SvgIcon.vue";
import { AzureClient } from "@fluidframework/azure-client";
import { InsecureTokenProvider } from "@fluidframework/test-client-utils";
import { useMindMapStore } from "@/stores/mindMapStore";
import { useQuasar } from 'quasar'
const { BlobServiceClient } = require("@azure/storage-blob");

const $Message = inject("$Message")
const store = useMainStore();
const router = useRouter();
const profile = reactive({
    name: "",
    teammodelId: "",
    picture: "",
});
const $q=useQuasar()
const mockImg = computed(() => `url("https://${location.host}/dall-e-img.webp")`)
// console.log(mockImg)
const isLogin = ref(false);
const showShareLinkBox = ref(false);
const showComfirmDelete = ref(false);
const showActionMenu = ref(false);
const currentView = ref("list");
const mindmapList = ref([]);
const pageBtns = ref([]);
const currentPageNum = ref(1);
const currentSortField = ref("updateTime");
const currentSortOrder = ref("desc");
const currentMindMap = ref({});
const serviceConfig = reactive({
    connection: {
        type: "remote",
        tenantId: "ac10fb96-cd7c-4536-882f-90000af1c214", // REPLACE WITH YOUR TENANT ID
        tokenProvider: new InsecureTokenProvider("2mOMLPmQYqbgJ2S7pMVZxh8gYu5Jp3X5C3LHY3XJkDWAn0qZi4JjJQQJ99AHAC16hbFy71xuAAAAAZFROfcC", /* REPLACE WITH YOUR PRIMARY KEY */
            { id: "userId", name: "John Doe" }),
        endpoint: "https://global.fluidrelay.azure.com" // REPLACE WITH YOUR AZURE ENDPOINT
    }
})
const currentMindMapURL = ref("");

const actionMenuPos = reactive({
    x: 0,
    y: 0
})

const search = ref("");

const getMyMindMapListBySort = async (field) => {
    currentPageNum.value = 1;
    if (currentSortField.value == field) {  // 重複點擊
        currentSortOrder.value = currentSortOrder.value == 'asc' ? 'desc' : 'asc';
    } else {
        currentSortField.value = field;
        currentSortOrder.value = 'asc';
    }
    let data = {
        tmid: profile.teammodelId,
        pageSize: 10,
        pageNumber: currentPageNum.value,
        sortField: currentSortField.value,
        sortOrder: currentSortOrder.value
    }
    mindmapList.value = [];
    await getMyMindMapList(data);
}

const createMindMap = () => {
    if (isLogin.value) {
        const data = {
            "mindmapName": "new topic",
            "mindmapCreator": {
                "id": profile.teammodelId,
                "name": profile.name
            },
            "mindmapData": ""

        }
        api.mindmapAPI.createMindMap(data).then(
            (res) => {
                // console.log(res);
                router.push({
                    path: '/MindMap',
                    query: {
                        id: res.id,
                        openCowork: res.openCowork,
                    }
                });
            }
        )
    }

    // router.push("/MindMap");
}
const mindMapStore = useMindMapStore();
const initMindMapBlob = () => {
    if (mindMapStore.mindMapBlobsasurl) {
        const serviceClient = new BlobServiceClient(
            mindMapStore.mindMapBlobsasurl + "?" + mindMapStore.mindMapBlobSas
        );
        const containerClient = serviceClient.getContainerClient("");
        mindMapStore.setMindMapBlobContainerClient(containerClient);
    }
}
const getMindMapSas = async () => {
    try {
        const res = await api.mindmapAPI.getMindMapSas();
        mindMapStore.setMindMapBlobSas(res);
        initMindMapBlob();
    } catch (error) {
        console.error('Error fetching MindMap SAS:', error);
        // 可以根据需要添加更多错误处理逻辑
    }
}
const openMindMapByID = (mindmap) => {
    router.push({
        path: '/MindMap',
        query: {
            id: mindmap.id
        }
    });
}
const openActionMenu = (mindmap, e) => {
    currentMindMap.value = mindmap;
    showActionMenu.value = true
    actionMenuPos.x = e.clientX - 100
    actionMenuPos.y = e.clientY
}

const loginTMID = () => {
    api.main.loginTmdID();
}

const logout = () => {
    if (isLogin.value) {
        api.main.logout(profile.teammodelId).then(async () => {
            const allHits = await JSON.parse(JSON.stringify(store.getAllHits));
            try {
                await api.main.saveAllMaterialsHits(allHits);
            } catch (err) {
                console.log(err);
            }
            $Message.info("已登出 ~")
            //mountMsg("已登出 ~ ");
            //router.push("/");
            isLogin.value = false;
            store.setIsLogIn(false);
            localStorage.removeItem("profile");
            sessionStorage.removeItem("id_token");
        });
    }
}

const getMyMindMapList = async (data) => {
    try {
        const res = await api.mindmapAPI.getMindMapsByTmid(data);
       
        // console.log(res)
        mindmapList.value.push(...res.data);
        pageBtns.value = Array.from({ length: res.totalPages }, (_, index) => index + 1);
    } catch (error) {
        console.error('Error fetching mindmap list:', error);
    }
};

const getTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
}

const openShareLinkBox = (mindmap) => {
    currentMindMap.value = mindmap;
    if (currentMindMap.value.openCowork) {
        currentMindMapURL.value = `${window.location.origin + "/MindMap"}?id=${currentMindMap.value.id}`
    }
    showShareLinkBox.value = true
}

const deleteMindMap = async (mid) => {
    try {
        await api.mindmapAPI.deleteMindMap({ id: mid });
        let data = {
            tmid: profile.teammodelId,
            pageSize: 10,
            pageNumber: currentPageNum.value,
            sortField: currentSortField.value,
            sortOrder: currentSortOrder.value
        }
        mindmapList.value = [];
        await getMyMindMapList(data);
        $Message.info("刪除成功 ~")
        showComfirmDelete.value = false
    } catch (error) {
        console.error('Error deleting mindmap:', error);
    }
};
const getMyMindMapListByPage = async (page) => {
    currentPageNum.value = page
    let data = {
        tmid: profile.teammodelId,
        pageSize: 10,
        pageNumber: currentPageNum.value,
        sortField: currentSortField.value,
        sortOrder: currentSortOrder.value,
        searchText: search.value
    }
    mindmapList.value = [];
    await getMyMindMapList(data);
};
const openComfirmDelete = (mindmap) => {
    currentMindMap.value = mindmap;
    showComfirmDelete.value = true
}
const closeComfirmDelete = () => {
    showComfirmDelete.value = false
}

const closeShareLinkBox = () => {
    showShareLinkBox.value = false
}

const initOpenCowork = async () => {
    //初始化Fluid Relay
    $Message.info("準備開放共享中...請稍後")
    const client = new AzureClient(serviceConfig);
    const containerSchema = {
        initialObjects: { mindHistory: SharedMap },
    };

    const { container } = await client.createContainer(containerSchema, "2");
    const containerId = await container.attach();

    //將設定寫回api
    api.mindmapAPI.updateMindMap({
        id: currentMindMap.value.id,
        "mindmapEditor": {
            "id": currentMindMap.value.mindmapEditor.id,
            "name": currentMindMap.value.mindmapEditor.name,
        },
        openCowork: true,
        fluidContainerId: containerId
    }).then((res) => {
        console.log(res);
        currentMindMapURL.value = `${window.location.origin + "/MindMap"}?id=${currentMindMap.value.id}`
        currentMindMap.value.openCowork = true;

    })
}

const closeOpenCowork = () => { //是否要斷開後續待側
    //將設定寫回api
    api.mindmapAPI.updateMindMap({
        id: currentMindMap.value.id,
        "mindmapEditor": {
            "id": currentMindMap.value.mindmapEditor.id,
            "name": currentMindMap.value.mindmapEditor.name,
        },
        openCowork: false,
    }).then((res) => {
        console.log(res);
        currentMindMap.value.openCowork = false;
    })
}

const handleClickOutside = (event) => {
    if (!event.target.closest('.more-btn') && !event.target.closest('.actions')) {
        showActionMenu.value = false
    }
}

const getMindMapImgUrl = (mindmap) => {
    try {
        if (mindMapStore.mindMapBlobsasurl && mindMapStore.mindMapBlobSas && mindmap?.snapshotUrl) {
            return 'url("' + mindMapStore.mindMapBlobsasurl + mindmap.snapshotUrl + '?' + mindMapStore.mindMapBlobSas + '")';
        } else {
            return `url("https://${location.host}/no-image.jpg")`;

        }
    } catch (err) {
        console.log(err);
    }
}
const setView = async(view) => {
    currentView.value = view
    search.value = ""
    let data = {
        tmid: profile.teammodelId,
        pageSize: 10,
        pageNumber: 1,
        sortField: currentSortField.value,
        sortOrder: currentSortOrder.value,
        searchText: search.value
    }
    mindmapList.value = [];
    await getMyMindMapList(data)
}
const searchMindMaps = async () => {
    let data = {
        tmid: profile.teammodelId,
        pageSize: 10,
        pageNumber: 1,
        sortField: currentSortField.value,
        sortOrder: currentSortOrder.value,
        searchText: search.value
    }
    mindmapList.value = [];
    $q.loading.show();
    await getMyMindMapList(data)
    $q.loading.hide();
}
const resetSearch = async() => {
    search.value = ""
    mindmapList.value = []
    let data = {
        tmid: profile.teammodelId,
        pageSize: 10,
        pageNumber: 1,
        sortField: currentSortField.value,
        sortOrder: currentSortOrder.value
    }
    mindmapList.value = [];
    await  getMyMindMapList(data)

}


onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});
onMounted(async () => {
    //初始化
    $q.loading.show();
    await getMindMapSas();
    document.addEventListener('click', handleClickOutside);
    if (localStorage.getItem("profile") && sessionStorage.getItem("access_token")) {
        isLogin.value = true;
        let localeProfile = JSON.parse(localStorage.getItem("profile"));
        profile.name = localeProfile.name;
        profile.teammodelId = localeProfile.id;
        profile.picture = localeProfile.picture;
        let data = {
            tmid: profile.teammodelId,
            pageSize: 10,
            pageNumber: currentPageNum.value,
            sortField: currentSortField.value,
            sortOrder: currentSortOrder.value
        }
        await getMyMindMapList(data);
        $q.loading.hide();
    } else {
        let url = new URL(window.location.href);
        let code =
            url.searchParams.has("code") == true ? url.searchParams.get("code") : "";
        if (code) {
            //登入取得token
            api.main
                .getToken()
                .then(async(res) => {
                    sessionStorage.setItem("id_token", res.id_token);
                    const decoded = jwt_decode(res.id_token);

                    isLogin.value = true;
                    store.setIsLogIn(true);
                    profile.name = decoded.name;
                    profile.teammodelId = decoded.sub;
                    profile.picture = decoded.picture;

                    //將access_token存本地, http 攔截器使用
                    sessionStorage.setItem("access_token", res.access_token);
                    sessionStorage.setItem("expires_in", res.expires_in);


                    localStorage.setItem(
                        "profile",
                        JSON.stringify({
                            name: profile.name,
                            id: profile.teammodelId,
                            picture: profile.picture,
                        })
                    );
                    let data = {
                        tmid: profile.teammodelId,
                        pageSize: 10,
                        pageNumber: 1,
                        sortField: currentSortField.value,
                        sortOrder: currentSortOrder.value
                    }
                    await getMyMindMapList(data);
                    $q.loading.hide();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
})


</script>
<style lang="less" scoped>
@import "../assets/color.less";
@import "../assets/mindmapHome.less";
</style>