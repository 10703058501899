export default {
  新增: "New node",
  編輯: "Edit Node",
  輸入文字: "Type something...",
  確定: "OK",
  取消: "Cancel",
  請先選取節點: "Please select the node first!",
  不可刪除有子項目的節點: "Nodes with sub-projects cannot be deleted!",
  新增失敗: "The new failed, the parent node has been deleted!",
  編輯失敗: "Editor failed, the selected node has been deleted!",
  加入根節點: "Add root node",
  左邊: "left",
  右邊: "right",
  已是最上面: "Already on top!",
  已是最下面: "Already at the bottom!",
  圖片: "Image",
  圖片過大提示字1: "The selected image is too large (please keep it under",
  圖片過大提示字2: "MB). Please choose another one!",
  連結: "Link",
  連結網址: "Link URL",
  連結名稱: "Link name",
  輸入URL: "Enter URL...",
  輸入連結名稱: "Enter link name...",
  註解: "Annotation",
  進階設定: "Advanced Settings",
  線條顏色: "Line Color",
  節點顏色: "Node Color",
  preEditHint:'Note: The previous editor of the current node is',
  confirmUpdate:', are you sure you want to modify it?',
  menu: {
    新增: "Add",
    刪除: "Delete",
    上移: "Move up",
    下移: "Move down",
    編輯: "Edit",
    複製: "Copy",
    檢視圖片: "View image",
    最後更新: "Last update",
  },
  imgEditor: {
    詢問離開動作: "Choose an action",
    保存圖片: "Save Image",
    捨棄變更: "Discard Changes",
    取消: "Cancel",
  },
};
