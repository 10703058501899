<template>
  <div class="message-wrap" :class='{ "paused": isPausedAnimation }'>
    <div class="message" role="alert">
      <svg-icon class="info-icon" icon-class="info" />
      <span class="font-medium">{{ props.text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "custom-message",
};
</script>
<script setup>

import SvgIcon from "./SvgIcon.vue";
import { defineProps, onMounted, ref } from "vue";

const isPausedAnimation = ref(false)

//vue3 props
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});
onMounted(() => {
  setTimeout(() => {
    isPausedAnimation.value=true
  }, 1200);
})

</script>

<style lang="less">
.message-wrap {
  @apply  fixed left-1/2 top-16 mx-auto max-w-3xl -translate-x-1/2 transform;
  z-index: 1000;

  .info-icon {
    @apply mr-3 inline h-5 w-5;
  }

  -webkit-animation: slideInOut 1.2s forwards;
  animation: slideInOut 1.2s forwards;

  .paused {
    -webkit-animation: slideInOut 0.1s paused;
    animation: slideInOut 0.1s paused;
    -webkit-animation-delay: 1s;
    /* 在50%添加停顿 */
    animation-delay: 1s;
  }
}

.message {
  white-space:nowrap;
  @apply mb-4 flex rounded-lg bg-white px-4 py-2 text-sm text-gray-700 drop-shadow-md border border-gray-200;
}

@-webkit-keyframes slideInOut {
  0% {
    @apply top-16;
  }

  80% {
    @apply top-24;

  }

  100% {
    @apply -top-12;
  }
}

@keyframes slideInOut {
  0% {
    @apply top-16;
  }

  80% {
    @apply top-24;

  }
  100% {
    @apply -top-12;
  }
}
</style>
