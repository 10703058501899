// stores/timer.js
import { defineStore } from 'pinia';
import { useGlobalMethods } from '@/utils/useGlobalMethods';
const { afterLoginRouter } = useGlobalMethods();

export const useTimerStore = defineStore('timer', {
  state: () => ({
    startTime: null,
    endTime: null,
    intervalId: null,
    remainingTime: 1800 // 30 minutes in seconds
  }),
  getters: {
    formattedTime: (state) => {
      const minutes = Math.floor(state.remainingTime / 60);
      const seconds = state.remainingTime % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  },
  actions: {
    startTimer() {
      this.startTime = Date.now();
      this.endTime = this.startTime + this.remainingTime * 1000;
      localStorage.setItem('startTime', this.startTime);
      localStorage.setItem('endTime', this.endTime);

      this.intervalId = setInterval(() => {
        const now = Date.now();
        const timeLeft = Math.max(0, Math.floor((this.endTime - now) / 1000));
        this.remainingTime = timeLeft;
        localStorage.setItem('remainingTime', this.remainingTime);

        if (this.remainingTime <= 0) {
          this.stopTimer();
          this.onTimerEnd();
        }
      }, 300);
    },
    stopTimer() {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.startTime = null;
      this.endTime = null;
      localStorage.removeItem('startTime');
      localStorage.removeItem('endTime');
      localStorage.removeItem('remainingTime');
    },
    loadTimer() {
      const storedStartTime = localStorage.getItem('startTime');
      const storedEndTime = localStorage.getItem('endTime');
      const storedRemainingTime = localStorage.getItem('remainingTime');

      if (storedStartTime && storedEndTime && storedRemainingTime) {
        this.startTime = Number(storedStartTime);
        this.endTime = Number(storedEndTime);
        this.remainingTime = Number(storedRemainingTime);

        const now = Date.now();
        const timeLeft = Math.max(0, Math.floor((this.endTime - now) / 1000));
        this.remainingTime = timeLeft;

        if (this.remainingTime > 0) {
          this.intervalId = setInterval(() => {
            const now = Date.now();
            const timeLeft = Math.max(0, Math.floor((this.endTime - now) / 1000));
            this.remainingTime = timeLeft;
            localStorage.setItem('remainingTime', this.remainingTime);

            if (this.remainingTime <= 0) {
              this.stopTimer();
              this.onTimerEnd();
            }
          }, 1000);
        } else {
          this.onTimerEnd();
        }
      }
    },
    onTimerEnd() {
      // 这里定义时间到时要触发的事件
      //alert('Time is up!');
      
      
      // 可以在这里触发其他事件，例如发出请求、导航到其他页面等

      setTimeout(() => {
        sessionStorage.removeItem("open_id");
        afterLoginRouter();
      }, "1000");
    },   
  }
});
