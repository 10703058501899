<template>
  <div class="profile-page">
    <Modal
      v-if="showConfirmDeleteModal"
      :option="deleteModalOption"
      @close="closeModal"
      @confirm="deleteMaterial()"
    />
    <Modal
      v-if="showConfirmDeletePackModal"
      :option="deleteModalOptionForPack"
      @close="showConfirmDeletePackModal = false"
      @confirm="deletePack()"
    />
    <EditMaterialForm
      v-if="showEditMaterialForm"
      @close="closeEditMaterialForm"
      @confirm="updateMaterial"
    />
    <CreateGroupForm
      v-if="showCreateGroupForm"
      @close="closeCreateGroupForm"
      @confirm="createGroup"
    />
    <CreatePackForm
      v-if="showCreatePackForm"
      @close="closeCreatePackForm"
      @confirm="createOrUpdatePack"
      :packFormMode="packFormMode"
      :packPageMode="'profile'"
      :materials="materials"
      :blobsas="blobsas"
    />
    <SelectedGroupForm
      v-if="showSelectedGroupForm"
      @confirm="setToGroup"
      @close="showSelectedGroupForm = false"
      :groupsNamelist="groupsNamelist"
      :listType="'group'"
    />
    <SelectedGroupForm
      v-if="showSelectedPackForm"
      @confirm="moveToPack"
      @close="showSelectedPackForm = false"
      :groupsNamelist="packslist"
      :listType="'pack'"
    />
    <!---使用者簡介-->
    <div class="userinfo-card">
      <div class="left-part">
        <div
          class="profile-img"
          :style="{ background: getImgUrl(currentProfile) }"
        ></div>
      </div>
      <div class="right-part">
        <div class="ml-5 text-3xl font-bold">
          {{ currentProfile.name }}
        </div>
        <p class="ml-5">{{ $t("已貢獻教材數") }}: {{ uploadNum }}</p>
        <p class="ml-5">
          {{ $t("簡介") }}: <span v-html="urlify(currentProfile.intro)"></span>
          <span class="text-sky-500 underline" v-show="checkCreateUserInfo()"
            ><router-link to="/setting">編輯與儲存個人檔案</router-link></span
          >
        </p>
      </div>
    </div>
    <div class="tab-wrap">
      <span
        class="tab"
        :class="{ 'tab-light': currentTabId == 0 }"
        @click="setTab(0)"
        >資源</span
      ><span
        class="tab"
        :class="{ 'tab-light': currentTabId == 1 }"
        @click="setTab(1)"
        >資源包</span
      ><span
        class="tab"
        :class="{ 'tab-light': currentTabId == 2 }"
        @click="setTab(2)"
        >群組</span
      ><span
        v-show="this.cardOption.allowEdit"
        class="tab"
        :class="{ 'tab-light': currentTabId == 3 }"
        @click="setTab(3)"
        >個人設定</span
      >
    </div>
    <!---群組-->
    <div class="grouptitle-flex" v-show="currentTabId == 2">
      <div class="mb-5 text-3xl font-bold group-title">&nbsp;</div>
      <SelectMenu
        v-show="this.cardOption.allowEdit"
        class="select-menu"
        :category="groupCate"
        :defaultSelect="$t('全部')"
        ref="groupTypeSelect"
      />
    </div>
    <div class="group-area" v-show="currentTabId == 2">
      <div class="groupball-wrap" v-show="this.cardOption.allowEdit">
        <div class="group-ball" @click="openCreateGroupForm()">+</div>
        <p class="font-bold mt-1">創建群組</p>
      </div>
      <div
        v-for="group in groups"
        :key="group.id"
        class="groupball-wrap"
        v-show="
          isFilterICreated ? group.groupCreator.id == currentProfile.id : true
        "
      >
        <div
          class="group-ball"
          @click="viewGroupProfile(group)"
          :class="{ loaded: group.isLoadingNow && group.groupImgURL }"
          v-lazy-background="getGroupImgUrl(group)"
        ></div>
        <!-- {{ group.groupCreator.id }} {{ currentProfile.id }} -->
        <p class="font-bold mt-1">{{ group.groupName }}</p>
      </div>
    </div>

    <!---資源包-->
    <div class="pack-area" v-show="currentTabId == 1">
      <div class="pack-wrap" v-show="this.cardOption.allowEdit">
        <div class="pack-ball" @click="openCreatePackForm()">
          <div>
            <p class="plus-icon">+</p>
            <p class="font-bold">創建資源包</p>
          </div>
        </div>
      </div>
      <CardPack
        v-for="item in packs"
        v-show="item.groupId == ''"
        :key="item.id + item.imgUrl"
        :materials="item"
        :blobsas="blobsas"
        :option="cardOption"
        @viewContent="viewPackContent(item)"
        @openEditPackForm="openEditPackForm"
        @confirmDelete="confirmDeletePack()"
        @openSelectedGroupForm="openSelectedGroupForm"
      />
    </div>
    <!---卡牌-->
    <div v-show="currentTabId == 0">
      <div class="mb-5 text-3xl font-bold">資源</div>
      <div class="card-wrap">
        <Card
          v-for="item in materials"
          v-show="!item.hidden && item.groupId == ''"
          :key="item.id"
          :materials="item"
          :blobsas="blobsas"
          :option="cardOption"
          @viewContent="viewContent(item)"
          @confirmDelete="confirmDelete"
          @openEditMaterialForm="openEditMaterialForm"
          @openSelectedGroupForm="openSelectedGroupForm"
          @confirmAddToPack="confirmAddToPack"
        />
      </div>
      <div class="mb-5 text-3xl font-bold" v-show="this.cardOption.allowEdit">
        {{ $t("被管理員設置為隱藏之教材") }}
      </div>
      <div class="card-wrap" v-show="this.cardOption.allowEdit">
        <Card
          v-for="item in materials"
          v-show="item.hidden"
          :key="item.id"
          :materials="item"
          :blobsas="blobsas"
          :option="cardOption"
        />
      </div>
    </div>
    <div v-show="currentTabId == 3">
      <div class="userinfo-card-setting">
        <div class="left-part" @click="showUserInfo()">
          <div
            class="profile-img"
            :style="{ background: getImgUrl(localeProfile) }"
          ></div>
          <div class="text-md mt-2 font-bold">{{ localeProfile.name }}</div>
        </div>
        <div class="right-part">
          <p class="font-bold">{{ $t("簡介文字") }}</p>
          <textarea v-model="intro"></textarea>
          <div class="save-btn" v-show="!isDBHasUser" @click="createUserInfo()">
            {{ $t("儲存") }}
          </div>
          <div
            class="save-btn"
            v-show="showUpdateBtn"
            @click="updateUserInfo()"
          >
            {{ $t("更新") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "../stores/mainStore";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import api from "@/api";
import { watch, inject } from "vue";
import { jsFn } from "@/utils/jsFn";
import jwt_decode from "jwt-decode";
import EditMaterialForm from "../components/EditMaterialForm.vue";
import CreateGroupForm from "@/components/CreateGroupForm.vue";
import CreatePackForm from "@/components/CreatePackForm.vue";
import SelectedGroupForm from "@/components/SelectedGroupForm.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import CardPack from "@/components/CardPack.vue";
import profile from "@/api/profile";

const { BlobServiceClient } = require("@azure/storage-blob");

export default {
  name: "profile-page",
  setup() {
    const store = useMainStore();
    const mountMsg = inject("mountMsg"); //從App注入
    return { store, mountMsg };
  },
  data() {
    return {
      //"blob刪除使用"
      blobsasurl: "https://teammodeltest.blob.core.windows.net/ht-community/",
      blobServiceClient: "",
      containerClient: "",

      currentProfile: "",
      blobsas: "",
      materials: "",
      uploadNum: 0,
      showConfirmDeleteModal: false,
      showConfirmDeletePackModal: false,
      showEditMaterialForm: false,
      cardOption: {
        pageName: "profile",
        allowEdit: false,
        allowMoveIn: false,
        allowCopy: false,
        allowAddInMyPack: true,
      },
      deleteModalOption: {
        title: "刪除提示",
        btnStyle: "danger",
        desc: "確定刪除這份教材嗎? 刪除後將無法復原!",
        showCloseIconBtn: false,
      },
      deleteModalOptionForPack: {
        title: "刪除提示",
        btnStyle: "danger",
        desc: "確定刪除這份資源包嗎? 刪除後將無法復原!",
        showCloseIconBtn: false,
      },
      editOption: {
        title: "",
      },
      showCreateGroupForm: false,
      showSelectedGroupForm: false,
      groups: [],
      groupsNamelist: [],
      groupCate: [this.$t("全部"), "僅顯示我創建的"],
      isFilterICreated: false,
      countGroupIcreated: 0,
      maxGroupCreated: 10,
      showCreatePackForm: false,
      packFormMode: "create",
      packs: [],
      currentTabId: 0,
      currentSetToGroupMode: "Material",
      showSelectedPackForm: false,
      currentLoginAccount: "",
      packslist: [],
      packsLoginOriginal: [],
      //個人設定
      intro: "",
      isDBHasUser: false,
      showUpdateBtn: false,
      localeProfile: "",
    };
  },
  components: {
    Card,
    Modal,
    EditMaterialForm,
    CreateGroupForm,
    CreatePackForm,
    SelectedGroupForm,
    SelectMenu,
    CardPack,
  },

  async mounted() {
    this.store.setCurrentPage("");
    watch(
      () => this.intro,
      () => {
        this.showUpdateBtn = true;
      }
    );

    watch(
      () => this.store.currentProfile.id,
      async (newVal) => {
        console.log(newVal);
        this.currentProfile = this.store.currentProfile;
        await this.getUserInfoById(this.currentProfile.id);
        await this.getMaterialsByUserId(this.currentProfile.id);
        this.checkAllowEdit();
      }
    );
    watch(
      () => this.$refs.groupTypeSelect.currentSelect,
      (newVal) => {
        this.isFilterICreated = newVal != this.$t("全部");
      }
    );

    try {
      await jsFn.checkSessionAccessTokenReady();
      this.blobsas =
        this.store.blobsas !== ""
          ? this.store.blobsas
          : await api.main.getSas();

      if (this.store.currentProfile) {
        this.currentProfile = this.store.currentProfile;
        await this.getUserInfoById(this.currentProfile.id);
        await this.getMaterialsByUserId(this.currentProfile.id);
        await this.refreshReasourcePacksIcreated();
        await this.getPacksLoginAcountCreated();
      } else if (sessionStorage.getItem("currentViewProfile")) {
        const getSavedProfile = JSON.parse(
          sessionStorage.getItem("currentViewProfile")
        );
        this.currentProfile = getSavedProfile;
        await this.getMaterialsByUserId(this.currentProfile.id);
        await this.refreshReasourcePacksIcreated();
        await this.getPacksLoginAcountCreated();
      } else {
        await this.getUserInfoById(this.$route.params.id);
        await this.getMaterialsByUserId(this.$route.params.id);
        await this.refreshReasourcePacksIcreated();
        await this.getPacksLoginAcountCreated();
      }

      this.initblob();

      await this.getGroupsIattend();

      this.localeProfile = JSON.parse(localStorage.getItem("profile"));

      api.main
        .getUserInfoById({ tmid: this.localeProfile.id })
        .then((res) => {
          this.intro = res.data.intro;
          this.isDBHasUser = true;
        })
        .catch((err) => {
          if (err.response.status == "404") {
            const idToken = sessionStorage.getItem("id_token");
            if (idToken) {
              const decoded = jwt_decode(idToken);
              // console.log(decoded)
              this.currentLoginAccount = {
                id: decoded.sub,
                name: decoded.name,
                picture: decoded.picture,
              };
              let data = {
                id: this.currentLoginAccount.id,
                name: this.currentLoginAccount.name,
                picture: this.currentLoginAccount.picture,
                intro: this.intro,
              };
              console.log(data);
              api.main.createUserInfo(data).then(() => {
                this.isDBHasUser = true;
              });
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    showUserInfo() {
      let idToken = sessionStorage.getItem("id_token");
      let serverLocation = "global";
      let accountUrl = profile.accountUrl[serverLocation];
      api.main.getCode(idToken, false).then((res) => {
        window.open(accountUrl + "?code=" + res);
        // console.log(res, "res");
      });
    },
    urlify(text) {
      return jsFn.urlify(text);
    },
    setTab(id) {
      this.currentTabId = id;
    },
    checkCreateUserInfo() {
      if (this.currentProfile.picture == "" && !this.currentProfile.intro) {
        let loginProfile = JSON.parse(localStorage.getItem("profile"));
        return this.currentProfile.id == loginProfile.id;
      } else {
        return false;
      }
    },
    getGroupsIattend() {
      api.main
        .getGroupsIattend({ tmid: this.currentProfile.id })
        .then((res) => {
          this.groups = res.data;

          this.groups.forEach((item) => {
            item.isLoadingNow = true;
          });
          this.groupsNamelist = this.groups.map((item) => item.groupName);
          this.groupsNamelist.unshift("未指定");
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async setToGroup(data) {
      console.log(this.currentSetToGroupMode);
      if (this.currentSetToGroupMode == "Material") {
        await this.setMaterialToGroup(data);
      } else if (this.currentSetToGroupMode == "Pack") {
        await this.setPackToGroup(data);
      }
    },
    async setPackToGroup(data) {
      let groupIndex = this.groups.findIndex(
        (item) => item.groupName == data.groupName
      );
      if (this.store.currentEditMaterialGroupMode == "move") {
        console.log(groupIndex);
        console.log(this.store.currentEditItem);
        let updateData = {
          id: this.store.currentEditItem.id,
          groupId: this.groups[groupIndex].id,
          groupAllowShow: !this.groups[groupIndex].isUploadToReview,
        };
        await this.updatePackInfo(updateData);
        await this.refreshReasourcePacksIcreated();
        this.showSelectedGroupForm = false;
      } else {
        let packFileInfo = JSON.parse(
          JSON.stringify(this.store.currentEditItem)
        );
        const packCreatorData = {
          id: this.currentProfile.id,
          name: this.currentProfile.name,
          picture: this.currentProfile.picture,
        };

        let data = {
          packCreator: packCreatorData,
          intro: packFileInfo.intro,
          imgUrl: packFileInfo.imgUrl,
          materialsList: packFileInfo.materialsList,
          groupId: this.groups[groupIndex].id,
          title: packFileInfo.title + "(複製)",
          isCopy: true,
          groupAllowShow: !this.groups[groupIndex].isUploadToReview,
        };
        try {
          await api.main.createResourcePack(data);
          this.showSelectedGroupForm = false;
          this.mountMsg("更新成功!");
        } catch (err) {
          console.log(err);
        }
      }
    },

    async setMaterialToGroup(data) {
      let groupIndex = this.groups.findIndex(
        (item) => item.groupName == data.groupName
      );

      if (this.store.currentEditMaterialGroupMode == "move") {
        console.log(groupIndex);
        console.log(this.store.currentEditItem);
        let updateData = {
          id: this.store.currentEditItem.id,
          type: this.store.currentEditItem.type,
          groupId: this.groups[groupIndex].id,
          groupAllowShow: !this.groups[groupIndex].isUploadToReview,
        };
        this.updateMaterial(updateData);
      } else {
        let materialFileInfo = JSON.parse(
          JSON.stringify(this.store.currentEditItem)
        );

        let updateId = jsFn.getUUID();
        materialFileInfo.id = updateId;
        materialFileInfo.groupId = this.groups[groupIndex].id;
        materialFileInfo.title = materialFileInfo.title + "(複製)";
        console.log(materialFileInfo);
        materialFileInfo.isCopy = true;
        (materialFileInfo.groupAllowShow =
          !this.groups[groupIndex].isUploadToReview),
          (materialFileInfo.school = {
            id: "",
            name: "",
          });
        try {
          await api.main.createMaterial(materialFileInfo);
          this.mountMsg("更新成功!");
        } catch (err) {
          console.log(err);
        }
      }
      this.showSelectedGroupForm = false;
    },
    updateMaterial(data) {
      api.main
        .updateMaterialById(data)
        .then((res) => {
          console.log(res);
          this.closeEditMaterialForm();
          this.getMaterialsByUserId(this.currentProfile.id);
          this.mountMsg("更新成功!");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeEditMaterialForm() {
      this.showEditMaterialForm = false;
    },
    openEditMaterialForm() {
      console.log(this.store.currentEditItem);
      console.log("openMeterial");
      this.showEditMaterialForm = true;
    },
    openSelectedGroupForm(data) {
      console.log("開選單");
      this.showSelectedGroupForm = true;
      this.currentSetToGroupMode = data;
    },
    async deleteBlobFolder() {
      const folderName = `${this.currentProfile.id}/${this.store.currentEditItem.id}`; // 資料夾名稱

      const containerClient = this.store.blobContainerClient;

      let iterator = containerClient
        .listBlobsFlat({ prefix: folderName })
        .byPage();
      let response = await iterator.next();

      while (!response.done) {
        for (const blob of response.value.segment.blobItems) {
          // 刪除資料夾中的每個 Blob
          await containerClient.deleteBlob(blob.name);
          console.log(`已刪除 Blob：${blob.name}`);
        }

        response = await iterator.next();
      }
      console.log(`已刪除資料夾：${folderName}`);
    },
    initblob() {
      if (this.blobsasurl != "") {
        this.blobServiceClient = new BlobServiceClient(
          this.blobsasurl + "?" + this.blobsas
        );
        this.containerClient = this.blobServiceClient.getContainerClient("");
        console.log(this.containerClient, "containerClient");
        this.store.setBlobContainerClient(this.containerClient);
      }
    },
    async deleteMaterial() {
      try {
        console.log(this.store.currentEditItem);

        await api.main.deleteMaterialById({
          id: this.store.currentEditItem.id,
          type: this.store.currentEditItem.type,
        });

        await this.deleteBlobFolder();

        this.showConfirmDeleteModal = false;
        this.mountMsg("刪除成功!");

        await this.getMaterialsByUserId(this.currentProfile.id);
      } catch (err) {
        console.log(err);
      }
    },
    confirmDelete() {
      console.log("confirmDelete");
      this.showConfirmDeleteModal = true;
    },
    confirmDeletePack() {
      this.showConfirmDeletePackModal = true;
    },
    async deletePack() {
      try {
        await api.main.deleteResourcePackById({
          id: this.store.currentEditItem.id,
        });
        this.mountMsg("刪除成功!");
        this.showConfirmDeletePackModal = false;
        await this.refreshReasourcePacksIcreated();
      } catch (err) {
        console.log(err); // 處理錯誤情況
      }
    },
    async getPacksLoginAcountCreated() {
      const res = await api.main.getResourcePacksIcreated({
        tmid: this.currentLoginAccount.id,
      });
      this.packsLoginOriginal = res.data;

      this.packslist = res.data
        .filter((item) => item.groupId == "")
        .map((item) => ({
          name: item.title,
          id: item.id,
        }));
    },
    async refreshReasourcePacksIcreated() {
      const res = await api.main.getResourcePacksIcreated({
        tmid: this.currentProfile.id,
      });
      this.packs = res.data;

      this.checkAllowEdit();
    },
    closeModal() {
      console.log("close");
      this.showConfirmDeleteModal = false;
    },
    checkAllowEdit() {
      const idToken = sessionStorage.getItem("id_token");
      if (idToken) {
        const decoded = jwt_decode(idToken);
        // console.log(decoded)
        this.currentLoginAccount = {
          id: decoded.sub,
          name: decoded.name,
          picture: decoded.picture,
        };
        if (this.currentProfile.id == decoded.sub) {
          this.cardOption.allowEdit = true;
          this.cardOption.allowMoveIn = true;
          this.cardOption.allowCopy = true;
        }
      }
    },
    checkBackgroundImageLoaded(imageUrl, group) {
      if (imageUrl) {
        const tempImg = new Image();
        tempImg.src = imageUrl;
        tempImg.onload = () => {
          setTimeout(() => {
            if (group?.isLoadingNow) group.isLoadingNow = false;
          });
          // console.log('背景图像已加载完成。');
        };
      }
    },
    getGroupImgUrl(group) {
      try {
        if (group?.groupImgURL && this.blobsas) {
          // console.log(group);
          const finalUrl =
            this.blobsasurl +
            group.groupCreator.id +
            "/groupImgs/" +
            group.groupImgURL +
            "?" +
            this.blobsas;

          this.checkBackgroundImageLoaded(`${finalUrl}`, group);
          return 'url("' + finalUrl + '")';
        } else if (!group?.groupImgURL) {
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getImgUrl(currentProfile) {
      try {
        if (currentProfile && currentProfile?.picture != "") {
          this.checkBackgroundImageLoaded(currentProfile?.picture);
          return "url(" + currentProfile?.picture + ")";
        } else if (!currentProfile?.picture) {
          return `url("https://${location.host}/no-image.jpg")`;
        }
      } catch (err) {
        console.log(err);
      }
    },
    viewContent(item) {
      this.store.viewContent(item);
    },
    async getUserInfoById(id) {
      try {
        const res = await api.main.getUserInfoById({ tmid: id });
        if (this.currentProfile === "") {
          this.currentProfile = res.data;
        } else {
          this.currentProfile.picture = res.data.picture;
          this.currentProfile.intro = res.data.intro;
        }
        sessionStorage.setItem(
          "currentViewProfile",
          JSON.stringify(this.currentProfile)
        );
      } catch (err) {
        if (err.response && err.response.status === 404) {
          if (this.currentProfile === "") {
            this.currentProfile = {
              id: "",
              name: "",
            };
          }
          this.currentProfile.picture = "";
          this.currentProfile.intro = "";
          sessionStorage.setItem(
            "currentViewProfile",
            JSON.stringify(this.currentProfile)
          );
        }
      }
    },
    getMaterialsByUserId(id) {
      api.main.getMaterialsByUserId({ tmid: id }).then((res) => {
        this.materials = res.data;
        this.uploadNum = this.materials.length;
      });
    },
    uploadImgBlob(targetFile, folderName) {
      return new Promise((r, j) => {
        let targetname =
          this.currentProfile.id + `/${folderName}/` + targetFile[0].name;
        let blockBlobClient =
          this.containerClient.getBlockBlobClient(targetname);
        blockBlobClient.uploadBrowserData(targetFile[0]).then(
          () => {
            // let url = decodeURIComponent(res._response.request.url);
            // url = url.substring(0, url.lastIndexOf("?"));
            r(targetFile[0].name); //僅傳檔名即可由本地拼裝
          },
          (err) => {
            j(err);
          }
        );
      });
    },
    async createGroup(formData) {
      console.log(formData, "form");
      try {
        let materialRes = await this.uploadImgBlob(
          formData.groupImgFile,
          "groupImgs"
        );
        console.log("上傳教材成功", materialRes + "?" + this.blobsas);
        console.log(formData, materialRes);
        let data = {
          groupCreator: {
            id: this.currentProfile.id,
            name: this.currentProfile.name,
            picture: this.currentProfile.picture,
          },
          groupName: formData.groupName,
          groupIntro: formData.groupIntro,
          isUploadToReview: formData.isUploadToReview,
          groupImgURL: materialRes,
        };
        api.main
          .createGroup(data)
          .then(async () => {
            this.closeCreateGroupForm();
            if (this.cardOption.allowEdit) {
              await this.getGroupsIattend();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    },
    openCreateGroupForm() {
      if (this.groups) {
        this.countGroupIcreated = this.groups.filter(
          (item) => item.groupCreator.id == this.currentProfile.id
        ).length;
      }

      if (this.countGroupIcreated < this.maxGroupCreated) {
        this.showCreateGroupForm = true;
      } else {
        this.mountMsg("已達個人最多創建10個群組 !");
      }
    },
    closeCreateGroupForm() {
      this.showCreateGroupForm = false;
    },
    viewGroupProfile(group) {
      this.store.viewGroupProfile(group);
    },
    viewPackContent(item) {
      this.store.viewPackContent(item);
    },
    openCreatePackForm() {
      if (this.materials.length != 0) {
        this.showCreatePackForm = true;
        this.packFormMode = "create";
      } else {
        this.mountMsg("請先至少使用HiTeach上傳一個資源，才可創建資源包!");
      }
    },
    openEditPackForm() {
      this.showCreatePackForm = true;
      this.packFormMode = "edit";
    },
    createOrUpdatePack(formData) {
      if (this.packFormMode == "create") this.createPack(formData);
      else this.updatePack(formData);
    },
    async moveToPack(formData) {
      console.log(formData);
      const index = this.packsLoginOriginal.findIndex(
        (item) => item.id === formData.groupName.id
      );
      console.log(index);
      let updateMaterials = this.packsLoginOriginal[index].materialsList;
      const mId = updateMaterials.findIndex(
        (item) => item.id === this.store.currentEditItem.id
      );
      if (mId == -1) {
        updateMaterials.push(this.store.currentEditItem);
      }

      const data = {
        id: this.packsLoginOriginal[index].id,
        materialsList: updateMaterials,
      };
      console.log(data);
      try {
        await api.main.updateResourcePackInfoById(data);
        this.mountMsg("更新成功!");
        this.showSelectedPackForm = false;
      } catch (err) {
        console.log(err);
      }
    },
    async updatePack(formData) {
      console.log(formData);

      const commonData = {
        id: this.store.currentEditItem.id,
        finalEditor: {
          id: this.currentProfile.id,
          name: this.currentProfile.name,
          picture: this.currentProfile.picture,
        },
        title: formData.title,
        intro: formData.intro,
        materialsList: formData.materialsList,
        grade: formData.grade,
        subject: formData.subject,
        language: formData.language,
        category: formData.category,
      };

      try {
        if (formData.pickImgType === 0) {
          let materialRes = await this.uploadImgBlob(
            formData.imgUrl,
            "packImgs"
          );
          console.log("上傳教材成功", materialRes + "?" + this.blobsas);

          const dataWithImgUrl = {
            ...commonData,
            imgUrl: materialRes,
          };

          await this.updatePackInfo(dataWithImgUrl);
        } else if (formData.pickImgType === 1 || formData.pickImgType === 2) {
          const dataWithImgUrl = {
            ...commonData,
            imgUrl: formData.imgUrl,
          };

          await this.updatePackInfo(dataWithImgUrl);
        } else if (formData.pickImgType === 3) {
          await this.updatePackInfo(commonData);
        }

        this.closeCreatePackForm();
        await this.refreshReasourcePacksIcreated();
      } catch (err) {
        console.log(err);
      }
    },

    async updatePackInfo(data) {
      try {
        await api.main.updateResourcePackInfoById(data);
        this.mountMsg("更新成功!");
      } catch (err) {
        console.log(err);
      }
    },

    async createPack(formData) {
      console.log(formData);

      const packCreatorData = {
        id: this.currentProfile.id,
        name: this.currentProfile.name,
        picture: this.currentProfile.picture,
      };

      let data = {
        packCreator: packCreatorData,
        title: formData.title,
        intro: formData.intro,
        imgUrl:
          formData.pickImgType === 0
            ? await this.uploadImgBlob(formData.imgUrl, "packImgs")
            : formData.imgUrl,
        materialsList: formData.materialsList,
        grade: formData.grade,
        subject: formData.subject,
        language: formData.language,
        category: formData.category,
      };

      try {
        await api.main.createResourcePack(data);
        this.closeCreatePackForm();
        await this.refreshReasourcePacksIcreated();
      } catch (err) {
        console.log(err);
      }
    },
    closeCreatePackForm() {
      this.showCreatePackForm = false;
      this.packFormMode = "create";
    },
    confirmAddToPack() {
      this.showSelectedPackForm = true;
    },
    async updateUserInfo() {
      let localeProfile = JSON.parse(localStorage.getItem("profile"));
      await api.main.updateUserInfoById({
        tmid: localeProfile.id,
        intro: this.intro,
      });

      this.showUpdateBtn = false;
      await this.getUserInfoById(localeProfile.id);
    },
    createUserInfo() {
      let localeProfile = JSON.parse(localStorage.getItem("profile"));
      let data = {
        id: localeProfile.id,
        name: localeProfile.name,
        picture: localeProfile.picture,
        intro: this.intro,
      };
      api.main.createUserInfo(data).then(() => {
        this.isDBHasUser = true;
      });
    },
  },
};
</script>

<style lang="less">
.profile-page {
  @apply p-10;

  .confirm-wrap {
    position: fixed;
    width: 100%;
  }
  .userinfo-card-setting {
    background-color: #fff;
    @apply flex flex-wrap rounded-md p-5;
    .left-part {
      flex: 1;
      cursor: pointer;
      .profile-img {
        @apply rounded-md;
        height: 100px;
        width: 100px;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
      }
    }
    .right-part {
      flex: 20;
      padding-left: 20px;
      textarea {
        @apply my-2 rounded-md border border-gray-400;
        width: 100%;
        padding: 10px !important;
        &:active,
        &:focus,
        &:hover {
          outline: none;
          @apply border-2 border-purple-600 !important;
        }
      }
      .save-btn {
        margin-bottom: 20px;
        @apply inline-block cursor-pointer rounded-md bg-sky-500 px-4 py-2 text-sm font-bold text-white;
        &:hover {
          @apply bg-sky-600;
        }
      }
    }
  }
  .userinfo-card {
    background-color: #fff;
    @apply flex rounded-md p-5 mb-5;

    .left-part {
      .profile-img {
        @apply rounded-md;
        height: 100px;
        width: 100px;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
      }
    }
  }
  .tab-wrap {
    position: relative;
    @apply mb-5 text-xl font-bold;

    .tab {
      @apply mr-5 text-gray-500 cursor-pointer;
    }

    .tab-light {
      @apply text-black !important;
    }
  }
  .grouptitle-flex {
    display: flex;
    position: relative;

    @media (max-width: theme("screens.lg")) {
      .group-title {
        flex: 1;
      }
      .select-menu {
        flex: 1;
        position: relative;
        top: -10px;
      }
    }
    @media (min-width: theme("screens.lg")) {
      .group-title {
        flex: 6;
      }
      .select-menu {
        flex: 1;
        position: relative;
        top: -10px;
      }
    }
  }
  .pack-area {
    @apply my-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8;
    .pack-wrap {
      text-align: center;
      position: relative !important;

      .loaded {
        @apply animate-pulse !important;
        // background: none !important;
        @apply bg-gray-500 !important;
        img {
          visibility: hidden;
        }
      }
      .pack-ball {
        display: flex;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中 */
        /* 其他样式 */
        @apply bg-sky-200 border border-sky-400;
        position: relative !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        height: 100%;
        width: 100%;
        font-size: 16px;
        line-height: 36px;
        vertical-align: baseline;
        min-height: 100px;
        cursor: pointer;
        border-radius: 10px;
        text-align: center !important;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        .plus-icon {
          font-size: 50px;
        }

        &:hover {
          @apply bg-sky-300 drop-shadow-xl;
          opacity: 0.8;
        }
      }
    }
  }
  .group-area {
    @apply my-8 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6  lg:grid-cols-8 xl:grid-cols-8 gap-8;

    .groupball-wrap {
      text-align: center;
      position: relative !important;

      .loaded {
        @apply animate-pulse !important;
        // background: none !important;
        @apply bg-gray-500 !important;
        img {
          visibility: hidden;
        }
      }
      .group-ball {
        @apply bg-sky-200 border border-sky-400;
        position: relative !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        height: 120px;
        width: 120px;
        font-size: 30px;
        line-height: 115px;
        cursor: pointer;
        border-radius: 50%;
        text-align: center !important;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;

        &:hover {
          @apply bg-sky-300 drop-shadow-xl;
          opacity: 0.8;
        }
        .group-icon {
          position: absolute;
          left: 50% !important;
          transform: translateX(-50%) !important;
          top: 25px;
        }
      }
    }
  }
}
</style>
