import mindmap from "./mindmap";
import konvaCanvas from "./konvaCanvas";
export default {
  konvaCanvas,
  mindmap,
  "header": {
    "digitalLearningOffice": "{region}政府教育局",
    "authorizationPlatform": "HiTeach 授权与活动平台",
    "cityInformation": "{region}资讯",
    "softwareIntroduction": "HiTeach 软件介绍",
    "learningResources": "学习资源",
    "latestNews": "最新消息",
    "contactUs": "联络我们",
    "login": "登入"
  },
  "eventPage": {
    applyCheck:'我要申请/查询',
    authorization:'授权',
    entry1:'县市统一采购HiTeach授权进入口',
    getAuthorization:'由此进入后申请授权',
    check:'我要报名/查询',
    event:'活动',
    competition:'县市XHiTeach竞赛活动',
    registration:'由此进入后申请报名',
    eventPreparation: "活动筹备中",
    notification: "通知"
  },
  "AccountInfo": {
    "availableProducts": "可申请商品",
    "noAvailableItems": "目前没有可申请的项目",
    "apply": "申请",
    "authorizationNote": "※如果 HiTeach 日期重复，重叠时间会往后累加！",
    "myAuthorizations": "我的授权",
    "customerSupport": "客服协助",
    "productName": "产品名称",
    "usageTime": "使用时间",
    "state": "状态",
    additionalItem: 'Additional Item',
    "confirmApplication": "确定要申请吗？",
    "inquiry": "询问",
    "yes": "对",
    teamModelAccount: '醍摩豆用户编号',
    schoolNotInList: '学校不在此次的统购清单中',
    schoolAuthorizationFull: '学校授权已满',
    clickToLearnHiTeach: '还不会使用 HiTeach 吗？,请点这里学习！',
    notApplied: '未申请',
    appliedNotAuthorized: '申请已受理'
  },
  "AccountLogin":{
    sendVerificationCode: '传送验证码',
    resend: '重新发送',
    haveTeamModelAccount: '我有,醍摩豆账号',
    noTeamModelAccount: "我没有,醍摩豆账号",
    chooseMethodToProceed: '请选择任一种方式继续下一步',
    bindExistingTeamModelAccount: '进入绑定原有醍摩豆账号页面',
    registerAndBindNewAccount: '新注册醍摩豆账号，并绑定教育云账号',
    completeWithin30Minutes: '此阶段请于{time}分钟内完成，如逾期将须重新登入',
    back: '返回',
    chooseBindingMethod: '请选择,任一种,方式绑定原有醍摩豆账号',
    qrCodeBinding: 'QR-Code',
    qrCodeExpired: '您的 QR Code 已失效，请点选下方更新按钮',
    update: '更新',
    useHiTA5AppToScan: '请使用 HiTA5 APP 扫码',
    emailPhoneUserID: '醍摩豆账号',
    password: '密码',
    bindNow: '立刻绑定',
    sms: '手机简讯',
    phoneNumber: '手机号码',
    verificationCode: '验证码',
    confirmVerificationCode: '确认验证码',
    sendingSMS: '传送简讯中...',
    registerNewAccountPrompt: '请问是否要注册全新醍摩豆账号？',
    confirm: '确定',
    cancel: '取消',
    bindingSuccessful: '绑定成功',
    qrCodeLogin: 'QRCode 登入',
    incorrectAccountOrPassword: '帐号或密码错误',
    enterAccountAndPassword: '请输入帐号及密码',
    incorrectPhoneNumberFormat: '手机号码格式错误',
    enterPhoneNumber: '请输入手机号码',
    incorrectAccountOrVerificationCode: '帐号或验证码错误',
    incorrectVerificationCodeFormat: '验证码格式错误',
    enterVerificationCode: '请输入验证码',
    verificationCodeSendFailed: '验证码发送失败',
    verificationCodeSent: '验证码已发送',
    afterBindingLoginWithEduCloud: '绑定后，下次即可直接使用教育云账号做登录啰！',
  }
};
