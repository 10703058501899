<template>
  <div class="content-page" :freshKey="freshKey">
    <ReportMsgForm
      v-show="showReportMsgForm"
      @close="closeReportMsgForm"
      @confirm="saveReportMsg"
    />
    <div v-if="currentContent" class="content-page-top">
      <div class="content-flex" v-if="!currentContent.hidden">
        <div class="content-left">
          <div v-if="currentContent.type == 'htex'">
            <ImgCarousel :images="htexSnapshotImgs" />
            <div class="btn-group">
              <a
                :href="getBlobUrl(currentContent)"
                class="link"
                @click="sendMsgHT(getBlobUrl(currentContent))"
                >{{ $t("下載") }}</a
              >
              <div
                class="report-btn"
                v-show="isShowReportBtn"
                @click="openReportMsgForm"
              >
                {{ $t("檢舉") }}
              </div>
            </div>
            <CCAuthBadge
              :ccShareAgainType="currentContent.ccShareAgainType"
              :ccShareCommercial="currentContent.ccShareCommercial"
            />
          </div>
          <div v-if="currentContent.type == 'audio'">
            <div
              class="img-main"
              v-show="!isStringEndsWithMP3(currentContent.blobUrl)"
              :class="{
                loaded: isLoadingNow && currentContent.imgBlobUrl,
                'image-noscroll': !currentContent.imgBlobUrl,
              }"
            >
              <img :src="getImgUrl(currentContent)" alt="" draggable="false" />
            </div>

            <div v-show="isStringEndsWithMP3(currentContent.blobUrl)">
              <audio
                style="width: 100%; margin-top: 70px"
                height="auto"
                controls
              >
                <source
                  :src="currentContent.blobUrl + '?' + blobsas"
                  type="audio/mpeg"
                />
                {{ $t("無法播放的格式") }}
              </audio>
            </div>
            <div class="btn-group">
              <a
                :href="getBlobUrl(currentContent)"
                class="link"
                @click="sendMsgHT(getBlobUrl(currentContent))"
                >{{ $t("下載") }}</a
              >
              <div
                class="report-btn"
                v-show="isShowReportBtn"
                @click="openReportMsgForm"
              >
                {{ $t("檢舉") }}
              </div>
            </div>
            <p
              v-show="!isStringEndsWithMP3(currentContent.blobUrl)"
              class="video-note"
            >
              {{ $t("直接下載提示字") }}
            </p>
            <CCAuthBadge
              :ccShareAgainType="currentContent.ccShareAgainType"
              :ccShareCommercial="currentContent.ccShareCommercial"
            />
          </div>
          <div v-if="currentContent.type == 'video'">
            <div
              class="img-main"
              v-show="!isStringEndsWithMP4(currentContent.blobUrl)"
              :class="{
                loaded: isLoadingNow && currentContent.imgBlobUrl,
                'image-noscroll': !currentContent.imgBlobUrl,
              }"
            >
              <img :src="getImgUrl(currentContent)" alt="" draggable="false" />
            </div>

            <div v-show="isStringEndsWithMP4(currentContent.blobUrl)">
              <video style="width: 100%" height="auto" controls>
                <source :src="getBlobUrl(currentContent)" type="video/mp4" />
                {{ $t("無法播放的格式") }}
              </video>
            </div>
            <div class="btn-group">
              <a
                :href="getBlobUrl(currentContent)"
                class="link"
                @click="sendMsgHT(getBlobUrl(currentContent))"
                >{{ $t("下載") }}</a
              >
              <div
                class="report-btn"
                v-show="isShowReportBtn"
                @click="openReportMsgForm"
              >
                {{ $t("檢舉") }}
              </div>
            </div>
            <p
              v-show="!isStringEndsWithMP4(currentContent.blobUrl)"
              class="video-note"
            >
              {{ $t("直接下載提示字") }}
            </p>
            <CCAuthBadge
              :ccShareAgainType="currentContent.ccShareAgainType"
              :ccShareCommercial="currentContent.ccShareCommercial"
            />
          </div>
          <div
            v-if="
              currentContent.type == 'exam' ||
              currentContent.type == 'back' ||
              currentContent.type == 'tutorial' ||
              currentContent.type == 'plan'
            "
          >
            <div
              class="img-main"
              :class="{
                loaded: isLoadingNow && currentContent.imgBlobUrl,
                'image-noscroll': !currentContent.imgBlobUrl,
              }"
            >
              <img :src="getImgUrl(currentContent)" alt="" draggable="false" />
            </div>
            <!-- <p class="video-note">目前無法預覽之格式，請直接下載</p> -->
            <div class="btn-group">
              <a
                :href="getBlobUrl(currentContent)"
                class="link"
                @click="sendMsgHT(getBlobUrl(currentContent))"
                >{{ $t("下載") }}</a
              >
              <div
                class="report-btn"
                v-show="isShowReportBtn"
                @click="openReportMsgForm"
              >
                {{ $t("檢舉") }}
              </div>
            </div>
            <CCAuthBadge
              :ccShareAgainType="currentContent.ccShareAgainType"
              :ccShareCommercial="currentContent.ccShareCommercial"
            />
          </div>
        </div>
        <div class="content-right">
          <!-- {{ currentContent }} -->
          <div v-if="currentContent.type == 'htex'" class="main-type">
            {{ $t("布題頁面") }}
          </div>
          <div v-if="currentContent.type == 'audio'" class="main-type">
            {{ $t("音訊") }}
          </div>
          <div v-if="currentContent.type == 'video'" class="main-type">
            {{ $t("影片") }}
          </div>
          <div v-if="currentContent.type == 'back'" class="main-type">
            {{ $t("背景圖") }}
          </div>
          <div v-if="currentContent.type == 'exam'" class="main-type">
            {{ $t("測驗卷") }}
          </div>
          <div v-if="currentContent.type == 'tutorial'" class="main-type">
            {{ $t("教材") }}
          </div>
          <div v-if="currentContent.type == 'plan'" class="main-type">
            {{ $t("教案") }}
          </div>
          <div class="main-title">{{ currentContent.title }}</div>
          <div class="badge-group">
            <span class="badge"
              ><span v-show="$i18n.locale == 'en-US'">Grade&nbsp;</span
              >{{ currentContent.grade
              }}<span v-show="$i18n.locale !== 'en-US'">年級</span></span
            >
            <span class="badge" v-show="currentContent.subject">{{
              currentContent.subject
            }}</span>
          </div>
          <p class="text-gray-600">
            {{ getTime(currentContent.timeStamp) }}・{{ $t("上傳者") }}：<span
              class="uploader"
              @click="viewProfile(currentContent.uploader)"
              >{{ currentContent.uploader.name }}</span
            >
          </p>
          <div
            class="font-bold"
            v-show="
              currentContent.school &&
              currentContent.school.id &&
              currentContent.school.name
            "
          >
            學校資料:{{ currentContent.school }}
          </div>
          <div class="font-bold">
            {{ $t("授課語系") }}：{{ currentContent.language }}
          </div>
          <div class="font-bold">
            {{ $t("簡介") }}：<span v-html="urlify(currentContent.intro)"></span>
          </div>
          <br />
          <div class="font-bold">
            {{ "最近更新者" }}：{{ getTime(currentContent.updateTimeStamp) }},
            {{ currentContent.finalEditor.name }}
          </div>
          <div class="tag-group">
            <span
              v-for="(item, index) in currentContent.category"
              :key="index + currentContent.category + currentContent.id"
              class="tag"
              >#{{ item }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="currentContent.hidden">該教材已被設置為隱藏</div>
    </div>
    <div v-if="!currentContent" class="content-page-top">
      {{ $t("暫無資料") }}
    </div>
    <div class="mt-8 mb-5 text-3xl font-bold" v-show="!currentContent.hidden">
      {{ $t("相關教材") }}
    </div>
    <div class="card-wrap">
      <Card
        v-for="item in relatedMaterials"
        v-show="!item.hidden"
        :key="item.id"
        :materials="item"
        :blobsas="blobsas"
        @viewContent="viewContent(item)"
      />
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { jsFn } from "@/utils/jsFn";
import zipTool from "@/utils/jszip";
import { useMainStore } from "../stores/mainStore";
import Card from "@/components/Card.vue";
import ImgCarousel from "@/components/ImgCarousel.vue";
import ReportMsgForm from "@/components/ReportMsgForm.vue";
import profile from "../api/profile";
import { inject } from "vue";
import CCAuthBadge from "@/components/CCAuthBadge.vue";
export default {
  name: "content-page",
  setup() {
    const store = useMainStore();
    const mountMsg = inject("mountMsg"); //從App注入
    return { store, mountMsg };
  },
  data() {
    return {
      relatedMaterials: [],
      currentContent: "",
      blobsas: "",
      isLoadingNow: true,
      freshKey: 0,
      htexSnapshotImgs: [],
      isloadingHtexImgs: true,
      showReportMsgForm: false,
    };
  },
  components: {
    Card,
    ImgCarousel,
    ReportMsgForm,
    CCAuthBadge,
  },
  computed: {
    isShowReportBtn: () => {
      return localStorage.getItem("profile") ? true : false;
    },
    isContentHidden: () => {
      return this.currentContent.hidden;
    },
  },
  async mounted() {
    this.store.setCurrentPage("");
    jsFn
      .checkSessionAccessTokenReady()
      .then(async () => {
        this.blobsas =
          this.store.blobsas !== ""
            ? this.store.blobsas
            : await api.main.getSas();

        if (this.store.currentContent) {
          this.currentContent = this.store.currentContent;
          this.saveLocal(this.currentContent);
          if (!this.currentContent.hidden)
            api.main.getRelatedMaterials(this.currentContent).then((res) => {
              this.relatedMaterials = res.data;
            });
          if (this.currentContent.type == "htex") this.getHtexFile();
        } else if (sessionStorage.getItem("currentContent")) {
          const deSavedContent = jsFn.decrypt(
            sessionStorage.getItem("currentContent"),
            "123"
          );
          const getSavedContent = JSON.parse(deSavedContent);
          //console.log(getSavedContent)
          //解密處理本地教材
          if (
            this.$route.params.type === getSavedContent.type &&
            this.$route.params.id === getSavedContent.id
          ) {
            this.currentContent = getSavedContent;
            console.log(this.currentContent);
          }
          if (this.currentContent.type == "htex") this.getHtexFile();
          if (!this.currentContent.hidden)
            api.main.getRelatedMaterials(this.currentContent).then((res) => {
              this.relatedMaterials = res.data;
            });
        } else {
          //沒有本地也沒有點擊，通常是直接給網址的時候，需要直接用api要資料
          api.main
            .getMaterialById({
              id: this.$route.params.id,
              type: this.$route.params.type,
            })
            .then(async (res) => {
              this.currentContent = res.data;
              // console.log(this.currentContent)
              if (this.currentContent.type == "htex") this.getHtexFile();
              if (!this.currentContent.hidden)
                api.main
                  .getRelatedMaterials(this.currentContent)
                  .then((res) => {
                    this.relatedMaterials = res.data;
                  });
            });
        }
      })
      .catch((err) => {
        console.log(err); //未取到access_token
      });
  },
  methods: {
    urlify(text) {
      return jsFn.urlify(text);
    },
    openReportMsgForm() {
      this.showReportMsgForm = true;
      this.store.setCurrentEditItem(this.currentContent);
    },
    closeReportMsgForm() {
      this.showReportMsgForm = false;
    },
    saveReportMsg(data) {
      api.main.createReportMsg(data).then(() => {
        this.closeReportMsgForm();
      });
    },
    sendMsgHT(url) {
      console.log(url);
      if (window.chrome.webview)
        window.chrome.webview.postMessage({
          downloadFileUrl: url,
        });
    },

    async convertBlobToFile(blobUrl) {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      const fileName = "htexTarget"; // You can set the desired file name here
      const file = new File([blob], fileName, { type: blob.type });
      return file;
    },

    async getHtexFile() {
      if (this.currentContent.type == "htex") {
        this.htexSnapshotImgs.push(this.getImgUrl(this.currentContent));
        const HTEXFile = await this.convertBlobToFile(
          this.getBlobUrl(this.currentContent)
        );
        // console.log(HTEXFile);

        await zipTool
          .getFileList(HTEXFile)
          .then(async (files) => {
            const indexJSON = files.find((item) => item.name === "index.json");
            if (indexJSON) {
              this.htexSnapshotImgs.push(
                `https://${location.host}/Spinner.gif`
              );
              const htexCoordinationText = await indexJSON.text();
              const htexCoordination = JSON.parse(htexCoordinationText);
              // console.log(htexCoordination);
              const imgpreviewLength =
                htexCoordination.slides.length > 10
                  ? 10
                  : htexCoordination.slides.length;
              for (let i = 0; i < imgpreviewLength; i++) {
                const targetImgName =
                  htexCoordination.slides[i].url.replace(".json", "") +
                  "_snapshot." +
                  htexCoordination.thumbnail.slice(16, 19);
                // console.log(targetImgName);
                // console.log(files);
                const imgIndex = files.findIndex(
                  (item) => item.name === targetImgName
                );
                // console.log(files[imgIndex]);
                // 创建图像元素
                this.htexSnapshotImgs.push(
                  URL.createObjectURL(files[imgIndex])
                );
              }
            }
            this.isloadingHtexImgs = false;
            this.htexSnapshotImgs.splice(1, 1);
          })
          .catch((err) => {
            if (err) {
              this.isloadingHtexImgs = false;
              this.htexSnapshotImgs.splice(1, 1);
            }
          });
      }
    },
    isStringEndsWithMP4(str) {
      const lastIndex = str.lastIndexOf(".mp4");
      return lastIndex !== -1 && lastIndex === str.length - 4;
    },
    isStringEndsWithMP3(str) {
      const lastIndex = str.lastIndexOf(".mp3");
      return lastIndex !== -1 && lastIndex === str.length - 4;
    },

    saveLocal(item) {
      const savedContent = JSON.parse(JSON.stringify(item));
      delete savedContent.pk;
      delete savedContent.ttl;
      // // delete savedContent.hidden;
      // delete savedContent.reported;
      console.log(savedContent);

      //初步加密教材到本地
      const encSavedContent = jsFn.encrypt(JSON.stringify(savedContent), "123");
      sessionStorage.setItem("currentContent", encSavedContent);
    },
    viewContent(item) {
      this.currentContent = item;
      this.saveLocal(this.currentContent);
      this.store.viewContent(item);
      this.freshKey++;
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可選，使滾動平滑進行
      });
      this.htexSnapshotImgs = [];
      this.isloadingHtexImgs = true;
      if (this.currentContent.type == "htex") this.getHtexFile();
      api.main.getRelatedMaterials(this.currentContent).then((res) => {
        this.relatedMaterials = res.data;
      });
    },
    checkBackgroundImageLoaded(imageUrl) {
      const tempImg = new Image();
      tempImg.src = imageUrl;
      tempImg.onload = () => {
        this.isLoadingNow = false;
        // console.log('背景图像已加载完成。');
      };
    },
    getBlobUrl(materials) {
      if (materials.blobUrl.includes(profile.blobsasurl)) {
        return materials.blobUrl + "?" + this.blobsas;
      } else {
        const finalUrl =
          profile.blobsasurl +
          materials.uploader.id +
          "/" +
          materials.id +
          "/" +
          materials.blobUrl +
          "?" +
          this.blobsas;
        return finalUrl;
      }
    },
    getImgUrl(materials) {
      try {
        if (materials.imgBlobUrl && this.blobsas) {
          if (materials.imgBlobUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials.imgBlobUrl + "?" + this.blobsas}`
            );
            return materials.imgBlobUrl + "?" + this.blobsas;
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.uploader.id +
              "/" +
              materials.id +
              "/" +
              materials.imgBlobUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`);
            return finalUrl;
          }
        } else if (!materials?.imgBlobUrl) {
          return "https://" + location.host + "/no-image.jpg";
        }
      } catch (err) {
        console.log(err);
      }
    },
    getTime(timestamp) {
      const date = new Date(timestamp * 1000);

      //const year = date.getFullYear();
      //const month = date.getMonth() + 1; // 月份从 0 开始，所以需要加 1
      //const day = date.getDate();
      // const hours = date.getHours();
      // const minutes = date.getMinutes();
      // const seconds = date.getSeconds();
      return date.toLocaleDateString();
    },
    viewProfile(uploader) {
      this.store.viewProfile(uploader);
    },
  },
};
</script>

<style lang="less">
.content-page {
  @apply m-12 mb-0;
  .content-page-top {
    @apply rounded-lg bg-white p-10;
  }
  .tag-group {
    @apply mt-5 text-xs font-bold text-sky-500;

    .tag {
      @apply mx-1;
    }
  }

  .content-flex {
    
    @media (min-width: theme("screens.lg")) {
      display: flex;
      flex-wrap: wrap;
    }
    @media (max-width: theme("screens.lg")) {
      display: block
    }

    .content-left {
      flex: 1;
      position: relative;
      @apply p-10;
      img {
        visibility: visible;
        pointer-events: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
      .video-note {
        @apply mt-2 text-center text-sm text-gray-500;
      }
      .btn-group {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
      }
      .link {
        flex: 8;
        width: 100%;
        margin-right: 10px;
        @apply mt-5 block rounded-md bg-sky-500 p-2 text-center font-bold text-white;
        &:hover {
          @apply bg-sky-600;
        }
      }
      .report-btn {
        flex: 1;
        width: 100%;
        white-space: nowrap;
        @apply cursor-pointer mt-5 block rounded-md bg-slate-200 p-2 text-center font-bold text-gray-800;
        &:hover {
          @apply bg-slate-300;
        }
      }
      .loaded {
        @apply animate-pulse;

        @apply bg-gray-500 !important;
        img {
          visibility: hidden;
        }
      }
      .image-noscroll {
        overflow: hidden !important;
        height: auto !important;
        width: 200px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border: unset !important;
        max-height: unset !important;
        background: none !important;
      }
      .img-main {
       
        margin-bottom: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        position: relative;
        img {
          @apply mx-auto;
          border: 1px solid rgba(128, 128, 128, 0.5);
          width: 100%;
          height: auto;
        }
      }
    }

    .content-right {
      flex: 2;

      @apply p-10;
      .main-type {
        line-height: 20px;

        @apply mb-5 inline-block rounded-sm bg-purple-600 p-2 font-bold text-white;
      }
      .main-title {
        line-height: 60px;
        @apply text-6xl font-bold;
      }
      .uploader {
        line-height: 50px;
        @apply cursor-pointer font-bold text-sky-500;
        text-decoration: underline;
      }
      .badge-group {
        line-height: 60px;
        .badge {
          @apply mx-1 cursor-pointer rounded-full bg-yellow-400 px-2 py-1 text-sm font-semibold text-black;
        }
      }
    }
  }
}
</style>
