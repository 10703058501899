<template>
  <div class="home-page div-centerY div-center">    
    <!-- <img class="sunPic" src="../assets/img/weather/weather_sun.png" alt=""> -->
    <div class="oneLine main">
      <img class="treePic" src="../assets/img/bean/bean_08.png" alt="">      
      <div class="div-centerY2">
        <div class="chooseDiv">
          <div class="button" @click="ActivityFun">
            <img class="otherPic2" src="../assets/img/symbol_arrow2red.png" alt="">
            <img class="buttomImg" src="../assets/img/area-01.png" alt="">
            <div class="pngText">
              {{ $t("eventPage.check") }}<br>
              <strong class="div-center">{{ $t("eventPage.event") }}</strong>
            </div>
          </div>
          <div class="textBox div-center">
            若要報名活動，請點選上方按鈕！
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import { inject } from 'vue'
import { useRouter } from 'vue-router'
// import { ElMessageBox } from 'element-plus';
// import profile from "../api/profile";
// import {Base64} from 'js-base64'

const routers = useRouter();

function routerskip(val) {
  routers.push(val);
}
/*function goInfo() {
  if (sessionStorage.getItem("open_name")) {
    routerskip("/AccountInfo");
  } else {
    sessionStorage.setItem("info","1");// 如果是從申請按鈕進來的  紀錄一下 以便完成綁定之後直接到申請頁
    let urlhost = location.host == "localhost:5005" ? "testHT.teammodel.net" : location.host
    let url = "https://oidc.tanet.edu.tw/onesteplogin?Auth_Request_RedirectUri=https://account.teammodel.net/quickopencode/educloudtw/"+profile.clientID["global"]+"?op_redirecturi="+Base64.encode("https://" + urlhost + "/Binding_CheckAccount")+"&Auth_Request_State=educloudtw&Auth_Request_Response_Type=code&Auth_Request_Client_ID=c58722f58275a8c81fcc5ed6b94f3e7b&Auth_Request_Nonce=aaaaa&Auth_Request_Scope=openid+email+profile+eduinfo&local=true";
    location.href = url;
  }
}*/

//點擊活動的事件
// const t = inject('$t');
function ActivityFun(){
  routerskip("/Activity_List");
}    

</script>

<style lang="less" scoped>
.div-center{/*水平置中*/
  display: flex; 
  justify-content: center; 
  text-align:center;
  width:100%;
}
.div-centerY{ /*垂直置中*/
  display: flex;    
  align-items: center;
  flex-direction: column;  //有時可能需要在加這個屬性
}
.div-centerY2{ /*垂直置中*/
  margin-top: auto;
  margin-bottom: auto;
}

.oneLine{ /*單行與折行顯示*/
  display: flex;
  flex-wrap: wrap; //元素折行呈現，元素空間夠的時候單行呈現，空間不夠則折行呈現
  flex-wrap: nowrap; //元素單行呈現  
}
.home-page {
  height: 80vh;
  position: relative;//父元素設置為相對定位
  // .sunPic{
  //   position: absolute;
  //   right: 30px;
  //   top: 0px;
  //   width: 150px;
  // }
  .main{
    .treePic{
      width: 500px;
      margin-right: 50px;
    }
    .chooseDiv{
      margin: 0 50px;   
      .button{
        position: relative;
        cursor: pointer;//滑鼠變成小手
        .pngText{
          position: absolute;
          transform: translate(50%, -150%);
          color: white;
          font-size: 20px;
        }
        .buttomImg{
          width: 250px;
          display: block;
        }
        .otherPic1{
          position: absolute;
          width: 100px;
          top: -80px; 
          left: -50px; 
        }
        .otherPic2{
          position: absolute;
          transform:scaleX(-1);
          width: 100px;
          bottom: -40px; 
          right: -120px; 
        }
      }
      .textBox{
        margin-top: 10px;
        line-height:30px; 
      }
    }
    .peoplePic{
      margin-top: 30px;
      height: 320px;
    }
  }
}
</style>
