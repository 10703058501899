<template>
  <div class="reported-view">
    <div class="mb-5 text-3xl font-bold" v-show="currentViewMode == 'time'">
      {{ $t("檢舉管理時間") }}
      <span v-show="showChangViewBtn">({{ reportedMsgs.length }})</span>
    </div>
    <div
      class="mb-5 text-3xl font-bold"
      v-show="currentViewMode == 'materialId' && showChangViewBtn"
    >
      {{ $t("檢舉管理教材") }}({{ reportedMsgsByMId.length }})
    </div>
    <div class="flex-wrap">
      <div class="search-wrap">
        <SearchBar
          class="search"
          @searchReport="searchReport"
          :placeholder="
            currentViewMode == 'time'
              ? $t('檢舉搜尋提示時間')
              : $t('檢舉搜尋提示教材')
          "
        />
      </div>
      <div class="select-wrap" v-show="currentViewMode == 'time'">
        <SelectMenu
          v-show="showChangViewBtn"
          class="select-item"
          :category="cateStatus"
          :defaultSelect="this.$t('未處理')"
          ref="selectStatus"
        />
      </div>
      <div class="select-wrap" v-show="currentViewMode == 'materialId'">
        <SelectMenu
          v-show="showChangViewBtn"
          class="select-item"
          :category="hideStatus"
          :defaultSelect="this.$t('未隱藏')"
          ref="hideStatus"
        />
      </div>
      <!---沿用改寫Sorter-->
      <div class="sort-block" v-show="showChangViewBtn">
        <div class="sort">
          <div>{{ $t("檢視模式") }}</div>
          <div
            class="sort-btn"
            @click="setView('time')"
            :class="{ 'ligt-btn': currentViewMode == 'time' }"
          >
            {{ $t("按時間近到遠") }}
          </div>

          <div
            class="sort-btn"
            @click="setView('materialId')"
            :class="{ 'ligt-btn': currentViewMode == 'materialId' }"
          >
            {{ $t("按教材ID匯總") }}
          </div>
        </div>
      </div>
    </div>
    <div class="listcard-wrap" v-if="currentViewMode == 'time'">
      <Loader v-show="!showChangViewBtn" />
      <div
        v-show="showChangViewBtn"
        v-for="(item, index) in displayedReportedMsgs"
        :key="index + item.id + 'time'"
      >
        <div class="list-card" v-if="item?.detail">
          <div
            class="card-img"
            :class="{
              loaded: item.detail.imgBlobUrl,
              'image-noscroll': !item.detail.imgBlobUrl,
            }"
          >
            <img :src="getImgUrl(item.detail)" alt="" draggable="false" />
          </div>

          <div class="card-left">
            <!-- <p>檢視教材:{{ item.detail }}</p> -->
            <div class="card-detail">
              <p class="text-3xl font-bold">{{ item.detail.title }}</p>
              <p>{{ $t("教材Id") }}：{{ item.detail.id }}</p>
              <p>
                {{ getTime(item.detail.timeStamp) }}・{{ $t("上傳者") }}：
                {{ item.detail?.uploader?.name }}
              </p>
              <p>{{ $t("類型") }}：{{ item.detail.type }}</p>
              <p>{{ $t("簡介") }}：{{ item.detail.intro }}</p>
              <p>
                {{ $t("附件") }}：<a
                  class="link"
                  :href="getBlobUrl(item?.detail)"
                  >link</a
                >・{{ $t("點擊數") }}：{{ item.detail.hits }}
              </p>
              <p>
                {{ $t("年級") }}：{{ item.detail.grade }}・{{ $t("科目") }}：{{
                  item.detail.subject
                }}・{{ $t("授課語系") }}：{{ item.detail.language }}
              </p>
              <p>
                {{ $t("標籤") }}：<span
                  v-show="item.detail.category.length > 0"
                  >{{ item.detail.category }}</span
                >
              </p>
              <p>
                {{ $t("隱藏") }}：<span
                  class="font-bold text-sky-500"
                  :class="{
                    'text-sky-500': item.detail.hidden,
                    'text-red-500': !item.detail.hidden,
                  }"
                  >{{ item.detail.hidden }}</span
                >
              </p>
            </div>
          </div>
          <div class="card-right">
            <p>{{ $t("檢舉時間") }}：{{ getTime(item.createdTime) }}</p>
            <p>{{ $t("檢舉流水號") }}：{{ item.id }}</p>
            <p>
              {{ $t("檢舉者") }}：<span class="font-bold">{{
                `${item.applicant?.name}(${item.applicant?.id})`
              }}</span>
            </p>
            <p>
              {{ $t("檢舉代碼") }}：<span class="font-bold text-sky-500">{{
                cateReason[item.reasonCode]
              }}</span>
            </p>
            <p>
              {{ $t("檢舉理由") }}：<span class="font-bold">{{
                item.note
              }}</span>
            </p>
            <p>
              {{ $t("審批狀態") }}：<span class="font-bold">{{
                item.reviewed
              }}</span>
            </p>
            <p>
              {{ $t("審批時間") }}：<span class="font-bold">{{
                item.reviewedTime == 0 ? 0 : getTime(item.reviewedTime)
              }}</span>
            </p>
          </div>
          <div class="card-btngroup" v-show="!item.reviewed">
            <p class="btn-accept" @click="acceptReport(item, index)">
              {{ $t("接受") }}
            </p>
            <p class="btn-reject" @click="rejectReport(item, index)">
              {{ $t("不受理") }}
            </p>
            <!-- <p class="btn-edit">編輯</p> -->
          </div>
          <div class="card-btngroup" v-show="item.reviewed">
            <p class="btn-accept" @click="reEditReport(index, '', false)">
              {{ $t("編輯") }}
            </p>
          </div>
        </div>
      </div>
      <div v-show="showChangViewBtn">
        <Pagination
          ref="pagination"
          :materials="reportedMsgs"
          @displayedItems="setDisplayedItems"
        />
      </div>
      <div
        class="text-md ml-5 mt-5 font-bold"
        v-show="displayedReportedMsgs.length == 0 && showChangViewBtn"
      >
        {{ $t("暫無資料") }}
      </div>
    </div>
    <div v-if="currentViewMode == 'materialId'">
      <div
        v-for="(item, index) in displayedReportedMsgs"
        :key="index + item.materialId"
      >
        <div
          class="list-card list-card-mview"
          v-if="item?.detail"
          @click="setShowList(index, !item.showList)"
        >
          <div
            class="card-img"
            :class="{
              loaded: item.detail.imgBlobUrl,
              'image-noscroll': !item.detail.imgBlobUrl,
            }"
          >
            <img :src="getImgUrl(item.detail)" alt="" draggable="false" />
          </div>
          <div class="card-left">
            <!-- <p>檢視教材:{{ item.detail }}</p> -->
            <div class="card-detail">
              <p class="text-3xl font-bold">{{ item.detail.title }}</p>
              <p>{{ $t("教材Id") }}：{{ item.detail.id }}</p>
              <p>
                {{ getTime(item.detail.timeStamp) }}・{{ $t("上傳者") }}:
                {{ item.detail.uploader.name }}
              </p>
              <p>{{ $t("類型") }}：{{ item.detail.type }}</p>
              <p>{{ $t("簡介") }}：{{ item.detail.intro }}</p>
              <p>
                {{ $t("附件") }} ：<a
                  class="link"
                  :href="getBlobUrl(item.detail)"
                  >link</a
                >
              </p>
              <p>{{ $t("點擊數") }} ：{{ item.detail.hits }}</p>
            </div>
          </div>
          <div class="card-left">
            <!-- <p>檢視教材:{{ item.detail }}</p> -->
            <div class="card-detail">
              <p>
                {{ $t("年級") }}：{{ item.detail.grade }}・{{ $t("科目") }}：{{
                  item.detail.subject
                }}・{{ $t("授課語系") }}：{{ item.detail.language }}
              </p>
              <p>
                {{ $t("標籤") }}：<span
                  v-show="item.detail.category?.length > 0"
                  >{{ item.detail.category }}</span
                >
              </p>

              <p>
                {{ $t("隱藏") }}：<span
                  class="font-bold text-sky-500"
                  :class="{
                    'text-sky-500': item.detail.hidden,
                    'text-red-500': !item.detail.hidden,
                  }"
                  >{{ item.detail.hidden }}</span
                >
              </p>
            </div>
            <div class="allbtn-group">
              <p
                :class="!item.detail.hidden ? 'btn-accept' : 'btn-reject'"
                @click="setAll(index, !item.detail.hidden)"
              >
                {{ !item.detail.hidden ? $t("一鍵接受") : $t("一鍵不受理") }}
              </p>
            </div>
            <div class="extend-btn">
              <span class="extend-text"
                >{{ $t("檢舉") }}({{ item.totalreports?.length }})</span
              >
              <svg-icon
                :class="
                  !item.showList ? 'arrow-icon' : 'arrow-icon arrow-icon-up'
                "
                icon-class="arrow-down "
              />
            </div>
          </div>
        </div>
        <div
          class="listcard-bottom"
          v-show="item.showList"
          v-for="(reportedItem, subId) in item.totalreports"
          :key="reportedItem.id"
        >
          <div class="card-detail">
            <p>#{{ subId + 1 }}</p>
            <p>
              {{ $t("檢舉時間") }}：{{ getTime(reportedItem.createdTime) }}・{{
                $t("檢舉流水號")
              }}：{{ reportedItem.id }}
            </p>
            <p>
              {{ $t("檢舉者") }}：{{
                `${reportedItem.applicant.name}(${reportedItem.applicant.id})`
              }}
            </p>
            <p>
              {{ $t("檢舉代碼") }}：<span class="font-bold text-sky-500">{{
                cateReason[reportedItem.reasonCode]
              }}</span>
            </p>
            <p>{{ $t("檢舉理由") }}：{{ reportedItem.note }}</p>
            <p>{{ $t("審批狀態") }}：{{ reportedItem.reviewed }}</p>
            <p>
              {{ $t("審批時間") }}：
              {{
                reportedItem.reviewedTime == 0
                  ? 0
                  : getTime(reportedItem.reviewedTime)
              }}
            </p>
          </div>
          <div class="card-btngroup" v-show="!reportedItem.reviewed">
            <p
              class="btn-accept"
              @click="acceptReport(reportedItem, index, subId)"
            >
              {{ $t("接受") }}
            </p>
            <p
              class="btn-reject"
              @click="rejectReport(reportedItem, index, subId)"
            >
              {{ $t("不受理") }}
            </p>
            <!-- <p class="btn-edit">編輯</p> -->
          </div>
          <div class="card-btngroup" v-show="reportedItem.reviewed">
            <p class="btn-accept" @click="reEditReport(index, subId, false)">
              {{ $t("編輯") }}
            </p>
          </div>
        </div>
        <br /><br />
      </div>
      <Pagination
        ref="pagination"
        :materials="reportedMsgsByMId"
        @displayedItems="setDisplayedItems"
      />
    </div>
  </div>
</template>

<script>
import { jsFn } from "@/utils/jsFn";
import { useMainStore } from "../stores/mainStore";
import profile from "../api/profile";
import api from "@/api";
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/Pagination.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import { watch } from "vue";
import SvgIcon from "../components/SvgIcon.vue";
import Loader from "../components/Loader.vue";
export default {
  name: "reported-view",
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      reportedMsgs: [],
      orinData: [],
      displayedReportedMsgs: [],
      blobsas: "",
      currentViewMode: "time",
      reportedMsgsByMId: [], //按MaterialId排
      orinDataByMId: [],
      cateStatus: [this.$t("未處理"), this.$t("已處理"), this.$t("全部")],
      hideStatus: [this.$t("未隱藏"), this.$t("已隱藏"), this.$t("全部")],
      showChangViewBtn: false,
      cateReason: [
        this.$t("reportmsg-form.錯誤或無法使用的檔案格式"),
        this.$t("reportmsg-form.有害或誤導性的內容"),
        this.$t("reportmsg-form.侵權內容"),
        this.$t("reportmsg-form.其他原因"),
      ],
    };
  },
  mounted() {
    this.store.setCurrentPage("reported");
    jsFn
      .checkSessionAccessTokenReady()
      .then(async () => {
        this.blobsas =
          this.store.blobsas !== ""
            ? this.store.blobsas
            : await api.main.getSas();
        this.reportedMsgs = await this.getAllReportMsgs(this.currentViewMode);
        for (let i = 0; i < this.reportedMsgs.length; i++) {
          this.reportedMsgs[i].detail = await this.getMaterialDetail(
            this.reportedMsgs[i]
          );
        }
        this.orinData = this.reportedMsgs;
        this.updateFilterReportList();
        this.showChangViewBtn = true;
      })
      .catch((err) => {
        console.log(err); //未取到access_token
      });

    watch(
      () => this.$refs.selectStatus.currentSelect,
      () => {
        this.updateFilterReportList();
      }
    );
    watch(
      () => this.$refs.hideStatus.currentSelect,
      () => {
        this.updateFilterReportList();
      }
    );

    if (this.$refs.pagination) this.$refs.pagination.currentPage = 1;
  },
  components: {
    SearchBar,
    Pagination,
    SelectMenu,
    SvgIcon,
    Loader,
  },
  methods: {
    updateFilterReportList() {
      let status;
      if (this.$refs.selectStatus && this.currentViewMode == "time") {
        status = this.$refs.selectStatus.currentSelect;
        if (this.$refs.pagination) this.$refs.pagination.currentPage = 1;
        if (status == this.$t("未處理")) {
          this.reportedMsgs = this.orinData.filter(
            (item) => item.reviewed == false
          );
        } else if (status == this.$t("已處理")) {
          this.reportedMsgs = this.orinData.filter(
            (item) => item.reviewed == true
          );
        } else {
          this.reportedMsgs = this.orinData;
        }
      } else if (
        this.$refs.hideStatus &&
        this.currentViewMode == "materialId"
      ) {
        status = this.$refs.hideStatus.currentSelect;
        if (this.$refs.pagination) this.$refs.pagination.currentPage = 1;
        console.log(this.orinDataByMId);
        if (status == this.$t("未隱藏")) {
          this.reportedMsgsByMId = this.orinDataByMId.filter(
            (item) => item.detail.hidden == false
          );
        } else if (status == this.$t("已隱藏")) {
          this.reportedMsgsByMId = this.orinDataByMId.filter(
            (item) => item.detail.hidden == true
          );
        } else {
          this.reportedMsgsByMId = this.orinDataByMId;
        }
      }
    },
    searchReport(data) {
      //   console.log(data);
      //   console.log(this.reportedMsgs);
      if (this.$refs.pagination) this.$refs.pagination.currentPage = 1;
      if (this.currentViewMode == "time") {
        if (data == "") {
          this.reportedMsgs = this.orinData;
        } else {
          this.reportedMsgs = this.reportedMsgs.filter(
            (item) =>
              item.detail.title.includes(data) ||
              item.detail.uploader.name.includes(data) ||
              item.applicant.name.includes(data)
          );
        }
      } else {
        if (data == "") {
          this.reportedMsgsByMId = this.orinDataByMId;
        } else {
          console.log(this.reportedMsgsByMId);
          this.reportedMsgsByMId = this.reportedMsgsByMId.filter(
            (item) =>
              item.detail.title.includes(data) ||
              item.detail.uploader.name.includes(data)
          );
        }
      }
    },
    setDisplayedItems(data) {
      this.displayedReportedMsgs = data;
    },
    reEditReport(index, subId, status) {
      //   console.log(index, this.$refs.pagination.currentPage);
      let finalIndex = this.$refs.pagination
        ? (this.$refs.pagination.currentPage - 1) * 10 + index
        : index;

      this.reportedMsgs = JSON.parse(JSON.stringify(this.reportedMsgs));
      if (this.currentViewMode == "time") {
        this.reportedMsgs[finalIndex].reviewed = status;
      } else {
        this.reportedMsgsByMId[finalIndex].totalreports[subId].reviewed =
          status;
      }
    },
    setAll(index, status) {
      let localDate = new Date();
      let finalIndex = this.$refs.pagination
        ? (this.$refs.pagination.currentPage - 1) * 10 + index
        : index;
      let timeStamp = Math.floor(localDate.getTime() / 1000);

      //如果隱藏狀態改變要發通知
      if (!this.reportedMsgsByMId[finalIndex].detail.hidden) {
        api.main
          .createMessage({
            title: "教材接獲檢舉隱藏通知",
            text: `被隱藏教材名稱:${this.reportedMsgsByMId[finalIndex].detail.title}`,
            importance: "important",
            fromUser: {
              id: "Admin",
              name: "Admin",
            },
            toUserId: this.reportedMsgsByMId[finalIndex].detail.uploader.id,
            msgImg:"",
          })
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }

      this.reportedMsgsByMId[finalIndex].detail.hidden = status;
      this.reportedMsgsByMId[finalIndex].totalreports.forEach(
        (item) => (item.reviewed = true)
      );
      this.reportedMsgsByMId[finalIndex].totalreports.forEach(
        (item) => (item.reviewedTime = timeStamp)
      );

      api.main
        .updateMaterialById({
          id: this.reportedMsgsByMId[finalIndex].materialId,
          type: this.reportedMsgsByMId[finalIndex].materialType,
          hidden: status,
        })
        .then(() => {
          for (
            let i = 0;
            i < this.reportedMsgsByMId[finalIndex].totalreports.length;
            i++
          ) {
            let item = this.reportedMsgsByMId[finalIndex].totalreports[i];
            api.main.updateReportMsgById({
              id: item.id,
              reviewed: true,
              reviewedTime: timeStamp,
            });
          }
        })
        .then(async () => {
          this.reportedMsgsByMId[finalIndex].showList = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    acceptReport(item, index, subId) {
      //   console.log(item);
      //   console.log(this.reportedMsgs[index]);
      let localDate = new Date();
      let timeStamp = Math.floor(localDate.getTime() / 1000);

      let finalIndex = this.$refs.pagination
        ? (this.$refs.pagination.currentPage - 1) * 10 + index
        : index;

      this.reportedMsgs = JSON.parse(JSON.stringify(this.reportedMsgs));

      //前端不刷新先改變狀態
      if (this.currentViewMode == "time") {
        //如果隱藏狀態改變要發通知
        if (!this.reportedMsgs[finalIndex].detail.hidden) {
          api.main
            .createMessage({
              title: "教材接獲檢舉隱藏通知",
              text: `被隱藏教材名稱:${this.reportedMsgs[finalIndex].detail.title}`,
              importance: "important",
              fromUser: {
                id: "Admin",
                name: "Admin",
              },
              toUserId: this.reportedMsgs[finalIndex].detail.uploader.id,
              msgImg:"",
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        }

        this.reportedMsgs[finalIndex].detail.hidden = true;
        this.reportedMsgs[finalIndex].reviewed = true;
        this.reportedMsgs[finalIndex].reviewedTime = timeStamp;
      } else {
        //如果隱藏狀態改變要發通知
        if (!this.reportedMsgsByMId[finalIndex].detail.hidden) {
          api.main
            .createMessage({
              title: "教材接獲檢舉隱藏通知",
              text: `被隱藏教材名稱:${this.reportedMsgsByMId[finalIndex].detail.title}`,
              importance: "important",
              fromUser: {
                id: "Admin",
                name: "Admin",
              },
              toUserId: this.reportedMsgsByMId[finalIndex].detail.uploader.id,
              msgImg:"",
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        }

        this.reportedMsgsByMId[finalIndex].detail.hidden = true;
        this.reportedMsgsByMId[finalIndex].totalreports[subId].reviewed = true;
        this.reportedMsgsByMId[finalIndex].totalreports[subId].reviewedTime =
          timeStamp;
      }
      api.main
        .updateMaterialById({
          id: item.materialId,
          type: item.materialType,
          hidden: true,
        })
        .then(
          api.main.updateReportMsgById({
            id: item.id,
            reviewed: true,
            reviewedTime: timeStamp,
          })
        )
        .then(async () => {})
        .catch((err) => {
          console.log(err);
        });
    },
    rejectReport(item, index, subId) {
      let localDate = new Date();
      let timeStamp = Math.floor(localDate.getTime() / 1000);

      let finalIndex = this.$refs.pagination
        ? (this.$refs.pagination.currentPage - 1) * 10 + index
        : index;

      this.reportedMsgs = JSON.parse(JSON.stringify(this.reportedMsgs));
      //前端不刷新先改變狀態
      if (this.currentViewMode == "time") {
        this.reportedMsgs[finalIndex].detail.hidden = false;
        this.reportedMsgs[finalIndex].reviewed = true;
        this.reportedMsgs[finalIndex].reviewedTime = timeStamp;
      } else {
        this.reportedMsgsByMId[finalIndex].detail.hidden = false;
        this.reportedMsgsByMId[finalIndex].totalreports[subId].reviewed = true;
        this.reportedMsgsByMId[finalIndex].totalreports[subId].reviewedTime =
          timeStamp;
      }
      api.main
        .updateMaterialById({
          id: item.materialId,
          type: item.materialType,
          hidden: false,
        })
        .then(
          api.main.updateReportMsgById({
            id: item.id,
            reviewed: true,
            reviewedTime: timeStamp,
          })
        )
        .then(async () => {})
        .catch((err) => {
          console.log(err);
        });
    },
    getTime(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString();
    },
    getBlobUrl(materials) {
      if (materials.blobUrl.includes(profile.blobsasurl)) {
        return materials.blobUrl + "?" + this.blobsas;
      } else {
        const finalUrl =
          profile.blobsasurl +
          materials.uploader.id +
          "/" +
          materials.id +
          "/" +
          materials.blobUrl +
          "?" +
          this.blobsas;
        return finalUrl;
      }
    },
    checkBackgroundImageLoaded(imageUrl) {
      if (imageUrl) {
        const tempImg = new Image();
        tempImg.src = imageUrl;
        tempImg.onload = () => {
          // console.log('背景图像已加载完成。');
        };
      }
    },
    getImgUrl(materials) {
      try {
        if (materials.imgBlobUrl && this.blobsas) {
          if (materials.imgBlobUrl.includes(profile.blobsasurl)) {
            this.checkBackgroundImageLoaded(
              `${materials?.imgBlobUrl + "?" + this.blobsas}`
            );
            return materials.imgBlobUrl + "?" + this.blobsas;
          } else {
            const finalUrl =
              profile.blobsasurl +
              materials.uploader.id +
              "/" +
              materials.id +
              "/" +
              materials.imgBlobUrl +
              "?" +
              this.blobsas;
            this.checkBackgroundImageLoaded(`${finalUrl}`);
            return finalUrl;
          }
        } else if (!materials?.imgBlobUrl) {
          return "https://" + location.host + "/no-image.jpg";
        }
      } catch (err) {
        console.log(err);
      }
    },
    getMaterialDetail(item) {
      return new Promise((r, j) => {
        api.main
          .getMaterialById({
            id: item.materialId.toString(),
            type: item.materialType.toString(),
          })
          .then((res) => {
            r(res.data);
          })
          .catch((err) => {
            j(err);
          });
      });
    },
    getAllReportMsgs(orderBy) {
      return new Promise((r, j) => {
        api.main
          .getAllReportMsgs({ orderBy: orderBy }) //time or materialId
          .then((res) => {
            r(res.data);
          })
          .catch((err) => {
            console.log(err);
            j();
          });
      });
    },
    setShowList(index, status) {
      let finalIndex = this.$refs.pagination
        ? (this.$refs.pagination.currentPage - 1) * 10 + index
        : index;
      this.reportedMsgsByMId[finalIndex].showList = status;
    },
    async setView(mode) {
      console.log(mode);
      this.currentViewMode = mode;
      this.showChangViewBtn = false;
      if (this.currentViewMode == "materialId") {
        this.$refs.selectStatus.currentSelect = this.$t("未處理");
        this.reportedMsgs = this.orinData;
        // if (this.reportedMsgsByMId.length == 0) {
        api.main.getAllReportMsgsMaterialId().then((res) => {
          console.log(res);
          //自行整理
          this.reportedMsgsByMId = res.data;
          this.reportedMsgs.forEach((item) => {
            let index = this.reportedMsgsByMId.findIndex(
              (mItem) => mItem.materialId == item.materialId
            );
            // console.log(item);
            // console.log(index);
            if (!this.reportedMsgsByMId[index].totalreports) {
              this.reportedMsgsByMId[index].totalreports = [];
            }
            this.reportedMsgsByMId[index].showList = false;
            this.reportedMsgsByMId[index].detail = item.detail;
            this.reportedMsgsByMId[index].totalreports.push({
              id: item.id,
              materialType: item.materialType,
              applicant: item.applicant,
              createdTime: item.createdTime,
              materialId: item.materialId,
              note: item.note,
              reasonCode: item.reasonCode,
              reviewedTime: item.reviewedTime,
              reviewed: item.reviewed,
            });
          });
          console.log(this.reportedMsgsByMId);
          this.orinDataByMId = JSON.parse(
            JSON.stringify(this.reportedMsgsByMId)
          );
          this.$refs.hideStatus.currentSelect = this.$t("未隱藏");
          this.updateFilterReportList();
          this.showChangViewBtn = true;
        });

        // }
      } else {
        this.reportedMsgs = await this.getAllReportMsgs(this.currentViewMode);
        for (let i = 0; i < this.reportedMsgs.length; i++) {
          this.reportedMsgs[i].detail = await this.getMaterialDetail(
            this.reportedMsgs[i]
          );
        }
        this.orinData = this.reportedMsgs;
        this.showChangViewBtn = true;
        this.updateFilterReportList();
      }
    },
  },
};
</script>

<style lang="less">
.reported-view {
  @apply p-10;
  @media (min-width: theme("screens.lg")) {
    .flex-wrap {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(128, 128, 128, 0.2);
      margin-bottom: 20px;
      .search-wrap {
        @apply mb-5;
        flex: 10;
        width: 100%;
      }
      .select-wrap {
        flex: 2;
        text-align: right;
        margin-top: 15px;
      }
      .sort-block {
        flex: 5;
        margin-top: 20px;
      }
    }
  }
  .listcard-wrap {
    @apply grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-3;
  }
  .list-card-mview {
    @apply border-b border-gray-300 cursor-pointer;
    position: relative;
    .card-left {
      border-right: unset !important;
    }
  }
  .list-card {
    @apply bg-white p-4;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .card-img {
      flex: 1;
      padding-right: 10px;
    }
    .card-left {
      flex: 2;
      padding-right: 10px;
      word-break: break-word;
      p {
        @apply my-2;
      }
      @apply border-r border-gray-300 text-sm;
      .link {
        @apply text-sky-500;
        text-decoration: underline;
      }
      .allbtn-group {
        position: absolute;
        right: 30px;
        top: 20px;
        .btn-accept,
        .btn-reject {
          @apply text-sky-500 hover:text-sky-700;
          cursor: pointer;
          display: inline-block;
          margin-right: 10px;
        }
        .btn-reject {
          @apply text-gray-500 hover:text-gray-700;
        }
      }
      .extend-btn {
        position: absolute;

        bottom: 10px;
        right: 30px;
        cursor: pointer;
        &:hover {
          @apply text-sky-500;
        }
        .extend-text {
          display: inline-block;
          margin-right: 5px;
          @apply text-sky-500;
        }
        .arrow-icon {
          display: inline-block;
        }
        .arrow-icon-up {
          display: inline-block;
          top: 14px !important;
          transform: scaleY(-1) !important;
          @apply text-sky-500;
        }
      }
    }
    .card-right {
      flex: 3;
      word-break: break-word;
      padding-left: 10px;
      p {
        @apply m-2 text-sm;
      }
    }
  }
  .listcard-bottom {
    @apply bg-white flex p-5  border-b border-gray-300;
    .card-detail {
      flex: 2;
      padding-left: 10px;
      p {
        @apply m-2 text-sm;
      }
    }
  }
  .card-btngroup {
    flex: 1;
    text-align: right;
    .btn-accept,
    .btn-reject {
      @apply text-sky-500 hover:text-sky-700;
      cursor: pointer;
      display: inline-block;
      margin-right: 10px;
    }
    .btn-reject {
      @apply text-gray-500 hover:text-gray-700;
    }
  }
}
</style>