export default {
  比例: "Scale",
  //AddImageBox
  檔案圖片: "Local Image",
  選取圖片作為物件: "Select image as an object",
  背景設置: "Background",
  選取圖片作為背景: "Select image as background",
  //AddTextBox & TextSetting
  文字工具: "Text Note Tool",
  編輯文字便利貼: "Edit",
  openURL_NewTab: "View link in new tab",
  刪除文字便利貼: "Delete",
  輸入文字: "Type text here",
  字體大小: "Font Size",
  字體顏色: "Font Color",
  顏色: "Color",
  便利貼顏色: "Sticky Note Color",
  確定: "OK",
  取消: "Cancel",
  //PenSetting
  畫筆: "Brush",
  通用畫筆: "Brush",
  高光筆: "Highlighter",
  鐳射筆: "Laser Pen",
  大小: "Size",
  //ShapeSetting
  圖形: "Shape",
  邊框: "Border",
  填充: "Fill",
  箭頭: "Arrow",
  直線: "Line",
  圓形: "Circle",
  橢圓: "Oval",
  矩形: "Rectangle",
  三角形: "Triangle",
  星形: "Star",
  所選圖片過大: "The selected image is too large (should be less than 3MB), please select again!",
  打開點擊的物件連結: "Open clicked object link?",
  多連結詢問: "The clicked object has multiple valid links, please select an action",
  目前沒有動作可復原: "No more undo",
  復原我最近一次刪除動作: "Revert my last deletion!",
  ModifyHint: "The content has been modified and will not be saved when you leave. Do you still want to leave?",
  物件化畫板: "Object Drawing Mode",
  重新整理圖層: "Refresh Layers!",
  刪除鎖定物件提示字: "You deleted the objects locked by the teacher. You can click Undo or reopen this material!",
  協作無法刪除鎖定提示字: "For collaboration, you cannot delete objects locked by teachers!",
  複製: "Duplicate",
  刪除: "Delete",
  移到最上層: "Move to the top",
  移到最下層: "Move to the bottom",
};
