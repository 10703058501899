<template>
  <div class="relative mt-2" ref="menu">
    <button
      type="button"
      class="select-btn"
      @click="showDropDown = !showDropDown"
    >
      <span
        class="ml-3 block truncate font-bold"
        v-if="currentSelect.name"
        >{{ currentSelect.name }}</span
      >
      <span class="ml-3 block truncate font-bold" v-else>{{
        currentSelect
      }}</span>
      <svg-icon
        class="arrow-icon"
        icon-class="arrow-down "
        v-show="!showDropDown"
      />
      <svg-icon
        class="arrow-icon arrow-icon-up text-purple-600"
        icon-class="arrow-down"
        v-show="showDropDown"
      />
    </button>

    <ul v-show="showDropDown" class="dropdown-wrap">
      <li
        v-for="(item, index) in category"
        :key="index + item"
        class="dropdown-item"
        id="listbox-option-0"
        @click="setCurrentSelect(item)"
      >
        <div class="flex items-center">
          <span class="ml-3 block truncate font-normal" v-if="item.name">{{
            item.name
          }}</span>
          <span class="ml-3 block truncate font-normal" v-else>{{ item }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { useMainStore } from "../stores/mainStore";
import SvgIcon from "./SvgIcon.vue";
export default {
  components: { SvgIcon },
  name: "select-menu",
  props: ["category", "defaultSelect"],
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      showDropDown: false,
      currentSelect: "",
    };
  },
  methods: {
    setCurrentSelect(item) {
      console.log(item);
      this.currentSelect = item;
      this.showDropDown = false;
    },
    closeMenu(event) {
      // 檢查點擊事件是否在選單內部
      if (!this.$refs.menu.contains(event.target)) {
        this.showDropDown = false;
      }
    },
  },
  mounted() {
    this.currentSelect = this.defaultSelect;
    document.addEventListener("click", this.closeMenu);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeMenu);
  },
};
</script>

<style lang="less">
.select-btn {
  @apply bg-white !important;
  @apply relative w-full cursor-pointer rounded-md  py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6;
  .arrow-icon {
    position: absolute;
    right: 12px;
    top: 14px;
  }
  .arrow-icon-up {
    top: 12px !important;
    transition: 1s !important;
    transform: scaleY(-1) !important;
  }
}
.dropdown-wrap {
  @apply absolute  mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
  z-index: 999;
  .dropdown-item {
    &:hover {
      @apply bg-slate-200 !important;
    }
    @apply relative cursor-pointer  select-none py-2 pl-3 pr-9 text-gray-900;
  }
}
</style>
