<template>
  <div class="addTextBox">
    <div class="textinput-card-md">
      <p class="textinput-title">{{ $t('konvaCanvas["文字工具"]') }}</p>
      <div class="textinput-scrollarea">
        <textarea class="textinput large-input" v-model="currentAddTextValue" 
          :placeholder="$t('konvaCanvas.輸入文字')"></textarea>
        <div class="divide-wrap">
          <div class="divide-line" />
        </div>
        <div class="slider_box">
          <p class="menu-title">
            {{ $t('konvaCanvas["字體大小"]') }}：<b class="textsize">{{ textSize }}</b>
          </p>
          <Slider v-model="textSize" :step="1" @on-change="size" :min="12" :max="72"></Slider>
        </div>

        <div class="ColorPicker_box ">
          <p class="menu-title" style="padding-left:5px">{{ $t('konvaCanvas["字體顏色"]') }}</p>

          <div class="q-gutter-sm" style="padding:10px 0 2px 0;">
            <div class="color-btn" :class="{ 'select-color': textColor == item.val }"
              :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id"
              v-show="index < 5" @click="chioceColor(item)" />
          </div>
          <div class="q-gutter-sm" style="padding:2px 0;">
            <div class="color-btn" :class="{ 'select-color': textColor == item.val }"
              :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id"
              v-show="index >= 5" @click="chioceColor(item)" />
          </div>
          <!--<q-color v-model="hex" no-header no-footer default-view="palette" class="my-picker" @change="ss" @input="cc" :palette="['#2d8cf0', '#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
        </div>
        <div class="stickerbg-setting">
          <p class="menu-title">{{ $t("konvaCanvas['便利貼顏色']") }}</p>
          <div class="q-gutter-sm sticker-btns">
            <div class="color-btn" :class="{ 'select-color': currentStickerColor == item }"
              :style="{ 'background-color': item }" v-show="index < 4" v-for="(item, index) in stickerColorArr"
              :key="index + 'sticker'" @click="setStickerColor(item)" />
            <div class="transparent-btn" @click="setStickerColor('transparent')"><svg-icon icon-class="no-color"
                class="nocolor-icon" :class="{ 'set-nocolor': currentStickerColor == 'transparent' }" /></div>
          </div>
        </div>
      </div>
      <div class="textinputbtn-group">
        <div class="textinput-btn" @click="addTextBox()">{{ $t('konvaCanvas["確定"]') }}</div>
        <div class="textinput-btn" @click="closeTextBox()">{{ $t('konvaCanvas["取消"]') }}</div>
      </div>
    </div>

    <div class="textinput-card-lg">
      <p class="textinput-title">{{ $t('konvaCanvas["文字工具"]') }}</p>
      <div class="textinput-scrollarea">
        <div class="flex-row">
          <div class="left-part">
            <textarea class="textinput large-input" v-model="currentAddTextValue"
              :placeholder="$t('konvaCanvas.輸入文字')"></textarea>
          </div>
          <div class="right-part">
            <div class="right-scroll-area">
            <div class="slider_box">
              <p class="menu-title">
                {{ $t('konvaCanvas["字體大小"]') }}：<b class="textsize">{{ textSize }}</b>
              </p>
              <Slider v-model="textSize" :step="1" @on-change="size" :min="12" :max="72"></Slider>
            </div>

            <div class="ColorPicker_box ">
              <p class="menu-title" style="padding-left:5px">{{ $t('konvaCanvas["字體顏色"]') }}</p>

              <div class="q-gutter-sm" style="padding:10px 0 2px 0;">
                <div class="color-btn" :class="{ 'select-color': textColor == item.val }"
                  :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id"
                  v-show="index < 5" @click="chioceColor(item)" ><svg-icon class='color-check' v-show="textColor == item.val" icon-class="check" /></div>
              </div>
              <div class="q-gutter-sm" style="padding:2px 0;">
                <div class="color-btn" :class="{ 'select-color': textColor == item.val }"
                  :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id"
                  v-show="index >= 5" @click="chioceColor(item)" ><svg-icon class='color-check white-check' v-show="textColor == item.val" icon-class="check" /></div>
              </div>
              <!--<q-color v-model="hex" no-header no-footer default-view="palette" class="my-picker" @change="ss" @input="cc" :palette="['#2d8cf0', '#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
            </div>
            <div class="stickerbg-setting">
              <p class="menu-title">{{ $t("konvaCanvas['便利貼顏色']") }}</p>
             
              <div class="q-gutter-sm sticker-btns">
                <div class="color-btn" :class="{ 'select-color': currentStickerColor == item }"
                  :style="{ 'background-color': item }" v-show="index < 4" v-for="(item, index) in stickerColorArr"
                  :key="index + 'sticker'" @click="setStickerColor(item)" ><svg-icon class='color-check' v-show="currentStickerColor == item" icon-class="check" /></div>
                <div class="transparent-btn" @click="setStickerColor('transparent')"><svg-icon icon-class="no-color"
                    class="nocolor-icon" :class="{ 'set-nocolor': currentStickerColor == 'transparent' }" /></div>
              </div>
            </div>
          </div>
            <div class="textinputbtn-group">
              <div class="textinput-btn" @click="addTextBox()">{{ $t('konvaCanvas["確定"]') }}</div>
              <div class="textinput-btn" @click="closeTextBox()">{{ $t('konvaCanvas["取消"]') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Konva from 'konva'
import SvgIcon from "../SvgIcon.vue";
export default {
  name: 'AddTextBox',
  components: {SvgIcon },
  data() {
    return {
      currentAddTextValue: '',
      button4: 'brush',
      hex: '#FF00FF',
      standard: 2,
      textSize: 20,
      textColor: 'red',

      colorArr: [
        { val: 'red', color: 'red' },
        { val: 'orange', color: 'orange' },
        { val: 'yellow', color: 'yellow' },
        { val: 'cyan', color: 'cyan' },
        { val: '#e0e0e0', color: '#e0e0e0' },
        { val: 'brown', color: 'brown' },
        { val: 'purple', color: 'purple' },
        { val: 'green', color: 'green' },
        { val: '#0000ff', color: '#0000ff' },
        { val: 'black', color: 'black' }
      ],
      currentStickerColor: 'rgb(255, 238, 139)', //目前文字工具的便利貼顏色
      stickerColorArr: ['rgb(255, 238, 139)', 'rgb(211, 255, 139)', 'rgb(139, 243, 255)', 'rgb(255, 191, 215)', 'transparent']
    }
  },
  methods: {
    genUUID() {
            let d = Date.now()
            //Performance.now() 亞毫秒級的時間戳記
            if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
                d += performance.now()
            }
            return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                //xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
                let r = (d + Math.random() * 16) % 16 | 0
                d = Math.floor(d / 16)
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
            })
    },
    closeTextBox() {
      this.$parent.showAddTextBox = false
      this.$parent.showObjMenu= false
    },
    size: function (e) {
      this.textSize = e
    },
    chioceColor: function (item) {
      this.textColor = item.val
    },
    setStickerColor(item) {
      this.currentStickerColor = item
    },
    getViewCenter() {
      // 获取舞台的宽度和高度
      let stage = this.$parent.stage
      let stageWidth = stage.width();
      let stageHeight = stage.height();

      // 获取舞台的偏移量
      let offsetX = stage.x();
      let offsetY = stage.y();
      let scale=stage.scaleX()

      // 计算舞台的中心点坐标
      let centerX = (stageWidth / 2 - offsetX)/scale;
      let centerY = (stageHeight / 2 - offsetY)/scale;
      console.log( { x: centerX, y: centerY })
      return { x: centerX, y: centerY };
    },
    addSticker(mode) {
      //新增便利貼物件
      let x, y
      let e = {
        type: "",
        x: x,
        y: y
      }
      let textNode = this.$parent.currentEditTextNode
      let layer = this.$parent.layer
      let stage = this.$parent.stage
      let currentIndex = layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length - 1 : 1
      //放大後視圖的中心點座標
      x = mode == 'new' ? this.getViewCenter().x- Math.floor(Math.random() * 100)  : textNode.attrs.x
      y = mode == 'new' ? this.getViewCenter().y- Math.floor(Math.random() * 100)  : textNode.attrs.y

      let uuid = this.genUUID()
      

      let pastText = new Konva.Label({
        uuid: uuid,
        x: x,
        y: y,
        name: 'pastText',
        draggable: true,
        listening: true,
        text: this.currentAddTextValue,
        index: currentIndex
      })
      pastText.add(
        new Konva.Tag({
          uuid: uuid,
          fill: this.currentStickerColor,
          shadowColor: 'black',
          shadowBlur: 10,
          shadowOffsetX: 10,
          shadowOffsetY: 10,
          shadowOpacity: 0.2,
          name: 'pastTextContent',
          draggable: false,
          listening: true,
          index: currentIndex
        })
      )
      pastText.add(
        new Konva.Text({
          uuid: uuid,
          text: this.currentAddTextValue,
          fontSize: this.textSize,
          fill: this.textColor,
          padding: 20,
          lineHeight: 1,
          width: 200,
          height: 200, //height 設為'auto' 可符合文字大小，但拉伸時抓不到實際高度
          name: 'pastTextContent',
          draggable: false,
          listening: true,
          index: currentIndex
        })
      )

      let tr = new Konva.Transformer({
        anchorStroke: '#00a6ff',
        anchorFill: '#fff',
        anchorSize: 12,
        anchorCornerRadius: 5,
        anchorStrokeWidth: 2,
        borderStroke: '#6ac9fc',
        borderStrokeWidth: 2,
        borderDash: [3, 3],
        padding: 10,
        name: 'default'
      })
      layer.add(pastText)
      this.$parent.moveHtexToTop()
      //儲存undo歷史
      this.$parent.undoHistory.push({ action: 'delete', item: JSON.parse(JSON.stringify(pastText)) })
      //新增前一動應提供該物件的初始化Attr
      this.$parent.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(pastText)) })
      this.$parent.isModified = true
      this.$parent.isSendByMe = true

      // this.$parent.yarrayAdd(pastText)

      if (mode == 'edit') {
        //殺掉原本的文字物件
        textNode.destroy()
        //殺掉原本的Transformer
        stage.find('Transformer').forEach(function (ele) {
          ele.destroy()
        })
      }
      layer.add(tr)
      tr.nodes([pastText])
      this.$parent.addMenuBtnToTr(tr, pastText)
    },
    addPureText(mode) {
      //新增便利貼物件
      let x, y
      let e = {
        type: "",
        x: x,
        y: y
      }
      let pastText = this.$parent.currentEditTextNode
      let layer = this.$parent.layer
      let stage = this.$parent.stage
      let currentIndex = layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length - 1 : 1
      // console.log(currentIndex,'currentIndex文字')
      x = mode == 'new' ? this.getViewCenter().x- Math.floor(Math.random() * 100) : pastText.attrs.x
      y = mode == 'new' ? this.getViewCenter().y- Math.floor(Math.random() * 100) : pastText.attrs.y

      //新增純文字物件
      let textNode = new Konva.Text({
        uuid: this.genUUID(),
        text: this.currentAddTextValue,
        x: x,
        y: y,
        fontSize: this.textSize,
        fill: this.textColor,
        draggable: true,
        width: 300,
        height: 'auto',
        name: 'text',
        index: currentIndex
      })

      let tr = new Konva.Transformer({
        anchorStroke: '#00a6ff',
        anchorFill: '#fff',
        anchorSize: 12,
        anchorCornerRadius: 5,
        anchorStrokeWidth: 2,
        borderStroke: '#6ac9fc',
        borderStrokeWidth: 2,
        borderDash: [3, 3],
        padding: 10,
        name: 'default'
      })
      layer.add(textNode)
      this.$parent.moveHtexToTop()
      //儲存undo歷史
      this.$parent.undoHistory.push({ action: 'delete', item: JSON.parse(JSON.stringify(textNode)) })
      //新增前一動應提供該物件的初始化Attr
      this.$parent.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(textNode)) })
      this.$parent.isModified = true
      this.$parent.isSendByMe = true

      // this.$parent.yarrayAdd(textNode)
      if (mode == 'edit') {
        pastText.destroy()
        //殺掉原本的Transformer
        stage.find('Transformer').forEach(function (ele) {
          ele.destroy()
        })
   
      }
      layer.add(tr)
      tr.nodes([textNode])
      this.$parent.addMenuBtnToTr(tr, textNode)
    },
    bigramSimilarity(first, second) {
            first = first.replace(/\s+/g, '')
            second = second.replace(/\s+/g, '')

            if (first === second) return 1; // identical or empty
            if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

            let firstBigrams = new Map();
            for (let i = 0; i < first.length - 1; i++) {
                const bigram = first.substring(i, i + 2);
                const count = firstBigrams.has(bigram)
                    ? firstBigrams.get(bigram) + 1
                    : 1;

                firstBigrams.set(bigram, count);
            };

            let intersectionSize = 0;
            for (let i = 0; i < second.length - 1; i++) {
                const bigram = second.substring(i, i + 2);
                const count = firstBigrams.has(bigram)
                    ? firstBigrams.get(bigram)
                    : 0;

                if (count > 0) {
                    firstBigrams.set(bigram, count - 1);
                    intersectionSize++;
                }
            }

            return (2.0 * intersectionSize) / (first.length + second.length - 2);
        },
    addTextBox() {
      let layer = this.$parent.layer
      let e = {
        type: "",
        x: "",
        y: ""
      }
      this.closeTextBox()
      this.$parent.isEditingTextNow = false
      this.$parent.isSendByMe = true
      document.body.classList.remove('cursor-fdj')

      if (this.$parent.currentEditTextNode == '') {
        this.currentStickerColor != 'transparent' ? this.addSticker('new') : this.addPureText('new')
      } else {
        //編輯物件
        if (this.$parent.currentEditTextNode.className == 'Text') {
          if (this.currentStickerColor == 'transparent') {
            let textNode = this.$parent.currentEditTextNode
            let preText=this.$parent.currentEditTextNode?.attrs?.text
            let currentText=this.currentAddTextValue

            textNode.setAttrs({ text: this.currentAddTextValue, fontSize: this.textSize, fill: this.textColor })
           
          } else this.addSticker('edit') //文字轉便利貼
        } else {
          if (this.currentStickerColor == 'transparent') this.addPureText('edit')
          //便利貼轉文字
          else {
            let pastText = this.$parent.currentEditTextNode
            let preText=this.$parent.currentEditTextNode.children[1].attrs.text
            let currentText=this.currentAddTextValue
            //Tag
            pastText.children[0].setAttrs({ fill: this.currentStickerColor })
            //Text
            pastText.children[1].setAttrs({ text: this.currentAddTextValue, fontSize: this.textSize, fill: this.textColor })
          }
        }
      }
      layer.draw()
      this.currentAddTextValue = ''
      this.$parent.currentEditTextNode = ''
      this.$parent.select()
      this.$parent.showObjMenu=false
    }
  }
}
</script>

<style lang="less">
@import '../../assets/color.less';



.ivu-transfer-no-max-height {
  transform-origin: center top;
  position: absolute;
  will-change: top, left;
  top: 32px;
  left: 3px !important;
}

.ivu-color-picker-saturation-wrapper {
  padding-bottom: 0% !important;
}

.ivu-color-picker-picker-hue-slider {
  height: 0px !important;
}

.q-color-picker__cube,
.col-auto {
  margin: 4px;
}

.q-color-picker {
  box-shadow: none !important;
}

.addTextBox {
  position: absolute;
  display: block;
  width: 100%;
  background-color: @editbtn-shadow;
  // border-radius: 5px;
  transition: 0.5s;
  color: black;
  height: 100%;
  z-index: 1004;
  overflow: hidden;

  .menu-title {
    font-weight: bolder;
    text-align: left;
    font-size: 14px;
  }

  .slider_box {
    width: 100%;
    padding: 5px;

    .textsize {
      color: @light-color;
    }

    .ivu-slider-wrap {
      height: 12px !important;
      border-radius: 10px !important;
    }

    .ivu-slider-bar {
      height: 12px !important;
      border-radius: 10px !important;
      background: @light-color !important;
    }

    .ivu-slider-button {
      width: 16px;
      height: 16px;
      position: relative;
      border: 2px solid @light-color;
      top: 2px;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .white-check{
    color: white;
  }
  .color-check{
    position: absolute;
    font-size:20px;
    left:9px;
    top:8px;
    cursor: pointer;
  }
  .color-btn {
    position: relative;
    text-align: center;
    height: 36px !important;
    width: 36px !important;
    line-height: 36px !important;
    border: 2px solid white;
    margin: 8px;
    display: inline-block;
    box-shadow: 0px 0px 2px gray;
    border-radius: 50%;
    cursor: pointer;
  }

  .select-color {
    border: 0px solid rgba(223, 223, 223, 0);
    box-shadow: 0px 0px 2px gray;
  }

  .textinput-card-md {
    display: block;
    top: 10%;
    transition: 0.5s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: @nomal-bgc;
    font-weight: bolder;
    padding: 20px 20px 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 102;
    text-align: center;
    overflow: hidden;

    .textinput-title {
      font-size: 20px;
      color: #3d3d3d;
    }

    .textinput-scrollarea {
      position: relative;
      max-height: 60vh;
      padding: 20px 20px 10px 20px;
      margin: 20px -20px;
      overflow: auto;

      @media screen and (max-height: 500px) {
        height: 40vh !important;
      }
    }

    .large-input {
      width: 80vw !important;
    }

    .textinput {
      border-radius: 5px;
      border: 2px solid @light-color;
      color: black;
      margin-top: 10px;
      width: 100%;
      min-height: 100px;
      font-size: 22px;
      padding: 10px;
    }

    .divide-wrap {
      background-color: @nomal-bgc;
      padding: 20px 0px;

      .divide-line {
        background-color: #ededed;
        border-bottom: 0.5px solid rgb(192, 192, 192);
      }
    }

    .stickerbg-setting {
      padding: 0 8px;

      .menu-title {
        font-weight: bolder;
        text-align: left;
      }

      .set-nocolor {
        color: rgb(26, 26, 26) !important;
      }

      .sticker-btns {
        white-space: nowrap;
      }

      .nocolor-icon {
        font-size: 32px;
        line-height: 40px;
        color: gray;
      }

      .transparent-btn {
        height: 40px !important;
        width: 40px !important;
        border: 4px solid white;
        margin: 8px;
        top: -14px !important;
        display: inline-block;
        position: relative;
        box-shadow: 0px 0px 2px gray;
        border-radius: 50%;
        cursor: pointer;
      }

      .color-btn {
        height: 36px !important;
        width: 36px !important;
        border: 2px solid white;
        margin: 8px;
        margin-top: 24px;
        display: inline-block;
        box-shadow: 0px 0px 2px gray;
        border-radius: 50%;
        cursor: pointer;
      }

      .select-color {
        border: 0px solid rgba(223, 223, 223, 0);
        box-shadow: 0px 0px 2px gray;
      }
    }

    .textinputbtn-group {
      display: flex;
      align-items: center;

      .textinput-btn {
        cursor: pointer;
        flex: 1;
        white-space: nowrap;
        padding: 5px 10px;
        margin: 10px 5px;
        border-radius: 5px;
        text-align: center;
        background-color: #d8d8d8;

        &:first-child {
          color: white;
          background-color: @light-color;
        }
      }
    }
  }

  @media (min-width: 769px) {
    .textinput-card-md {
      display: none !important;
    }
  }

  .textinput-card-lg {
    display: none;
  }

  @media (min-width: 769px) {
    .textinput-card-lg {
      display: block;
      top: 10%;
      transition: 0.5s;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: @nomal-bgc;
      font-weight: bolder;
      padding: 20px 20px 10px 20px;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      z-index: 102;
      text-align: center;
      overflow: hidden;

      .flex-row{
        display: flex;
        width: 100%;
        .left-part{
          flex:1
        }
        .right-part{
          flex:1;
          position: relative;
          .right-scroll-area{
            position: relative;
            max-height: 50vh;
            padding: 0px 20px 10px 20px;
            margin: 20px -20px;
            overflow: auto;
          }
        }
      }

      .textinput-title {
        font-size: 20px;
        color: #3d3d3d;
      }

      .textinput-scrollarea {
        position: relative;
        max-height: 80vh;
        padding: 20px 20px 10px 20px;
        margin: 20px -20px;
        overflow: auto;

        @media screen and (max-height: 500px) {
          height: 40vh !important;
        }
      }

      .large-input {
        width: 40vw !important;
        height: 50vh;
        margin: 0px 20px;
      }

      .textinput {
        border-radius: 5px;
        border: 2px solid @light-color;
        color: black;
        margin-top: 10px;
        min-height: 100px;
        font-size: 22px;
        padding: 10px;
      }

      .divide-wrap {
        background-color: @nomal-bgc;
        padding: 20px 0px;

        .divide-line {
          background-color: #ededed;
          border-bottom: 0.5px solid rgb(192, 192, 192);
        }
      }

      .stickerbg-setting {
        padding: 0 8px;

        .menu-title {
          font-weight: bolder;
          text-align: left;
        }

        .set-nocolor {
          color: rgb(26, 26, 26) !important;
        }

        .sticker-btns {
          white-space: nowrap;
        }

        .nocolor-icon {
          font-size: 32px;
          line-height: 40px;
          color: gray;
        }

        .transparent-btn {
          height: 40px !important;
          width: 40px !important;
          border: 4px solid white;
          margin: 8px;
          top: -14px !important;
          display: inline-block;
          position: relative;
          box-shadow: 0px 0px 2px gray;
          border-radius: 50%;
          cursor: pointer;
        }

        .color-btn {
          height: 36px !important;
          width: 36px !important;
          border: 2px solid white;
          margin: 8px;
          margin-top: 24px;
          display: inline-block;
          box-shadow: 0px 0px 2px gray;
          border-radius: 50%;
          cursor: pointer;
        }

        .select-color {
          border: 0px solid rgba(223, 223, 223, 0);
          box-shadow: 0px 0px 2px gray;
        }
      }

      .textinputbtn-group {
        display: flex;
        align-items: center;
        position: absolute;
        bottom:10px;
        width: 100%;
      
        .textinput-btn {
          cursor: pointer;
          flex: 1;
          width: 100%;
          white-space: nowrap;
          padding: 5px 10px;
          margin: 10px 5px;
          border-radius: 5px;
          text-align: center;
          background-color: #d8d8d8;

          &:first-child {
            color: white;
            background-color: @light-color;
          }
        }
      }
    }
  }
}</style>
