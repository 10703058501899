/* eslint-disable */
import JSZip from 'jszip'

export default {
    /**
     * @param { File } file // 可解壓縮的檔案
     * @return { Array } // 返回File array
     */
    getFileList: function(file) {
        return new Promise( async (resole, reject) => {
            // var path = require('path');
            let result = []
            
            try {
                let zip = await JSZip.loadAsync(file)
                for (let zobj of Object.values(zip.files)) {
                    if (zobj.dir) continue;
                    const zblob = await zobj.async('blob')
                    const zfile = new File([zblob], zobj.name, {
                        lastModified: zobj.date.getTime()
                    })
                    result.push(zfile);
                }
                resole(result)
            } catch (err) {
                reject(err.message)
            }
        })
    },
    /**
     * @param { String } fName// 壓縮後的檔名
     * @param { * } cont // 要壓縮的內容
     * @returns { * }
     */
    cmpToBase64:function(fName, cont) {
        return new Promise( (resole, reject) => {
            try{
                var zip = new JSZip()
                zip.file(fName, cont)
                zip.generateAsync({type: 'base64', compression: 'DEFLATE'}).then((data) => {
                    resole(data)
                })
            } catch(error){
                reject(error)
            }
        })
    },
    unzByBase64: function(fName, zipfile){
        return new Promise( async (resole, reject) => {
            try {
                var unzip = new JSZip()
                await unzip.loadAsync(zipfile, { base64: true }).then(function(data) {
                    data.file(fName).async('string').then(function(content) {
                        resole(content)
                    })
                })
            } catch (error) {
                reject(error)
            }
        })
    }
}
