import { post } from "@/api/http";

export default {
  getMindMapSas: function () {
    return post("/mindmap/sas");
  },
  createMindMap: function (data) {
    return post("/mindmap/create-mindmap", data);
  },
  getMindMapsByTmid: function (data) {
    return post("/mindmap/get-mindmaps", data);
  },
  getMindMapByMid: function (data) {
    return post("/mindmap/get-mindmap-by-mid", data);
  },
  updateMindMap: function (data) {
    return post("/mindmap/update-mindmap", data);
  },
  deleteMindMap: function (data) {
    return post("/mindmap/delete-mindmap", data);
  },
};
