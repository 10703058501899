export default {
    比例:'比例',
    //AddImageBox
    檔案圖片:'檔案圖片',
    選取圖片作為物件:'選取圖片作為物件',
    背景設置:'背景設置',
    選取圖片作為背景:'選取圖片作為背景',
    //AddTextBox & TextSetting
    文字工具:'文字便利貼工具',
    編輯文字便利貼:'編輯',
    "openURL_NewTab": "於新分頁檢視連結",
    刪除文字便利貼:'刪除',
    輸入文字: '輸入文字',
    字體大小:'字體大小',
    字體顏色:'字體顏色',
    顏色:'顏色',
    便利貼顏色:'便利貼顏色',
    確定:'確定',
    取消:'取消',
    //PenSetting
    畫筆:'畫筆',
    通用畫筆:'通用畫筆',
    高光筆:'螢光筆',
    鐳射筆:'雷射筆',
    大小:'大小',
    //ShapeSetting
    圖形:'圖形',
    邊框:'邊框',
    填充:'填充',
    箭頭:'箭頭',
    直線:'直線',
    圓形:'圓形',
    橢圓:'橢圓',
    矩形:'矩形',
    三角形:'三角形',
    星形:'星形',
    所選圖片過大:'所選圖片過大(應小於3MB)，請重新選擇！',
    打開點擊的物件連結:'打開點選的物件連結?',
    多連結詢問:'偵測到點選的物件含有多個有效連結，請選擇一個動作',
    目前沒有動作可復原: '目前沒有動作可復原',
    復原我最近一次刪除動作:'復原我最近一次刪除動作!',
    ModifyHint: '內容有作過修改, 離開將不會儲存, 請問仍然要離開嗎?',
    物件化畫板:'物件化畫板',
    重新整理圖層:'重新整理圖層!',
    刪除鎖定物件提示字:'刪除了教師鎖定的物件，可點按還原或重開教材!',
    協作無法刪除鎖定提示字:'協作無法刪除教師鎖定的物件!',
    複製:'複製一份',
    刪除:'刪除',
    移到最上層:'移到最上層',
    移到最下層:'移到最下層'   
    }