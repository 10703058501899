<template>
  <div class="mindmap">
    <div class="mindmap-name-label" v-show="showNameLabel" :style="{ left: nameLabelX + 'px', top: nameLabelY + 'px' }">
      {{ currentNameLabel }}
    </div>
    <q-dialog v-model="showImgEditor" id="mindmap-img-editor" persistent>
      <mind-map-img-editor />
    </q-dialog>
    <div v-show="isModified" class="saving-hint" :class="{'left-hint':!selfEditMode}">偵測變動，等候保存到雲端</div>
    <div v-show="isStartSave && !isModified" class="saved-hint" :class="{'left-hint':!selfEditMode}">已保存</div>
    <div @click="loginTMID()" class="temp-btn" v-if="!isLogin">login</div>
    <div class="img-viewer" v-if="showBigImg" @click="clickImg()">
      <img :src="currentViewImg" alt="" />
    </div>
    <!---md按鈕-->
    <div class="import-md-btn controls" v-if="isLogin&&selfEditMode" @click="triggerInputMD">
      <svg-icon iconClass="import" class="import-icon" />
    </div>
    <!-- 隱藏的文件輸入 -->
    <input type="file" accept=".md" ref="fileInput" @change="InputMD" style="display: none;" />
    
    <div class="addnode-wrap" v-show="showAddNoteTool">
      <div class="addnode-card-md">
        <p class="addnode-title-large">
          {{ ctrlNodeMode == "add" ? $t("mindmap.新增") : $t("mindmap.編輯") }}
        </p>
        <div class="card-scroll-area">
          <input id="node-text-focus" class="addnode-text small-textarea" v-model="nodeText"
            :placeholder="$t('mindmap.輸入文字')" maxlength="20" />
          <p class="text-length">{{ nodeTextLength }}/20</p>
          <div class="addnode-direction" v-if="isCurrentSelectRoot">
            <div class="addnode-title">{{ $t("mindmap.加入根節點") }}</div>
            <label class="radio-btn" :class="{ 'check-btn': direction === 'left' }"><input type="radio"
                v-model="direction" value="left" />{{
      $t("mindmap.左邊")
    }}</label>
            <label class="radio-btn" :class="{ 'check-btn': direction === 'right' }"><input type="radio"
                v-model="direction" value="right" />{{
      $t("mindmap.右邊")
    }}</label>
          </div>
          <div class="addnode-title more-setting-btn" @click="showMoreSetting = !showMoreSetting">
            {{ $t("mindmap.進階設定") }}&nbsp;<span><svg-icon iconClass="arrowdown"
                :class="{ 'arrowdown-rotate': showMoreSetting }" /></span>
          </div>
          <div class="more-setting" v-show="showMoreSetting">
            <div class="divide-wrap">
              <div class="divide-line" />
            </div>
            <!--色彩編輯-->
            <p class="addnode-title" style="padding-top: 10px">
              {{ $t("mindmap.線條顏色") }}
            </p>
            <div class="q-gutter-sm" style="padding: 10px 0 2px 0">
              <div class="color-btn" :class="{ 'select-color': currentLineColor == item }"
                :style="{ 'background-color': item }" v-for="(item, index) in lineColorArr" :key="item"
                v-show="index < 5" @click="setLineColor(item)">
                <svg-icon class="color-check" v-show="currentLineColor == item" icon-class="check" />
              </div>
            </div>
            <div class="q-gutter-sm" style="padding: 2px 0">
              <div class="color-btn" :class="{ 'select-color': currentLineColor == item }"
                :style="{ 'background-color': item }" v-for="(item, index) in lineColorArr" :key="item"
                v-show="index >= 5" @click="setLineColor(item)">
                <svg-icon class="color-check color-check-white" v-show="currentLineColor == item" icon-class="check" />
              </div>
            </div>

            <p class="addnode-title" style="margin-top: 5px">
              {{ $t("mindmap.節點顏色") }}
            </p>
            <div class="q-gutter-sm" style="padding: 10px 0 2px 0">
              <div class="color-btn" :class="{ 'select-color': currentNodeColor == item }"
                :style="{ 'background-color': item }" v-show="index < 4" v-for="(item, index) in nodeColorArr"
                :key="index + 'sticker'" @click="setNodeColor(item)">
                <svg-icon class="color-check" v-show="currentNodeColor == item" icon-class="check" />
              </div>
            </div>
            <div class="divide-wrap">
              <div class="divide-line" />
            </div>
            <!--圖片編輯-->
            <div class="addnode-title">{{ $t("mindmap.圖片") }}</div>
            <div class="btn-group-init">
              <label class="btn" v-show="currentImgUrl == ''">
                <svg-icon icon-class="add_imgbgc2" class="draw-icon img_upload-icon" />
                <input ref="mainImageUploaderInit" type="file" accept="image/JPEG,image/png"
                  @change="imgfileChangeHandler" @click="resetImageUploader" name="img-upload"
                  class="img-upload-input" />
              </label>
              <div class="btn" v-show="currentImgUrl == ''" @click="openWhiteEditor()">
                <svg-icon icon-class="draw-initial" class="draw-icon draw-icon-small" />
              </div>
            </div>

            <p v-show="isOverMaxImageSize" class="warning-text">
              {{ $t("mindmap.圖片過大提示字1") }}&nbsp;{{
      `${maxImageSize}${$t("mindmap.圖片過大提示字2")}`
    }}
            </p>
            <div class="uploader-wrap" v-show="currentImgUrl != ''">
              <div class="uploader">
                <img class="uploader-img" :src="currentImgUrl" alt="" @click="openEditor()" />
              </div>
            </div>
            <div class="btn-group-under" v-show="currentImgUrl != ''">
              <label class="btn">
                <svg-icon icon-class="add_imgbgc2" class="img_upload-icon" />
                <input ref="mainImageUploader" type="file" accept="image/JPEG,image/png" @change="imgfileChangeHandler"
                  @click="resetImageUploader" name="img-upload" class="img-upload-input" />
              </label>
              <div class="btn btn-canvas" v-show="currentImgUrl != ''" @click="openWhiteEditor()">
                <svg-icon icon-class="draw-initial" class="draw-icon draw-icon-small" />
              </div>
              <div class="btn btn-close" @click="currentImgUrl = ''">
                <svg-icon icon-class="close" class="close-icon" />
              </div>
            </div>

            <div class="divide-wrap">
              <div class="divide-line" />
            </div>
            <!--連結編輯-->
            <div>
              <div class="addnode-title">{{ $t("mindmap.連結") }}</div>
              <textarea class="addnode-text small-textarea" v-model="nodeTextURL"
                :placeholder="$t('mindmap.輸入URL')"></textarea>
            </div>
            <div class="divide-wrap">
              <div class="divide-line" />
            </div>
            <!--註解編輯-->
            <div>
              <div class="addnode-title">{{ $t("mindmap.註解") }}</div>
              <textarea class="addnode-text" v-model="nodeTextNote" :placeholder="$t('mindmap.輸入文字')"
                maxlength="50"></textarea>
              <p class="text-length">{{ nodeTextNoteLength }}/50</p>
            </div>
          </div>
        </div>
        <div class="addnodebtn-area" v-show="!isUploadImg">
          <div class='addnode-warning' v-show="ctrlNodeMode == 'edit' && !isCurrentNodePreEditByMe && !isTool">{{
      $t('mindmap.preEditHint') + "\n" + nodeEditBy.name + "\n" + $t('mindmap.confirmUpdate') }}</div>
          <div class="addnodebtn-group">
            <div class="addnode-btn" @click="confirm()">{{ $t('mindmap["確定"]') }}</div>
            <div class="addnode-btn" @click="showAddNoteTool = false">{{ $t('mindmap["取消"]') }}</div>
          </div>
        </div>
      </div>

      <div class="addnode-card-lg">
        <p class="addnode-title-large">
          {{ ctrlNodeMode == "add" ? $t("mindmap.新增") : $t("mindmap.編輯") }}
        </p>
        <div class="card-scroll-area">
          <input id="node-text-focus-large" class="addnode-text small-textarea" v-model="nodeText"
            :placeholder="$t('mindmap.輸入文字')" maxlength="20" />
          <p class="text-length">{{ nodeTextLength }}/20</p>
          <div class="addnode-direction" v-if="isCurrentSelectRoot">
            <div class="addnode-title">{{ $t("mindmap.加入根節點") }}</div>
            <label class="radio-btn" :class="{ 'check-btn': direction === 'left' }"><input type="radio"
                v-model="direction" value="left" />{{
      $t("mindmap.左邊")
    }}</label>
            <label class="radio-btn" :class="{ 'check-btn': direction === 'right' }"><input type="radio"
                v-model="direction" value="right" />{{
      $t("mindmap.右邊")
    }}</label>
          </div>
          <div class="addnode-title more-setting-btn" @click="showMoreSetting = !showMoreSetting">
            {{ $t("mindmap.進階設定") }}&nbsp;<svg-icon iconClass="arrowdown"
              :class="{ 'arrowdown-rotate': showMoreSetting }" />
          </div>
          <div class="divide-wrap" v-show="showMoreSetting">
            <div class="divide-line" />
          </div>
          <div class="flex-wrap" v-show="showMoreSetting">
            <div class="left-part">
              <div class="img-area">
                <div class="addnode-title">{{ $t("mindmap.圖片") }}</div>
                <div class="btn-group-init">
                  <label class="btn btn-upload" v-show="currentImgUrl == ''">
                    <svg-icon icon-class="add_imgbgc2" class="draw-icon img_upload-icon" />
                    <input ref="mainImageUploaderInit" type="file" accept="image/JPEG,image/png"
                      @change="imgfileChangeHandler" @click="resetImageUploader" name="img-upload"
                      class="img-upload-input" />
                  </label>
                  <div class="btn btn-canvas" v-show="currentImgUrl == ''" @click="openWhiteEditor()">
                    <svg-icon icon-class="draw-initial" class="draw-icon draw-icon-small" />
                  </div>
                </div>

                <p v-show="isOverMaxImageSize" class="warning-text">
                  {{ $t("mindmap.圖片過大提示字1") }}&nbsp;{{
      `${maxImageSize}${$t("mindmap.圖片過大提示字2")}`
    }}
                </p>
                <div class="uploader-wrap" v-show="currentImgUrl != ''">
                  <div class="uploader">
                    <img class="uploader-img" :src="currentImgUrl" alt="" @click="openEditor()" />
                  </div>
                </div>
                <div class="btn-group-under" v-show="currentImgUrl != ''">
                  <label class="btn">
                    <svg-icon icon-class="add_imgbgc2" class="img_upload-icon" />
                    <input ref="mainImageUploader" type="file" accept="image/JPEG,image/png"
                      @change="imgfileChangeHandler" @click="resetImageUploader" name="img-upload"
                      class="img-upload-input" />
                  </label>
                  <div class="btn btn-canvas" v-show="currentImgUrl != ''" @click="openWhiteEditor()">
                    <svg-icon icon-class="draw-initial" class="draw-icon draw-icon-small" />
                  </div>
                  <div class="btn btn-close" @click="currentImgUrl = ''">
                    <svg-icon icon-class="close" class="close-icon" />
                  </div>
                </div>
              </div>
              <div class="divide-wrap">
                <div class="divide-line" />
              </div>
              <div class="addnode-title">{{ $t("mindmap.連結") }}</div>
              <textarea class="addnode-text small-textarea" v-model="nodeTextURL"
                :placeholder="$t('mindmap.輸入URL')"></textarea>
            </div>
            <div class="right-part">
              <!--色彩編輯-->
              <p class="addnode-title" style="padding-top: 10px">
                {{ $t("mindmap.線條顏色") }}
              </p>
              <div class="q-gutter-sm" style="padding: 10px 0 2px 0">
                <div class="color-btn" :class="{ 'select-color': currentLineColor == item }"
                  :style="{ 'background-color': item }" v-for="(item, index) in lineColorArr" :key="item"
                  v-show="index < 5" @click="setLineColor(item)">
                  <svg-icon class="color-check" v-show="currentLineColor == item" icon-class="check" />
                </div>
              </div>
              <div class="q-gutter-sm" style="padding: 2px 0">
                <div class="color-btn" :class="{ 'select-color': currentLineColor == item }"
                  :style="{ 'background-color': item }" v-for="(item, index) in lineColorArr" :key="item"
                  v-show="index >= 5" @click="setLineColor(item)">
                  <svg-icon class="color-check color-check-white" v-show="currentLineColor == item"
                    icon-class="check" />
                </div>
              </div>

              <p class="addnode-title" style="margin-top: 5px">
                {{ $t("mindmap.節點顏色") }}
              </p>
              <div class="q-gutter-sm" style="padding: 10px 0 2px 0">
                <div class="color-btn" :class="{ 'select-color': currentNodeColor == item }"
                  :style="{ 'background-color': item }" v-show="index < 4" v-for="(item, index) in nodeColorArr"
                  :key="index + 'sticker'" @click="setNodeColor(item)">
                  <svg-icon class="color-check" v-show="currentNodeColor == item" icon-class="check" />
                </div>
              </div>
              <div class="divide-wrap">
                <div class="divide-line" />
              </div>

              <div class="addnode-title">{{ $t("mindmap.註解") }}</div>
              <textarea class="addnode-text" v-model="nodeTextNote" :placeholder="$t('mindmap.輸入文字')"
                maxlength="50"></textarea>
              <p class="text-length">{{ nodeTextNoteLength }}/50</p>
            </div>
          </div>
        </div>
        <div class="addnodebtn-area" v-show="!isUploadImg">
          <div class='addnode-warning' v-show="ctrlNodeMode == 'edit' && !isCurrentNodePreEditByMe && !isTool">{{
      $t('mindmap.preEditHint') + "\n" + nodeEditBy.name + "\n" + $t('mindmap.confirmUpdate') }}</div>
          <div class="addnodebtn-group">
            <div class="addnode-btn" @click="confirm()">{{ $t('mindmap["確定"]') }}</div>
            <div class="addnode-btn" @click="showAddNoteTool = false">{{ $t('mindmap["取消"]') }}</div>
          </div>
        </div>
      </div>
    </div>

    <!--mindMap 工具按鈕-->
    <div class="mindmap-tool" v-if="isLogin">
      <div @click="relayout()" class="controls">
        <svg-icon iconClass="refresh" />
      </div>
    </div>
    <!-- <div class="mindmap-tool">
      <div @click="openNode('add')" class="controls"><svg-icon iconClass="add" class="add-icon" /></div>
      <div @click="deleteSelectNode()" class="controls" v-show="!isCurrentSelectRoot"><svg-icon iconClass="minus-pure"
          class="minus-icon" /></div>
      <div @click="copyNode()" v-show="!isCurrentSelectRoot" class="controls"><svg-icon iconClass="copy2" class="copy-icon" /></div>
      <div @click="moveNode('up')" class="controls" v-show="!isCurrentSelectRoot"><svg-icon iconClass="arrowdown"
          class="moveup-icon" /></div>
      <div @click="moveNode('down')" class="controls" v-show="!isCurrentSelectRoot"><svg-icon iconClass="arrowdown"
          class="movedown-icon" /></div>
      <div @click="openNode('edit')" class="controls"><svg-icon iconClass="pencil-alt" /></div>
    </div> -->
    <!--mindMap 工具選單按鈕-->
    <div class="mindmap-menu" :style="{ top: posY + 'px', left: posX + 'px' }" v-show="showNodeMenu">
      <div class="menu-item" @click="openNode('add')">
        <span class="menu-icon-wrap"><svg-icon iconClass="add" class="add-icon" /></span>{{ $t("mindmap.menu.新增") }}
      </div>
      <div class="menu-item" @click="deleteSelectNode()" v-show="!isCurrentSelectRoot && isCurrentNodeCanBeDelete">
        <span class="menu-icon-wrap"><svg-icon iconClass="minus-pure" class="minus-icon" /></span>{{
      $t("mindmap.menu.刪除") }}
      </div>
      <div class="menu-item" @click="moveNode('up')" v-show="!isCurrentSelectRoot && isCurrentNodeCanMoveUp">
        <span class="menu-icon-wrap"><svg-icon iconClass="arrowdown" class="moveup-icon" /></span>{{
      $t("mindmap.menu.上移") }}
      </div>
      <div class="menu-item" @click="moveNode('down')" v-show="!isCurrentSelectRoot && isCurrentNodeCanMoveDown">
        <span class="menu-icon-wrap"><svg-icon iconClass="arrowdown" class="movedown-icon" /></span>{{
      $t("mindmap.menu.下移") }}
      </div>
      <div class="menu-item" @click="openNode('edit')">
        <span class="menu-icon-wrap"><svg-icon iconClass="pencil-alt" class="pencil-icon" /></span>{{
      $t("mindmap.menu.編輯") }}
      </div>
      <div class="menu-item" @click="copyNode()" v-show="!isCurrentSelectRoot">
        <span class="menu-icon-wrap"><svg-icon iconClass="copy2" class="copy-icon" /></span>{{ $t("mindmap.menu.複製") }}
      </div>

      <div v-show="showSelectedNote" class="divide-wrap">
        <div class="divide-line" />
      </div>
      <div v-show="showSelectedNote" class="note-wrap">
        <p class="note-text">{{ nodeTextNote }}</p>
      </div>
      <div class="divide-wrap" v-show="nodeEditBy.id != '' && nodeEditBy.name != ''">
        <div class="divide-line" />
      </div>
      <div class="node-info" v-show="nodeEditBy.id != '' && nodeEditBy.name != ''">
        <span class="dot" />
        <span v-show="nodeEditBy.seat != 'wb'">
          {{ nodeEditBy.seat }}&nbsp; </span>{{ nodeEditBy.name }}
      </div>
    </div>

    <!-- <div class="controls btn-logout" @click="save()">
      <svg-icon iconClass="save" class="save-icon" />
    </div> -->

    <!-- <div class="controls btn-logout" @click="logout()">
      <svg-icon iconClass="log_out" class="logout-icon" />
    </div> -->

    <!-- <div class="controls btn-send" @click="refresh()">
      <svg-icon iconClass="refreshht" class="refresh-icon" />
    </div> -->

    <div id="jsmind_container"></div>
    <div id="content"></div>
  </div>
</template>

<script>
import { SharedTree, TreeViewConfiguration, SchemaFactory, Tree, SharedMap } from "fluid-framework";
import { AzureClient } from "@fluidframework/azure-client";
import { InsecureTokenProvider } from "@fluidframework/test-client-utils"
import { ref, provide, getCurrentInstance, reactive, toRaw, isReactive, isRef, inject } from "vue"; //Vue3 composition API
import api from "@/api";
import { useMindMapStore } from "@/stores/mindMapStore";
import jwt_decode from "jwt-decode";
import "../api/jsmindlib/jsmind.css";
import * as jsMind from "../api/jsmindlib/jsmind.js";
import jmNodeIconStr from "../api/jsmindlib/jmNodeIconString.js";
import SvgIcon from "./SvgIcon.vue";
import MindMapImgEditor from "./MindMapImgEditor.vue";
import { QDialog } from "quasar";
import { useRoute } from 'vue-router';
import * as htmlToImage from 'html-to-image'
import md from "../api/jsmindlib/mdToJsMind.js";

window.jsMind = jsMind;

const { BlobServiceClient } = require("@azure/storage-blob");

export default {
  components: { MindMapImgEditor, SvgIcon, QDialog },
  name: "MindMap",

  setup() {
    let that = getCurrentInstance().ctx;

    const route = useRoute();
    const iviewMessage = inject("$Message")
    const showImgEditor = ref(false);
    const mindMapStore = useMindMapStore();
    const inputImgFileBeforeOpenCanvas = ref('');
    const currentImgUrl = ref('');
    let fluidMindRoot = reactive({});
    let fluidMindHistory = reactive({});

    const initMindMapBlob = () => {
      if (mindMapStore.mindMapBlobsasurl) {
        const serviceClient = new BlobServiceClient(
          mindMapStore.mindMapBlobsasurl + "?" + mindMapStore.mindMapBlobSas
        );
        const containerClient = serviceClient.getContainerClient("");
        mindMapStore.setMindMapBlobContainerClient(containerClient);
      }
    };

    const mindMapUploadFile = (currentFile,noTimeTitle=false) => {
      //跟konvaCanvasUploadFile 相同，之後要再細分資料夾

      let times = new Date().getTime();
      return new Promise((r, j) => {
        let promises = [];
        let targetfilename = noTimeTitle?currentFile.name:times + currentFile.name;
        let blockBlobClient =
          mindMapStore.mindMapBlobContainerClient.getBlockBlobClient(
            targetfilename
          );
        promises.push(blockBlobClient.uploadBrowserData(currentFile));

        Promise.all(promises).then(
          () => {
            let finallink =
              mindMapStore.mindMapBlobsasurl + targetfilename;
            r({
              url: finallink,
              name: targetfilename,
            });
          },
          (err) => {
            j(err);
          }
        );
      });
    }


    const sf = new SchemaFactory("fluidMindMap");


    class Mind extends sf.object("MindData", {
      id: sf.string,
      topic: sf.string,
      expanded: sf.boolean,
      direction: sf.optional(sf.string),
      lineColor: sf.optional(sf.string),
      "background-color": sf.optional(sf.string),
      children: sf.arrayRecursive("childrenArray", [() => Mind])
    }) { }



    const testFluidShareTreeInMind = async () => {

      const serviceConfig = {
        connection: {
          type: "remote",
          tenantId: "ac10fb96-cd7c-4536-882f-90000af1c214", // REPLACE WITH YOUR TENANT ID
          tokenProvider: new InsecureTokenProvider("036b6b18a15efe12b468ad5eff743cb0", /* REPLACE WITH YOUR PRIMARY KEY */
            { id: "userId", name: "John Doe" }),
          endpoint: "https://global.fluidrelay.azure.com", // REPLACE WITH YOUR AZURE ENDPOINT
        }
      };
      const client = new AzureClient(serviceConfig);

      const containerSchema = {
        initialObjects: { mind: SharedTree },
      };

      const treeViewConfiguration = new TreeViewConfiguration({ schema: Mind });


      let mind

      if (!location.hash) {
        const { container } = await client.createContainer(containerSchema, "2");
        mind = container.initialObjects.mind.viewWith(treeViewConfiguration);

        mind.initialize(new Mind({
          id: 'a',
          topic: "Root",
          expanded: true,
          children: []
        }));

        const containerId = await container.attach();
        location.hash = containerId;
      }
      else {
        let id = location.hash.substring(1);
        const { container } = await client.getContainer(id, containerSchema, "2");
        mind = container.initialObjects.mind.viewWith(treeViewConfiguration);
      }
      fluidMindRoot = mind.root;


      const updateMindMap = () => {
        console.log("test mind changed!", that.replaceChildrenOuterBracesWithArray(deepToRaw(fluidMindRoot.children)))
        that.mind.data.children = that.replaceChildrenOuterBracesWithArray(deepToRaw(fluidMindRoot.children))
        that.reloadLayout(false)
      }

      Tree.on(fluidMindRoot, "nodeChanged", updateMindMap);
      // Setting "fluidStarted" is just for our test automation
      window.fluidStarted = true;
    }

    const testFluidShareMapInMind = async (containerId) => {
      that.init_data()

      const serviceConfig = {
        connection: {
          type: "remote",
          tenantId: "ac10fb96-cd7c-4536-882f-90000af1c214", // REPLACE WITH YOUR TENANT ID
          tokenProvider: new InsecureTokenProvider("2mOMLPmQYqbgJ2S7pMVZxh8gYu5Jp3X5C3LHY3XJkDWAn0qZi4JjJQQJ99AHAC16hbFy71xuAAAAAZFROfcC", /* REPLACE WITH YOUR PRIMARY KEY */
            { id: "userId", name: "John Doe" }),
          endpoint: "https://global.fluidrelay.azure.com", // REPLACE WITH YOUR AZURE ENDPOINT
        }
      };
      const client = new AzureClient(serviceConfig);

      const containerSchema = {
        initialObjects: { mindHistory: SharedMap },
      };

      let id = containerId;
      const { container } = await client.getContainer(id, containerSchema, "2");


      fluidMindHistory = container.initialObjects.mindHistory;
      console.log(fluidMindHistory)

      const updateMindMap = (changed) => {
        console.log(changed)
        let actionItem = fluidMindHistory.get(changed.key)

        if (actionItem === undefined || actionItem === null) {
          console.error("actionItem is undefined or null, cannot parse it");
          return;
        }
        let item;
        try {
          item = JSON.parse(actionItem);
        } catch (error) {
          console.error("Failed to parse actionItem as JSON:", actionItem);
          return;
        }
        //同樣人登入不會更新
        if (item && !that.isSentByMe(item)) {
          that.updateStageNode(item);
        }
      }

      fluidMindHistory.on('valueChanged', updateMindMap);

      const mapSize = fluidMindHistory.size; // 获取 SharedMap 的总大小
      let currentIndex = 0; // 初始化当前索引

      if (fluidMindHistory) {
        //init 一開始從原始紀錄遍歷
        fluidMindHistory.forEach((value) => {
          currentIndex++; // 每次迭代增加索引
          let item = JSON.parse(value);
          if (item && !that.isSentByMe(item)) {
            that.updateStageNode(item);
          }
          if (currentIndex === mapSize) {
            that.relayout(2000, true)
          }
        })
      }
    }

    const setFluidShareMap = (action, item, actionType = " ") => {
      if (fluidMindHistory && !that.selfEditMode) {
        console.log(item, "action")
        const id = Date.now() + '-' + that.genUUID(); //加時間排序後續可能會用到排序，避免多用戶重複

        const finalItem = JSON.stringify({
          from: that.fromInfo.seat,
          uuid: item.nodeID,
          action: action,
          actionType: actionType,
          item: JSON.stringify(item)
        })
        fluidMindHistory.set(id, finalItem);
      }
    }

    const convertToMind = (node) => {
      return new Mind({
        id: node.id,
        topic: node.topic,
        expanded: node.expanded,
        direction: node.direction,
        lineColor: node.lineColor,
        "background-color": node["background-color"],
        children: Array.isArray(node.children) ? node.children.map(convertToMind) : []
      });
    };

    //用tree存整份mindmap
    const setFluidShareTreeMindRoot = () => {

      fluidMindRoot.id = that.jm.get_data("node_tree").data.id;
      let finalChildren = that.jm.get_data("node_tree").data.children.map(convertToMind)
      fluidMindRoot.children = finalChildren;
    };

    //  純檢視印出用
    const deepToRaw = (obj) => {
      if (isReactive(obj) || isRef(obj)) {
        obj = toRaw(obj);
      }
      if (Array.isArray(obj)) {
        return obj.map(deepToRaw);
      } else if (obj && typeof obj === 'object') {
        const rawObj = {}
        for (const key in obj) {
          rawObj[key] = deepToRaw(obj[key]);
        }
        return rawObj;
      }
      return obj;
    }

    provide("showImgEditor", showImgEditor); //向子組件提供參數
    provide("inputImgFileBeforeOpenCanvas", inputImgFileBeforeOpenCanvas);
    provide("mindMapUploadFile", mindMapUploadFile);
    provide("currentImgUrl", currentImgUrl);


    iviewMessage.loading({
      content: "",
      duration: 0.001
    });

    return {
      iviewMessage,
      route,
      currentImgUrl,
      showImgEditor,
      mindMapStore,
      initMindMapBlob,
      inputImgFileBeforeOpenCanvas,
      mindMapUploadFile,
      //shareTree整份存的方式
      testFluidShareTreeInMind,
      setFluidShareTreeMindRoot,
      //shareMap存歷史的方式
      testFluidShareMapInMind,
      setFluidShareMap,
    };
  },
  data() {
    return {
      profile: {
        name: "",
        teammodelId: "",
        picture: "",
      },
      isLogin: false,
      currentViewImg: "",
      showBigImg: false,
      jmNodeAttechIcon: jmNodeIconStr.attachment,
      jm: null,
      ctrlNodeMode: "add",
      currentNode: null,
      showAddNoteTool: false,
      nodeText: "",
      nodeTextNote: "", //更詳細的說明文字
      nodeTextURL: "",
      direction: "left",
      isCurrentSelectRoot: false,
      isCurrentNodeCanBeDelete: false,
      isCurrentNodeCanMoveUp: false,
      isCurrentNodeCanMoveDown: false,
      showNodeMenu: false,
      currentNodeHasImg: false,
      posX: 0,
      posY: 0,
      mind: {
        meta: { name: "jsMind-demo-tree", author: "", version: "" },
        format: "node_tree",
        data: {
          id: "root",
          topic: "<p>root</p>",
          nodeText: "root",
          nodeImg: "",
          nodeLink: "",
          expanded: true,
          children: [
            {
              id: "uuid1",
              topic: "<p>node1</p>",
              nodeText: "node1",
              nodeImg: "",
              nodeLink: "",
              expanded: true,
              direction: "left",
              lineColor: "red",
              "background-color": "rgb(255, 238, 139)",
            },
            {
              id: "uuid2",
              topic: "<p>node2</p>",
              nodeText: "node2",
              nodeImg: "",
              nodeLink: "",
              expanded: true,
              direction: "left",
              lineColor: "red",
              "background-color": "rgb(255, 238, 139)",
            },
            {
              id: "uuid3",
              topic: "<p>node3</p>",
              nodeText: "node3",
              nodeImg: "",
              nodeLink: "",
              expanded: true,
              direction: "right",
              lineColor: "red",
              "background-color": "rgb(255, 238, 139)",
            },
            {
              id: "uuid4",
              topic: "<p>node4</p>",
              nodeText: "node4",
              nodeImg: "",
              nodeLink: "",
              expanded: true,
              direction: "right",
              lineColor: "red",
              "background-color": "rgb(255, 238, 139)",
            },
          ],
        },
      },
      options: {
        log_level: "error",
        container: "jsmind_container",
        editable: false,
        theme: "webirs",
        support_html: true,
        view: {
          engine: "svg",
          line_width: 4, // 線條粗細
          line_color: "#84c9c2", // 線條顏色
        },
      },
      maxImageSize: 3,
      isOverMaxImageSize: false,
      isUploadImg: false,
      showSelectedNote: false,
      nodeEditBy: { seat: "", name: "" },
      showMoreSetting: false,
      currentLineColor: "#0000ff",
      currentNodeColor: "rgb(255, 238, 139)",
      lineColorArr: [
        "red",
        "orange",
        "yellow",
        "cyan",
        "#e0e0e0",
        "brown",
        "purple",
        "green",
        "#0000ff",
        "black",
      ],
      nodeColorArr: [
        "rgb(255, 238, 139)",
        "rgb(211, 255, 139)",
        "rgb(139, 243, 255)",
        "rgb(255, 191, 215)",
      ],
      anonymousSeat: "",
      selfEditMode: false,
      isModified: false,
      isStartSave: false, //是否開始自動存
      autoSaveIntervalId: null,
      currentMoveTargetNode: "",
      isCurrentNodePreEditByMe: false,
      nameLabelX: 0,
      nameLabelY: 0,
      currentNameLabel: '',
      showNameLabel: false,
      isNoSnapShot: false,
    };
  },
  async mounted() {
    this.anonymousSeat = 'anonymous-' + this.genUUID();
    let that = getCurrentInstance().ctx;
    await that.getMindMapSas();

    if (!this.route.query?.id) {
      that.iviewMessage.warning("初始化失敗，請確認網址是否正確~")
      return
    }

    let url = new URL(window.location.href);
    let code = url.searchParams.has("code") == true ? url.searchParams.get("code") : "";


    if (!sessionStorage.getItem("access_token") && !code) {
      that.iviewMessage.warning("新開視窗，請先登入~")
      return
    } else if (!sessionStorage.getItem("access_token") && code) {
      //登入取得token
      api.main
        .getToken()
        .then((res) => {
          sessionStorage.setItem("id_token", res.id_token);
          const decoded = jwt_decode(res.id_token);

          this.isLogin = true;

          this.profile.name = decoded.name;
          this.profile.teammodelId = decoded.sub;
          this.profile.picture = decoded.picture;

          //將access_token存本地, http 攔截器使用
          sessionStorage.setItem("access_token", res.access_token);
          sessionStorage.setItem("expires_in", res.expires_in);


          localStorage.setItem(
            "profile",
            JSON.stringify({
              name: this.profile.name,
              id: this.profile.teammodelId,
              picture: this.profile.picture,
            })
          );
          this.initMindMap();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      this.isLogin = true
      this.initMindMap();
    }

    let jmDom = document.getElementById("jsmind_container");


    jmDom.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
    jmDom.addEventListener("click", function (e) {
      that.handleNodeMenu(e);
      if (e.target.className == "mindmap-img") {
        if (e.target?.src) {
          that.currentImgUrl = e.target.src;
          that.viewBigImg();
        }
      }
    });
    jmDom.addEventListener("dblclick", function (e) {
      that.handleEdit(e);
    });
    document.addEventListener("keyup", function (e) {
      if (that.showAddNoteTool) {
        if (e.key == "Enter") {
          that.confirm();
        }
      }
    });

    // this.$mobileirs.IMSendMsg(0, 0, 43)// 開啟時向ＨＴ取得目前節點資訊

    // Variables to store mouse position
    let startX, startY;
    // Mouse down event listener
    // 滑鼠按下事件監聽器
    jmDom.addEventListener("mousedown", function (event) {
      event.preventDefault();
      startX = event.clientX;
      startY = event.clientY;
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    });

    const scrollFactor = 1;

    // 滑鼠移動事件監聽器
    function onMouseMove(event) {
      // 計算滑鼠移動的偏移量
      const deltaX = event.clientX - startX;
      const deltaY = event.clientY - startY;

      // 更新捲動位置
      let jmInner = jmDom.querySelector(".jsmind-inner");
      jmInner.scrollLeft -= deltaX * scrollFactor;
      jmInner.scrollTop -= deltaY * scrollFactor;

      // 更新滑鼠位置
      startX = event.clientX;
      startY = event.clientY;
    }

    // 滑鼠釋放事件監聽器
    function onMouseUp() {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }
    // 累加器变量，记录经过的时间（单位：毫秒）
    let accumulatedTime = 0;
    const snapshotInterval = 5 * 60 * 1000; // 5 分鐘
    const tolerance = 10 * 1000; // 10 秒

    //自動存計時器
    this.autoSaveIntervalId = setInterval(async () => {
      if (this.isModified) {
        await this.save()
        // await this.saveMindMapSnapshot()
        this.isStartSave = true
        this.isModified = false
        //如有變動每5分鐘存一次截圖
        // 檢查是否達到保存快照的時間點（允許±10秒誤差）
        if (accumulatedTime !== 0 && accumulatedTime % snapshotInterval < tolerance) {
            await this.saveMindMapSnapshot();
        }
      }
      if(this.isNoSnapShot){
        await this.saveMindMapSnapshot()
        this.isNoSnapShot=false
      }
      accumulatedTime += 10000
    }, 10000);
  },
  beforeUnmount() {
    clearInterval(this.autoSaveIntervalId);
    this.autoSaveIntervalId = null;
  },
  computed: {
    fromInfo() {
      let seat = this.anonymousSeat
      let name = "anoymous"
      if (localStorage.getItem("profile")) {
        let localeProfile = JSON.parse(localStorage.getItem("profile"));
        seat = localeProfile.id
        name = localeProfile.name
      }
      return {
        seat: seat,
        name: name,
        // seat: this.$mobileirs.HiGroup ? 'G' + this.$mobileirs.HiGroupID : this.$mobileirs.seat,
        // name: this.$mobileirs.HiGroup ? this.$mobileirs.HiGroupName : this.$mobileirs.name
      };
    },
    // mindMapInfo() {
    //   return this.$mobileirs.msgMindMapInfo
    // },

    nodeTextLength() {
      return this.nodeText.length;
    },
    nodeTextNoteLength() {
      return this.nodeTextNote.length;
    },
  },
  watch: {
    // mindMapInfo(value) {
    //   console.log(value)
    //   this.mind = value
    //   if (this.jm == null) {
    //     this.$q.loading.show()
    //     this.init_data()
    //     this.reloadLayout()

    //   } else {
    //     this.reloadLayout()
    //   }
    // },
  },

  methods: {
    initMindMap() {

      //根據mindmapId 去取得當前資料
      let data = { mid: this.route.query.id }
      api.mindmapAPI.getMindMapByMid(data).then(
        (res) => {
          console.log(res.data)
          if (!res.data.openCowork) {
            //如果openCowork false，等於自編本地初始化
            this.selfEditMode = true
            this.iviewMessage.info("初始化成功")
            if (res.data.mindmapData != "") {
              let tempData = JSON.parse(res.data.mindmapData)
              this.replaceNodeImgUrl(tempData)
              this.mind.data = tempData
            }
            if(!res.data.snapshotUrl) {
              this.isNoSnapShot = true
            }
            this.init_data()
            this.relayout(2000, true)
          } else {
            this.selfEditMode = false
            if (res.data.mindmapData != "") {
              let tempData = JSON.parse(res.data.mindmapData)
              this.replaceNodeImgUrl(tempData)
              this.mind.data = tempData
            }

            this.testFluidShareMapInMind(res.data.fluidContainerId);
          }
        }
      ).catch((error) => {
        this.iviewMessage.warning("初始化失敗" + error.message);
      })

    },
    loginTMID() {
      api.main.loginTmdID();
    },
    replaceNodeImgUrl(node) {
      if (node.nodeImg) {
        let newImgBlobLink = node.nodeImg.replace(/\?sv.*$/, "") + "?" + this.mindMapStore.mindMapBlobSas;
        node.nodeImg = newImgBlobLink;

        // 如果 topic 中包含 <img> 标签，替换其中的 src URL
        if (node.topic) {
          node.topic = node.topic.replace(/(<img[^>]+src=")[^"]+("[^>]*>)/, `$1${newImgBlobLink}$2`);
        }
      }

      // 递归遍历 children 节点
      if (node.children && node.children.length > 0) {
        node.children.forEach(childNode => this.replaceNodeImgUrl(childNode));
      }
    },
    async getMindMapSas() {
      try {
        const res = await api.mindmapAPI.getMindMapSas();
        this.mindMapStore.setMindMapBlobSas(res);
        this.initMindMapBlob();
      } catch (error) {
        console.error('Error fetching MindMap SAS:', error);
        // 可以根据需要添加更多错误处理逻辑
      }
    },
    async saveMindMapSnapshot() {
      let that = this;
      this.$q.loading.show();
      let jmDom = document.getElementById("jsmind_container");
      let jmnodesElement = document.querySelector("jmnodes");

      // 先将Dom放大到跟绘图区等大，再截图
      jmDom.style.height = jmnodesElement.offsetHeight + "px";
      jmDom.style.width = jmnodesElement.offsetWidth + "px";
      this.jm.resize();

      // 将异步操作封装在Promise中
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          htmlToImage.toPng(jmDom).then(async dataURL => {
            try {
              let compressFile = await that.dataURLtoFile(
                dataURL,
                this.route.query.id + "-" + "snapshot.png"
              );
              let size = parseFloat(compressFile.size / 1000000).toFixed(4);

              let res = await that.mindMapUploadFile(compressFile,true);

              console.log(size + "MB", res.url + "?" + that.mindMapStore.mindMapBlobSas);

              let data = {
                "mindmapName": this.jm.get_data("node_tree")?.data.nodeText,
                "mindmapEditor": {
                  "id": this.fromInfo.seat,
                  "name": this.fromInfo.name
                },
                "id": this.route.query.id,
                "snapshotUrl": res.name
              };

              await api.mindmapAPI.updateMindMap(data);
              that.iviewMessage.success("更新截圖");

              // 恢复原始大小
              jmDom.style.height = "100vh";
              jmDom.style.width = "100%";
              that.jm.resize();
              that.$q.loading.hide();

              resolve(); // 任务完成，调用resolve
            } catch (error) {
              that.$q.loading.hide();
              reject(error); // 发生错误时调用reject
            }
          }).catch(error => {
            that.$q.loading.hide();
            reject(error); // 捕获htmlToImage.toPng的错误
          });
        }, 200);
      });
    },
    relayout(time = 200, showLoading = false) {
      if (showLoading) this.$q.loading.show()
      setTimeout(() => {
        this.jm.show(this.jm.get_data("node_tree"));

        this.jm.view.relayout();
        if (showLoading) this.$q.loading.hide()
      }, time);
    },
    replaceChildrenOuterBracesWithArray(obj) {
      // 先處理內部 children 結構
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key].children === 'object' && obj[key].children !== null) {
            this.replaceChildrenOuterBracesWithArray(obj[key].children);
            obj[key].children = Object.values(obj[key].children);
          }
        }
      }
      // 將最外層對象轉換為數組
      return Object.values(obj);
    },
    copyNode() {
      let copyTargetNode = this.jm.get_selected_node();
      if (copyTargetNode) {
        this.jm.enable_edit(); //啟用編輯
        this.showNameLabel = false
        let itemInfo = {
          fromInfo: this.fromInfo,
          note: copyTargetNode.data?.note || "",
          lineColor: copyTargetNode.data?.lineColor,
          "background-color": copyTargetNode.data?.["background-color"],
          nodeText: copyTargetNode.data?.nodeText || "",
          nodeImg: copyTargetNode.data?.nodeImg || "",
          nodeLink: copyTargetNode.data?.nodeLink || "",
        };
        let nodeID = this.genUUID();
        this.jm.insert_node_after(
          copyTargetNode,
          nodeID,
          copyTargetNode.topic,
          itemInfo,
          copyTargetNode.direction
        );
        this.jm.disable_edit(); //關閉編輯
        this.showNodeMenu = false;

        let item = {
          direction: copyTargetNode.direction,
          beforeNodeID: copyTargetNode.id,
          nodeID: nodeID,
          nodeContent: copyTargetNode.topic,
          ...itemInfo,
        };
        this.setFluidShareMap("copy", item, "copy");
        // this.setFluidShareTreeMindRoot()
        this.jm.select_clear(); //清除選中狀態，避免白色字體殘留
        this.isModified = true;
      } else {
        this.$Message.info(this.$t("mindmap.請先選取節點"));
      }
    },
    setLineColor(item) {
      this.currentLineColor = item;
    },
    setNodeColor(item) {
      this.currentNodeColor = item;
    },
    refresh() {
      // this.$mobileirs.IMSendMsg(0, 0, 43)// 向ＨＴ取得目前節點資訊，然後就會進入重整
    },
    reloadLayout(showLoading = true) {
      if (showLoading) this.$q.loading.show()
      setTimeout(() => {
        this.jm.show(this.mind);
        this.jm.view.relayout();
        if (showLoading) this.$q.loading.hide()
      }, showLoading ? 1000 : 200);
    },

    resetImageUploader() {
      if (this.$refs.mainImageUploader) {
        this.$refs.mainImageUploader.value = "";
      }
      if (this.$refs.mainImageUploaderInit) {
        this.$refs.mainImageUploaderInit.value = "";
      }
    },
    openWhiteEditor() {
      this.isOverMaxImageSize = false;
      this.inputImgFileBeforeOpenCanvas = "";
      this.showImgEditor = true;
    },
    openEditor() {
      this.inputImgFileBeforeOpenCanvas = this.currentImgUrl;
      this.showImgEditor = true;
    },
    imgfileChangeHandler(e) {
      let that = this;
      this.isOverMaxImageSize = false;
      //這邊圖片直接做成上傳檔案
      let fileName = e.target.files[0].name;

      let fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);

      fileReader.onload = (e) => {
        let imageObj = new Image();
        imageObj.setAttribute("crossOrigin", "Anonymous");
        imageObj.src = e.target.result;
        imageObj.onload = async function () {
          let ratio = that.calScaleRatio(
            imageObj.width,
            imageObj.height,
            1024,
            1024
          ).ratio;
          if (imageObj.width <= 1024 && imageObj.height <= 1024) ratio = 1;
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const originalWidth = imageObj.width;
          const originalHeight = imageObj.height;
          const canvasWidth = originalWidth * ratio;
          const canvasHeight = originalHeight * ratio;
          canvas.width = canvasWidth;
          canvas.height = canvasHeight;
          context.drawImage(imageObj, 0, 0, canvasWidth, canvasHeight);
          let compressFile = await that.dataURLtoFile(
            canvas.toDataURL("images/jpeg", 1),
            fileName
          );
          let size = parseFloat(compressFile.size / 1000000).toFixed(4);

          if (!size) return;
          if (size > this.maxImageSize) {
            that.isOverMaxImageSize = true;
          } else {
            that.isUploadImg = true;
            that.$q.loading.show();
            that.mindMapUploadFile(compressFile).then((res) => {
              that.currentImgUrl =
                res.url + "?" + that.mindMapStore.mindMapBlobSas;
              that.isUploadImg = false;
              that.$q.loading.hide();
            });
          }
        };
      };
    },
    updateStageNode(actionItem) {
      this.jm.enable_edit(); //啟用編輯
      this.showNameLabel = false
      let item = JSON.parse(actionItem.item);
      let itemInfo = {
        fromInfo: item?.fromInfo,
        note: item?.note,
        lineColor: item?.lineColor,
        "background-color": item?.["background-color"], //按照jsMind套件中的命名
        nodeText: item?.nodeText || "",
        nodeImg: item?.nodeImg || "",
        nodeLink: item?.nodeLink || "",
      };
      let nodeID = item?.nodeID;
      let itemContent = item?.nodeContent;


      // 將圖片更換為最新sas
      if (itemContent && itemInfo.nodeImg) {

        let newImgBlobLink = itemInfo.nodeImg.replace(/\?sv.*$/, "") + "?" + this.mindMapStore.mindMapBlobSas;

        // 使用正則表達式匹配并替换 src 中的 URL
        itemContent = itemContent.replace(/(<img[^>]+src=")[^"]+("[^>]*>)/, `$1${newImgBlobLink}$2`);
      }

      let beforeNodeID = item?.beforeNodeID; //先明確指示要搬到誰前面，以免協議太快先後亂掉
      let parentNodeID = item?.parentNodeID;
      let direction = item?.direction;

      switch (actionItem.action) {
        case "add": {
          let addTargetNode = this.jm.get_node(item.parentNodeID)
          if (addTargetNode != null && this.jm.get_node(nodeID) == null) {
            this.jm.add_node(
              addTargetNode,
              nodeID,
              itemContent,
              itemInfo,
              direction
            );
            const newNode = this.jm.get_node(nodeID)
            this.setCurrentNameLabel(item, newNode._data.view)
            setTimeout(() => {
              if (!this.showNodeMenu) {
                this.jm.show(this.jm.get_data("node_tree"))
                this.jm.view.relayout()
              }
            }, 200);
          }
          break;
        }
        case "delete": {
          let deleteTargetNode = this.jm.get_node(nodeID);
          this.setCurrentNameLabel(item, deleteTargetNode._data.view)
          if (deleteTargetNode != null) {
            this.jm.remove_node(deleteTargetNode);
          }
          break;
        }
        case "update": {
          let updateTargetNode = this.jm.get_node(nodeID);
          if (updateTargetNode != null) {
            this.jm.update_node(nodeID, itemContent, itemInfo);
            this.setCurrentNameLabel(item, updateTargetNode._data.view)
            setTimeout(() => {
              if (!this.showNodeMenu) {
                this.jm.show(this.jm.get_data("node_tree"))
                this.jm.view.relayout()
              }
            }, 200);
          }
          break;
        }
        case "copy": {
          let copyTargetNode = this.jm.get_node(beforeNodeID);
          if (copyTargetNode != null) {
            this.jm.insert_node_after(
              copyTargetNode,
              nodeID,
              copyTargetNode.topic,
              itemInfo,
              copyTargetNode.direction
            );
            let copyNode = this.jm.get_node(nodeID)
            this.setCurrentNameLabel(item, copyNode._data.view)
            setTimeout(() => {
              if (!this.showNodeMenu) {
                this.jm.show(this.jm.get_data("node_tree"))
              }
            }, 200);
          }
          break;
        }
        case "moveToBottom":
        case "moveToTop": {
          let moveTargetNode = this.jm.get_node(nodeID);
          let preNode = this.jm.get_node(beforeNodeID);
          let parentNode = this.jm.get_node(parentNodeID);
          if (
            moveTargetNode != null &&
            preNode != null &&
            parentNode != null
          ) {
            this.jm.move_node(
              moveTargetNode,
              preNode.id,
              parentNode.id,
              moveTargetNode.direction
            );
            let afterMoveNode = actionItem.action == 'moveToBottom' ? this.jm.get_node(preNode.id) : this.jm.get_node(moveTargetNode.id)
            this.setCurrentNameLabel(item, afterMoveNode._data.view)
          }
          break;
        }
      }
      this.jm.disable_edit(); //關閉編輯
      this.isModified = true;
    },
    init_data() {
      this.jm = new jsMind(this.options);
      this.jm.show(this.mind);
    },

    zoomIn: function () {
      this.jm.view.zoomIn();
    },

    zoomOut: function () {
      this.jm.view.zoomOut();
    },
    viewBigImg() {
      this.currentViewImg = this.currentImgUrl
      this.showBigImg = true;
      this.showNodeMenu = false;
    },
    clickImg() {
      this.showBigImg = false
    },
    handleEdit() {
      if (this.jm.get_selected_node() !== null) {
        this.isCurrentSelectRoot = this.jm.get_selected_node().isroot;
        this.isCurrentNodeCanBeDelete =
          this.jm.get_selected_node().children.length == 0;
        this.isCurrentNodeCanMoveUp =
          this.jm.find_node_before(this.jm.get_selected_node()) != null;
        this.isCurrentNodeCanMoveDown =
          this.jm.find_node_after(this.jm.get_selected_node()) != null;

        let tempDiv = document.createElement("div");
        tempDiv.innerHTML = this.jm.get_selected_node().topic;
        this.currentNodeHasImg = tempDiv.querySelector("img") != null;

        if (this.currentNodeHasImg) {
          this.currentImgUrl = tempDiv.querySelector("img").src;
        }

        this.nodeEditBy = this.jm.get_selected_node().data?.fromInfo
          ? this.jm.get_selected_node().data?.fromInfo
          : { seat: "", name: "" };
        this.openNode("edit");
        this.showNodeMenu = false;
        this.showSelectedNote = false;
        this.nodeTextNote = "";
      } else {
        this.showNodeMenu = false;
        this.showSelectedNote = false;
        this.nodeTextNote = "";
      }
    },
    setCurrentNameLabel(item, nodePos) {
      this.showNameLabel = true
      this.currentNameLabel = item?.fromInfo.seat == "wb" ? item?.fromInfo.name : item?.fromInfo.seat + "\n" + item?.fromInfo.name
      this.nameLabelX = nodePos.abs_x + nodePos.width - 10
      this.nameLabelY = nodePos.abs_y - 10
    },
    handleNodeMenu(e) {
      if (this.jm.get_selected_node() !== null) {
        this.showNameLabel = false
        this.isCurrentSelectRoot = this.jm.get_selected_node().isroot;
        this.isCurrentNodeCanBeDelete =
          this.jm.get_selected_node().children.length == 0;
        this.isCurrentNodeCanMoveUp =
          this.jm.find_node_before(this.jm.get_selected_node()) != null;
        this.isCurrentNodeCanMoveDown =
          this.jm.find_node_after(this.jm.get_selected_node()) != null;

        let tempDiv = document.createElement("div");
        tempDiv.innerHTML = this.jm.get_selected_node().topic;
        this.currentNodeHasImg = tempDiv.querySelector("img") != null;

        if (this.currentNodeHasImg) {
          this.currentImgUrl = tempDiv.querySelector("img").src;
        }

        this.nodeEditBy = this.jm.get_selected_node().data?.fromInfo
          ? this.jm.get_selected_node().data?.fromInfo
          : { seat: "", name: "" };

        if (this.jm.get_selected_node().data?.note) {
          this.nodeTextNote = this.jm.get_selected_node().data?.note;
          this.showSelectedNote = true;
        } else {
          this.showSelectedNote = false;
        }
        this.showNodeMenu = true;
        let viewportHeight = window.innerHeight;
        // 距离底部的距离
        let distanceToBottom = viewportHeight - e.clientY;
        this.posX = e.clientX + 20;

        if (distanceToBottom < 200) {
          if (viewportHeight > 500) {
            this.posY = e.clientY - 200;
          } else if (viewportHeight < 500) {
            this.posY = Math.floor((viewportHeight - 240) / 3);
          }
        } else {
          this.posY = e.clientY;
        }

      } else {
        this.showNodeMenu = false;
        this.showSelectedNote = false;
        this.nodeTextNote = "";
      }
    },
    confirm() {
      if (this.ctrlNodeMode == "add") {
        this.addNodeToSelectNode();
      } else if (this.ctrlNodeMode == "edit") {
        this.updateSelectNode();
      }
    },
    updateSelectNode() {
      let itemInfo = {
        fromInfo: this.fromInfo,
        note: this.nodeTextNote,
        lineColor: this.currentLineColor,
        "background-color": this.currentNodeColor, //按照jsMind套件中的命名
        nodeText: this.nodeText,
        nodeImg: "",
        nodeLink: "",
      };
      let itemContent = `<p>${this.nodeText}</p>`;
      if (this.currentImgUrl != "") {
        itemContent += `<img class='mindmap-img' src="${this.currentImgUrl}"/><br>`;
        itemInfo.nodeImg = this.currentImgUrl;
      }
      if (this.nodeTextURL.trim() != "") {
        let url = this.fixURL(this.nodeTextURL);
        itemContent += `<a class="node-url" target="_blank" href="${url}">${this.jmNodeAttechIcon}</a>`;
        itemInfo.nodeLink = url;
      }

      this.jm.enable_edit(); //啟用編輯
      if (this.jm.get_node(this.currentNode.id)) {
        this.jm.update_node(this.currentNode.id, itemContent, itemInfo);
        let item = {
          nodeID: this.currentNode.id,
          nodeContent: itemContent,
          ...itemInfo,
        };
        this.setFluidShareMap("update", item, "update");
        // this.setFluidShareTreeMindRoot()
      } else {
        this.ssage.info(this.$t("mindmap.編輯失敗"));
      }

      this.jm.disable_edit(); //關閉編輯
      this.showNodeMenu = false;
      this.showAddNoteTool = false;

      this.jm.select_clear(); //清除選中狀態，避免白色字體殘留
      this.isModified = true;
    },
    openNode(mode) {
      this.ctrlNodeMode = mode;
      this.currentNode = this.jm.get_selected_node();
      this.showMoreSetting = false;
      if (this.currentNode) {
        if (mode === "add") {
          this.nodeText = "";
          this.currentImgUrl = "";
          this.nodeTextURL = "";
          this.nodeTextNote = "";
          this.isCurrentSelectRoot = this.currentNode.isroot;
          this.currentLineColor = this.currentNode.data?.lineColor;
          this.currentNodeColor = this.currentNode.data?.["background-color"];
        } else if (mode === "edit") {
          this.isCurrentNodePreEditByMe = this.currentNode.data?.fromInfo?.seat === this.fromInfo?.seat
          this.currentLineColor = this.currentNode.data?.lineColor;
          this.currentNodeColor = this.currentNode.data?.["background-color"];
          let tempDiv = document.createElement("div");
          tempDiv.innerHTML = this.currentNode.topic;
          let filteredString = tempDiv.querySelector("p").textContent;
          this.nodeText = filteredString;

          let imgElement = tempDiv.querySelector("img");
          // 獲取圖片的 src 屬性值
          this.currentImgUrl = imgElement ? imgElement.src : "";

          let urlElement = tempDiv.querySelector("a");
          this.nodeTextURL = urlElement ? urlElement.href : "";

          this.isCurrentSelectRoot = false;
          this.nodeTextNote = this.currentNode?.data?.note || "";
        }
        this.showAddNoteTool = true;
        this.$nextTick(() => {
          document.getElementById("node-text-focus")?.focus();
          document.getElementById("node-text-focus-large")?.focus();
        });
      } else {
        this.$Message.info(this.$t("mindmap.請先選取節點"));
      }
    },
    fixURL(url) {
      // 使用正則表達式檢查URL是否以"http://"或"https://"開頭
      if (!/^https?:\/\//i.test(url)) {
        // 如果沒有，添加"http://"到URL的開頭
        url = "http://" + url;
      }
      return url;
    },
    addNodeToSelectNode() {
      this.jm.enable_edit(); //啟用編輯
      let itemInfo = {
        fromInfo: this.fromInfo,
        note: this.nodeTextNote,
        lineColor: this.currentLineColor,
        "background-color": this.currentNodeColor, //按照jsMind套件中的命名
        nodeText: this.nodeText,
        nodeImg: "",
        nodeLink: "",
      };
      let nodeID = this.genUUID();
      let itemContent = `<p>${this.nodeText}</p>`;
      if (this.currentImgUrl != "") {
        itemContent += `<img class='mindmap-img' src="${this.currentImgUrl}"/><br>`;
        itemInfo.nodeImg = this.currentImgUrl;
      }
      if (this.nodeTextURL.trim() != "") {
        let url = this.fixURL(this.nodeTextURL);
        itemContent += `<a class="node-url" target="_blank" href="${url}">${this.jmNodeAttechIcon}</a>`;
        itemInfo.nodeLink = url;
      }
      if (this.jm.get_node(this.currentNode.id) != null) {
        this.jm.add_node(
          this.currentNode,
          nodeID,
          itemContent,
          itemInfo,
          this.direction
        );
        let item = {
          direction: this.direction,
          parentNodeID: this.currentNode.id,
          nodeID: nodeID,
          nodeContent: itemContent,
          ...itemInfo,
        };
        this.setFluidShareMap("add", item, "add");
      } else {
        this.$Message.info(this.$t("mindmap.新增失敗"));
      }

      this.jm.disable_edit(); //關閉編輯
      this.showNodeMenu = false;
      this.showAddNoteTool = false;
      this.jm.select_clear(); //清除選中狀態，避免白色字體殘留
      this.isModified = true;
    },
    deleteSelectNode() {
      //僅可刪除沒有子節點的節點
      this.jm.enable_edit(); //啟用編輯

      this.currentNode = this.jm.get_selected_node();
      if (this.currentNode == null) {
        this.$Message.info(this.$t("mindmap.請先選取節點"));
        return;
      }
      if (this.currentNode.children.length == 0) {
        let item = {
          nodeID: this.currentNode.id,
          fromInfo: this.fromInfo,
        };
        this.setFluidShareMap("delete", item, "delete");
        this.jm.remove_node(this.currentNode);
        // this.setFluidShareTreeMindRoot()
      } else {
        this.$Message.info(this.$t("mindmap.不可刪除有子項目的節點"));
      }

      this.jm.disable_edit(); //關閉編輯
      this.showNodeMenu = false;
      this.isModified = true;
    },
    genUUID() {
      let d = Date.now();
      //Performance.now() 亞毫秒級的時間戳記
      if (
        typeof performance !== "undefined" &&
        typeof performance.now === "function"
      ) {
        d += performance.now();
      }
      return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        //xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },
    moveNode(type) {
      this.jm.enable_edit(); //啟用編輯

      this.currentNode = this.jm.get_selected_node();
      if (this.currentNode == null) {
        this.$Message.info(this.$t("mindmap.請先選取節點"));
        return;
      }
      if (type == "up") {
        let preNode = this.jm.find_node_before(this.currentNode);
        if (preNode == null) {
          this.$Message.info(this.$t("mindmap.已是最上面"));
        } else {
          this.jm.move_node(
            this.currentNode,
            preNode.id,
            preNode.parent.id,
            this.currentNode.direction
          );
          let item = {
            direction: this.currentNode.direction,
            parentNodeID: preNode.parent.id,
            beforeNodeID: preNode.id,
            nodeID: this.currentNode.id,
            fromInfo: this.fromInfo,
          };
          this.setFluidShareMap("moveToTop", item, "moveToTop");
          // this.setFluidShareTreeMindRoot()
        }
      } else if (type == "down") {
        let nextNode = this.jm.find_node_after(this.currentNode);
        if (nextNode == null) {
          this.$Message.info(this.$t("mindmap.已是最下面"));
        } else {
          this.jm.move_node(
            nextNode,
            this.currentNode.id,
            this.currentNode.parent.id,
            nextNode.direction
          );

          let item = {
            direction: nextNode.direction,
            parentNodeID: this.currentNode.parent.id,
            beforeNodeID: this.currentNode.id,
            nodeID: nextNode.id,
            fromInfo: this.fromInfo,
          };
          this.setFluidShareMap("moveToBottom", item, "moveToBottom");
          // this.setFluidShareTreeMindRoot()
        }
      }

      this.jm.disable_edit(); //關閉編輯
      this.showNodeMenu = false;
      this.isModified = true;
    },
    logout() {
      this.$mobileirs.showMindMap = false;
    },
    async save() {
      let data = {
        "mindmapName": this.jm.get_data("node_tree")?.data.nodeText,
        "mindmapEditor": {
          "id": this.fromInfo.seat,
          "name": this.fromInfo.name
        },
        "mindmapData": JSON.stringify(this.jm.get_data("node_tree")?.data),
        "id": this.route.query.id
      };

      try {
        let res = await api.mindmapAPI.updateMindMap(data);
        console.log(res, "res");
        this.iviewMessage.success("保存成功");
      } catch (error) {
        console.error("保存失败", error);
        this.iviewMessage.error("保存失败，请稍后再试");
      }
    },
    isSentByMe(item) {
      return item?.from === this.fromInfo?.seat;
    },
    calScaleRatio(imgTargetWidth, imgTargetHeight, bgWidth, bgHeight) {
      let ratio = 0;
      if (imgTargetWidth > bgWidth && imgTargetHeight < bgHeight) {
        ratio = bgWidth / imgTargetWidth;
      } else if (imgTargetWidth < bgWidth && imgTargetHeight > bgHeight) {
        ratio = bgHeight / imgTargetHeight;
      } else if (imgTargetWidth < bgWidth && imgTargetHeight < bgHeight) {
        ratio =
          bgWidth / imgTargetWidth < bgHeight / imgTargetHeight
            ? bgWidth / imgTargetWidth
            : bgHeight / imgTargetHeight;
      } else {
        //1.判斷要縮哪一邊
        let imgWidthMinusCanvasWidth = imgTargetWidth - bgWidth;
        let imgHeightMinusCanvasHeight = imgTargetHeight - bgHeight;
        //console.log(imgWidthMinusCanvasWidth,imgHeightMinusCanvasHeight)
        if (imgWidthMinusCanvasWidth > imgHeightMinusCanvasHeight)
          ratio = bgWidth / imgTargetWidth;
        else {
          ratio = bgHeight / imgTargetHeight;
        }
      }
      let screenOffsetX = (imgTargetWidth * ratio - bgWidth) / 2; //整體置中與白邊的左右差距
      let screenOffsetY = (imgTargetHeight * ratio - bgHeight) / 2; //整體置中與白邊的上下差距

      let output = {
        ratio: ratio,
        screenOffsetX: screenOffsetX,
        screenOffsetY: screenOffsetY,
      };

      return output;
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    triggerInputMD() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    InputMD(event) {
      const file = event.target.files[0];
      if (file) {
        
        const reader = new FileReader();
        reader.onload = (e) => {
          const mdData = e.target.result;
          console.log(mdData); // 在這裡可以處理 Markdown 文件的內容
          this.mind = md.mdToJsMind(mdData, this)
          this.jm.show(this.mind);
          this.isModified = true;
         
        };
        reader.readAsText(file);
      }
    },
  },
};
</script>

<style lang="less">
#mindmap-img-editor {
  margin: 0 !important;
  z-index: 1010 !important;
  background: #fff !important;
  position: relative !important;



  .canvas-qcard {
    top: 0 !important;
  }

  .q-dialog__inner--minimized>div {
    max-width: none !important;
    max-height: none !important;
  }

  .q-dialog__inner {
    padding: 0 !important;
  }
}

.mindmap {
  jmnode {
    max-width: 150px;
    font-size: 0;

    p {
      white-space: normal;
      word-break: keep-all;
      word-wrap: break-word;
      overflow-wrap: break-word;
      font-size: 16px !important;
    }

    text-align: center !important;

    .node-url {
      cursor: pointer;
      display: flex;
      align-items: center;
      /* 水平居中对齐 */
      justify-content: center;

      /* 垂直居中对齐 */
      .node-url-icon {
        margin-top: 5px;

        path {
          fill: blue !important;
        }
      }

      &:hover {
        .node-url-icon {
          path {
            fill: rgb(255, 255, 255) !important;
          }
        }
      }
    }

    .mindmap-img {
      margin: 5px 0px;
    }

    .myNoteName {
      font-size: 40px;
    }
  }
}
</style>
<style lang="less" scoped>
@import '../assets/color.less';
.left-hint{
  left: 10px !important;
}
.saving-hint {
  position: fixed;
  top: 30px;
  left: 80px;
  color: rgba(0, 0, 0, 0.5);
  z-index: 1002;
}

.saved-hint {
  position: fixed;
  top: 30px;
  left: 80px;
  color: @light-color;
  z-index: 1001;
}

// Import Quasar css
@import "quasar/dist/quasar.css";

svg {
  display: unset //不明被哪個套件影響;;
}

input[type="radio"],
input[type="checkbox"] {
  visibility: hidden;
  margin-left: -10px;
}

@import "../assets/mindmap.less";
</style>