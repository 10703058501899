export default {
  比例: "比例",
  //AddImageBox
  檔案圖片: "文件图片",
  選取圖片作為物件: "选取图片作为对象",
  背景設置: "背景设置",
  選取圖片作為背景: "选取图片作为背景",
  //AddTextBox & TextSetting
  文字工具: "文字工具",
  編輯文字便利貼: "编辑",
  openURL_NewTab: "于新分页检视连结",
  刪除文字便利貼: "删除",
  輸入文字: "输入文字",
  字體大小: "字体大小",
  字體顏色: "字体颜色",
  顏色: "颜色",
  便利貼顏色: "便利贴颜色",
  確定: "确定",
  取消: "取消",
  //PenSetting
  畫筆: "画笔",
  通用畫筆: "通用画笔",
  高光筆: "高光笔",
  鐳射筆: "镭射笔",
  大小: "大小",
  //ShapeSetting
  圖形: "图形",
  邊框: "边框",
  填充: "填充",
  箭頭: "箭头",
  直線: "直线",
  圓形: "圆形",
  橢圓: "椭圆",
  矩形: "矩形",
  三角形: "三角形",
  星形: "星形",
  所選圖片過大: "所选图过大(应小于3MB)，请重新选择！",
  打開點擊的物件連結: "打开点击的对象连结?",
  多連結詢問: "侦测到点击的对象含有多个有效连结，请选择一个动作",
  目前沒有動作可復原: "目前没有动作可复原",
  復原我最近一次刪除動作: "复原我最近一次删除动作!",
  ModifyHint: "内容有修改过，离开将不保存，您还想离开吗？",
  物件化畫板: "对象化画板",
  重新整理圖層: "重新整理图层!",
  刪除鎖定物件提示字: "删除了教师锁定的对象，可点击复原或重开教材!",
  協作無法刪除鎖定提示字: "协作无法删除教师锁定的对象!",
  複製: "克隆",
  刪除: "删除",
  移到最上層: "移到最上层",
  移到最下層: "移到最下层",
};
