<template>
  <div class="selectmulti-menu relative mt-2" ref="menuMulti">
    <button
      type="button"
      class="select-btn"
      @click="showDropDown = !showDropDown"
    >   
       
      <span
        v-for="(item, index) in currentSelect"
        class="display-select"
        v-show="currentSelect != '' && !selectAll"
        :key="item"
        >{{ item=='0'?"通用":item }}{{ index < currentSelect.length - 1 ? "," : "" }}</span
      >
      <span class="ml-3 truncate font-bold" v-show="selectAll">{{
        $t("全部")
      }}</span>
      <span
        class="ml-3 truncate font-bold"
        v-show="!selectAll && currentSelect == ''"
        >{{ "請選擇..." }}</span
      >
      <svg-icon
        class="arrow-icon"
        icon-class="arrow-down "
        v-show="!showDropDown"
      />
      <svg-icon
        class="arrow-icon arrow-icon-up text-purple-600"
        icon-class="arrow-down"
        v-show="showDropDown"
      />
    </button>
    <!--下拉-->
    <ul v-show="showDropDown" class="dropdown-wrap">
      <label class="dropdown-item flex items-center" :for="$t('全部')"  >
        <input
          type="checkbox"
          :value="$t('全部')"
          v-model="selectAll"
          @change="toggleSelect()"
          :id="$t('全部')"
        />
        <p class="ml-2">{{ $t("全部") }}</p>
      </label>

      <label
        v-for="(item, index) in category"
        class="flex items-center dropdown-item"
        :for="item.value"
        :key="index + item"
      >
        <input
          type="checkbox"
          :id="item.value"
          :value="item.value"
          v-model="currentSelect"
        />
        <p class="ml-2">{{ item.label }}</p>
      </label>
    </ul>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
import { watch } from "vue";
export default {
  name: "selectmulti-menu",
  components: { SvgIcon },
  props: ["category", "defaultSelect"],

  data() {
    return {
      selectAll: true,
      showDropDown: false,
      currentSelect: [],
    };
  },

  mounted() {
    this.currentSelect=this.category.map(item=>item.value)
    document.addEventListener("click", this.closeMenu);
    watch(
      () => this.currentSelect,
      (newValue) => {
        if (newValue&&this.selectAll==true) {
          if (newValue.length > 0 && newValue.length < this.category.length) {
            this.selectAll = false;
          }
        }
      }
    )
    //   watch(
    //     () => this.selectAll,
    //     (newValue) => {
    //       newValue ? this.setSelectAll() : this.clearSelectAll();
    //     }
    //   );
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeMenu);
  },
  methods: {
    toggleSelect(){
        this.currentSelect = this.selectAll?this.category.map(item=>item.value):[];
    },
    
    closeMenu(event) {
      // 檢查點擊事件是否在選單內部
      if (this.$refs?.menuMulti) {
        if (!this.$refs?.menuMulti?.contains(event.target)) {
          this.showDropDown = false;
        }
      }
    },
  },
};
</script>

<style lang="less">
.selectmulti-menu {
  .select-btn {
    @apply bg-white !important;
    @apply relative w-full cursor-pointer rounded-md py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .display-select {
      @apply ml-3 font-bold inline-block;
    }
    .arrow-icon {
      position: absolute;
      right: 12px;
      top: 14px;
    }

    .arrow-icon-up {
      top: 12px !important;
      transition: 1s !important;
      transform: scaleY(-1) !important;
    }
  }

  .dropdown-wrap {
    @apply absolute mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
    z-index: 999;

    .dropdown-item {
      &:hover {
        @apply bg-slate-200 !important;
      }

      @apply relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900;
    }
  }
}
</style>
