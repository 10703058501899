import { createRouter, createWebHistory } from "vue-router";

import TestPage from "./views/TestPage.vue";
// import Home from "./views/Home.vue";
import HomePage_Taoyuan from "./views/HomePage_Taoyuan.vue";
import HomePage_Activity from "./views/HomePage_Activity.vue";
import CatePage from "./views/CatePage.vue";
import Content from "./views/Content.vue";
import Profile from "./views/Profile.vue";
import Setting from "./views/Setting.vue";
import Management from "./views/Management.vue";
import Reported from "./views/Reported.vue"
import GroupProfile from "./views/GroupProfile.vue"
import PackContent from "./views/PackContent.vue"
import AccountInfo from "./views/Authorize/AccountInfo.vue"
import Binding_CheckAccount from "./views/Authorize/Binding_CheckAccount.vue"
import Binding_Choose from "./views/Authorize/Binding_Choose.vue"
import Activity_List from "./views/Activity/Activity_List.vue"
import Activity_Info from "./views/Activity/Activity_Info.vue"
import Activity_Data from "./views/Activity/Activity_Data.vue"
import MindMapHome from "./views/MindMapHome.vue"
import MindMap from "./components/MindMap.vue"
import Activity_CreateClass from "./views/Activity/Activity_CreateClass.vue"



const routes = [
  {
    path: "/testPage",
    name: "TestPage",
    component: TestPage,
    meta: { requiresAuth: false },
  },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  //   meta: { requiresAuth: false },
  // },
  {
    path: "/",
    name: "Home",
    component: HomePage_Taoyuan,
    meta: { requiresAuth: false,title: '桃園市HiTeach授權活動平台' },
  },
  {
    path: "/HomePage_Taoyuan",
    name: "HomePage_Taoyuan",
    component: HomePage_Taoyuan,
    meta: { requiresAuth: false },
  },
  {
    path: "/HomePage_Activity",
    name: "HomePage_Activity",
    component: HomePage_Activity,
    meta: { requiresAuth: false },
  },//HomePage_Activity
  {
    path: "/AccountInfo",
    name: "AccountInfo",
    component: AccountInfo,
    meta: { requiresAuth: false },
  },
  {
    path: "/Binding_CheckAccount",
    name: "Binding_CheckAccount",
    component: Binding_CheckAccount,
    meta: { requiresAuth: false },
  },
  {
    path: "/Binding_Choose",
    name: "Binding_Choose",
    component: Binding_Choose,
    meta: { requiresAuth: false },
  },  
  {
    path: "/Activity_List",
    name: "Activity_List",
    component: Activity_List,
    meta: { requiresAuth: false },
  }, 
  {
    path: "/Activity_Info",
    name: "Activity_Info",
    component: Activity_Info,
    meta: { requiresAuth: false,title: '全國閱讀好小子學藝競賽' },
  },
  {
    path: "/goodkid",
    name: "goodkid",
    component: Activity_Info,
    meta: { requiresAuth: false,title: '全國閱讀好小子學藝競賽'  },
  },
  {
    path: "/goodkid/Activity_CreateClass",
    name: "Activity_CreateClass",
    component: Activity_CreateClass,
    meta: { requiresAuth: false,title: '全國閱讀好小子學藝競賽'  },
  },
  {
    path: "/Activity_Data",
    name: "Activity_Data",
    component: Activity_Data,
    meta: { requiresAuth: false },
  },
  {
    path: "/:type",
    name: "CatePage",
    component: CatePage,
    meta: { requiresAuth: false },
  },
  //  {
  //   path: "/Activity/:type/:id",
  //   name: "Activity_Info",
  //   component: Activity_Info,
  //   meta: { requiresAuth: false },
  // },
  {
    path: "/content/:type/:id",
    name: "Content",
    component: Content,
    meta: { requiresAuth: false },
  },
  {
    path: "/profile/:id",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: false },
  },
  {
    path: "/group/:id",
    name: "GroupProfile",
    component: GroupProfile,
    meta: { requiresAuth: false },
  },
  {
    path: "/pack/:id",
    name: "PackContent",
    component: PackContent,
    meta: { requiresAuth: false },
  },
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    meta: { requiresAuth: true },
  },
  {
    path: "/management",
    name: "Management",
    component: Management,
    meta: { requiresAuth: true },
  },
  {
    path:"/reported",
    name:"Reported",
    component:Reported,
    meta: { requiresAuth: true },
  },
  {
    path: "/mindmaphome",
    name: "MindMapHome",
    component: MindMapHome,
    meta: { requiresAuth: false },
  },
  {
    path: "/mindmap",
    name: "MindMap",
    component: MindMap,
    meta: { requiresAuth: false },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'HiTeach授權活動平台';
  next();
});

export default router;
