<template>
  <div class="home-page div-centerY">    
    <div class="twoButton div-center">
      <div class="chooseDiv">
        <div class="button" @click="goInfo()">
          <img class="otherPic1" src="../assets/img/bean/bean_03.png" alt="">
          <img class="buttomImg" src="../assets/img/area-01.png" alt="">
          <div class="pngText">
            {{ $t("eventPage.applyCheck") }}<br>
            <strong>{{ $t("eventPage.authorization") }}</strong>
          </div>
        </div>
        <div class="textBox">
          {{ $t("eventPage.entry1") }}<br>
          {{ $t("eventPage.getAuthorization") }}
        </div>
      </div>
      <div class="chooseDiv">
        <div class="button" @click="ActivityFun">
          <img class="otherPic2" src="../assets/img/bean/bean_01.png" alt="">
          <img class="buttomImg" src="../assets/img/area-01.png" alt="">
          <div class="pngText">
            {{ $t("eventPage.check") }}<br>
            <strong>{{ $t("eventPage.event") }}</strong>
          </div>
        </div>
        <div class="textBox">
          {{ $t("eventPage.competition") }}<br>
          {{ $t("eventPage.registration") }}
        </div>
      </div>
    </div>
    <img class="peoplePic" src="../assets/img/people.png" alt="">
  </div>
</template>

<script setup>
import { inject } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus';
import profile from "../api/profile";
import {Base64} from 'js-base64'

const routers = useRouter();


function routerskip(val) {
      routers.push(val);
    }
function goInfo() {
  if (sessionStorage.getItem("open_name")) {
    routerskip("/AccountInfo");
  } else {
    sessionStorage.setItem("info","1");// 如果是從申請按鈕進來的  紀錄一下 以便完成綁定之後直接到申請頁
    let urlhost = location.host == "localhost:5005" ? "testHT.teammodel.net" : location.host
    let data = {
       client_id: profile.clientID["global"],
       op_redirecturi: "https://" + urlhost + "/Binding_CheckAccount"
    }
    let datastr = encodeURIComponent(Base64.encode(JSON.stringify(data)));
    //location.href = "https://account.teammodel.net/quickopencode/educloudtw?client_id=" + profile.clientID["global"] + "&callback=https://" + urlhost + "/Binding_CheckAccount";
    //let url = "https://oidc.tanet.edu.tw/onesteplogin?Auth_Request_RedirectUri=https://account.teammodel.net/quickopencode/educloudtw/"+profile.clientID["global"]+"?op_redirecturi="+Base64.encode("https://" + urlhost + "/Binding_CheckAccount")+"&Auth_Request_State=educloudtw&Auth_Request_Response_Type=code&Auth_Request_Client_ID=c58722f58275a8c81fcc5ed6b94f3e7b&Auth_Request_Nonce=aaaaa&Auth_Request_Scope=openid+email+profile+eduinfo&local=true";
    //let redirectUri ='https://account.teammodel.net/quickopencode/educloudtw/'+profile.clientID["global"]+"?op_redirecturi="+Base64.encode("https://" + urlhost + "/Binding_CheckAccount");
    //let url = 'https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=' + redirectUri + '&scope=openid+email+profile+eduinfo&state=educloudtw&nonce=aaaaa';
    let url = "https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=https://account.teammodel.net/&scope=openid+email+profile+eduinfo&state="+datastr+"&nonce=aaaaa";
    location.href = url;

    //https://oidc.tanet.edu.tw/onesteplogin?Auth_Request_RedirectUri=https://account.teammodel.net/quickopencode/educloudtw/4a6fd5c8-dfe2-4aef-ad9c-d5cb04488ae3?op_redirecturi=aHR0cHM6Ly9odGNvbW11bml0eS1yYy50ZWFtbW9kZWwubmV0L0JpbmRpbmdfQ2hlY2tBY2NvdW50&Auth_Request_State=educloudtw&Auth_Request_Response_Type=code&Auth_Request_Client_ID=c58722f58275a8c81fcc5ed6b94f3e7b&Auth_Request_Nonce=aaaaa&Auth_Request_Scope=openid+email+profile+eduinfo&local=true

  }

}

//點擊活動的事件
const t = inject('$t');
function ActivityFun(){
  ElMessageBox.alert('暫無活動', t('eventPage.notification'), {
    confirmButtonText: 'OK',
    callback: () => {//action
      // if (action === 'confirm') {
      // }
    },
  });
}    

</script>

<style lang="less">
.div-center{/*水平置中*/
  display: flex; 
  justify-content: center; 
  text-align:center;
  width:100%;
}
.div-centerY{ /*垂直置中*/
  display: flex;    
  align-items: center;
  flex-direction: column;  //有時可能需要在加這個屬性
}
.home-page {
  .twoButton{
    margin-top: 150px;
  }
  .chooseDiv{
    margin: 0 50px;   
    .button{
      position: relative;
      cursor: pointer;//滑鼠變成小手
      .pngText{
        position: absolute;
        transform: translate(50%, -150%);
        color: white;
        font-size: 20px;
      }
      .buttomImg{
        width: 250px;
        display: block;
      }
      .otherPic1{
        position: absolute;
        width: 100px;
        top: -80px; /* 根据需要调整位置 */
        left: -50px; /* 水平居中 */
      }
      .otherPic2{
        position: absolute;
        width: 100px;
        bottom: -70px; /* 根据需要调整位置 */
        right: -100px; /* 水平居中 */
      }
    }
    .textBox{
      margin-top: 10px;
      line-height:30px; 
    }
  }
  .peoplePic{
    margin-top: 30px;
    height: 320px;
  }
}
</style>
