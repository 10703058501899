<template>
  <div class="shape-setting">
    <p class="menu-title">{{ $t('konvaCanvas.圖形') }}</p>
    <div class="shapes_box">
      <div v-for="(items, index) in shapeList" :key="index" class="shapes_icon" :title="items.title" @click="shapesSelect(items, index)" :style="currentIndex == index ? 'color:' + color2 : ''">
        <span v-if="items.fill == false">
          <svg-icon v-if="items.value == 'long-arrow-alt-right'" icon-class="add_arrow2" class="fill-icon" style="position:relative;left:3px; " />
          <q-icon :name="items.icon" v-if="items.value == 'line'" style="transform: rotate(-45deg); position:relative;top:3px;" />
          <q-icon :name="items.icon" v-else-if="items.value == 'ellipse'" style="transform: scaleX(1.3);" />
          <q-icon :name="items.icon" v-if="items.value != 'long-arrow-alt-right' && items.value != 'ellipse' && items.value != 'line'" style="position:relative;top:2px;"/>
        </span>
        <span v-if="items.fill == true">
          <svg-icon class="fill-icon svg-fill-icon" :icon-class="items.icon" />
        </span>
      </div>
    </div>
    <br />
    <!-- <div class="ColorPicker_box">
      <p class="menu-title">{{ $t('konvaCanvas.邊框') }}</p>

      <div class="q-gutter-sm" style="padding:10px 0 2px 0;">
        <div class="color-btn" :style="{ 'border-color': item.val, 'border-width': color2 == item.val ? '5px' : '2px' }" v-for="(item, index) in colorArr2" :key="item.id" v-show="index < 5" @click="borderSelect(item)" />
      </div>
      <div class="q-gutter-sm" style="padding:2px 0;">
        <div class="color-btn" :style="{ 'border-color': item.val, 'border-width': color2 == item.val ? '5px' : '2px' }" v-for="(item, index) in colorArr2" :key="item.id" v-show="index >= 5" @click="borderSelect(item)" />
      </div> -->

    <!--<q-color no-header no-footer default-view="palette" class="my-picker" @change="borderColor" :palette="['#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
    <!-- </div>
    <br /> -->
    <div class="ColorPicker_box ">
      <p class="menu-title">{{ $t('konvaCanvas.填充') }}</p>

      <div class="q-gutter-sm" style="padding:10px 0 2px 0;">
        <div class="color-btn" :class="{ 'select-color': color == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index < 5" @click="colorSelect(item)" />
      </div>
      <div class="q-gutter-sm" style="padding:2px 0;">
        <div class="color-btn" :class="{ 'select-color': color == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index >= 5" @click="colorSelect(item)" />
      </div>

      <!--<q-color v-model="hex" no-header no-footer default-view="palette" class="my-picker" @change="colorSelect" :palette="['#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
    </div>
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon.vue";
import { QIcon } from 'quasar'
import { ref,reactive,inject } from 'vue';
export default {
  name: 'ShapeSetting',
  components: { SvgIcon, QIcon },
  setup() {
    const updateShapeType = inject('updateShapeType');
    const updateShapeColor = inject('updateShapeColor');
    const shapeState=inject('shapeState')
    let currentIndex=ref(0)
    let color=ref('transparent')
    let color2=ref('red')
    let $t=inject('$t')

    const shapeList=reactive([
        { fill: false, icon: 'trending_flat', value: 'long-arrow-alt-right', title: $t('konvaCanvas.箭頭') },
        { fill: false, icon: 'panorama_fish_eye', value: 'circle', title: $t('konvaCanvas.圓形') },
        { fill: false, icon: 'panorama_fish_eye', value: 'ellipse', title: $t('konvaCanvas.橢圓') },
        { fill: false, icon: 'check_box_outline_blank', value: 'square', title: $t('konvaCanvas.矩形') },
        { fill: false, icon: 'change_history', value: 'campground', title: $t('konvaCanvas.三角形') },
        { fill: false, icon: 'star_border', value: 'star', title: $t('konvaCanvas.星形') },
        { fill: false, icon: 'remove', value: 'line', title: $t('konvaCanvas.直線') },
        { fill: true, icon: 'circle-fill', value: 'circle', title:$t('konvaCanvas.圓形') },
        { fill: true, icon: 'ellip-fill', value: 'ellipse', title: $t('konvaCanvas.橢圓') },
        { fill: true, icon: 'rec-fill', value: 'square', title: $t('konvaCanvas.矩形') },
        { fill: true, icon: 'tri-fill', value: 'campground', title: $t('konvaCanvas.三角形') },
        { fill: true, icon: 'star-fill', value: 'star', title: $t('konvaCanvas.星形') }
      ])

    const shapesSelect = (value,index) => {
      currentIndex.value=index
      updateShapeType({
        shapeType: value.value,
        shapeIconType: value.icon,
        shapeFill: value.fill,
        shapeColor: value.fill==true?shapeState.shapeBorderColor:'transparent',
        shapeBorderColor :shapeState.shapeBorderColor
      });
    };

    const colorSelect= (item)=> {
      updateShapeColor({
        shapeColor: shapeList[currentIndex.value].fill==true?item.val:'transparent',
        shapeBorderColor: item.val
      })
      color.value = item.val
      color2.value = item.val
    }
    return {
      shapesSelect,colorSelect,currentIndex,color,color2,shapeList
    };
  },
  data() {
    return {
     
      colorArr: [
        { val: 'red', color: 'red' },
        { val: 'orange', color: 'orange' },
        { val: 'yellow', color: 'yellow' },
        { val: 'cyan', color: 'cyan' },
        { val: '#e0e0e0', color: '#e0e0e0' },
        { val: 'brown', color: 'brown' },
        { val: 'purple', color: 'purple' },
        { val: 'green', color: 'green' },
        { val: '#0000ff', color: '#0000ff' },
        { val: 'black', color: 'black' }
      ],
    
      colorArr2: [
        { val: 'red', color: 'red' },
        { val: 'orange', color: 'orange' },
        { val: 'yellow', color: 'yellow' },
        { val: 'cyan', color: 'cyan' },
        { val: '#e0e0e0', color: '#e0e0e0' },
        { val: 'brown', color: 'brown' },
        { val: 'purple', color: 'purple' },
        { val: 'green', color: 'green' },
        { val: '#0000ff', color: '#0000ff' },
        { val: 'black', color: 'black' }
      ],
    }
  },
}
</script>

<style lang="less">
@import '../../assets/color.less';
.q-color-picker__cube {
  border-radius: 50%;
  box-sizing: border-box;
}
.shape-setting {
  color: @text-color;
  .shapes_box {
    overflow: hidden;
    font-size: 24px;
    margin-top: 10px;
    margin-left: -10px;
  }
  .shapes_icon,
  .shapes_icon_bg {
    width: 16%;
    height: 50px;
    display: block;
    float: left;
  }
  .svg-fill-icon {
    font-size: 24px;
  }
  .shapes_icon > .svg-inline--fa {
    font-size: 18px;
  }
  .shapes_icon_bg > .svg-inline--fa {
    font-size: 20px;
  }

  .ColorPicker_box {
    width: 100%;
  }

  .color-btn {
    width: 24px;
    height: 24px;
    border: 2px solid white;
    margin: 10px;
    display: inline-block;
    box-shadow: 0px 0px 2px gray;
    border-radius: 50%;
  }
  .select-color {
    border: 0px solid rgba(223, 223, 223, 0);
    box-shadow: 0px 0px 2px gray;
  }
  .menu-title {
    font-weight: bolder;
    text-align: left;
    font-size: 14px;
  }
}
</style>
