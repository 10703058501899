<template>
  <div class="group-invitation">
    <div class="main-form">
      <div class="text-gray-900 font-medium text-lg text-center">
        {{ "發送群組邀請" }}
      </div>
      <div class="scroll-wrap">
        <div class="upload-item">
          <label class="search-bar" ref="searchMenu">
            <input :placeholder="$t('搜尋')" v-model="search" />
            <div
              class="delete-btn"
              v-show="search.length !== 0"
              @click="resetSearch()"
            >
              <svg-icon icon-class="close" />
            </div>
            <button
              type="submit"
              class="search-btn"
              @click="searchContent()"
              :class="{ 'dis-search': search.length == 0 }"
            >
              <svg-icon icon-class="search" class="search-icon" />
            </button>
          </label>

          <div
            class="search-result"
            v-show="search != '' && filterUsers != ''"
            ref="searchDropdown"
          >
            <div
              v-for="member in filterUsers"
              :key="member.id"
              class="member-wrap"
              @click="addInvitedList(member)"
            >
              <img :src="member.picture" alt="" />
              <p class="name">{{ member.name }}&nbsp;({{ member.id }})</p>
            </div>
          </div>
          <div
            class="search-result"
            v-show="search != '' && filterUsers == ''"
            ref="searchDropdown"
          >
            <div class="no-data">
              <p v-show="showNoData">{{ $t("暫無資料") }}</p>
              <p class="note">*請確認輸入帳號請確認完全相符，並按下搜尋</p>
            </div>
          </div>
          <div class="invited-list">
            <p class="py-1 font-semibold">邀請名單</p>
            <div
              v-for="member in invitedList"
              :key="member.id"
              class="member-wrap"
            >
              <img :src="member.picture" alt="" />
              <p class="name">{{ member.name }}&nbsp;({{ member.id }})</p>
              <div class="icon-wrap" @click="removeFromInvtedList(member)">
                <div class="remove-icon">
                  <svg-icon icon-class="close" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto mt-5 normal p-4 pt-0">
        <button class="left-btn" @click="confirm()">
          {{ $t("確定") }}
        </button>
        <button class="right-btn" @click="close()">
          {{ $t("取消") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
import api from "@/api";
import { watch,inject } from "vue";
export default {
  components: { SvgIcon },
  name: "group-invitation",
  props: {
    groupMembers: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      search: "",
      filterUsers: [],
      invitedList: [],
      showNoData: false,
    };
  },
  setup() {
    const mountMsg = inject("mountMsg"); //從App注入
    return { mountMsg };
  },
  beforeUnmount() {
    window.removeEventListener("click", () => {});
  },
  async mounted() {
    document.addEventListener("click", this.closeDropDown);

    watch(
      () => this.search,
      () => {
        if(this.search==''){
          this.showNoData=false;
          this.filterUsers = [];
        }
      }
    );
    
  },
  methods: {
    closeDropDown(event) {
      if (this.$refs.searchMenu || this.$refs.searchDropdown) {
        if (
          !this.$refs.searchMenu.contains(event.target) &&
          !this.$refs.searchDropdown.contains(event.target)
        ) {
          this.search = "";
          this.filterUsers = [];
          this.showNoData = false;
        }
      }
    },
    removeFromInvtedList(member) {
      let memberIndex = this.invitedList.findIndex(
        (item) => item.id == member.id
      );
      if (memberIndex !== -1) {
        this.invitedList.splice(memberIndex, 1);
      }
      // console.log(this.invitedList)
    },
    addInvitedList(member) {
      let existId = this.groupMembers.findIndex((item) => item.id == member.id);
      if (existId !== -1) {
        this.mountMsg("成員已在群組中!");
      } else {
        let memberIndex = this.invitedList.findIndex(
          (item) => item.id == member.id
        );
        if (memberIndex == -1) {
          this.invitedList.push(member);
        }
      }
      // console.log(this.invitedList)
    },
    async searchContent() {
      let data=[];
      data.push(this.search)
      try {
        const res = await api.main.getCoreUsers(data)
        this.filterUsers = res
        if(this.filterUsers=='')this.showNoData = true
      }catch(err){
        console.log(err)
      }
    },
    resetSearch() {
      this.search = "";
    },
    close() {
      this.invitedList = [];
      this.$emit("close");
    },
    confirm() {
      let toUserIds = this.invitedList.map((item) => item.id);
      this.$emit("confirm", toUserIds);
      this.invitedList = [];
    },
  },
};
</script>

<style lang="less">
.group-invitation {
  z-index: 999;
  @apply flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800/50;
  .main-form {
    min-width: 40%;
    max-height: 90vh;
    .scroll-wrap {
      @apply p-2 pb-20 px-8;
      width: 100%;
      max-height: 85vh;
      overflow: auto;
    }

    @apply bg-white  rounded-lg relative  flex flex-col pt-4;

    .search-bar {
  @apply relative flex max-w-2xl items-center rounded-lg bg-white ring-1 ring-black ring-opacity-20;
  input {
    @apply w-full h-10 px-4 py-2 text-gray-800 text-xl rounded-lg;
    outline: none;
    &:focus {
      @apply rounded-lg border-2 border-purple-500;
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
    }
  }

  .search-btn {
    @apply flex h-10 w-12 cursor-pointer items-center justify-center rounded-r-lg bg-purple-500 text-white;

    &:active,
    &:hover {
      @apply bg-purple-600 !important;
    }
    .search-icon {
      font-size: 20px;
    }
  }
  .delete-btn {
    @apply absolute mr-2 mt-0 cursor-pointer;
    color: gray;
    right: 50px;
  }
}
    .no-data {
      color: gray;
      padding: 20px;
      .note {
        @apply text-sky-500 text-sm pt-2;
      }
    }
    .member-wrap {
      &:hover {
        cursor: pointer;
        @apply bg-slate-200 !important;
      }
      display: flex;
      align-items: center;
      padding-right: 20px;
      border-bottom: 1px solid rgba(128, 128, 128, 0.2);
      @apply px-5;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .name {
        flex: 1;
        @apply font-bold m-4;
        width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .search-result {
      @apply absolute   max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
      z-index: 1000;
      max-height: 200px;
      overflow: auto;
      z-index: 1000;
      position: absolute;
      background-color: #fff;
      width: 80%;
    }
  }
  .invited-list {
    @apply pt-2;
  }
  .upload-item {
    @apply my-3;
    input[type="text"],
    textarea {
      @apply ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 sm:text-sm sm:leading-6 w-full py-2 px-5 rounded-md mt-2;
    }
    textarea {
      max-height: 180px;
      overflow: auto;
    }
  }
  .title {
    @apply font-bold;
  }
  .normal {
    .left-btn {
      @apply text-white font-bold border py-1 px-4 rounded mr-2 bg-sky-500 border-sky-500;

      &:hover {
        @apply bg-sky-600;
      }
    }

    .right-btn {
      @apply bg-transparent text-gray-700 font-semibold py-1 px-4 border border-gray-500 rounded;

      &:hover {
        @apply bg-sky-100;
      }
    }
  }
}
</style>