<template>
  <div class="ccbadge">
    <div
      class="badgelayout"
      v-for="badge in badges"
      :key="badge.type"
      v-show="shouldShowBadge(badge)"
    >
      <a :rel="badge.rel" :href="badge.href">
        <img
          alt="創用 CC 授權條款"
          style="border-width: 0"
          :src="badge.imgSrc"
        />
      </a>
      <p class="ml-2 text-xs">
        本著作係採用
        <a class="text-sky-600" :rel="badge.rel" :href="badge.href">{{
          badge.text
        }}</a>
        授權.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ccAuth_Badge",
  props: {
    ccShareAgainType: {
      type: Number,
      required: true,
      default: function () {
        return 3;
      },
    },
    ccShareCommercial: {
      type: Boolean,
      required: true,
      default: function () {
        return false;
      },
    },
  },
  computed: {
    badges() {
      return [
        {
          type: 1,
          href: "http://creativecommons.org/licenses/by/4.0/",
          imgSrc: "https://i.creativecommons.org/l/by/4.0/88x31.png",
          text: "創用 CC 姓名標示 4.0 國際 授權條款",
          ccShareCommercial: true,
        },
        {
          type: 1,
          href: "http://creativecommons.org/licenses/by-nc/4.0/",
          imgSrc: "https://i.creativecommons.org/l/by-nc/4.0/88x31.png",
          text: "創用 CC 姓名標示-非商業性 4.0 國際 授權條款",
          ccShareCommercial: false,
        },
        {
          type: 2,
          href: "http://creativecommons.org/licenses/by-nd/4.0/",
          imgSrc: "https://i.creativecommons.org/l/by-nd/4.0/88x31.png",
          rel: "license",
          text: "創用 CC 姓名標示-禁止改作 4.0 國際 授權條款",
          ccShareCommercial: true,
        },
        {
          type: 2,
          href: "http://creativecommons.org/licenses/by-nc-nd/4.0/",
          imgSrc: "https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png",
          rel: "license",
          text: "創用 CC 姓名標示-非商業性-禁止改作 4.0 國際 授權條款",
          ccShareCommercial: false,
        },
        {
          type: 3,
          href: "http://creativecommons.org/licenses/by-sa/4.0/",
          imgSrc: "https://i.creativecommons.org/l/by-sa/4.0/88x31.png",
          rel: "license",
          text: "創用 CC 姓名標示-相同方式分享 4.0 國際 授權條款",
          ccShareCommercial: true,
        },
        {
          type: 3,
          href: "http://creativecommons.org/licenses/by-nc-sa/4.0/",
          imgSrc: "https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png",
          rel: "license",
          text: "創用 CC 姓名標示-非商業性-相同方式分享 4.0 國際 授權條款",
          ccShareCommercial: false,
        },
      ];
    },
  },
  methods: {
    shouldShowBadge(badge) {
      return (
        badge.type === this.ccShareAgainType &&
        badge.ccShareCommercial === this.ccShareCommercial
      );
    },
  },
};
</script>
<style less="less">
.ccbadge {
  @apply mt-10;
  .badgelayout {
    @media (min-width: theme("screens.lg")) {
      display: flex;
    }
    @media (max-width: theme("screens.lg")) {
      display: block;
    }
  }
}
</style>