<template>
  <div id="default-carousel">
    <!-- Carousel wrapper -->
    <div class="imgs-wrap">
      <div v-for="(item, index) in images" :key="item" v-show="currentImgId == index">
        <img :src="item" alt="..." />
      </div>
    </div>
    <!-- Slider indicators -->
    <div class="indicators" v-show="images.length>1">
      <button v-for="(item, index) in images" :key="item + index" type="button" @click="currentImgId = index"></button>
    </div>
    <!-- Slider controls -->
    <button type="button" class="controls group -left-16" @click="prevPage()" v-show="images.length>1">
      <span>
        <svg-icon class="btn-icon" icon-class="arrow-pre" />
      </span>
    </button>
    <button type="button" class="controls group -right-16" @click="nextPage()" v-show="images.length>1">
      <span>
        <svg-icon class="btn-icon" icon-class="arrow-next" />
      </span>
    </button>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
export default {
  components: { SvgIcon },
  name: "img_carousel",
  props: ["images"],
  data() {
    return {
      currentImgId: 0,
    };
  },
  methods: {
    prevPage() {
      if (this.currentImgId > 0) this.currentImgId--;
      else {
        this.currentImgId = this.images.length - 1;
      }
    },
    nextPage() {
      if (this.currentImgId < this.images.length - 1) this.currentImgId++;
      else {
        this.currentImgId = 0;
      }
    },
  },
};
</script>
<style lang="less">
#default-carousel {
  @apply relative mx-auto max-w-2xl;
  .imgs-wrap {
    @apply relative h-56 overflow-hidden rounded-lg sm:h-64 xl:h-80 2xl:h-96;
    img {
      @apply absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2 duration-700 ease-in-out;
    }
  }
  .indicators {
    @apply absolute -bottom-3 mb-5 left-1/2 z-30 flex -translate-x-1/2 space-x-3;
    button {
      @apply h-3 w-3 rounded-full bg-gray-500/50;
    }
  }
  .controls {
    @apply absolute  top-0 z-30 flex h-full cursor-pointer items-center justify-center px-4 focus:outline-none;
    span {
      @apply inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500/30 group-hover:bg-gray-500/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white sm:h-10 sm:w-10;
    }
    .btn-icon {
      @apply h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6;
    }
  }
}
</style>
